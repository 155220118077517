import styled from "styled-components";
import { color } from "../../../../data/store";

const WrapOverlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
`

const Container = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    .title {
        background-color: ${color.whiteFF};
        height: 18px;
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        text-align: center;
        padding-bottom: 10px;
        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: calc(100% - 3px);
            padding-bottom: 10px;
            cursor: pointer;
        }
    }
    .body {
        display: flex;
        flex: 1;
        overflow: hidden;
        padding: 0 20px;
        flex-direction: column;
    }
`

export {
    WrapOverlay,
    Container
}