import Axios from "axios"

// constant
import { host } from "../../../host"
import * as types from './types'

// helper
import catchErrorApi from "../../../helper/catchErrorApi"

// action
import { removeLoading, addLoading } from "../../commonAction"

export const apiGetListReports = `${host}admin/exportJSON/filterListReport`

const allReports = {
    CodeName: "ALL",
    ID: "ALL",
    Name: "Tất cả nhóm báo cáo",
    ParentCode: ""
}

export const getListReports = ({ ParentCode, CodeName = '', Searchtext = ''}) => async dispatch => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        ParentCode: ParentCode === allReports.CodeName ? null : ParentCode,
        CodeName,
        Searchtext
    })

    try {
        const res = await Axios.post(apiGetListReports, dataRequest)
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "",
        //         "ListReports": [
        //             {
        //                 "ID": "665944c586449b01932fd0f9",
        //                 "ParentCode": "CLDV",
        //                 "CodeName": "CLDV01",
        //                 "Name": "Báo cáo chất lượng dịch vụ theo CN"
        //             },
        //             {
        //                 "ID": "665959e286449b01932fd0fd",
        //                 "ParentCode": "CLDV",
        //                 "CodeName": "CLDV02",
        //                 "Name": "Báo cáo chất lượng dịch vụ theo CN và loại giao dịch"
        //             },
        //             {
        //                 "ID": "66597dec86449b01932fd0fe",
        //                 "ParentCode": "CLDV",
        //                 "CodeName": "CLDV04",
        //                 "Name": "Báo cáo chất lượng dịch vụ theo GDV và loại giao dịch"
        //             },
        //             {
        //                 "ID": "665d9246a81e22b4de7210e3",
        //                 "ParentCode": "HTKH",
        //                 "CodeName": "HTKH01",
        //                 "Name": "Báo cáo chi tiết hành trình KH"
        //             },
        //             {
        //                 "ID": "665d9287a81e22b4de7210e4",
        //                 "ParentCode": "HTKH",
        //                 "CodeName": "HTKH02",
        //                 "Name": "Báo cáo tổng hợp hành trình KH"
        //             },
        //             {
        //                 "ID": "6667f17f33794c47b745b017",
        //                 "ParentCode": "HTKH",
        //                 "CodeName": "HTKH03",
        //                 "Name": "Báo cáo tổng hợp hành trình KH chọn 1 loại giao dịch"
        //             },
        //             {
        //                 "ID": "66597e8786449b01932fd0ff",
        //                 "ParentCode": "ND",
        //                 "CodeName": "ND01",
        //                 "Name": "Báo cáo nhận diện sinh trắc học tại Kiosk"
        //             },
        //             {
        //                 "ID": "66597eeb86449b01932fd100",
        //                 "ParentCode": "SLGD",
        //                 "CodeName": "SLGD01",
        //                 "Name": "Báo cáo số lượng giao dịch KH tự phục vụ theo CN và loại giao dịch"
        //             },
        //             {
        //                 "ID": "66597f2486449b01932fd101",
        //                 "ParentCode": "SLGD",
        //                 "CodeName": "SLGD02",
        //                 "Name": "Báo cáo số lượng giao dịch KH tự thực hiện - xác thực vân tay không thành công"
        //             },
        //             {
        //                 "ID": "66597f4886449b01932fd102",
        //                 "ParentCode": "SLGD",
        //                 "CodeName": "SLGD03",
        //                 "Name": "Báo cáo chi tiết giao dịch KH tự thực hiện - xác thực vân tay không thành công"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36ca9",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH01",
        //                 "Name": "Báo cáo tổng hợp số lượng KH đăng ký đủ STH khuôn mặt và vân tay"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36caa",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH02",
        //                 "Name": "Báo cáo tổng hợp số lượng KH chỉ đăng ký STH khuôc mặt và KH không đăng ký STH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cab",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH03",
        //                 "Name": "Báo cáo tổng hợp số lượng KH thay đổi thông tin STH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cac",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH04",
        //                 "Name": "Báo cáo tổng hợp tình trạng xác thực vân tay tại quầy"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cad",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH05",
        //                 "Name": "Báo cáo chi tiết KH đăng ký đủ STH khuôn mặt và vân tay"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cae",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH06",
        //                 "Name": "Báo cáo chi tiết KH chỉ đăng ký STH khuôn mặt và KH không đăng ký STH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36caf",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH07",
        //                 "Name": "Báo cáo chi tiết KH thay đổi thông tin STH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb0",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH08",
        //                 "Name": "Báo cáo chi tiết KH xóa thông tin STH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb1",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH08_1",
        //                 "Name": "Báo cáo xóa STH của cán bộ hội sở"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb2",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH09",
        //                 "Name": "Báo cáo lịch sử Đăng ký / Thay đổi STH của khách hàng"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb3",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH10",
        //                 "Name": "Báo cáo chi tiết xác thực thông tin STH tại quầy"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb4",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH11",
        //                 "Name": "Báo cáo chi tiết KH không được phê duyệt Đăng ký / Thay đổi thông tin STH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb5",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH12",
        //                 "Name": "Báo cáo các trường hợp trùng STH vân tay"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb6",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH13",
        //                 "Name": "Báo cáo chi tiết các trường hợp thay đổi thông tin CIF của KH"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb7",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH14",
        //                 "Name": "Báo cáo các trường hợp trùng STH khuôn mặt"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cb8",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH15",
        //                 "Name": "Báo cáo chi tiết KH đã đăng ký khuôn mặt trên App đến quầy giao dịch lần đầu"
        //             },
        //             {
        //                 "ID": "668e37d75b8c584b7cc36cc6",
        //                 "ParentCode": "STH",
        //                 "CodeName": "STH16",
        //                 "Name": "Báo cáo chi tiết KH đã đăng ký khuôn mặt trên Kênh ngoài quầy đến quầy giao dịch lần đầu"
        //             },
        //             {
        //                 "ID": "665982dd86449b01932fd112",
        //                 "ParentCode": "TGPV",
        //                 "CodeName": "TGPV01",
        //                 "Name": "Báo cáo thời gian phục vụ tại CN theo vé"
        //             },
        //             {
        //                 "ID": "665982ff86449b01932fd113",
        //                 "ParentCode": "TGPV",
        //                 "CodeName": "TGPV02",
        //                 "Name": "Báo cáo thời gian phục vụ tại CN theo loại giao dịch"
        //             },
        //             {
        //                 "ID": "6659831d86449b01932fd114",
        //                 "ParentCode": "TGPV",
        //                 "CodeName": "TGPV05",
        //                 "Name": "Báo cáo thời gian phục vụ của GDV theo vé"
        //             },
        //             {
        //                 "ID": "6659833d86449b01932fd115",
        //                 "ParentCode": "TGPV",
        //                 "CodeName": "TGPV06",
        //                 "Name": "Báo cáo thời gian phục vụ của GDV theo loại giao dịch"
        //             }
        //         ]
        //     }
        // }
        const { data } = res;
        const list = data.ListReports || []
        const listToObj = list.reduce((obj, curr) => {
			//sth STH08_1 la bao dac biet chi hien thi cho TSC
			if(curr && curr.CodeName === "STH08_1"){
				curr.OnlyTSC = true
			}
			return({ ...obj, [curr.CodeName]: curr })
		}, {})
		console.log(listToObj)
        if (!ParentCode) {
            dispatch({
                type: types.GET_LIST_PARENT_REPORTS,
                payload: {
                    listParents: {
                        [allReports.CodeName]: { ...allReports },
                        ...listToObj
                    },
                    condFilterParents: {
                        ParentCode,
                        CodeName,
                        Searchtext
                    }
                }
            })
            dispatch(removeLoading())
            return;
        }
        dispatch({
            type: types.GET_LIST_REPORTS,
            payload: {
                list: listToObj,
                condFilter: {
                    ParentCode,
                    CodeName,
                    Searchtext
                }
            }
        })
        dispatch(removeLoading())
    }
    catch (err) {
        dispatch(removeLoading())

        catchErrorApi(
            err,
            dispatch,
            !ParentCode ? types.GET_LIST_PARENT_REPORTS_ERROR : types.GET_LIST_REPORTS_ERROR,
            () => false
        )
    }
}

export const setConditionFilter = ({ ParentCode = '', CodeName = '', Searchtext = '' }) => dispatch => {
    if (!ParentCode) {
        dispatch({
            type: types.SET_PARENT_CONDITION_FILTER,
            payload: {
                condFilterParents: {
                    ParentCode,
                    CodeName,
                    Searchtext
                }
            }
        })
        return
    }

    dispatch({
        type: types.SET_CONDITION_FILTER,
        payload: {
            condFilter: {
                ParentCode,
                CodeName,
                Searchtext
            }
        }
    })
}

export const clearListReport = () => ({
    type: types.CLEAR_LIST_REPORT
})