import React from 'react'

// import animation
import loadingAnimation from "../../../../helper/loadingAnimation.json";
import Lottie from 'react-lottie-player';

/**
 * 
 * @param {string="250px"} size size of icon loading(width === height === size)
 * @param {boolean=true} loadingPage true if loading fullscreen, else loading element
 */
export default function LoadingControl({size = 250, loadingPage = true}) {
  return (
    loadingPage?
      <div 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999999999,
          background:"rgba(0, 0, 0, 0.6)",
        }}
      >
        <div 
          style={{
            width: "100px", 
            height: "100px", 
            background:"rgba(124, 211, 247, 0.65)",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            textAlign: "center",
            fontFamily: "SVN-Gilroy",
            fontSize: "14px",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: 1.25
          }}
        >
          <Lottie
            loop
            animationData={loadingAnimation}
            play
            style={{ width: size, height: size, marginTop: 14, marginLeft: (100 - size)/2}}

          />
          <div style={{marginTop: "8px"}}>Đang tải...</div>
        </div>
      </div>
      :
      <Lottie
        loop
        animationData={loadingAnimation}
        play
        style={{ width: 25, height: 25}}
      />
  )
}
