import React, { useEffect, useState } from 'react'
import { Container } from './index.style';
import CheckboxControl from '../CheckBoxControl';

const DeviceConfigControl = ({
    isDisabled = false,
    isHorizontal = true,
    onChanged,
    deviceConfig = {
        isKioskChecked: false,
        isTabletChecked: false,
        isLCDChecked: false
    }
}) => {
    const [isKioskChecked, setIsKioskChecked] = useState(false);
    const [isTabletChecked, setIsTabletChecked] = useState(false);
    const [isLCDChecked, setIsLCDChecked] = useState(false);

    useEffect(() => {
        setIsKioskChecked(deviceConfig.isKioskChecked)
        setIsTabletChecked(deviceConfig.isTabletChecked)
        setIsLCDChecked(deviceConfig.isLCDChecked)
    }, [deviceConfig])    

    const onChangedOfKioskCheck = () => {
        console.log("isKioskChecked: ", !isKioskChecked, "isTabletChecked: ", isTabletChecked, "isLCDChecked: ", isLCDChecked);
        onChanged(!isKioskChecked, isTabletChecked, isLCDChecked)
        //---
        setIsKioskChecked(!isKioskChecked);
    };

    const onChangedOfTabletCheck = () => {
        console.log("isKioskChecked: ", isKioskChecked, "isTabletChecked: ", !isTabletChecked, "isLCDChecked: ", isLCDChecked);
        onChanged(isKioskChecked, !isTabletChecked, isLCDChecked)
        //---
        setIsTabletChecked(!isTabletChecked);
    };

    const onChangedOfLCDCheck = () => {
        console.log("isKioskChecked: ", isKioskChecked, "isTabletChecked: ", isTabletChecked, "isLCDChecked: ", !isLCDChecked);
        onChanged(isKioskChecked, isTabletChecked, !isLCDChecked)
        //---
        setIsLCDChecked(!isLCDChecked);
    };

    return (
        <Container isHorizontal={isHorizontal}>
            <p className='title-text'>Cấu hình thiết bị</p>
            <div className="content">
                <CheckboxControl
                    isDisabled={isDisabled}
                    size={isHorizontal ? 17 : 14}
                    label="Thiết bị Kiosk"
                    labelFontSize={14}
                    isChecked={isKioskChecked}
                    onChanged={onChangedOfKioskCheck} />

                <CheckboxControl
                    isDisabled={isDisabled}
                    size={isHorizontal ? 17 : 14}
                    marginLeft={isHorizontal ? 20 : 0}
                    marginTop={isHorizontal ? 0 : 10}
                    label="Thiết bị Tablet"
                    labelFontSize={14}
                    isChecked={isTabletChecked}
                    onChanged={onChangedOfTabletCheck} />
                <CheckboxControl
                    isDisabled={isDisabled}
                    size={isHorizontal ? 17 : 14}
                    marginLeft={isHorizontal ? 20 : 0}
                    marginTop={isHorizontal ? 0 : 10}
                    label="Thiết bị LCD"
                    labelFontSize={14}
                    isChecked={isLCDChecked}
                    onChanged={onChangedOfLCDCheck} />
            </div>
        </Container>
    );
}

export default DeviceConfigControl;
