/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectEndDate from '../../controls/Tools/SelectEndDate'

// style
import { extendStylesTable, extendStylesHeadStaff } from './index.styles'
import {
    WrapGridTools,
    GridColTools
} from '../../controls/Tools/index.styles'

// action
import {
    getAdminRemoveBioReport,
    exportAdminRemoveBioReport,
} from '../../../../../../../actions/reportsAction/detailsReport/biometrics/adminRemoveBioReport'

import { getListHeadStaff } from '../../../../../../../actions/commonAction'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'
import SelectHeadStaff from '../../controls/Tools/SelectHeadStaff'

const lstTitleCols = [
    'Mã CN',
    'Tên CN',
    'Số CIF',
    'Tên Khách hàng',
    'Loại Khách hàng ',
    'Ngày thực hiện',
    'THÔNG TIN BỊ XÓA',
    'LÝ DO XÓA/GHI CHÚ',
    'CÁN BỘ HỘI SỞ',
]

// const DEFAULT_FROM_DATE = moment().startOf('days')
// const DEFAULT_TO_DATE = moment().endOf('days')

const ReportDeleteSTHByHeadStaff = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
			headStaff: null
        }
    )

    const [dataTable, setDataTable] = useState([])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, headStaff } = stateFilter
        dispatch(
            getAdminRemoveBioReport({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
				AdminName: headStaff
            })
        )
    }, [stateFilter])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, headStaff } = stateFilter
        dispatch(
            exportAdminRemoveBioReport({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
				AdminName: headStaff
            })
        )
    }, [stateFilter])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
		//?
        if (Roles.includes(ADMIN_ROLE)) return true
		
        dispatch(
            getAdminRemoveBioReport({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
				AdminName: ""
            })
        )

        return true
    }, [userReducer.dataLogin])

	useEffect(() => {
        dispatch(getListHeadStaff())
    }, [])

    useEffect(() => {
        setDataTable(detailsReport.listData)
    }, [detailsReport.listData])


    return (
        <div>
            <Header title={title} />
            <WrapGridTools numOfCol={5}>
                <GridColTools templateRow="none">
                    <SelectHeadStaff
                        defaultValue={stateFilter.headStaff}
                        onChange={(headStaff) => dispatchFilter({ headStaff })}
                        extendStyles={extendStylesHeadStaff}
                        label="CBHSC"
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectStartDate
                        defaultValue={stateFilter.fromDate}
                        onChange={(fromDate) => dispatchFilter({ fromDate })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                    />
                </GridColTools>
            </WrapGridTools>
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
                numOfRow={9}
            />
        </div>
    )
}

export default ReportDeleteSTHByHeadStaff
