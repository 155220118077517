/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//css
import { Bound } from './index.styles'
//component
import SingleChoiseDropDownControl from '../../MainScreen/Control/SingleChoiceDropDownControl'
import InputControl from '../../MainScreen/Control/InputControl'
import DeviceConfigControl from '../../MainScreen/Control/DeviceConfigControl'
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup'
// data
import { color } from '../../../../data/store'
import { location } from '../../../../data/singleChoiseData';
import { types } from '../../../../data/dataBranch';
import {
    getArrayCity,
    getDistrictArrayByCityCode,
    getWardArrayByDistrictCode,
    getNameCity,
    getNameWard,
    getNameDistrict
} from '../../../../tools'
/* import action */
import { getAllBranch, getAllOfficeV2 } from '../../../../actions/officeAction'
import DropDownSearchObjV2 from '../../MainScreen/Control/DropDownSearchObjV2'
import { isArray, isNumber, isObject } from '../../../../helper/checkValidity'
import strToStrNoAccent from '../../../../helper/strToStrNoAccent'

const MAX_CHARACTERS = 50;
const TransType = {
    HEAD: "Trụ sở chính",
    TRADING: "Phòng giao dịch",
    BRANCH: "Chi nhánh"
}

const PopupTransactionV2 = ({ alertPopup, onShowErrPopup, titleHeader, dataNeedModify, outPopup, handleOnClick, showPopupInBiometric }) => {
    const dispatch = useDispatch()
    //---
    const { dataLogin, allOfficeList, allBranchList } = useSelector(state => ({
        dataLogin: state.userReducer.dataLogin,
        allOfficeList: state.filterOfficeReducer.allOfficeList,
        allBranchList: state.filterOfficeReducer.allBranchList,
    }))
    //---
    const [listBranch, setListBranch] = useState([])
    //---
    useEffect(() => {
        dispatch(getAllOfficeV2())
    }, [])

    useEffect(() => {
        if (!dataLogin?.UID) return
        dispatch(getAllBranch(dataLogin?.UID))
    }, [dataLogin?.UID])

    useEffect(() => {
        setListBranch(allBranchList.map(({ ID, Name, BranchCode }) => ({
                key: ID,
                value: `${BranchCode} - ${Name}`,
                BranchCode
            })).sort((a, b) =>
                Number(a.BranchCode) - Number(b.BranchCode)
            )
        )
    }, [allBranchList])

    useEffect(() => {
        const newState = getState(dataNeedModify)
        setState(newState)
    }, [allBranchList, allOfficeList, dataNeedModify])
    //---
    const getCurrentBranch = (parentID, branchCode, officeType) => {
        let branch = allBranchList.find(item => item.ID === parentID)
        if(!isObject(branch)){
            branch = allOfficeList.find(item => item.ID === parentID)
        }
        console.log(`getCurrentBranch -> isObject: ${!isObject(branch)}`);
        
        return branch??{}
    }

    const getTransType = (officeType) => {
        const type = types.find(item => item.type === officeType)
        return type?type.name:''
    }

    const getSuffix = (branchCode, officeType) => {
        const transType = getTransType(officeType)
        if(officeType !== dataNeedModify.OfficeType){
            if(transType === TransType.BRANCH){
                return '98'
            }
            return ''
        }
        //----
        if(transType === TransType.BRANCH){
            return branchCode?branchCode.slice(branchCode.length - 2):'98'
        } else if(transType === TransType.TRADING){
            return branchCode?branchCode.slice(branchCode.length - 2):''
        } else {
            return ''
        }   
    }
    const getPrefix = (branchCode, officeType) => {
        const transType = getTransType(officeType)
        if(officeType !== dataNeedModify.OfficeType){
            if(transType === TransType.TRADING){
                return 'xxx'
            } 
            return ''
        }
        //---
        if(transType === TransType.BRANCH){
            return branchCode?branchCode.slice(0, -2):''
        } else if(transType === TransType.TRADING){
            return branchCode?branchCode.slice(0, -2):'xxx'
        } else {
            return ''
        } 
    }
    const getTypeBranchCode = (branchCode, officeType) => {
        const transType = getTransType(officeType)
        if(officeType === TransType.HEAD){
            return '99998'
        }
        //---
        if(officeType !== dataNeedModify.OfficeType){
            return ''
        }
        //---
        if(transType === TransType.BRANCH){
            return branchCode?branchCode.slice(0, -2):''
        } else {
            return branchCode?branchCode.slice(branchCode.length - 2):''
        }
    }
  
    
    const getState = (transInfo)=>{
        return {
            data: location,
            dataCity: getArrayCity(),
            dataDistrict: transInfo ? getDistrictArrayByCityCode(transInfo.Province) : [],
            dataWard: transInfo ? getWardArrayByDistrictCode(transInfo.District) : [],
            branch: transInfo ? getCurrentBranch(transInfo.ParentID) : {},
            suffix: transInfo ? getSuffix(transInfo.BranchCode, transInfo.OfficeType) : "98", //Branch
            prefix: transInfo ? getPrefix(transInfo.BranchCode, transInfo.OfficeType) : "xxx", //Trading
            parentID: transInfo ? transInfo.ParentID : "",
            nameTransactionRoom: transInfo ? transInfo.Name : "",
            typeBranchCode: transInfo ? getTypeBranchCode(transInfo.BranchCode, transInfo.OfficeType) : "",
            addressTransactionRoom: transInfo ? transInfo.Address : "",
            typeTrans: transInfo ? getTransType(transInfo.OfficeType) : types[0].name,
            city: transInfo ? getNameCity(transInfo.Province)[0] : "",
            district: transInfo ? getNameDistrict(transInfo.District)[0] : "",
            ward: transInfo ? getNameWard(transInfo.Ward)[0] : "",
            idShow: "",
            resetDistWard: false,
            resetWard: false,
            isCompleteShowErr: false,
            deviceConfig: {
                hasKioskDeviceConfig: transInfo
                    ? transInfo.HasKioskDeviceConfig
                    : false,
                hasTabletDeviceConfig: transInfo
                    ? transInfo.HasTabletDeviceConfig
                    : false,
                hasLcdDeviceConfig: transInfo
                    ? transInfo.HasLcdDeviceConfig
                    : false,
            }
        }
    }

    const [state, setState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        getState(dataNeedModify)
    );
    //---
    const handleSelectTrans = (item) => {
        if (item === state.typeTrans) return ``
        const officeType = types.find(e => e.name === item).type
        setState({ 
            typeTrans: item,
            suffix: dataNeedModify ? getSuffix(dataNeedModify.BranchCode, officeType) : "98", //Branch
            prefix: dataNeedModify ? getPrefix(dataNeedModify.BranchCode, officeType) : "xxx", //Trading
            typeBranchCode: dataNeedModify ? getTypeBranchCode(dataNeedModify.BranchCode, officeType) : "",
            deviceConfig: {
                hasKioskDeviceConfig: dataNeedModify
                    ? dataNeedModify.HasKioskDeviceConfig
                    : false,
                hasTabletDeviceConfig: dataNeedModify
                    ? dataNeedModify.HasTabletDeviceConfig
                    : false,
                hasLcdDeviceConfig: dataNeedModify
                    ? dataNeedModify.HasLcdDeviceConfig
                    : false,
            },
            branch: dataNeedModify ? getCurrentBranch(dataNeedModify.ParentID) : {},
        })
    }

    const handleSelectBranch = (value) => {
        let data = value
        if(isArray(value)){
            if(value.length === 0){
                return
            }
            data = data[0].BranchCode
        }
        const branchCode = data.split("-")[0]
        if(branchCode === state.branch?.BranchCode){
            return
        }
        const branchObj = allBranchList.find(item => item.BranchCode === branchCode)
        const prefix = branchCode.slice(0, -2)
        setState({
            typeBranchCode: "",
            branch: branchObj,
            parentID: branchObj?.ID,
            prefix: prefix
        })
        //---
        setListBranch(
            allBranchList.map(({ ID, Name, BranchCode }) => ({
                key: ID,
                value: `${BranchCode} - ${Name}`,
                BranchCode
            })).sort((a, b) =>
                Number(a.BranchCode) - Number(b.BranchCode)
            )
        )
    }

    const onBranchCodeChange = (e) => {
        const values = e.target.value
        setState({
            typeBranchCode: values.trim()
        })
    }
    const onBranchNameChange = (e) => {
        const values = e.target.value
        setState({
            nameTransactionRoom: values
        })
    }
    const onAddressChange = (e) => {
        const values = e.target.value
        setState({
            addressTransactionRoom: values
        })
    }

    const getText = (type) => async (text) => {
        if (type === 'city')
            setState({
                dataDistrict: getDistrictArrayByCityCode(text.code),
            })
        else if (type === 'district')
            setState({
                dataWard: getWardArrayByDistrictCode(text.code),
            })
        setState({ [type]: text })
    }

    const cityTrans = getText("city");
    const districtTrans = getText("district");
    const wardTrans = getText("ward");

    const setIdShow = (id) => {
        setState({ idShow: id })
    }

    const onDeviceConfigChanged = (isKioskChecked, isTabletChecked, isLCDChecked) => {
        setState({
            deviceConfig: {
                hasKioskDeviceConfig: isKioskChecked,
                hasTabletDeviceConfig: isTabletChecked,
                hasLcdDeviceConfig: isLCDChecked
            }
        })
    }
    const onSubmit = () => {
        let typeBranchCodeItem = ""
        if (state.typeTrans === "Chi nhánh") {
            if(!isNumber(state.typeBranchCode) || state.typeBranchCode.length<1){
                onShowErrPopup({
                    content: 'Mã chi nhánh/PGD không đúng định dạng.',
                    note: '(*) Vui lòng nhập ít nhất 1 số'
                })
                return
            }
            typeBranchCodeItem = `${state.typeBranchCode}${state.suffix}`
        } else if (state.typeTrans === "Phòng giao dịch") {
            if(!isNumber(state.typeBranchCode) || state.typeBranchCode.length !== 2){
                onShowErrPopup({
                    content: 'Mã chi nhánh/PGD không đúng định dạng.',
                    note: '(*) Vui lòng nhập 2 số cuối của PGD'
                })
                return
            }
            typeBranchCodeItem = `${state.prefix}${state.typeBranchCode}`
        } else {
            typeBranchCodeItem = "99998"
        }
        //--- Check match BranchCode(BranchCode Chi nhánh)
        if (dataNeedModify) {
            const { ID } = dataNeedModify;
            if ((typeBranchCodeItem) && allOfficeList.find(item =>
                item.ID !== ID && item.BranchCode.trim().replace(/\s+/g, " ") === typeBranchCodeItem.trim().replace(/\s+/g, " ")
            )) {
                if (!state.isCompleteShowErr) {
                    onShowErrPopup({
                        content: 'Mã chi nhánh/PGD đã tồn tại.',
                        note: '(*) Vui lòng kiểm tra lại'
                    })
                    return
                }
            }
        } else {
            if ((typeBranchCodeItem) && allOfficeList.find(item =>
                item.BranchCode.trim().replace(/\s+/g, " ") === typeBranchCodeItem.trim().replace(/\s+/g, " ")
            )) {
                if (!state.isCompleteShowErr) {
                    onShowErrPopup({
                        content: 'Mã chi nhánh/PGD đã tồn tại.',
                        note: '(*) Vui lòng kiểm tra lại'
                    })
                    return;
                }
            }
        }
        //--- Check match nameTransactionRoom(Name PGD)
        if (dataNeedModify) {
            const { ID } = dataNeedModify;
            if ((state.nameTransactionRoom) && allOfficeList.find(item =>
                item.ID !== ID && item.Name.trim().replace(/\s+/g, " ") === state.nameTransactionRoom.trim().replace(/\s+/g, " ")
            )
            ) {
                if (!state.isCompleteShowErr) {
                    onShowErrPopup({
                        content: 'Tên chi nhánh/PGD đã tồn tại.',
                        note: '(*) Vui lòng kiểm tra lại'
                    })
                    return
                }
            }
        } else {
            if ((state.nameTransactionRoom) && allOfficeList.find(item =>
                item.Name.trim().replace(/\s+/g, " ") === state.nameTransactionRoom.trim().replace(/\s+/g, " ")
            )) {
                if (!state.isCompleteShowErr) {
                    onShowErrPopup({
                        content: 'Tên chi nhánh/PGD đã tồn tại.',
                        note: '(*) Vui lòng kiểm tra lại'
                    })
                    return
                }
            }
        }
        //--- Check parentID === ID
        if (dataNeedModify) {
            if (dataNeedModify.ID === state.parentID) {
                onShowErrPopup({
                    content: 'Chi nhánh/PGD đang chọn trùng với chi nhánh cần thay đổi',
                    note: '(*) Vui lòng kiểm tra lại'
                })
                return;
            }
        }
        //--- Update
        if (dataNeedModify) {
            const { ID, currentPage } = dataNeedModify;
            let { parentID, nameTransactionRoom, addressTransactionRoom, city, district, ward, typeTrans, deviceConfig } = state;
            if (nameTransactionRoom.length > MAX_CHARACTERS) {
                onShowErrPopup({
                    content: 'Tên chi nhánh/PGD không đúng định dạng',
                    note: `(*) Vui lòng nhập dưới ${MAX_CHARACTERS} kí tự`
                })
                return;
            }
            if (addressTransactionRoom.length > MAX_CHARACTERS) {
                onShowErrPopup({
                    content: 'Địa chỉ không đúng định dạng',
                    note: `(*) Vui lòng nhập dưới ${MAX_CHARACTERS} kí tự`
                })
                return;
            }
            if (nameTransactionRoom.trim() && typeBranchCodeItem.trim() && addressTransactionRoom.trim() && city && district && ward && typeTrans) {
                let typeTransItem = ""
                types.forEach(item => {
                    if (item.name === typeTrans) {
                        typeTransItem = item.type;
                    }
                })
                const dataUpdate = {
                    ID,
                    parentID,
                    nameTransactionRoom,
                    typeBranchCodeItem: typeBranchCodeItem,
                    addressTransactionRoom,
                    city: city.code,
                    district: district.code,
                    ward: ward.code,
                    typeTransItem,
                    deviceConfig
                }
                //---
                //console.log("updateOffice", dataUpdate);
                handleOnClick(dataUpdate, currentPage);
                outPopup();
            } else {
                onShowErrPopup({
                    content: 'Vui lòng điền đầy đủ thông tin',
                })
            }
        } else {
            //--- Add new
            let { parentID, nameTransactionRoom, addressTransactionRoom, city, district, ward, typeTrans, deviceConfig } = state;
            if (nameTransactionRoom.length > MAX_CHARACTERS) {
                onShowErrPopup({
                    content: 'Tên chi nhánh/PGD không đúng định dạng',
                    note: `(*) Vui lòng nhập dưới ${MAX_CHARACTERS} kí tự`
                })
                return;
            }
            if (addressTransactionRoom.length > MAX_CHARACTERS) {
                onShowErrPopup({
                    content: 'Địa chỉ không đúng định dạng',
                    note: `(*) Vui lòng nhập dưới ${MAX_CHARACTERS} kí tự`
                })
                return;
            }
            if (nameTransactionRoom.trim() && typeBranchCodeItem.trim() && addressTransactionRoom.trim() && city && district && ward && typeTrans) {
                let typeTransItem = ""
                types.forEach(item => {
                    if (item.name === typeTrans) {
                        typeTransItem = item.type;
                    }
                })
                const dataCreate = {
                    parentID,
                    nameTransactionRoom,
                    typeBranchCodeItem: typeBranchCodeItem,
                    addressTransactionRoom,
                    city: city.code,
                    district: district.code,
                    ward: ward.code,
                    typeTransItem,
                    deviceConfig
                }
                //---    
                //console.log("createOffice:", dataCreate);
                handleOnClick(dataCreate)
                outPopup();
            } else {
                onShowErrPopup({
                    content: 'Vui lòng điền đầy đủ thông tin',
                })
            }
        }
    }

    const handleKeyPress = (event) => {
        const valueText = event.target.value
        if (valueText.length >= event.target.maxLength) {
            event.preventDefault()
        }
        if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-" || event.key === ".") {
            event.preventDefault()
        }
    }

    const handleBranchSearch = (keySearch) => {
        var value = strToStrNoAccent(keySearch).toLowerCase()
        if(value.length===0){
            setListBranch(allBranchList.map(({ ID, Name, BranchCode }) => ({
                key: ID,
                value: `${BranchCode} - ${Name}`,
                BranchCode
            })).sort((a, b) =>
                Number(a.BranchCode) - Number(b.BranchCode)
            ))
        } else{
            const newListBranch = allBranchList.filter(
                item=>(item.BranchCode.toLowerCase().indexOf(value) >= 0 || 
                strToStrNoAccent(item.Name).toLowerCase().indexOf(value) >= 0)
            )
            setListBranch(newListBranch.map(({ ID, Name, BranchCode }) => ({
                    key: ID,
                    value: `${BranchCode} - ${Name}`,
                    BranchCode
                })).sort((a, b) =>
                    Number(a.BranchCode) - Number(b.BranchCode)
                )
            )
        }
    }

    return (
        <Bound>
            <div className="popupHeader">
                <h4 className='title'>{titleHeader}</h4>
                <span onClick={() => outPopup()} className='ic_close'></span>
            </div>
            <div className="popupMainBody">
                <div className='type-transaction'>
                    <div className='type-officeName'>
                        <SingleChoiseDropDownControl
                            isRequired={true}
                            title="loại VP giao dịch"
                            id="selectTypeTrans"
                            idShow={state.idShow}
                            setIdShow={setIdShow}
                            titleChoise='Chọn loại VP'
                            getText={handleSelectTrans}
                            data={state.data.office}
                            defaultValue={state.typeTrans}
                        />
                    </div>
                    <div className="code-officeName">
                        <InputControl
                            type='number'
                            onKeyPress={handleKeyPress}
                            className={
                                (state.typeTrans === "Trụ sở chính" ? "" : ((state.prefix === "xxx" || Object.values(state.branch).length !== 0) && state.typeTrans === "Phòng giao dịch")) ? "input-prefix"
                                    : (state.suffix === "98" && state.typeTrans === "Chi nhánh") ? "input-suffix"
                                        : ""
                            }
                            isRequired={true}
                            title='MÃ CN/PGD'
                            onChange={onBranchCodeChange}
                            placeHolder={state.typeTrans === "Chi nhánh" ? 'Mã CN' : state.typeTrans === "Phòng giao dịch" ? "Mã PGD" : ""}
                            value={state.typeTrans === "Trụ sở chính" ? "99998" : state.typeBranchCode}
                            maxLength={state.typeTrans === "Phòng giao dịch" && (state.prefix === "xxx" || Object.values(state.branch).length !== 0) ? 2 : 50}
                            prefix={
                                state.typeTrans === "Trụ sở chính" ? null
                                    : (state.typeTrans === "Phòng giao dịch") 
                                        ? state.prefix
                                        : null
                            }
                            suffix={
                                state.typeTrans === "Trụ sở chính" ? null
                                    : (state.typeTrans === "Chi nhánh") ? state.suffix
                                        : null
                            }
                            isDisabled={state.typeTrans === "Trụ sở chính" ? true : false}
                        />
                    </div>
                </div>
                {
                    state.typeTrans === "Phòng giao dịch" &&
                    <div className='type-branch'>
                        <DropDownSearchObjV2
                                title='Chi nhánh'
                                isRequired={true}
                                lstItemFixed={[]}
                                lstItems={listBranch}
                                defaultValue={
                                    isObject(state.branch)
                                    ?   [{
                                            key: state.branch.ID,
                                            value: `${state.branch.BranchCode} - ${state.branch.Name}`,
                                            BranchCode: state.branch.BranchCode
                                        }]
                                    : undefined
                                }
                                onChange={handleSelectBranch}
                                onSearch={handleBranchSearch}
                                haveSearch={true}
                                placeHolder="Nhập mã, tên chi nhánh"
                                placeHolderSelect='Chọn chi nhánh'
                            />
                    </div>
                }
                <InputControl
                    isRequired={true}
                    title='TÊN CN/PGD'
                    onChange={onBranchNameChange}
                    placeHolder='Nhập tên phòng giao dịch'
                    value={state.nameTransactionRoom}
                    maxLength={50}
                />
                <InputControl
                    isRequired={true}
                    title='ĐỊA CHỈ'
                    onChange={onAddressChange}
                    placeHolder='Nhập địa chỉ'
                    value={state.addressTransactionRoom}
                    maxLength={50}
                />
                <div className="info_Address">
                    <div className="city">
                        <SingleChoiseDropDownControl
                            isRequired={true}
                            id="selectCity"
                            idShow={state.idShow}
                            setIdShow={setIdShow}
                            getText={cityTrans}
                            titleChoise='Chọn thành phố'
                            data={state.dataCity}
                            title='TỈNH/TP'
                            defaultValue={state.city ? state.city.name : ""}
                        />
                    </div>
                    <div className="district">
                        <SingleChoiseDropDownControl
                            isRequired={true}
                            id="selectDistrict"
                            idShow={state.idShow}
                            setIdShow={setIdShow}
                            getText={districtTrans}
                            titleChoise='Chọn quận/ huyện'
                            data={state.city ? state.dataDistrict : null}
                            title='QUẬN/ HUYỆN'
                            defaultValue={state.district ? state.district.name : ""}
                        />
                    </div>
                    <div className="ward">
                        <SingleChoiseDropDownControl
                            isRequired={true}
                            id="selectWard"
                            idShow={state.idShow}
                            setIdShow={setIdShow}
                            getText={wardTrans}
                            titleChoise='Chọn phường/xã'
                            data={state.district ? state.dataWard : null}
                            title='PHƯỜNG/ XÃ'
                            defaultValue={state.ward ? state.ward.name : ''}
                        />
                    </div>
                </div>
                <DeviceConfigControl
                    isDisabled={state.typeTrans === "Trụ sở chính" ? true : false}
                    onChanged={onDeviceConfigChanged}
                    deviceConfig={{
                        isKioskChecked: state.deviceConfig.hasKioskDeviceConfig,
                        isTabletChecked: state.deviceConfig.hasTabletDeviceConfig,
                        isLCDChecked: state.deviceConfig.hasLcdDeviceConfig
                    }}
                />
            </div>
            <div className="popupFooter">
                <ButtonConfirmPopup
                    //isDisabled={true}
                    bg_color={color.blue_005993}
                    onClick={onSubmit}
                    titleConfirm='HOÀN TẤT' />
            </div>
        </Bound>
    )
}

export default PopupTransactionV2