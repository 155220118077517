import styled, { css } from "styled-components";
import { color } from "../../../../../../../data/store";

const RowContainer = styled.div`
    display: grid;
    ${prop => prop.isUploadFilePopup
        ? css`grid-template-columns: minmax(50px, 50px) minmax(80px, 1fr)  75px 75px 75px 75px 75px 75px 75px 75px 75px 75px 75px 50px;`
        : css`grid-template-columns: minmax(50px, 50px) minmax(80px, 0.3fr) minmax(80px, 1fr) 75px 75px 75px 75px 75px 75px 75px 75px 75px 75px 75px;`
    }
    grid-gap: 8px; 
    ${prop => prop.isUploadFilePopup
        ? css` width: calc(100% - 10px);`
        : css` width: 100%;`
    }
   
    height: ${props => props.isHeader ? 'auto' : '38px'};
    padding: ${prop => prop.isHeader ? '0px 0px 5px 0px' : '8px 0px;'};

    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    margin-top: ${props => props.isHeader ? 0 : '5px'};
    box-shadow: ${props => props.isHeader ? 'none' : '0px 2px 13px rgba(0, 0, 0, 0.08)'};
    background-color: ${props => props.isHeader
        ? ''
        : props.selectedStatus
            ? props.selectedBgColor ?? 'white'
            : 'white'
    };
    border-radius: 4px;

    :hover{
        background-color: ${props => props.isHeader ? '' : '#DEF5FF'};
    }

    .column {
        font-size: ${props => props.isHeader ? '11px' : '14px'};
        font-weight: ${props => props.isHeader ? 600 : 500};
        color: ${props => props.isHeader ? color.blue_005993 : color.black19};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        &.time{
            text-align: center;
        }
        &.lastIndex{
            padding-left: 5px;
            padding-top: 3px;
            .icon{
                width:16px;
                height:16px;
            }
        }
    }
`

export { RowContainer }