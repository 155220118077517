
import styled from 'styled-components';
import { color } from '../../../../data/store';

const StyleBiometric = styled.div`
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 14px 0 0 0;
  .title {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.26;
    color: ${color.blue_005993};
    text-transform:uppercase;
    margin: 26px 0 14px 0;
  }
  .content {
    font-size: 14px;
    font-weight: 500;
    color: #979797;
    span, .text-gdv {
      color:#222222;
    }
    .type_reason{
      word-break: break-all;
      color: rgb(215,18,73) !important;
    }
    ~.dataNeed {
      margin: 14px 0 0 0;
      span {
        color:${color.blue_005993};
      }
    }
  }
  .dataType{
      span{
        color: #0fb11f;
      }
    }
    .last {
      margin: 14px 0 0 0;
    }
    .text-ellipst {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${'' /* position: relative; */}
        pointer-events:none;
    }
    .text-ellipst:after {
        content: "";
        display: block;
        position: absolute;
        top: -15px;
        right: 0;
        width: 100%;
        height: 48px;
        z-index: 1;
        pointer-events: initial;
    }

    .text-ellipst:hover:after{
      cursor: pointer;
    }

    .cover{
      position: relative;
      width: 100%;
      display: flex;
    }
    #tooltip_ {
      position: absolute;
      top: -66%;
      left: 50%;
      z-index: 999;
      width: fit-content;
      visibility: hidden;
      background-color: #fff;
      padding: 11px;
      -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    .text-ellipst:hover + #tooltip_{
      visibility: visible;
      transition: opacity 0.5s ease;
      opacity: 1;
    }
    .mt-14{
        margin-top: 14px;
    }

    .c-text{
      margin-top: 9px;
      font-family: SVN-Gilroy;
      font-size: 14px;
      line-height: 128%;
      /*color: #F31919;*/
      .type_reason{
        word-break: break-word;
      }
    }
`

export {
    StyleBiometric
}