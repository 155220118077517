import * as types from "../../actions/types";

const initialState = {
    LCDInfo: {},//data reponse when create LCD
    dataUpdate: {},//data reponse when update LCD
    listLCD: [], //data reponse when get all LCD
    total: 0, //data reponse when get all LCD
    resDel: {},//data reponse when delete LCD
    dataGenKey: {},//data reponse when generate key LCD
    currentPage: 1,
    err: ''
}

export default function LCDReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLEAR_KEY_GEN_LCD: {
            const { lcdId, lcdName, key } = action.payload
            return {
                ...state,
                listLCD: state.listLCD.map((item) =>
                    item.ID === lcdId ? { ...item, [key]: undefined } : item
                ),
                [key]:
                    state[key].ConnectID === `LCD${lcdName}`
                        ? {}
                        : state[key],
            }
        }

        case types.DEVICE_CONNECTED: {
            const { deviceType, parentId } = action.payload;
            if (deviceType !== 'LCD') return state;
            return {
                ...state,
                listLCD: state.listLCD.map(item => item.ID === parentId ? { ...item, IsActive: true } : item)
            }
        }

        case types.CREATE_LCD:
            return {
                ...state,
                LCDInfo: action.data,
                err: ''
            }
        case types.CREATE_LCD_ERR:
            return {
                ...state,
                err: action.err
            }

        case types.UPDATE_LCD:
            return {
                ...state,
                dataUpdate: action.data,
                err: ''
            }
        case types.UPDATE_LCD_ERR:
            return {
                ...state,
                err: action.err
            }

        case types.GET_ALL_LCD:
            return {
                ...state,
                listLCD: action.listLCD,
                total: action.total,
                currentPage: action.pageNumber,
                err: ''
            }
        case types.GET_ALL_LCD_ERR:
            return {
                ...state,
                err: action.err
            }

        case types.DELETE_LCD:
            return {
                ...state,
                resDel: action.data,
                err: ''
            }

        case types.DELETE_LCD_ERR:
            return {
                ...state,
                err: action.err
            }

        case types.GENERATE_KEY_LCD:
            const newData = state.listLCD.map((item) => {
                return {
                    ...item,
                    dataGenKey:
                        `LCD${item.Name}` === action.data.ConnectID
                            ? action.data
                            : item.dataGenKey,
                }
            })
            return {
                ...state,
                dataGenKey: action.data,
                listLCD: newData,
                err: '',
                type: action.type,
            }
        case types.GENERATE_KEY_LCD_ERR:
            return {
                ...state,
                err: action.err
            }       
        default:
            return state
    }
}