import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ic_more_gray from "../../../../images/ic_more_gray.svg";

//Control
import PagingControl from "./../Control/PagningControl";
import EmptyControl from "../Control/EmptyControl";
import SubHeaderControl from "../Control/SubHeaderControl";

//Data
import { emptyData, subHeaderData } from "../../../../data/data";
import { dataCity } from "../../../../data/VietNamData/DataCity"
import { dataDistrict } from "../../../../data/VietNamData/DataDistrict"
import { dataWard } from "../../../../data/VietNamData/DataWard"
// import action
import {
  filterOffice,
  resetStateOffice,
  alertFillterOffice,
  saveTextSearchOffice,
  clearTextSearchOffice
} from "../../../../actions/officeAction";

// import funtion
import { formatNumber } from "../../../../tools";
import { types } from "../../../../data/dataBranch";

//images
import ic_tick_circle from "../../../../images/tick-circle.svg";
import ic_tick_circle_blue from "../../../../images/tick-circle-blue.svg";
import { color } from "../../../../data/store";

const Bound = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 0 40px;
  .body-branch {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 4px;
      table-layout: fixed;
      padding-bottom: 40px;
      min-width: 1040px;
      tr {
        width: 100%;
      }
      tr.item:hover {
        cursor: pointer;
      }
      thead tr th {
        font-family: "SVN-Gilroy";
        font-size: 11px;
        font-weight: bold;
        color: ${color.blue_005993};
        border-collapse: collapse;
        text-transform: uppercase;
        text-align: left;
        height: 30px;
        padding-bottom: 10px;
      }
      td {
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.68;
        color: #191919;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        div {
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          color: #c8c8c8;
        }
      }
      .number-format {
        padding-left: 10px;
        width: 80px;
      }
      .point-transaction {
        padding-right: 12px;
        width: 150px;
      }
      .address {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        padding-right: 20px;
      }
      .type-transaction {
        width: 150px;
      }
      .counter-number {
        width: 100px;
      }
      .device-kiosk {
        width: 100px;
      }
      .device-config{
        width: 200px;
        display:table-cell;
        vertical-align: middle;  
        .content{
          display: flex;
          width: auto;
        }
        .icon {
          width: 16px;
          height: 16px;
        }
        .text {
          color: var(--Black, #191919);
          font-family: SVN-Gilroy;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 128%;
          margin-left: 2px;
          width: auto;
        }
      }
      .button-control {
        width: 80px;
        text-align: right;
        .more-button {
          cursor: pointer;
          margin-top: 10px;
        }
      }
      .tab {
        padding-right: 5px;
        text-align: right;
      }
      tbody .item {
        height: 48px;
        transition: all 0.2s;
        background-color: #ffffff;
        td:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        td:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .paging-content{
      display: flex;
      width: calc(100% - 10px);
      justify-content: end;
      .more-button {
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
`;

const limitPerPage = 10;
class BranchContainer extends Component {
  state = {
    currentPage: 1,
    // startIndex: 0,
    // endIndex: 10,
    data: [],
    isLoading: false,
  };
  componentDidMount() {
    this.props.filterOffice({}, 10, 1);
  }
  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.filterOfficeReducer.dataFilter) !== JSON.stringify(this.props.filterOfficeReducer.dataFilter)) {
      this.setState({ currentPage: 1 })
    }
  }
  onPageChange = page => {
    let currentPage = page;
    this.setState({ currentPage });

    this.props.filterOffice(
      this.props.filterOfficeReducer.dataFilter,
      10,
      page
    );
  };
  onShowControl(index, e) {
    let moreBtn = document.getElementById("more_button_id_" + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect();
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound = bound.top + scrollTop;
      let leftBound = bound.left + scrollLeft;
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      };
      let data = { ...e, currentPage: this.state.currentPage };
      this.props.onShowControl(offsetPopup, "Branch", data);
    }
  }

  searchFilter = offficeName => {
    const dataFilter = {
      name: offficeName,
    };

    this.props.alertContent('')
    this.props.filterOffice(dataFilter, 10, 1)
    this.props.saveTextSearchOffice(offficeName)
  }

  onReset() {
    this.props.filterOffice({}, 10, 1)
  }

  componentWillUnmount() {
    this.props.clearTextSearchOffice();
  }

  number = n => {
    return n > 9 ? n : "0" + n;
  };

  onAddMoreSubmit = () => {
    this.props.showAddBranchPopup();
    this.props.clearTextSearchOffice();
  }

  getOfficeList = () => {
    let { listOffice, allOfficeList} = this.props.filterOfficeReducer;
    if(listOffice === undefined || listOffice === null || listOffice.length === 0){
      listOffice = allOfficeList
    }
    //---
    if(listOffice.length > limitPerPage){
      var start = (this.state.currentPage - 1) * limitPerPage
      var end = start + limitPerPage;
      if(end > listOffice.length){
        end = listOffice.length  - 1;
      }
      listOffice = listOffice.slice(start, end)
    }
    return listOffice
  }

  renderDeviceConfigItem(value, hasDevice, marginLeft) {
    return (
      <div className="content" style={{marginLeft: `${marginLeft}px`}}>
        <img
            className="icon"
            src={hasDevice?ic_tick_circle_blue:ic_tick_circle}
            alt={hasDevice?"ic_tick_circle_blue":"ic_tick_circle"}
        />
        <div className="text">{value}</div>
      </div>
    )
  }

  render() {
    let {total, alert} = this.props.filterOfficeReducer;
    let listOffice = this.getOfficeList(); 
    let elmBranch;
    if (listOffice) {
      elmBranch = listOffice.map((e, index) => {
        let checkType = types.find(item => item.type === e.OfficeType);
        return (
          <tr key={index} className="item" onDoubleClick={() => this.props.onShowPopupEdit({ ...e, currentPage: 1 })}>
            <td className="number-format">
              {formatNumber(index + 1 + 10 * (this.state.currentPage - 1), 5)}
            </td>
            <td className="point-transaction">{e.BranchCode}</td>
            <td className="point-transaction">{e.Name}</td>
            <td className="address">{`${e.Address}, ${(dataWard[e.Ward] && dataWard[e.Ward].name) || ""}, ${(dataDistrict[e.District] && dataDistrict[e.District].name) || ""}, ${(dataCity[e.Province] && dataCity[e.Province].name) || ""}`}</td>
            <td className="type-transaction">{checkType.name}</td>
            <td className="counter-number">
              {e.NumOfCounter <= 0 ? (
                <div>Chưa kết nối...</div>
              ) : (
                this.number(e.NumOfCounter)
              )}{" "}
            </td>
            <td className="device-kiosk">
              {e.NumOfKiosk <= 0 ? (
                <div>Chưa kết nối...</div>
              ) : (
                this.number(e.NumOfKiosk)
              )}
            </td>
            <td className="device-config">
              <div className="content">
                {this.renderDeviceConfigItem("Kiosk", e.HasKioskDeviceConfig??false, 0)}
                {this.renderDeviceConfigItem("Tablet", e.HasTabletDeviceConfig??false, 20)}
                {this.renderDeviceConfigItem("LCD", e.HasLcdDeviceConfig??false, 20)}
              </div>
              
            </td>
            <td className="button-control tab">
              <img
                src={ic_more_gray}
                className="more-button"
                id={"more_button_id_" + index}
                alt="ic_more"
                onClick={() => {
                  this.onShowControl(index, e);
                }}
              />
            </td>
          </tr>
        );
      });
    }
    return (
      <Bound>
        <SubHeaderControl
          typeSearch='searchBranch'
          textSearch={this.props.filterOfficeReducer.textSearch}
          searchFilter={this.searchFilter}
          data={subHeaderData.branch}
          toggleFilter={refSearch => {
            this.props.toggleFilter(refSearch);
          }}
          onAddMoreSubmit={() => this.onAddMoreSubmit()}
          onReset={() => this.onReset()}
        />
        {listOffice && listOffice.length > 0 ? (
          <div className="body-branch">
            <div className="paging-content">
              {total > 10 ? (
                <PagingControl
                  currentPage={this.state.currentPage}
                  totalItem={total}
                  limitPerPage={limitPerPage}
                  onChangePage={this.onPageChange}
                />
              ) : null}
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th className="number-format">Stt</th>
                  <th className="device-kiosk">Mã CN/PGD</th>
                  <th className="point-transaction">chi nhánh/PGD</th>
                  <th className="address">ĐỊA ĐIỂM</th>
                  <th className="type-transaction">LOẠI VP GIAO DỊCH</th>
                  <th className="counter-number">SL QUẦY</th>
                  <th className="device-kiosk">SL KIOSK</th>
                  <th className="device-config">CẤU HÌNH THIẾT BỊ</th>
                  <th className="button-control"></th>
                </tr>
              </thead>
              <tbody>{elmBranch}</tbody>
            </table>
          </div>
        ) :
          listOffice.length <= 0 ?
            <React.Fragment>
              <div className="body-branch">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="number-format">Stt</th>
                      <th className="device-kiosk">Mã CN/PGD</th>
                      <th className="point-transaction">điểm giao dịch</th>
                      <th className="address">ĐỊA ĐIỂM</th>
                      <th className="type-transaction">LOẠI VP GIAO DỊCH</th>
                      <th className="counter-number">SỐ QUẦY</th>
                      <th className="device-kiosk">Thiết bị Tablet</th>
                      <th className="button-control"></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <EmptyControl
                alertContent={alert}
                content={emptyData.branch}
              />
            </React.Fragment>
            : alert === '' &&
            <EmptyControl
            />
        }
      </Bound>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.createOfficeReducer.success) {
      this.props.filterOffice({}, 10, 1);
      this.setState({ currentPage: 1 });
      this.props.resetStateOffice();
    }
    if (
      JSON.stringify(prevProps.filterOfficeReducer.dataFilter) !==
      JSON.stringify(this.props.filterOfficeReducer.dataFilter)
    ) {
      this.setState({ currentPage: 1 });
    }
  }
}

const mapStateToProps = state => ({
  filterOfficeReducer: state.filterOfficeReducer,
  createOfficeReducer: state.createOfficeReducer
});

const mapDispatchToProps = dispatch => ({
  filterOffice: (dataFilter, pageSize, pageNumber) =>
    dispatch(filterOffice(dataFilter, pageSize, pageNumber)),

  resetStateOffice: () => dispatch(resetStateOffice()),
  alertContent: (data) => dispatch(alertFillterOffice(data)),
  saveTextSearchOffice: (text) => dispatch(saveTextSearchOffice(text)),
  clearTextSearchOffice: () => dispatch(clearTextSearchOffice()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(BranchContainer);
