import React, { useEffect, useState } from 'react'
//css
import { Bound } from './index.styles'

const TEXT = "TEXT"
const TEXT_ACTIVE = "TEXT_ACTIVE"
const TEXT_ERROR = "TEXT_ERROR"

const InputControl = ({ title, placeHolder, type, isRequired = false, prefix, suffix, maxLength, isDisabled, error, handleValidationInput, className, ...props }) => {

    const [typeInput, setTypeInput] = useState(type)
    const [errorInput, setErrorInput] = useState("")
    const [errorBorder, setErrorBorder] = useState(false)

    useEffect(() => {
        if (!props.value && !errorInput) return
        if (props.disabled) return
        setTypeInput(TEXT_ACTIVE)
        if (!errorInput) {
            setTypeInput(TEXT_ACTIVE)
        } else {
            setTypeInput(TEXT_ERROR)
        }
    }, [props.value, errorInput])

    useEffect(() => {
        setErrorInput(error)
    }, [error]);

    useEffect(() => {
        if (!props.disabled) return
        setTypeInput(TEXT)
    }, [props.disabled])

    const _handleFocusInput = () => {
        setErrorInput("")
        setErrorBorder(false)
        switch (typeInput) {
            case TEXT:
                setTypeInput(TEXT_ACTIVE)
                break;
            case TEXT_ERROR:
                setTypeInput(TEXT_ACTIVE)
                break;
            default: return;
        }
    }

    const _handleBlurInput = (e) => {
        const valueText = e.target.value
        if (!valueText) {
            setTypeInput(TEXT)
        } else {
            setTypeInput(TEXT_ACTIVE)
        }
        const callback = (textErrorInput) => {
            setErrorInput(textErrorInput)
            setErrorBorder(true)
            setTypeInput(TEXT_ERROR)
        }
        handleValidationInput && handleValidationInput(props.value, callback)
    }

    return (
        <Bound isDisabled={isDisabled} className={className}>
            {
                title &&
                <p className='title-text'>
                    {title}
                    {isRequired && <span className='title-notice'>*</span>}
                </p>
            }
            <div className='wrap-input'>
                {
                    prefix &&
                    <p className='prefix' title={prefix}>{prefix}</p>
                }
                <input
                    {...props}
                    spellCheck="false"
                    type={type}
                    onFocus={_handleFocusInput}
                    onBlur={_handleBlurInput}
                    maxLength={maxLength}
                    placeholder={placeHolder}
                    className={`${errorBorder || errorInput ? "error-input" : ""}`}
                />
                {
                    suffix &&
                    <p className='suffix'>{suffix}</p>
                }
            </div>

        </Bound>
    )
}

export default InputControl