import React from 'react'
import { Container } from './index.style';

const CheckboxControl = ({ size, marginLeft, marginTop, labelFontSize, label, isChecked = false, isDisabled = false, onChanged }) => {
    
    return (
        <Container
            sizeCheckBox={size}
            marginLeft={marginLeft}
            marginTop={marginTop}
            labelFontSize={labelFontSize} >
            <label className={isDisabled ? "content disabled" : "content"}>
                <div className='label'>{label}</div>
                <input type="checkbox" checked={isChecked} onChange={onChanged} />
                <span className="checkmark" />
            </label>
        </Container>
    );
}

export default CheckboxControl;