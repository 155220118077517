import React from 'react'

// styles
import { WrapTable, WrapInfos, Header, Body } from './index.styles'
import { ScrollTable } from '../../../controls/Table/index.styles'

// component
import { Row } from '../../../controls/Table'

const titleBeforeCol = (
    <div className="row-title data-before">
        <div className="header-item">Mã CN</div>
        <div className="header-item">Tên CN</div>
        <div className="header-item">Số GTTT</div>
        <div className="header-item">Họ Tên KH</div>
        <div className="header-item">Kênh tác nghiệp khuôn mặt</div>
        <div className="header-item">Loại KH</div>
        <div className="header-item">GDV/Điện toán</div>
        <div className="header-item">KSV/BGD</div>
        <div className="header-item">Thời gian tác nghiệp</div>
        <div className="header-item">Thời gian phê duyệt</div>
    </div>
)

const titleAfterCol = (
    <div className="row-title data-after">
        <div className="header-item">Mã CN</div>
        <div className="header-item">Tên CN</div>
        <div className="header-item">Số GTTT</div>
        <div className="header-item">Họ Tên KH</div>
        <div className="header-item">Kênh tác nghiệp khuôn mặt</div>
        <div className="header-item">Loại KH</div>
        <div className="header-item">GDV/Điện toán</div>
        <div className="header-item">KSV/BGD</div>
        <div className="header-item">Thời gian tác nghiệp</div>
        <div className="header-item">Thời gian phê duyệt</div>
        <div className="header-item">Trạng Thái phê duyệt</div>
        <div className="header-item">Lý do từ chối</div>
    </div>
)

const Table = ({ lstData }) => {
    // const titleCol = useMemo(
    //     () => (
    //         <div className="row-title">
    //             <div className="header-item">Mã CN</div>
    //             <div className="header-item">Tên CN</div>
    //             <div className="header-item">Số CIF</div>
    //             <div className="header-item">Họ Tên KH</div>
    //             <div className="header-item">Loại KH</div>
    //             <div className="header-item">GDV/Điện toán</div>
    //             <div className="header-item">KSV/BGD</div>
    //             <div className="header-item">Thời gian tác nghiệp</div>
    //             <div className="header-item">Thời gian phê duyệt</div>
    //             <div className="header-item">Trạng Thái phê duyệt</div>
    //             <div className="header-item">Lý do từ chối</div>
    //         </div>
    //     ),
    //     []
    // )

    return (
        <ScrollTable>
            <WrapTable>
                <Header>
                    <div className="header-item">Mã truy vấn</div>
                    <WrapInfos>
                        <div className="header-item">
                            Khách hàng đăng ký trước
                        </div>
                        {titleBeforeCol}
                    </WrapInfos>
                    <WrapInfos>
                        <div className="header-item">
                            Khách hàng đăng ký sau
                        </div>
                        {titleAfterCol}
                    </WrapInfos>
                </Header>
                <Body numOfRow={6}>
                    {lstData.map((item, key) => (
                        <Row data={item} key={key} />
                    ))}
                </Body>
            </WrapTable>
        </ScrollTable>
    )
}

export default Table
