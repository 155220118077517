import styled from "styled-components";

export const ContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    /* padding:0 40px; */
    .wrap-transaction {
        overflow:hidden;
        display:flex;
        flex-direction:column;
        width:100%;
        margin-top: 26px;
        &__title {
            font-family: "SVN-Gilroy";
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            color: #005993;
            text-transform: uppercase;
            margin-left: 10px;
            display:flex;
        }
        .scroll{
        display:flex;
        flex:1;
        overflow:hidden;
        margin-top: 17px;
        &__list-transaction {
            width:100%;
            overflow:auto;
            padding-bottom: 40px;
            & > * {
            margin-bottom: 5px;
            }
            ::-webkit-scrollbar { 
            display: none; 
            }
        }
        } 
    }
`;