import React, { Component } from 'react';
import styled from 'styled-components';
import ic_close from '../../../../images/ic_close_gray.svg'
import UserInformation from './UserInformation';
import UserBiometric from './UserBiometric';
import ActionControlCheckBio from './Control/ActionControlCheckBio';
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup';
import ic_history from '../../../../images/Icons_History.svg'
import { Status, ACCEPT, REJECT, PENDING, THUMB_LEFT, dataFingerPrint, THUMB_RIGHT, INDEX_LEFT, INDEX_RIGHT, MIDDLE_LEFT, MIDDLE_RIGHT, RING_LEFT, RING_RIGHT, PINKY_LEFT, PINKY_RIGHT, NR, FACE, DELETE, REGISTER, UPDATE, customType, ISACTIVE, ACTIVE, BioInfosType, HANG, titleUFace, titleCloseActiveFace, statusS, statusU, statusN, titleDFace, NR_FINGER } from '../../../../data/infosType';
import { color } from '../../../../data/store';


const Bound = styled.div`
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate(-50%,-50%);
  width: 705px;
  ${'' /* height: 516px; */}
  height:fit-content;
  border-radius: 10px;
  background-color: #fafafa;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .header {
    padding: 16px 10px 0 20px;
    width: calc(100% - 30px);
    height: 68px;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #e3e3e3c4;
    .block_header_title {
      display: flex;
      justify-content:space-between;
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .main_content{
    /* height: 352px; */
  }
  .footer_check {
    /* margin: 20px 0 0 0; */
    width: 705px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    .block_action{
      width:17%;
      display: flex;
      margin: 0 0 0 30px;
      /* justify-content:space-between; */
      h4{
        text-decoration: underline;
      }
    }
    .block_btn {
      margin: 0 20px 0 0;
      display: flex;
      align-items: center;
      justify-content:space-between;

      & > *:not(:first-child) {
        margin-left: 10px;
      }
    }
    .block_load {
      margin: 0 0 0 15px;
    }
  }
`

export const TabLayout = styled.div`
  display: flex;
  justify-content:space-between;
  margin: 10px 0 0 0;
  .title_content {
    display: flex;
    .tab_title_single {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: ${color.blue_005993};
      margin: 0 0 7px 20px;
    }
    .tab_title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #c8c8c8;
      margin: 0 13px 0 0;
      cursor: pointer;
      padding: 0px 8px 15px 8px;
    }
  }
  .title_info {
    display: flex;
    .cif {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: ${color.blue_005993};
      margin: 0 25px 0 0;
      .text_cif{
        color: #222222;
      }
    }
    .status {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: ${color.blue_005993};
      .text_status {
        color:#979797;
        margin: 0 15px 0 0;
      }
    }
  }
`

var _ = require('lodash');
const pageSize = 100000000000000000;
export default class PopupCheckBiometricCustomer extends Component {

  state = {
    changeTab: 1,
    dataUser: this.props.dataUser ?? {},
    dataCurrent: this.props.dataCurrent ?? [],
  }

  changeTab = (changeTab) => {
    this.setState({
      changeTab
    })
  }

  showPopupNotice = () => {
    this.props.showPopupInBiometric(this.state.dataUser, 'NoticeBiometric')
  }

  showConfirmBio = () => {
    this.props.showPopupInBiometric({}, 'confirmCheckBio')
  }

  showHistoryBio = () => {
    const { dataUser } = this.state;
    this.props.showPopupInBiometric(
      this.props.getBioHistoryList(
        dataUser.CustomerInfos.CustomerNumber,
        pageSize,
        1,
        dataUser.CompleteAt
      ),
      'BiometricHistory'
    )
  }

  showApproveBio = () => {
    this.props.showPopupInBiometric(this.state.dataUser, 'approveDataBiometric')
  }

  showRejectBio = () => {
    this.props.showPopupInBiometric(this.state.dataUser, 'rejectDataBiometric')
  }

  handleConfirmExecute = () => {
    const { dataUser } = this.state
    const { ID } = dataUser
    this.props.fixErrorAcceptBios(ID, this.handleConfirmSuccess)
  }

  handleConfirmSuccess = () => {
    this.props.alertPopup('Xử lý thành công')
    const { dataUser } = this.state
    const { CustomerInfos: { CustomerNumber } } = dataUser
    this.props.getListErrorAcceptBios(CustomerNumber)
    this.props.outPopup()
  }

  showMatchFace = () => {
    const { BioDatas } = this.state.dataUser
    let bioFace = BioDatas.find(item => item.BioType && item.BioType === FACE)
    let duplicateFace = bioFace?.DuplicateFaces;

    this.props.showPopupInBiometric({
      DuplicateFaces: duplicateFace,
      Name: this.state.dataUser.CustomerInfos.Name,
      DuplicateFaceNote: this.state.dataUser.DuplicateFaceNote,
      CustomerCif: this.state.dataUser.CustomerInfos.CustomerNumber
    }, 'MatchFace')
  }

  showFaceImage = (biotype) => {
    let dataIsActive, dataIsConfirmFinger, titleStatus, bioUpdate, textFaceRegister, bioActionTpe, enrollFaceR, isActiveFaceR, textStatus, dataRemoveNote
    let { BioDatas, CurrentBioData, BioInfosType } = this.state.dataUser
    BioDatas = JSON.parse(JSON.stringify(BioDatas))
    CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))

    let dataBioDatas = BioDatas.map(item => item)

    let dataCurrent = this.state.dataCurrent.map(item => item)

    const cloneBioDatas = _.cloneDeep(BioDatas);
    if (CurrentBioData) {
      bioUpdate = BioInfosType === UPDATE && CurrentBioData.map(item => {
        const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
        if (indexBio < 0) return item;
        const dataBio = cloneBioDatas[indexBio];
        if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
          return item;
        }

        let isSame = 0
        for (let y in dataBio) {

          if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
            titleStatus = titleCloseActiveFace
            textStatus = statusN
          }
          if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {
            if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
              titleStatus = titleCloseActiveFace
              textStatus = statusN
            }
            if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
              isSame = 1;
              // debugger
            }
            if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
              isSame = 2;
              // debugger
            }
            if (isSame === 2) {
              if ((dataBio.BioActionType === DELETE || dataBio.BioActionType === UPDATE) && (item.BioActionType === REGISTER || item.BioActionType === UPDATE || item.BioActionType === '') &&
                (dataBio.EnrollData !== item.EnrollData && dataBio.EnrollData !== null)) {
                // debugger
                isSame = 0;
                if (dataBio.BioActionType === REGISTER) {
                  titleStatus = titleCloseActiveFace
                  textStatus = statusN
                } else if (dataBio.BioActionType === UPDATE) {
                  if (dataBio.EnrollData) {
                    titleStatus = titleUFace
                    textStatus = statusU
                  } else {
                    titleStatus = titleCloseActiveFace
                    textStatus = statusS
                  }
                } else if (dataBio.BioActionType === DELETE) {
                  // titleStatus = titleUFace
                  // textStatus = statusU
                  titleStatus = titleDFace
                  textStatus = statusS
                }
                item[y] = dataBio[y]
                // debugger
                // titleStatus = titleUFace
                // textStatus = statusU
                continue
              } else {
                // debugger
                isSame = 0;
                dataBio[y] = item[y]
                item.matchCheck = true;
                if (item.matchCheck) {
                  titleStatus = titleCloseActiveFace
                  textStatus = statusN
                }
                break
              }
            }

            if (dataBio.BioType === FACE && item.BioType === FACE) {
              if (dataBio.BioActionType === REGISTER) {
                titleStatus = titleCloseActiveFace
                textStatus = statusN
              } else if (dataBio.BioActionType === UPDATE) {
                if (dataBio.EnrollData) {
                  titleStatus = titleUFace
                  textStatus = statusU
                } else {
                  titleStatus = titleCloseActiveFace
                  textStatus = statusS
                }
              } else if (dataBio.BioActionType === DELETE) {
                // titleStatus = titleUFace
                // textStatus = statusU
                titleStatus = titleDFace
                textStatus = statusS
              }
              item[y] = dataBio[y]
              // titleStatus = titleUFace
              // textStatus = statusU
            } else {
              titleStatus = titleCloseActiveFace
              textStatus = statusN
            }
          }
        }
        cloneBioDatas.splice(indexBio, 1);
        return item;
      })
    } else {
      bioUpdate = BioDatas
      bioUpdate.forEach(item => {
        if (item.BioActionType === REGISTER && item.BioType === FACE) {
          textFaceRegister = 'FaceRegister'
          enrollFaceR = item.EnrollData
          isActiveFaceR = item.IsActive
          titleStatus = titleCloseActiveFace
          textStatus = statusN
          bioActionTpe = item.BioActionType
        }
      })
    }


    // let dataBioUpdate = dataUser.BioInfosType === UPDATE && bioUpdate.concat(cloneBioDatas)
    // let informFinger = dataUser.BioInfosType === UPDATE ? dataBioUpdate : dataBioDatas
    // console.log('as', dataCurrent)
    let informFinger = BioInfosType === UPDATE ? dataCurrent : dataBioDatas
    // let bioTypeFaceUpdate = BioInfosType === UPDATE && dataBioUpdate.find(item => item.BioType === FACE)
    let bioTypeFaceUpdate = BioInfosType === UPDATE && dataCurrent.find(item => item.BioType === FACE)
    let bioTypeFaceRegister = cloneBioDatas.find(item => item.BioType === FACE)

    let faceBio = BioInfosType === UPDATE ? bioTypeFaceUpdate : bioTypeFaceRegister
    informFinger.forEach(item => {
      if (item.BioType === biotype.BioType) {
        dataIsConfirmFinger = item.IsConfirmFinger
        dataIsActive = item.IsActive

      }
    })
    let checkIsActive
    if (BioInfosType !== REGISTER && CurrentBioData.length === 0) {
      checkIsActive = bioTypeFaceRegister.IsActive
    } else {
      checkIsActive = dataIsActive
    }
    BioDatas.forEach(item => {
      if (item.BioActionType === DELETE && item.BioType === FACE) {
        dataRemoveNote = item.RemoveNote
      }
    })
    this.props.showPopupInBiometric(
      {
        Face: textFaceRegister === 'FaceRegister'
          ? enrollFaceR && enrollFaceR
          : (faceBio
            ? faceBio.EnrollData
            : (bioTypeFaceRegister && bioTypeFaceRegister.EnrollData)
          ),
        IsConfirmFinger: dataIsConfirmFinger,
        IsActive: textFaceRegister === 'FaceRegister' ? isActiveFaceR : checkIsActive,
        ID: this.state.dataUser.ID,
        Note: this.state.dataUser.Note,
        Title: titleStatus,
        FromChannel: textFaceRegister === 'FaceRegister' ? bioTypeFaceRegister?.FromChannel : bioTypeFaceUpdate?.FromChannel,
        StatusFace: 'FaceRegister',
        TextStatus: textStatus,
        RemoveNote: dataRemoveNote,
        BioActionType: textFaceRegister === 'FaceRegister' ? bioActionTpe && bioActionTpe : faceBio && faceBio.BioActionType
      }, 'ShowFaceImage', this.state.dataCurrent)
  }

  showFaceUpdateImage = (biotype) => {
    let dataIsActive, dataIsConfirmFinger, titleStatus, bioUpdate, textFaceRegister, enrollFaceR, isActiveFaceR, textStatus, dataRemoveNote

    let { BioDatas, BioInfosType, CurrentBioData } = this.state.dataUser
    BioDatas = JSON.parse(JSON.stringify(BioDatas))
    CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))

    let dataBioDatas = BioDatas.map(item => item)
    let dataCurrent = this.state.dataCurrent.map(item => item)

    const cloneBioDatas = _.cloneDeep(BioDatas);
    if (CurrentBioData) {
      bioUpdate = BioInfosType === UPDATE && CurrentBioData.map(item => {
        const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
        if (indexBio < 0) return item;
        const dataBio = cloneBioDatas[indexBio];
        if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
          return item;
        }
        let isSame = 0
        for (let y in dataBio) {
          if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
            titleStatus = titleCloseActiveFace
            textStatus = statusN
          }
          if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {
            if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
              titleStatus = titleCloseActiveFace
              textStatus = statusN
            }
            if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
              isSame = 1;
              // debugger
            }
            if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
              isSame = 2;
              // debugger
            }
            if (isSame === 2) {
              if ((dataBio.BioActionType === DELETE || dataBio.BioActionType === UPDATE) && (item.BioActionType === REGISTER || item.BioActionType === UPDATE || item.BioActionType === '') &&
                (dataBio.EnrollData !== item.EnrollData && dataBio.EnrollData !== null)) {
                // debugger
                isSame = 0;
                if (dataBio.BioActionType === REGISTER) {
                  titleStatus = titleCloseActiveFace
                  textStatus = statusN
                } else if (dataBio.BioActionType === UPDATE) {
                  if (dataBio.EnrollData) {
                    titleStatus = titleUFace
                    textStatus = statusU
                  } else {
                    titleStatus = titleCloseActiveFace
                    textStatus = statusS
                  }
                } else if (dataBio.BioActionType === DELETE) {
                  titleStatus = titleDFace
                  textStatus = statusS
                }
                item[y] = dataBio[y]
                continue
              } else {
                // debugger
                isSame = 0;
                dataBio[y] = item[y]
                item.matchCheck = true;
                if (item.matchCheck) {
                  titleStatus = titleCloseActiveFace
                  textStatus = statusN
                }
                break
              }
            }
            if (dataBio.BioType === FACE && item.BioType === FACE) {
              if (dataBio.BioActionType === REGISTER) {
                titleStatus = titleCloseActiveFace
                textStatus = statusN
              } else if (dataBio.BioActionType === UPDATE) {
                if (dataBio.EnrollData) {
                  titleStatus = titleUFace
                  textStatus = statusU
                } else {
                  titleStatus = titleCloseActiveFace
                  textStatus = statusS
                }
              } else if (dataBio.BioActionType === DELETE) {
                // titleStatus = titleUFace
                // textStatus = statusU
                titleStatus = titleDFace
                textStatus = statusS
              }
              item[y] = dataBio[y]
              // titleStatus = titleUFace
              // textStatus = statusU
            } else {
              titleStatus = titleCloseActiveFace
              textStatus = statusN
            }
          }
        }
        cloneBioDatas.splice(indexBio, 1);
        return item;
      })
    } else {
      bioUpdate = BioDatas
      bioUpdate.forEach(item => {
        if (item.BioActionType === REGISTER && item.BioType === FACE) {
          textFaceRegister = 'FaceRegister'
          enrollFaceR = item.EnrollData
          isActiveFaceR = item.IsActive
          titleStatus = titleCloseActiveFace
          textStatus = statusN
        }
      })
    }

    let informFinger = BioInfosType === UPDATE ? dataCurrent : dataBioDatas
    let bioTypeFaceUpdate = BioInfosType === UPDATE && dataCurrent.find(item => item.BioType === FACE)
    let bioTypeFaceRegister = cloneBioDatas.find(item => item.BioType === FACE)

    let faceBio = BioInfosType === UPDATE ? bioTypeFaceUpdate : bioTypeFaceRegister
    BioDatas.forEach(item => {
      if (item.BioActionType === DELETE && item.BioType === FACE) {
        dataRemoveNote = item.RemoveNote
      }
    })

    informFinger.forEach(item => {
      if (item.BioType === biotype.BioType) {
        dataIsConfirmFinger = item.IsConfirmFinger
        dataIsActive = item.IsActive
      }
    })

    this.props.showPopupInBiometric(
      {
        Face: textFaceRegister === 'FaceRegister' ? enrollFaceR : faceBio.EnrollData,
        IsConfirmFinger: dataIsConfirmFinger,
        IsActive: textFaceRegister === 'FaceRegister' ? isActiveFaceR : dataIsActive,
        ID: this.state.dataUser.ID,
        Note: this.state.dataUser.Note,
        Title: titleStatus,
        StatusFace: 'FaceRegister',
        TextStatus: textStatus,
        RemoveNote: dataRemoveNote
      }, 'ShowFaceImage', this.state.dataCurrent)
  }

  showFaceZoomImage = (biotype) => {
    let titleStatus, bioUpdate

    const { dataUser } = this.state
    let { BioDatas, BioInfosType, CurrentBioData } = this.state.dataUser
    BioDatas = JSON.parse(JSON.stringify(BioDatas))
    CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))

    const cloneBioDatas = _.cloneDeep(BioDatas);
    if (CurrentBioData) {
      bioUpdate = BioInfosType === UPDATE && CurrentBioData.map(item => {
        const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
        if (indexBio < 0) return item;
        const dataBio = cloneBioDatas[indexBio];
        if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
          return item;
        }
        Object.keys(dataBio).forEach(y => {
          if (dataBio.EnrollData !== null && dataBio.BioType === FACE && dataBio !== null) {
          }
          if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {
            item[y] = dataBio[y];
          }
        });
        cloneBioDatas.splice(indexBio, 1);
        return item;
      })
    } else {
      bioUpdate = BioDatas
    }

    let dataBioUpdate = dataUser.BioInfosType === UPDATE && bioUpdate.concat(cloneBioDatas)
    let bioTypeFaceUpdate = BioInfosType === UPDATE && dataBioUpdate.find(item => item.BioType === FACE)
    let bioTypeFaceRegister = cloneBioDatas.find(item => item.BioType === FACE)

    let faceBio = BioInfosType === UPDATE ? bioTypeFaceUpdate : bioTypeFaceRegister
    this.props.showPopupInBiometric(
      {
        Face: faceBio.EnrollData,
        Title: titleStatus,
        TextFace: 'FaceZoom'
      }, 'ShowFaceImage', this.state.dataCurrent)
  }

  showFingerInfoCustomer = (biotype) => {
    let match, dataRemoveNote, bioUpdate, BioType, CreateAt, ConfirmData, EnrollData, IsActive, IsConfirmFinger, BioActionType, dataEnroll, dataCreate, dataBioType, dataConfirm, dataBioActionType, dataIsActive, dataIsConfirmFinger
    let { dataUser, dataCurrent } = this.state
    let { BioDatas, CurrentBioData } = this.state.dataUser
    BioDatas = JSON.parse(JSON.stringify(BioDatas))
    CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))
    dataCurrent = JSON.parse(JSON.stringify(dataCurrent))


    let dataBioDatas = BioDatas.map(item => item)
    let findIndexDataCurrent = dataCurrent.find(finds => finds.BioType === biotype)
    const cloneBioDatas = _.cloneDeep(BioDatas);
    if (CurrentBioData) {
      bioUpdate = this.state.dataUser.BioInfosType === UPDATE && CurrentBioData.map(item => {
        const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
        item.matchData = true
        if (indexBio < 0) {
          return item;
        }
        const dataBio = cloneBioDatas[indexBio];
        if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
          return item;
        }

        let isSame = 0;
        let isMatchData = 0
        let isMatchData1 = 0
        for (let y in dataBio) {
          if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {

            if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
              isSame = 1;
            }
            if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
              isSame = 2;
            }
            if (isSame === 2) {
              if (dataBio.BioActionType === DELETE && (item.BioActionType === REGISTER || item.BioActionType === '')) {
                isSame = 0;
                item[y] = dataBio[y]
                continue
              } else {
                isSame = 0;
                dataBio[y] = item[y]
                item.matchData = true;
                break
              }
            }
            item[y] = dataBio[y]
            if (item.BioActionType === UPDATE || item.BioActionType === DELETE) {
              // debugger
              isMatchData1 = 1
            }
            if (isMatchData1 === 1) {
              // debugger
              isMatchData1 = 0
              isMatchData = false
            }
          }
        }
        if (isMatchData === false) item.matchData = isMatchData
        cloneBioDatas.splice(indexBio, 1);
        return item;
      })
    } else {
      bioUpdate = BioDatas
    }

    let dataBioUpdate = dataUser.BioInfosType === UPDATE && bioUpdate.concat(cloneBioDatas)
    let informFinger = dataUser.BioInfosType === UPDATE ? dataBioUpdate : dataBioDatas

    dataBioType = informFinger.find(item => item.BioType === biotype)
    dataBioActionType = informFinger.find(item => item.BioType === biotype && item.BioActionType)
    match = informFinger.find(item => item.BioType === biotype && item.matchData)

    informFinger.forEach(item => {
      if (item.BioType === biotype) {
        dataIsConfirmFinger = item.IsConfirmFinger
        dataIsActive = item.IsActive
        dataRemoveNote = item.RemoveNote
      }
    })

    let elmBioActionFinger
    let index = 0
    if (dataBioType.BioType !== FACE) {
      if (typeof dataBioActionType === 'undefined') {
        elmBioActionFinger = BioInfosType[REGISTER].content
      }
      if (dataBioActionType && dataBioActionType.BioActionType === REGISTER) {
        elmBioActionFinger = BioInfosType[REGISTER].content
      } else if (dataBioActionType && dataBioActionType.BioActionType === DELETE) {
        elmBioActionFinger = BioInfosType[DELETE].dlFinger
      }
      else if (dataBioActionType && dataBioActionType.BioActionType === UPDATE) {
        // check close, active finger
        if (dataIsActive) {
          if (dataIsConfirmFinger) {
            elmBioActionFinger = customType[ACTIVE].vlFinger
          } else {
            elmBioActionFinger = customType[ISACTIVE].vlFinger
          }
        } else {
          // check hang finger'
          if (dataBioActionType && dataBioActionType.BioActionType === UPDATE) {
            index = 1
            if (index === 1 && dataIsConfirmFinger === false) {
              elmBioActionFinger = customType[ISACTIVE].vlFinger
            } else {
              elmBioActionFinger = customType[HANG].vlFinger
            }
          }
        }
      }
    }

    dataConfirm = informFinger.find(item => item.BioType === biotype && item.ConfirmData)
    dataEnroll = informFinger.find(item => item.BioType === biotype && item.EnrollData)
    dataCreate = informFinger.find(item => item.BioType === biotype && item.CreateAt)

    BioType = dataBioType && dataBioType.BioType
    ConfirmData = dataConfirm && dataConfirm.ConfirmData

    EnrollData = dataEnroll && dataEnroll.EnrollData
    IsActive = dataIsActive
    IsConfirmFinger = dataIsConfirmFinger
    CreateAt = dataCreate && dataCreate.CreateAt
    BioActionType = dataBioActionType && dataBioActionType.BioActionType

    if (BioType === THUMB_LEFT) {
      BioType = dataFingerPrint[THUMB_LEFT].label
    } else if (BioType === THUMB_RIGHT) {
      BioType = dataFingerPrint[THUMB_RIGHT].label
    } else if (BioType === INDEX_LEFT) {
      BioType = dataFingerPrint[INDEX_LEFT].label
    } else if (BioType === INDEX_RIGHT) {
      BioType = dataFingerPrint[INDEX_RIGHT].label
    } else if (BioType === MIDDLE_LEFT) {
      BioType = dataFingerPrint[MIDDLE_LEFT].label
    } else if (BioType === MIDDLE_RIGHT) {
      BioType = dataFingerPrint[MIDDLE_RIGHT].label
    } else if (BioType === RING_LEFT) {
      BioType = dataFingerPrint[RING_LEFT].label
    } else if (BioType === RING_RIGHT) {
      BioType = dataFingerPrint[RING_RIGHT].label
    } else if (BioType === PINKY_LEFT) {
      BioType = dataFingerPrint[PINKY_LEFT].label
    } else if (BioType === PINKY_RIGHT) {
      BioType = dataFingerPrint[PINKY_RIGHT].label
    }

    this.props.showPopupInBiometric(
      {
        BioActionType: BioActionType,
        BioType: BioType,
        CreateAt: findIndexDataCurrent ? findIndexDataCurrent.CreateAt : CreateAt,
        ConfirmData: findIndexDataCurrent ? findIndexDataCurrent.ConfirmData : ConfirmData,
        EnrollData: findIndexDataCurrent ? findIndexDataCurrent.EnrollData : EnrollData,
        IsActive: IsActive,
        IsConfirmFinger: IsConfirmFinger,
        FromChannel: dataEnroll?.FromChannel,
        IsActiveCurrent: findIndexDataCurrent ? findIndexDataCurrent.IsActive : IsActive,
        IsConfirmFingerCurrent: findIndexDataCurrent ? findIndexDataCurrent.IsConfirmFinger : IsConfirmFinger,
        ID: this.state.dataUser.ID,
        Note: this.state.dataUser.Note,
        BioActionFinger: elmBioActionFinger,
        RemoveNote: dataRemoveNote,
        MatchData: match
      }
      , 'FingerInfoCustomer'
    )
  }

  acceptBioInfos = () => {
    this.showApproveBio()
  }

  render() {
    let status, clsStatus
    let { changeTab, dataUser } = this.state;
    if (dataUser.Status === ACCEPT) {
      status = Status[ACCEPT].label
      clsStatus = Status[ACCEPT].colorA
    } else if (dataUser.Status === PENDING) {
      status = Status[PENDING].value
      clsStatus = Status[PENDING].colorP
    } else {
      status = Status[REJECT].label
      clsStatus = Status[REJECT].colorR
    }

    // console.log('status', status)
    let content_tab_layout = null;
    if (changeTab === 1) {
      content_tab_layout = <UserInformation
        typeInternal='gdv'
        dataUser={this.props.dataUser}
      // role = {this.props.role}
      />
    } else if (changeTab === 2) {
      content_tab_layout = <UserBiometric
        showFingerInfoCustomer={this.showFingerInfoCustomer}
        showMatchFace={this.showMatchFace}
        showFaceImage={this.showFaceImage}
        dataUser={JSON.parse(JSON.stringify(this.props.dataUser))}
        showFaceUpdateImage={this.showFaceUpdateImage}
        showFaceZoomImage={this.showFaceZoomImage}
        roles={this.props.roles}
      />
    }

    return (
      <Bound>
        <div className="header">
          <div className="block_header_title">
            <div className="title">
              Thông tin dữ liệu
            </div>
            <img
              onClick={this.props.outPopup}
              src={ic_close} alt="ic_close" />
          </div>
          <TabLayout>
            <div className="title_content">
              {
                dataUser ?
                  <React.Fragment>
                    <p
                      onClick={() => this.changeTab(1)}
                      style={changeTab === 1 ? { borderBottom: `2.5px solid ${color.blue_005993}`, color: '#222222' } : {}}
                      className='tab_title'>Thông tin dữ liệu</p>
                    {
                      ![NR, NR_FINGER].includes(this.state.dataUser.BioInfosType) &&
                      <p
                        onClick={() => this.changeTab(2)}
                        style={changeTab === 2 ? { borderBottom: `2.5px solid ${color.blue_005993}`, color: '#222222' } : {}}
                        className='tab_title'>Sinh trắc học</p>
                    }
                  </React.Fragment>
                  :
                  <p className='tab_title_single'>Thông tin dữ liệu</p>
              }
            </div>
            {dataUser &&
              <div className="title_info">
                <div className="cif">
                  CIF: <p className='text_cif'> &nbsp;{dataUser.CustomerInfos.CustomerNumber}</p>
                </div>
                <div className="status">
                  Trạng thái: <p className='text_status' style={{ color: clsStatus }}> &nbsp; {status}</p>
                </div>
              </div>
            }
          </TabLayout>
        </div>
        <div className="main_content">
          {content_tab_layout}
        </div>
        <div className="footer_check">
          <div className="block_action">

            <ActionControlCheckBio
              icon={ic_history}
              onSubmit={this.showHistoryBio}
              isTootltip={true}
            />
          </div>

          <div className="block_btn">
            {
              this.props.isFlowBioErrorExecute ?
                <ButtonConfirmPopup
                  width='145px'
                  bg_color='rgba(0, 89, 147, 1)'
                  titleConfirm='xác nhận xử lý'
                  onClick={this.handleConfirmExecute}
                />
                :
                <>
                  <ButtonConfirmPopup
                    width='80px'
                    bg_color={dataUser.Status === PENDING ? 'rgba(215, 18, 73, 1)' : 'rgba(215, 18, 73, 0.1)'}
                    titleConfirm='Từ chối'
                    onClick={() => dataUser.Status === PENDING ? this.showRejectBio() : null}
                  />
                  <ButtonConfirmPopup
                    width='80px'
                    bg_color={dataUser.Status === PENDING ? 'rgba(0, 89, 147, 1)' : 'rgba(0, 89, 147, 0.1)'} //'${color.blue_005993}'
                    titleConfirm='Đồng ý'
                    onClick={() => dataUser.Status === PENDING ? this.acceptBioInfos() : null}
                  />
                </>
            }
          </div>
        </div>
      </Bound>

    )
  }
}
