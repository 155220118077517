/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { css } from 'styled-components'

// styles
import { extendStylesDropdownSupervisor } from './index.styles'

// component
import DropdownSupervisor from './DropdownSupervisor'

const labels = {
    BGD: 'BGD',
    KSV_BGD: 'KSV_BGD',
    KSV_BGD_TRUOC: 'KSV_BGD_TRUOC',
    KSV_BGD_SAU: 'KSV_BGD_SAU',
}

const labelsDesc = {
    [labels.BGD]: {
        title: 'Ban Giám Đốc'
    },
    [labels.KSV_BGD]: {
        title: 'KSV/Ban Giám Đốc'
    },
    [labels.KSV_BGD_TRUOC]: {
        title: <>
            <div>KSV/Ban Giám Đốc</div>
            <div>(KH đăng ký trước)</div>
        </>
    },
    [labels.KSV_BGD_SAU]: {
        title: <>
            <div>KSV/Ban Giám Đốc</div>
            <div>(KH đăng ký sau)</div>
        </>
    },
}

const ALL_SUPERVISOR = 'Tất cả'

const SelectSupervisor = ({ 
    defaultValue, 
    onChange, 
    label = labels.BGD, 
    extendStyles 
}) => {
    const commonReducer = useSelector((state) => state.commonReducer)

    const [lstItems, setLstItems] = useState([])
    const [value, setValue] = useState(defaultValue || ALL_SUPERVISOR)

    const _handleOnChange = useCallback((val) => {
        setValue(val)
        if (ALL_SUPERVISOR === val) {
            onChange(undefined)
            return
        }
        onChange(val)
    }, [])

    useEffect(() => {
        switch (label) {
            case labels.BGD:
                setLstItems([ALL_SUPERVISOR, ...commonReducer.listInSupervisors])
                break;
            case labels.KSV_BGD:
            case labels.KSV_BGD_TRUOC:
            case labels.KSV_BGD_SAU:
                {
                    const lstUsers =
                        [...commonReducer.listInSupervisors, ...commonReducer.listExSupervisors]
                            .reduce((lst, item) => lst.includes(item) ? lst : lst.concat(item), [])
                    lstUsers.sort((a, b) => a.localeCompare(b))
                    setLstItems([ALL_SUPERVISOR, ...lstUsers])
                    break;
                }
            default:
                break;
        }
    }, [
        label,
        commonReducer.listInSupervisors,
        commonReducer.listExSupervisors,
    ])

    useEffect(() => {
        setValue(ALL_SUPERVISOR)
    }, [
        commonReducer.listInSupervisors,
        commonReducer.listExSupervisors
    ]);

    return (
        <DropdownSupervisor
            extendStyles={css`${extendStylesDropdownSupervisor}${extendStyles}`}
            label={labelsDesc[label]?.title}
            lstItemFixed={[]}
            lstItems={lstItems}
            onChange={_handleOnChange}
            defaultValue={value}
            placeHolder="Nhập User"
        />
    )
}

export default SelectSupervisor
