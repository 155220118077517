import React, { useEffect } from 'react'

// styles
import { WrapOverlay, OptionListDiv } from './index.styles'

const PopupOptionListCounterItem = ({
    offsetPopup,
    currentPage,
    counterInfos,
    funcCall: { onShowPopupEdit, onShowPopupDelete, onShowPopupReset },
}) => {

    useEffect(() => {
        document.body.style.overflowX = 'hidden'
        return () => {
            document.body.style.overflowX = 'visible'
        }
    }, [])

    const _handleModify = (e) => {
        onShowPopupEdit({
            ...counterInfos,
            currentPage,
        })
    }

    const _handleResetCounter = (e) => {
        onShowPopupReset()
    }

    const _handleRemove = (e) => {
        onShowPopupDelete({
            ...counterInfos,
            currentPage,
        })
    }

    return (
        <WrapOverlay>
            <OptionListDiv offset={offsetPopup}>
                <div className="modify" onClick={_handleModify}>
                    Tùy chỉnh
                </div>
                {
                    ((!!counterInfos.TabletDevice && counterInfos.TabletDevice !== "VIRTUAL-TABLET") || 
                        !!counterInfos.DesktopDevice) &&
                    <div className="reset-counter" onClick={_handleResetCounter}>
                        Thoát quầy
                    </div>
                }
                <div className="remove" onClick={_handleRemove}>
                    Xóa quầy
                </div>
            </OptionListDiv>
        </WrapOverlay>
    )
}

export default PopupOptionListCounterItem
