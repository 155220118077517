import styled, { css } from 'styled-components'

const WrapReport = styled.div`

`;

// const lstPxs = [80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80]
// const sumPx = lstPxs.reduce((total, curr) => total + curr)
// const gridArr = lstPxs.map(item => `minmax(${item}px, ${Math.round((item/sumPx)*100)}fr)`)

const lstPxs = [90, 90, 90, 150, 170, 120, 200, 90, 90, 90, 90, 90, 90, 90]
const sumPx = lstPxs.reduce((total, curr) => total + curr)
const gridArr = lstPxs.map(item => `minmax(${item}px, ${(item/sumPx).toPrecision(2)}fr)`)
// console.log("Report Sumaries");
// console.log(gridArr);
const extendStylesTable = css`
    .header, .row {
        grid-template-columns: ${gridArr.join(" ")}
    }
`;

export {
    WrapReport,
    extendStylesTable
}