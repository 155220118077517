import React from 'react'

// styles
import {
    extendStylesSelect,
    extendStylesDropdown,
    DropDownHeadStaffStyles,
} from './index.styles'

// component
import DropDownSearch from '../../../../../../Control/DropDownSearch'

const DropDownHeadStaff = ({ extendStyles, label, ...props }) => {
    return (
        <>
            <div className="label-tools-item">{label}</div>
            <DropDownHeadStaffStyles
                className="tools-item"
                extendStyles={extendStyles}
            >
                <DropDownSearch
                    {...props}
                    extendStylesSelect={extendStylesSelect}
                    extendStylesDropdown={extendStylesDropdown}
                />
            </DropDownHeadStaffStyles>
        </>
    )
}

export default DropDownHeadStaff
