import Axios from 'axios'

// helper
import catchErrorApi from '../../helper/catchErrorApi'

// constant
import { host } from '../../host'
import * as types from './types'

export const apiGetDuplBioDetails = `${host}admin/exportJSON/get-dupbio-details`

export const clearDuplBioDetails = () => ({
    type: types.GET_DUPL_BIO_DETAILS_ERR,
    payload: {
        error: ''
    }
})

export const getDuplBioDetails = (codeName, callback) => async dispatch => {
    dispatch(clearDuplBioDetails())
    const dataQuery = JSON.stringify({
        CodeName: codeName
    })
    try {
        const res = await Axios.post(apiGetDuplBioDetails, dataQuery)
        const { data } = res;
        const isSuccess = data.Code === 0
        if (isSuccess) {
            dispatch({
                type: types.GET_DUPL_BIO_DETAILS,
                dupl_bio_details: data.Details
            })
        }

    }
    catch (err) {
        // console.log(err)
        const handleError400 = () => {
            const { Code } = err.response.data;
            const CODE_NOT_FOUND_QUERY = 3
            if (Code === CODE_NOT_FOUND_QUERY) {
                dispatch({
                    type: types.GET_DUPL_BIO_DETAILS_ERR,
                    payload: {
                        error: "Không tìm thấy thông tin khách hàng"
                    }
                })
                return true
            }
            return false
        }
        catchErrorApi(
            err,
            dispatch,
            types.GET_DUPL_BIO_DETAILS_ERR,
            handleError400
        )
    }
    callback()
}

