import * as types from "../../actions/types";

const initialState = {
  generalOfficeSelected: {},
  officeManagementList: [],
  success: false,
  err: ''
}

const getGeneralOfficeSelected = ({isReset = false, generalOfficeSelected = '', officeManagementList=[]}) => {
  if(officeManagementList.length===0){
    return ''
  }
  //---
  if(generalOfficeSelected==='' || isReset){
    return officeManagementList[0]
  }
  //---
  const officeIndex = officeManagementList.findIndex((element)=>element.name === generalOfficeSelected)
  if(officeIndex >= 0){
    return generalOfficeSelected
  }
  //---
  return officeManagementList[0]
}

export default function officeManagementReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_GENARAL_OFFICE_SELECTED:
      return {
        ...state,
        generalOfficeSelected: action.dataInfo
      }
    case types.GET_OFFICES_MANAGEMENT:
      return {
        ...state,
        success: action.success,
        officeManagementList: action.dataList,
        generalOfficeSelected: getGeneralOfficeSelected({
          isReset: action.isReset, 
          generalOfficeSelected: state.generalOfficeSelected, 
          officeManagementList: action.dataList
        }),
        err: ''
      }
    case types.GET_OFFICES_MANAGEMENT_ERR:
      return {
        ...state,
        err: action.err
      }
    default:
      return state
  }
}