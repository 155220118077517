/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DatePicker from 'react-date-picker'
import moment from 'moment'

import SingleChoiseDropDownControl from '../Control/SingleChoiceDropDownControl'
import { OFFINSUPERVISOR, OFFEXSUPERVISOR } from '../../../../data/userRole'
import { setSupervisorSingleChoise } from '../../../../data/singleChoiseData'
import { BioInfosType, NR } from '../../../../data/infosType'
import { getBioInfos } from '../../../../actions/supervisorAction/biometricAction'
import DropDownSearch from '../Control/DropDownSearch'
import { color } from '../../../../data/store'

const Bound = styled.div`
    padding: 0 0 0 20px;
    box-sizing: border-box;
    font-family: SVN-Gilroy;
    font-style: normal;
    height: 100%;

    .block-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 27px 20px 40px 0;
        background: #ffffff;

        .btn-reset,
        .btn-search {
            background: ${color.blue_005993};
            border-radius: 18px;
            padding: 10px 13px;
            box-sizing: border-box;
            text-transform: uppercase;
            color: #ffffff;
            font-weight: bold;
            cursor: pointer;
        }
        .btn-reset {
            margin: 0 12px 0 0;
        }
    }

    .main-scroll {
        overflow: auto;
        height: 89%;

        ::-webkit-scrollbar {
            width: 5px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #c4c4c4;
            border-radius: 5px;
        }
        .dateBefore {
            font-weight: bold;
            margin: 0 20px 30px 0;
            p {
                margin: 3px 0 0 0;
                font-size: 11px;
                text-transform: uppercase;
                color: ${color.blue_005993};
            }
        }
        .dateAfter {
            margin: 0 20px 0 0;
            p {
                margin: 3px 0 0 0%;
                font-size: 11px;
                text-transform: uppercase;
                color: ${color.blue_005993};
            }
        }

        .select-user {
            margin: 30px 20px 0 0;

            label {
                margin: 3px 0 0 0%;
                font-size: 11px;
                text-transform: uppercase;
                color: ${color.blue_005993};
                font-weight: bold;
            }
        }

        .dropdown-typeData {
            margin: 30px 20px 0 0;
            &.last {
                width: 100%;
                height: 55px;
                margin: 30px 20px 150px 0;
            }
        }
    }

    .date-picker {
        width: 100%;
        .react-date-picker__wrapper {
            width: 100%;
            height: 40px;
            border: 0.5px solid #d6d6d6;
            border-radius: 4px;
            .react-date-picker__inputGroup {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 128%;
                display: flex;
                align-items: center;
                color: #222222;
                margin: 0 0 0 9px;
            }
        }
        .react-calendar {
            width: 94.5%;
            position: absolute;
            right: 11px;
            top: 4px;
            border: 0.5px solid #bebebe;
            border-radius: 4px;
            max-width: unset;

            .react-calendar__tile--active {
                background: ${color.blue_005993};
            }
            .react-calendar__navigation {
                button:first-child,
                button:last-child {
                    display: none;
                }
                button:nth-child(3) {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #222222;
                }
            }
            .react-calendar__viewContainer {
                .react-calendar__month-view__weekdays {
                    display: none !important;
                }
                .react-calendar__month-view__days {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                }
            }
        }
    }
`
const dateFormat = 'dd/MM/yyyy'

const CustomerType = [
    {
        type: 'ALL',
        name: 'Tất cả',
    },
    {
        type: 'INCUST',
        name: 'KH Nội bộ',
    },
    {
        type: 'EXCUST',
        name: 'KH Thông thường',
    },
]

const statusData = [
    {
        type: 'ALL',
        name: 'Tất cả',
    },
    {
        type: 'PENDING',
        name: 'Chờ phê duyệt',
    },
    {
        type: 'ACCEPT',
        name: 'Đồng ý',
    },
    {
        type: 'REJECT',
        name: 'Từ chối',
    },
]

const ALL_VAL = 'Tất cả'
const AUTO_VAL = 'Auto'

function BiometricFilter({
    callBackGetSearch,
    valueCustNumOrName,
    callBackReset,
}) {
    const { userReducer, commonReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        commonReducer: state.commonReducer,
    }))

    const dispatch = useDispatch()
    const listFilterRef = useRef(null)
    const startDayref = useRef(null)
    const endDayref = useRef(null)

    const [listBioInfosType, setListBioInfosType] = useState([])
    const [dateBefore, setDateBefore] = useState(moment().startOf('days')._d)
    const [dateAfter, setDateAfter] = useState(moment().endOf('days')._d)
    const [idShow, setIdShow] = useState('')

    const [valueGDV, setValueGDV] = useState(ALL_VAL)
    const [valueKSV, setValueKSV] = useState(ALL_VAL)

    const [selectBioInfosType, setSelectBioInfosType] = useState(null)
    const [selectStatusType, setSelectStatusType] = useState(null)
    const [selectCustomerType, setSelectCustomerType] = useState(null)

    useEffect(() => {
        const calendars = document.querySelectorAll('.react-date-picker__inputGroup');
        calendars.forEach(item => {
            const inputs = item.querySelectorAll('input');
            inputs.forEach(input => input.setAttribute('disabled', 'disabled'))
        })
    }, [])

    useEffect(() => {
        let listBioInfosTypeEX = setSupervisorSingleChoise.bioInfosType
        listBioInfosTypeEX = listBioInfosTypeEX.map((item) => ({ ...item }))
        let listBioInfosTypeIN = listBioInfosTypeEX.filter(
            (item) => item.name !== BioInfosType[NR].label
        )
        let listBioInfosType = []

        const { Roles } = userReducer.dataLogin
        switch (true) {
            case Roles.includes(OFFEXSUPERVISOR):
                listBioInfosType = listBioInfosTypeEX
                break;
            default:
                listBioInfosType = listBioInfosTypeIN
                break;
        }

        setListBioInfosType(listBioInfosType)
    }, [userReducer])

    useEffect(() => {
        if (callBackGetSearch) _onSearch()
    }, [callBackGetSearch])

    const _getDate = (type, date) => {
        if (type === 'dateBefore') {
            setDateBefore(moment(date).startOf('days')._d)

            if (moment(date).add(30, 'days') > moment())
                setDateAfter(moment().endOf('days')._d)
            else setDateAfter(moment(date).add(30, 'days').endOf('days')._d)
        } else {
            setDateAfter(moment(date).endOf('days')._d)
        }
    }

    const _setIdShow = (id) => {
        setIdShow(id)
    }

    const _onReset = () => {
        callBackReset()
        setDateBefore(moment().startOf('days')._d)
        setDateAfter(moment().endOf('days')._d)

        setValueGDV(ALL_VAL)
        setValueKSV(ALL_VAL)

        setSelectBioInfosType(null)
        setSelectStatusType(null)
        setSelectCustomerType(null)

        const data = {
            CustNumOrName: '',
            OfficeID: userReducer.dataLogin.OfficeID,
            Status: 'PENDING',
            FromCreateAt: moment(moment().startOf('days')._d),
            ToCreateAt: moment().endOf('days').toISOString(),
            PageSize: 10,
            PageNumber: 1,
        }

        dispatch(getBioInfos(data))
    }
    const _onSearch = () => {

        const statusType =
            !selectStatusType/*  || selectStatusType.type === 'ALL' */
                ? 'PENDING'
                : selectStatusType.type === 'ALL'
                    ? ''
                    : selectStatusType.type

        const bioType =
            !selectBioInfosType || selectBioInfosType.type === 'ALL'
                ? ''
                : selectBioInfosType.type

        const customerType =
            !selectCustomerType || selectCustomerType.type === 'ALL'
                ? ''
                : selectCustomerType.type

        const data = {
            CustNumOrName: valueCustNumOrName ? valueCustNumOrName.trim() : '',
            OfficeID: userReducer.dataLogin.OfficeID,
            TellerName: valueGDV === ALL_VAL ? '' : valueGDV,
            SupervisorName: [ALL_VAL, AUTO_VAL].includes(valueKSV) ? '' : valueKSV,
            AutoReview: valueKSV === AUTO_VAL ? true : '',
            FromCreateAt: moment(dateBefore),
            ToCreateAt: moment(dateAfter),
            CustomerType: customerType,
            PageSize: 10,
            PageNumber: 1,
            Segment: undefined,
            Status: statusType,
            BioType: bioType,
        }

        dispatch(getBioInfos(data))
    }

    const selectTellers = (() => {
        const { Roles } = userReducer.dataLogin
        switch (true) {
            case Roles.includes(OFFINSUPERVISOR) && Roles.includes(OFFEXSUPERVISOR):
                return {
                    title: 'USER GDV/Điện toán',
                    lstItems: [...commonReducer.listTellers, ...commonReducer.listInbioCollectors]
                        .reduce((lst, item) => lst.includes(item) ? lst : lst.concat(item), [])
                        .sort((a, b) => a.localeCompare(b))
                }
            case Roles.includes(OFFEXSUPERVISOR):
                return {
                    title: 'USER GDV',
                    lstItems: commonReducer.listTellers
                }
            case Roles.includes(OFFINSUPERVISOR):
                return {
                    title: 'USER Điện toán',
                    lstItems: commonReducer.listInbioCollectors
                }
            default:
                return {
                    title: '',
                    lstItems: []
                }
        }
    })()

    const selectSupervisors = (() => {
        const { Roles } = userReducer.dataLogin
        switch (true) {
            case Roles.includes(OFFINSUPERVISOR) && Roles.includes(OFFEXSUPERVISOR):
                return {
                    title: 'USER KSV/Ban Giám Đốc',
                    lstItems: [...commonReducer.listInSupervisors, ...commonReducer.listExSupervisors]
                        .reduce((lst, item) => lst.includes(item) ? lst : lst.concat(item), [])
                        .sort((a, b) => a.localeCompare(b))
                }
            case Roles.includes(OFFEXSUPERVISOR):
                return {
                    title: 'USER KSV',
                    lstItems: commonReducer.listExSupervisors
                }
            case Roles.includes(OFFINSUPERVISOR):
                return {
                    title: 'USER Ban Giám Đốc',
                    lstItems: commonReducer.listInSupervisors
                }
            default:
                return {
                    title: '',
                    lstItems: []
                }
        }
    })()


    return (
        <Bound>
            <div className="block-btn">
                <div className="btn-reset" onClick={_onReset}>
                    Reset
                </div>
                <div className="btn-search" onClick={_onSearch}>
                    Tìm kiếm
                </div>
            </div>

            <div className="main-scroll" ref={listFilterRef}>
                <div className="dateBefore">
                    <p>Từ ngày</p>
                    <DatePicker
                        ref={startDayref}
                        className="date-picker"
                        onChange={(date) => _getDate('dateBefore', date)}
                        value={dateBefore}
                        format={dateFormat}
                        maxDate={moment().endOf('days')._d}
                        clearIcon={null}
                    />
                </div>
                <div className="dateAfter">
                    <p>Đến ngày</p>
                    <DatePicker
                        ref={endDayref}
                        className="date-picker"
                        onChange={(date) => _getDate('dateAfter', date)}
                        value={dateAfter}
                        format={dateFormat}
                        maxDate={
                            moment(dateBefore).add(30, 'days').endOf('days') >
                                moment().endOf('days')
                                ? moment().endOf('days')._d
                                : moment(dateBefore)
                                    .add(30, 'days')
                                    .endOf('days')._d
                        }
                        minDate={dateBefore}
                        clearIcon={null}
                    />
                </div>

                <div className="select-user">
                    <label>{selectTellers.title}</label>
                    <DropDownSearch
                        lstItemFixed={[ALL_VAL]}
                        lstItems={selectTellers.lstItems}
                        onChange={(val) => setValueGDV(val)}
                        defaultValue={valueGDV}
                        placeHolder="Nhập User"
                    />
                </div>

                <div className="select-user">
                    <label>{selectSupervisors.title}</label>
                    <DropDownSearch
                        lstItemFixed={[ALL_VAL, AUTO_VAL]}
                        lstItems={selectSupervisors.lstItems}
                        onChange={(val) => setValueKSV(val)}
                        defaultValue={valueKSV}
                        placeHolder="Nhập User"
                    />
                </div>

                <div className="dropdown-typeData">
                    <SingleChoiseDropDownControl
                        id="selectBioInfosType"
                        getText={(data) => setSelectBioInfosType(data)}
                        data={listBioInfosType}
                        title="loại dữ liệu"
                        titleChoise="Tất cả loại dữ liệu"
                        notSort={true}
                        idShow={idShow}
                        setIdShow={_setIdShow}
                        defaultValue={
                            !selectBioInfosType && 'Tất cả'
                        }
                    />
                </div>
                <div className="dropdown-typeData">
                    <SingleChoiseDropDownControl
                        id="selectStatusType"
                        getText={(data) => setSelectStatusType(data)}
                        data={statusData}
                        title="Trạng thái phê duyệt"
                        titleChoise="Chọn trạng thái dữ liệu"
                        idShow={idShow}
                        notSort={true}
                        setIdShow={_setIdShow}
                        defaultValue={!selectStatusType && 'Chờ phê duyệt'}
                    />
                </div>
                {
                    userReducer.dataLogin.Roles.includes(OFFINSUPERVISOR) &&
                    userReducer.dataLogin.Roles.includes(OFFEXSUPERVISOR) &&
                    <div className="dropdown-typeData">
                        <SingleChoiseDropDownControl
                            id="selectCustomerType"
                            getText={(data) => setSelectCustomerType(data)}
                            data={CustomerType}
                            title="loại khách hàng"
                            titleChoise="Chọn loại khách hàng"
                            notSort={true}
                            idShow={idShow}
                            setIdShow={_setIdShow}
                            defaultValue={!selectCustomerType && 'Tất cả'}
                        />
                    </div>
                }
                <div className="dropdown-typeData last"></div>
            </div>
        </Bound>
    )
}

export default React.memo(BiometricFilter)
