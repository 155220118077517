import { css } from 'styled-components'

const lstPxs = [90, 200, 90, 120, 150, 150, 150, 200, 200, 150, 150, 150, 150, 200, 160, 90, 90, 90, 90, 90, 90]
const sumPx = lstPxs.reduce((total, curr) => total + curr)
const gridArr = lstPxs.map(item => `minmax(${item}px, ${(item / sumPx).toPrecision(2)}fr)`)
// console.log("Report Detailed");
// console.log(gridArr);
const extendStylesTable = css`
    .header, .row {
        grid-template-columns: ${gridArr.join(" ")}
    }
`;

export {
    extendStylesTable
}