import styled from "styled-components";

export const ContainerStyled = styled.div`
    padding: 13px 13px 20px 13px;
    width: 454px;
    height: auto;
    background-color:#fafafa;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .row_header{
        display: flex;
        justify-content:space-between;
        align-items: center;
        .titleHeader{
            font-size: 20px;
            font-weight: bold;
            line-height: 1.1;
            color: #000000;
        }
        .ic_close {
            cursor: pointer;
        }
    }
    .row_main{
        /* padding: 0 7px; */
        margin: 20px 0 0 0;
        h4 {
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            text-transform:uppercase;
            margin: 16px 0 0 0;
        }
        .AllCounter{
            height: 44px;
        }
        
    }
    .div-alert {
        font-family: 'SVN-Gilroy';
        font-size: 13px;
        font-weight: 500;
        color: #d71249;
        margin-top: 7px;
        /*margin-bottom: 7px;*/
    }
    .row_flex{
        display: flex;
        flex-direction: row;
        align-items: center;
        .numberCounter{
            width:200px;
        }
      .checkBox_priority{
          margin: 5px 0 0 44px;
          .radio-field {
            display: flex;
            align-items: center;
            position: relative;
            input[type="checkbox"] {
              position: absolute;
              height: 0;
              width: 0;
              opacity:0;
            }        
            .checkmark {
              height: 14px;
              width: 14px;
              border: solid 0.8px #c8c8c8;
              background: #ffffff;
              border-radius: 4px;
            }
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
            input[type="checkbox"]:checked ~ .checkmark:after {
              display: block;
            }
            .checkmark::after {
              left: 5px;
              top: 1px;
              width: 4px;
              height: 8px;
              border: solid #d71249;
              border-width: 0 2px 2px 0;    
              transform: rotate(45deg);
            }
            .text_label {
              margin-left: 5px;
              margin-top: 1px;
              font-size: 14px;
              font-weight: 500;
              vertical-align: middle;
              color: #000000;
            }
          }
      }
    }
    .row_tablet{
        display: flex;
        flex-direction: column;
        width: 100%;
        .line_top {
            height: 1;
            width: calc(100% - 16px);
            padding-left: 8px;
            background-color: #E7E6E6;
        }
        .line_bottom {
            height: 1;
            width: 100%;
            background-color: #E7E6E6;
        }
        .title {
            color: var(--Gray, #636060);
            font-family: SVN-Gilroy;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 138.462% */
            letter-spacing: -0.26px;
            text-transform: uppercase;
        }
    }
    .row_btn{
      margin: 16px 0 0 0;
      display:flex;
      justify-content:flex-end;
      flex-direction: column;
      .guid-text{
        /* height: 36px; */
        width: 264px;
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.3;
        color: #787878;
      }
      .btn-add{
        display: flex;
        justify-content: flex-end;
      }
      .btn_success{
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
`