import styled from "styled-components";
import { color } from "../../../../../../../data/store";

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: 50px minmax(150px, 0.2fr) minmax(150px, 0.15fr) minmax(200px, 0.4fr) minmax(150px, 0.15fr) minmax(150px, 0.15fr) 80px;
    grid-gap: 8px; 
    width: 100%;
    height: ${props => props.isHeader ? 'auto' : '38px'};
    padding: ${prop => prop.isHeader ? '0px 0px 5px 0px' : '8px 0px;'};
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    margin-top: ${props => props.isHeader ? 0 : '5px'};
    box-shadow: ${props => props.isHeader ? 'none' : '0px 2px 13px rgba(0, 0, 0, 0.08)'};
    background-color: ${props => props.isHeader
        ? ''
        : props.selectedStatus
            ? props.selectedBgColor ?? 'white'
            : 'white'
    };
    border-radius: 4px;

    :hover{
        background-color: ${props => props.isHeader ? '' : '#DEF5FF'};
    }

    .column {
        font-size: ${props => props.isHeader ? '11px' : '14px'};
        font-weight: ${props => props.isHeader ? 600 : 500};
        color: ${props => props.isHeader ? color.blue_005993 : color.black19};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        text-transform: 'uppercase';

        &.lastIndex{
            display:flex;
            padding-left: 5px;
            justify-content: end;
            .icon{
                width:16px;
                height:16px;
                margin-right: 10px;
            }
        }

        &.buttonControl {
            text-align: right;
            .moreButton {
                cursor: pointer;
                margin-top: 10px;
            }
        }
        
    }
`

export { RowContainer }