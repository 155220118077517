import Axios from 'axios'

// constant
import * as types from '../types'
import { host } from '../../../../host'

// helper
import catchErrorApi from '../../../../helper/catchErrorApi'
import autoDownloadFromBlob from '../../../../helper/autoDownloadFromBlob'

// action
import { addLoading, removeLoading } from '../../../commonAction'
import ExportExcel from '../../../../ui/Main/MainScreen/Reports/ReportDetails/controls/Tools/ExportExcel'

const apiGetAdminRemoveBioReport = `${host}admin/exportJSON/admin-removeBio`

export const getAdminRemoveBioReport = ({
    FromDate,
    ToDate,
    ReportCodeName,
	AdminName
}) => async (dispatch) => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
		ExportExcel: false,
        ReportCodeName,
		AdminName
    })

    try {
        const res = await Axios.post(apiGetAdminRemoveBioReport, dataRequest)
        const { data } = res
        dispatch({
            type: types.GET_ADMIN_BIO_REPORT,
            payload: {
                condFilter: {
                    FromDate,
                    ToDate,
                    ReportCodeName,
                    AdminName,
                },
                listData: data.Data || [],
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(
            err,
            dispatch,
            types.GET_ADMIN_BIO_REPORT_ERROR,
            () => false
        )
    }
}

export const exportAdminRemoveBioReport = ({
    FromDate,
    ToDate,
    ReportCodeName,
    AdminName,
}) => async (dispatch) => {
    //if (!BranchCodes) return

    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        ExportExcel: true,
        ReportCodeName,
        AdminName,
    })

    try {
        const res = await Axios.post(apiGetAdminRemoveBioReport, dataRequest, {
            responseType: 'blob',
        })
        dispatch(removeLoading())
        const { data } = res
		autoDownloadFromBlob(data, ReportCodeName)
        
    } catch (err) {
        dispatch(removeLoading())
        console.log(err)
    }
}
