import React, { useState } from 'react'
import ic_close from "../../../../../../images/ic_close_gray.svg"
import ButtonControl from '../../../Control/ButtonControl'
import { WrapContent, WrapOverlay } from './index.styles'
import { WrapContainer } from '../index.styles'
import SingleChoiceDropDownControl from '../../../Control/SingleChoiceDropDownControl'
import InputTextControlV2 from '../../../Control/InputTextControlV2'
import { color } from '../../../../../../data/store'
import TextAreaControlV2 from '../../../Control/TextAreaControlV2'

export const AdviseServicePopupType = {
    ADD: 1,
    UPDATE: 2,
}
export default function AdviseServicePopup({type, dataConfig, dataInfo, onAddAdviseService, onUpdateAdviseService, onClose}) {
    const renderStatusList = () => {
        let statusList = []
        dataConfig.statusList.forEach(element => {
            statusList.push({
                code: element.code,
                name: element.value
            })
        });
        return statusList
    }

    const statusList = renderStatusList();

    const getAdviseServiceStatus = (statusCode) => {
        let status = statusList.find(
            (item) => item.code === statusCode
        )
        if(status === undefined){
            status = statusList[1]
        }
        return status
    }

    //---
    const [idShow, setIdShow] = useState('')
    const [adviseServiceName, setAdviseServiceName] = useState(dataInfo?dataInfo.name:'')
    const [adviseServiceType, setAdviseServiceType] = useState(dataInfo?dataInfo.type:'')
    const [adviseServiceStatus, setAdviseServiceStatus] = useState(dataInfo?getAdviseServiceStatus(dataInfo.status):statusList[1])
    const [adviseServiceDescript, setAdviseServiceDescript] = useState(dataInfo?dataInfo.descript:'')
    
    //---
    const checkInvalidData = ({
        isUpdateConfirmBtStatus= true,
        nameValue= adviseServiceName,
        typeValue= adviseServiceType,
        descriptValue= adviseServiceDescript
    }) => {
        let isInvalid = false
        if( nameValue === "" || descriptValue === "" || typeValue === ""){
                isInvalid = true
            }
        if(isUpdateConfirmBtStatus && isDisableConfirmBt !== isInvalid) {
            setIsDisableConfirmBt(isInvalid)
        }
        //---
        return isInvalid
    }
    const [isDisableConfirmBt, setIsDisableConfirmBt] = useState(checkInvalidData({
        isUpdateConfirmBtStatus: false
    }))
    //---
    const onAddAdviseServiceAction = () => {
        onAddAdviseService && onAddAdviseService({
            name: adviseServiceName,
            type: adviseServiceType,
            descript: adviseServiceDescript,
            status: adviseServiceStatus.code
        })
    }

    const onUpdateAdviseServiceAction = () => {
        onUpdateAdviseService && onUpdateAdviseService({
            id: dataInfo.id,
            name: adviseServiceName,
            type: adviseServiceType,
            descript: adviseServiceDescript,
            status: adviseServiceStatus.code
        })
    }

    const onCloceBt = () => {
        onClose && onClose()
    }

    const onAdviseServiceNameChanged = (value) => {
        console.log(`adviseServiceName: ${value}`);
        setAdviseServiceName(value)
        //---
        checkInvalidData({
            nameValue: value
        })
    }

    const onAdviseServiceTypeChanged = (value) => {
        console.log(`adviseServiceType: ${value}`);
        setAdviseServiceType(value)
        //---
        checkInvalidData({
            typeValue: value
        })
    }

    const onAdviseServiceStatusChanged = (value) => {
        console.log(`adviseServiceStatus: ${value.code} - ${value.name}`);
        setAdviseServiceStatus(value)
    }

    const onAdviseServiceDescriptChanged = (value) => {
        console.log(`adviseServiceDescript: ${value}`);
        setAdviseServiceDescript(value)
        //---
        checkInvalidData({
            descriptValue: value
        })
    }

    const renderHeaderContent = () => {
        return (
            <div className="header">
                <div className="title-content">
                    <div className="label">
                        {
                            type===AdviseServicePopupType.ADD
                            ? 'Thêm mới dịch vụ tư vấn'
                            : 'Tuỳ chỉnh dịch vụ tư vấn'
                        }
                    </div>
                    <div className="btn-container">
                        <img className="icon" src={ic_close} alt='ic_close' onClick={onCloceBt}/>
                    </div>
                </div>
            </div>
        )
    }

    const renderBodyContent = (dataList) => {
        return (
            <div className="body">
                <div className='row first'>
                    <InputTextControlV2
                        isRequired={true}
                        marginBottom={0}
                        borderWidth={0}
                        backgroundColor={color.whiteF7}
                        title='TÊN DỊCH VỤ'
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        onChange={onAdviseServiceNameChanged}
                        placeHolder='Nhập tên dịch vụ'
                        maxLength={50}
                        isDisabled={false}
                        defaultValue={adviseServiceName}
                    />
                </div>
                <div className='row'>
                    <InputTextControlV2
                        isRequired={true}
                        marginBottom={0}
                        borderWidth={0}
                        backgroundColor={color.whiteF7}
                        title='MÃ DỊCH VỤ'
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        onChange={onAdviseServiceTypeChanged}
                        placeHolder='Nhập mã dịch vụ'
                        maxLength={50}
                        isDisabled={false}
                        defaultValue={adviseServiceType}
                    />
                </div>
                <div className="row">
                    <SingleChoiceDropDownControl
                        heightControl={42}
                        setWidth='100%'
                        borderWidth={0}
                        backgroundColor={color.whiteF7}
                        isRequired={true}
                        title="TRẠNG THÁI"
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        id="selectStatus"
                        idShow={idShow}
                        setIdShow={setIdShow}
                        getText={onAdviseServiceStatusChanged}
                        data={statusList}
                        defaultValue={adviseServiceStatus.name}
                    />
                </div>
                <div className="row">
                    <TextAreaControlV2
                        isRequired={true}
                        backgroundColor={color.whiteF7}
                        borderWidth={0}
                        title='MÔ TẢ DỊCH VỤ'
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        onChange={onAdviseServiceDescriptChanged}
                        placeHolder='Nhập mô tả dịch vụ'
                        maxLength={255}
                        defaultValue={adviseServiceDescript}
                    />
                </div>
            </div>
        )
    }

    const rendFooterContent = () => {
        return (
            <div className="footer">
                <div className="btn-content">
                    <ButtonControl
                        isDisable={isDisableConfirmBt}
                        className="btn-content"
                        content={
                            type === AdviseServicePopupType.ADD
                            ? "Xác nhận"
                            : "Lưu"
                        }
                        widthButton={"97px"}
                        heightButton={"33px"}
                        fontSize={"14px"}
                        fontWeight={700}
                        onClick={
                            type === AdviseServicePopupType.ADD
                            ? onAddAdviseServiceAction
                            : onUpdateAdviseServiceAction
                        }
                    />
                </div>
            </div>
        )
    }

    return (
        <WrapOverlay>
        <WrapContainer>
          <WrapContent>
            {renderHeaderContent()}
            {renderBodyContent()}
            {rendFooterContent()}
          </WrapContent>
        </WrapContainer>
      </WrapOverlay>
    )
}
