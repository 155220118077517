import React, { Component } from "react";
import styled from "styled-components";
import icon from "./../../../../images/ic_add_white.svg";
import { color } from "../../../../data/store";

const Bound = styled.div`
  display: flex;
  align-items: center;
  .add {
    padding: ${props => props.isActive ? '0 20px 0 20px' : '0 20px 0 10px'};
    height: 36px;
    border-radius: 18px;
    background-color: ${props => props.bg_color || color.blue_005993};
    border: none;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: ${props => props.cursor || "pointer"};
    text-transform: uppercase;
    outline: none;
    font-family: SVN-Gilroy;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    .text{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default class AddMore extends Component {
  render() {
    const { onSubmit, isActive } = this.props;
    return (
      <Bound
        cursor={this.props.cursor}
        bg_color={this.props.bg_color}
        isActive = {isActive}
      >
        <button
          type="button"
          className="add"
          onClick={onSubmit ? onSubmit : () => { }}
        >
        {
          !isActive && <img src={icon} alt="add" />
        }
          <p className='text'>
            {this.props.namebtn}
          </p>
        </button>
      </Bound>
    );
  }
}
