import React from 'react'

// styles
import { ScrollTable, WrapRow, WrapTable } from './index.styles'

const valuesValidRow = ['string', 'number']

export const Row = ({ data }) => (
    <WrapRow className="row">
        {data.map((item, key) => (
            <div
                key={key}
                title={valuesValidRow.includes(typeof item) ? item : ''}
            >
                {valuesValidRow.includes(typeof item) ? (
                    <div>
                        {
                            (typeof item) === 'string'
                            ? item.split("\n").map((i,key) => {
                                    return <div className='value' key={key}>{i}</div>;
                              })
                            : <div className='value'>{item}</div>
                        }
                    </div>
                ) : (
                    item
                )}
            </div>
        ))}
    </WrapRow>
)

const Table = ({
    lstTitleCols = [],
    lstData = [],
    extendStyles,
    numOfRow = 8,
    headerHeight = 0
}) => {
    return (
        <ScrollTable>
            <WrapTable extendStyles={extendStyles} numOfRow={numOfRow} headerHeight={headerHeight}>
                <div className="header">
                    {lstTitleCols.map((item, key) => (
                        <div key={key}>{item}</div>
                    ))}
                </div>
                <div className="body">
                    {/* <div className="wrapper"> */}
                    {lstData.map((item, key) => (
                        <Row data={item} key={key} />
                    ))}
                    {/* </div> */}
                </div>
            </WrapTable>
        </ScrollTable>
    )
}

export default Table
