import React, { Component } from 'react'
import styled from "styled-components";
// import { connect } from 'react-redux';

//container
import BlockNameControl from "./BlockNameControl";
import AddMore from "./AddMore";
import SearchControl from './SearchControl';
import SortControl from './SortControl';
import ButtonControl from './ButtonControl';
import ic_upload from '../../../../images/ic_upload.svg'
import ic_save from '../../../../images/ic_save.svg'


const Bound = styled.div`
    display:flex;
    margin-bottom:7px;
    margin-left: ${ prop => prop.horizontalMargin ?? 0}px;
    margin-right: ${ prop => prop.horizontalMargin ?? 0}px;
    height:70px;
    flex-direction:row;
    justify-content:space-between;
    .block-left{
        display:flex;
        align-items:center;
    }
    .counter-remain{
        margin-right: 30px;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        span{
            font-size: 14px;
            font-weight: bold;
            color: #d71249;
        }
    }
    .add{
        margin-right: 7px;
    }
`

export default class SubHeaderControl extends Component {
    state = {
        isDisableSaveInfoBt: this.props.isDisableSaveInfoBt
    }

    onSubmit = () => {
        this.props.onAddMoreSubmit();
    }

    onReset = () => {
        this.props.onReset()
    }

    onUploadFile = () => {
        this.props.onUploadFile()
    }

    onSaveInfo = () => {
        if(this.state.isDisableSaveInfoBt){
            return
        }
        //---
        this.props.onSaveInfo()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isDisableSaveInfoBt !== this.props.isDisableSaveInfoBt) {
            this.setState({ 
                isDisableSaveInfoBt: this.props.isDisableSaveInfoBt
             })
        }
    }

    render() {
        const { data, horizontalMargin } = this.props;
        return (
            <Bound horizontalMargin={horizontalMargin}>
                <BlockNameControl
                    mainname={data.mainTitle}
                    subname={data.subTitle}
                />
                <div className="block-left">
                    {
                        data.buttonArr.search &&
                        <SearchControl
                            textSearch={this.props.textSearch}
                            typeSearch = {this.props.typeSearch}
                            total={this.props.total}
                            ref={ref => this.search = ref}
                            searchFilter={this.props.searchFilter}
                            inputname={data.buttonArr.search.placeHolder} />
                    }
                    {
                        Object.keys(this.props).includes('isShowFilter')
                            ? this.props.isShowFilter
                                ? <SortControl
                                    refSearch={this.search}
                                    toggleFilter={() => { this.props.toggleFilter(this.search) }}
                                />
                                : null
                            : data.buttonArr.filter &&
                            <SortControl
                                refSearch={this.search}
                                toggleFilter={() => { this.props.toggleFilter(this.search) }}
                            />
                    }
                    {
                        Object.keys(this.props).includes("counterRemain") ?
                            <div className="counter-remain">
                                Số quầy còn lại: <span>{`0${this.props.counterRemain}`.slice(-2)}</span>
                            </div>
                            : null
                    }
                    {
                        //!isActives && data.buttonArr.search &&
                        data.buttonArr.search &&
                        <AddMore
                            namebtn={data.resetButton}
                            isActive= {true}
                            onSubmit = {this.onReset}/>
                    }

                    {
                        this.props.isAdd === true ?
                            null
                            :
                            data.buttonArr.addMore &&
                            <AddMore
                                namebtn={data.buttonArr.addMore.title}
                                onSubmit={this.onSubmit} />
                    }
                    {
                        data.buttonArr.uploadFile &&
                        <ButtonControl 
                            widthButton={"100px"}
                            content="TẢI FILE"
                            icon={ic_upload}
                            onClick={this.onUploadFile}
                        />
                    }
                     {
                        data.buttonArr.saveInfo &&
                        <ButtonControl
                            isDisable={this.state.isDisableSaveInfoBt} 
                            widthButton={"100px"}
                            content="LƯU LẠI"
                            icon={ic_save}
                            onClick={this.onSaveInfo}
                        />
                    }
                </div>
            </Bound>
        )
    }

}