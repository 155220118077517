import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// Image
import ic_close from '../../../images/ic_close_gray.svg'
// Component
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiceDropDownControl'
import LoadingControl from '../MainScreen/Control/LoadingControl'
// Data
import { setSupervisorSingleChoise } from '../../../data/singleChoiseData'
import {
    PENDING,
    VerifyCustomer,
    VERIFYSUCCESSFULLY,
    SEEN,
    VERIFYUNSUCCESSFULLY,
    NOTVERIFY,
} from '../../../data/dataVerifyCustomer'
//Action
import { getListVerifyFingerHistory } from '../../../actions/supervisorAction/verifyCustomerAction'
import DatePicker from 'react-date-picker'
import moment from 'moment'

const Bound = styled.div`
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    font-family: 'SVN-Gilroy';
    .loading {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 108px 248px 0 0;
        p {
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #787878;
            padding-left: 6px;
        }
    }
    .popup-filter-bound {
        display: flex;
        flex-direction: column;
        width: 200px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 80px - 80px - 83px);
        background-color: white;
        position: absolute;
        bottom: 0;
        right: -240px;
        transition: right 0.5s;
        align-items: center;
        .div_check {
            width: 200px;
            margin: 0 0 20px 0;
            p {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 126%;
                text-transform: uppercase;
                color: #005993;
                margin: 0 0 5px 0;
            }
        }
        .div_header {
            width: 100%;
            margin: 0 0 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                width: 64px;
                height: 36px;
                border-radius: 18px;
                background-color: #005993;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                cursor: pointer;
            }
            .close-btn {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    .date-picker {
        width: 100%;
        .react-date-picker__wrapper {
            width: 100%;
            border: 0.5px solid #d6d6d6;
            border-radius: 4px;
            .react-date-picker__inputGroup {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 128%;
                display: flex;
                align-items: center;
                color: #222222;
                margin: 0 0 0 9px;
            }
        }
        .react-calendar {
            width: 224px;
            position: absolute;
            right: 0;
            top: 4px;
            border: 0.5px solid #d6d6d6;
            border-radius: 4px;

            .react-calendar__tile--active {
                background: #005993;
                border-radius: 50%;
            }
            .react-calendar__navigation {
                button:first-child,
                button:last-child {
                    display: none;
                }
                button:nth-child(3) {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 15px;
                    display: flex;
                    justify-content: center;
                    color: #222222;
                }
            }
            .react-calendar__viewContainer {
                .react-calendar__month-view__weekdays {
                    display: none !important;
                }
                .react-calendar__month-view__days {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                }
            }
        }
    }
`

const dateFormat = 'dd-MM-y'
class PopupVerifyCustomerFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idShow: '',
            DataStatus: '',
            CustType: '',
            VerifyStatus: '',
            data: setSupervisorSingleChoise,
            isLoading: false,
            types: '',
            date: new Date(),
        }
    }

    isMouseEnter = false

    getDataStatus = (type) => (text) => {
        this.setState({ [type]: text })
    }

    getCustType = (type) => (text) => {
        this.setState({ [type]: text })
    }

    getVerifyCustomer = (type) => (text) => {
        this.setState({ [type]: text })
    }

    setIdShow = (id) => {
        this.setState({ idShow: id })
    }

    getRangeDate = (date) => {
        this.setState({
            date,
        })
    }

    onSubmit = async () => {
        this.setState({
            isLoading: true,
        })
        const { DataStatus, VerifyStatus, date } = this.state
        let dataFilter = {}

        if (date) {
            let m = moment(this.state.date)
            // let m = moment(this.state.date).utcOffset(0);
            m.set({ hour: 0, minute: 0, second: 0 })
            // m.toISOString()
            dataFilter['Dates'] = date
            dataFilter.Dates = m.format()
            // console.log(dataFilter.Dates)
        }

        if (VerifyStatus) {
            dataFilter['VerifyStatus'] = VerifyStatus.name
            if (
                VerifyStatus.name === VerifyCustomer[VERIFYUNSUCCESSFULLY].label
            ) {
                dataFilter.VerifyStatus =
                    VerifyCustomer[VERIFYUNSUCCESSFULLY].name
            } else if (
                VerifyStatus.name === VerifyCustomer[VERIFYSUCCESSFULLY].label
            ) {
                dataFilter.VerifyStatus =
                    VerifyCustomer[VERIFYSUCCESSFULLY].name
            } else {
                dataFilter.VerifyStatus = VerifyCustomer[NOTVERIFY].name
            }
        }

        // if(CustType){
        //     dataFilter['CustType'] = CustType.name
        //     if(CustType.name === 'CN1'){
        //         dataFilter.CustType = 'CN1'
        //     }else if(CustType.name === 'CN2'){
        //         dataFilter.CustType = 'CN2'
        //     }else if(CustType.name === 'CN3'){
        //         dataFilter.CustType = 'CN3'
        //     }else if(CustType.name === 'CN4'){
        //         dataFilter.CustType = 'CN4'
        //     }else if(CustType.name === 'CN5'){
        //         dataFilter.CustType = 'CN5'
        //     }else if(CustType.name === 'CN6'){
        //         dataFilter.CustType = 'CN6'
        //     }
        // }

        if (DataStatus) {
            dataFilter['DataStatus'] = DataStatus.name
            if (DataStatus.name === VerifyCustomer[SEEN].label) {
                dataFilter.DataStatus = VerifyCustomer[SEEN].name
            } else if (DataStatus.name === VerifyCustomer[PENDING].label) {
                dataFilter.DataStatus = VerifyCustomer[PENDING].name
            }
        }
        if (Object.keys(dataFilter).length !== 0) {
            // if(this.state.date) {
            this.props.getListVerifyFingerHistory({
                // CustomerSegment: dataFilter.CustType,
                DataStatus: this.props.verifyCustomerReducer
                    .lastConditionFilterVerifyCustomer.DataStatus,
                VerifyStatus: dataFilter.VerifyStatus,
                PageSize: 10,
                PageNumber: 1,
                LessthanCreateAt: dataFilter.Dates,
                // LessthanCreateAt: m.format()
            })
            this.props.onClose()
        } else {
            this.props.onClose()
        }
    }

    render() {
        const { data } = this.state

        let listVerifyStatus = data.verifyStatus
        listVerifyStatus = listVerifyStatus.map((item) => ({
            name: item.name,
        }))

        const VerifyStatus = this.getVerifyCustomer('VerifyStatus')

        return (
            <Bound
                onMouseDown={() => {
                    if (!this.isMouseEnter) {
                        this.props.onClose()
                    }
                }}
            >
                {this.state.isLoading && (
                    <div className="loading">
                        <LoadingControl loadingPage={false} size="24px" />
                        <p>Đang tải...</p>
                    </div>
                )}
                <div
                    className="popup-filter-bound"
                    id="filter_verify_customer_id"
                    onMouseEnter={() => {
                        this.isMouseEnter = true
                    }}
                    onMouseLeave={() => {
                        this.isMouseEnter = false
                    }}
                >
                    <div className="div_header">
                        <img
                            src={ic_close}
                            alt="close"
                            className="close-btn"
                            onClick={() => {
                                this.props.onClose()
                            }}
                        />
                        <div onClick={() => this.onSubmit()} className="title">
                            FILTER
                        </div>
                    </div>
                    <div className="div_check">
                        <p>Ngày</p>
                        <DatePicker
                            className="date-picker"
                            onChange={this.getRangeDate}
                            value={this.state.date}
                            format={dateFormat}
                            clearIcon={false}
                        />
                    </div>
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectDataType"
                            getText={VerifyStatus}
                            data={listVerifyStatus}
                            title="TRẠNG THÁI XÁC THỰC"
                            titleChoise="Chọn trạng thái"
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}
                        />
                    </div>
                    {/* {
                            (this.state.VerifyStatus.name === VerifyCustomer[VERIFYUNSUCCESSFULLY].label || this.state.VerifyStatus.name === VerifyCustomer[NOTVERIFY].label) &&
                            <div className="div_check">
                                <SingleChoiseDropDownControl
                                    id="selectDataStatus"
                                    getText={DataStatus}
                                    data={listStatus}
                                    title='trạng thái xem'
                                    titleChoise='Chọn trạng thái xem'                            
                                    idShow={this.state.idShow}
                                    setIdShow={this.setIdShow}/>
                            </div>
                        } */}

                    {/* <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectCustomer"                            
                            getText={CustType}
                            data={listCustType}
                            title='loại khách hàng'
                            titleChoise='Tất cả khách hàng'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div>  */}
                </div>
            </Bound>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        verifyCustomerReducer: state.verifyCustomerReducer,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getListVerifyFingerHistory: (dataQuery) => {
            dispatch(getListVerifyFingerHistory(dataQuery))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: null,
})(PopupVerifyCustomerFilter)
