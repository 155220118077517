import React, { useState } from 'react'
import { DropDownContainer } from './index.styles'
import DropdownItem from './DropdownItem'

export default function DropdownControl({id, index, dataInfo, menuList,onItemSelected, onSubItemSelected, onShowOptionPopup}) {
    const [isShowMenu, setIsShowMenu] = useState(false)
    const onShowHideMenuList = () => {
        if(menuList && menuList.length > 0){
            setIsShowMenu(!isShowMenu)
        }
    }
    //---
    const onItemSelectedAction = (index, dataInfo) => {
        console.log(`onItemDoubleClick: Root - ${index} - ${dataInfo.Name}`);
        //----
        onItemSelected && onItemSelected(dataInfo)
    }

    const onSubItemDoubleClick = (index, menuItem) => {
        console.log(`onSubItemDoubleClick: ${index} - ${menuItem.Name}`);
        //----
        onSubItemSelected && onSubItemSelected(menuItem)
    }

    const onShowOptionPopupAction = (index, menuItem) => {
        console.log(`onShowOptionPopup: ${index} - ${menuItem.Name}`);
        //----
        onShowOptionPopup && onShowOptionPopup(index, menuItem)
    }
    return (
        <DropDownContainer id={id} isShowMenu={isShowMenu} numberOfItems={menuList.length}>
            <DropdownItem
                index = {index}
                isRoot = {true}
                dataInfo = {dataInfo}
                onClick = {onShowHideMenuList}
                onShowTransPopup={onItemSelectedAction}
            />
            {
                menuList &&
                <div className="menuContent">
                   {
                    menuList.map((menuItem, index) => {
                        return  <DropdownItem
                                    key={`dropdownItem_${index}`}
                                    index = {index}
                                    isRoot = {false}
                                    dataInfo = {menuItem}
                                    onDoubleClick = {onSubItemDoubleClick}
                                    onShowOptionPopup = {onShowOptionPopupAction}
                                />
                    })
                   }
                </div>
            }
        </DropDownContainer>
    )
}
