import React, { useEffect, useState } from 'react'
import { RowContainer } from './index.styles'
import ic_error from '../../../../../../../images/ic_error_v3.svg'
import ic_success from '../../../../../../../images/ic_success.svg'
import { Tooltip } from '@mui/material'

export default function ConfigureByBranchItem({ isHeader, index, dataInfo, isSelected, selectedBgColor, isUploadFilePopup, onItemSelected, isDisableName }) {
  const [selectedStatus, setSelectedStatus] = useState(isSelected)
  //---
  const renderHeader = () => {
    return (
      <RowContainer isUploadFilePopup={isUploadFilePopup} isHeader={true}>
        <div className="column">STT</div>
        <div className="column">MÃ ĐGD</div>
        {
          !isUploadFilePopup &&
          <div className="column">Tên CN/PGD</div>
        }
        <div className="column time">08:00-08:30</div>
        <div className="column time">08:30-09:00</div>
        <div className="column time">09:00-09:30</div>
        <div className="column time">09:30-10:00</div>
        <div className="column time">10:00-10:30</div>
        <div className="column time">10:30-11:00</div>
        <div className="column time">13:30-14:00</div>
        <div className="column time">14:00-14:30</div>
        <div className="column time">14:30-15:00</div>
        <div className="column time">15:00-15:30</div>
        <div className="column time">15:30-16:00</div>
        {
          isUploadFilePopup &&
          <div className="column time" />
        }
      </RowContainer>
    )
  }

  /*
    branchCode: string,
    name: string,
    time01: int,
    time02: int,
    time03: int, 
    time04: int,
    time05: int,
    time06: int,
    time07: int,
    time08: int,
    time09: int,
    time10: int,
    time11: int, 
   */

  const renderItem = (index, dataInfo) => {
    const onClickAction = () => {
      onItemSelected && onItemSelected(index, dataInfo)
      //---
      setSelectedStatus(true)
    }
    const indexValue = index < 10 ? `0${index}` : index
    return (
      <RowContainer selectedStatus={selectedStatus} selectedBgColor={selectedBgColor} isUploadFilePopup={isUploadFilePopup} isHeader={false} onClick={onClickAction}>
        <div className="column">{indexValue}</div>

        <Tooltip title={dataInfo.branchCode}>
          <div className="column">{dataInfo.branchCode}</div>
        </Tooltip>
        {
          !isUploadFilePopup &&
          <Tooltip title={dataInfo.name}>
            <div className="column ">{dataInfo.name}</div>
          </Tooltip>
        }

        <div className="column time">{dataInfo.time01}</div>
        <div className="column time">{dataInfo.time02}</div>
        <div className="column time">{dataInfo.time03}</div>
        <div className="column time">{dataInfo.time04}</div>
        <div className="column time">{dataInfo.time05}</div>
        <div className="column time">{dataInfo.time06}</div>
        <div className="column time">{dataInfo.time07}</div>
        <div className="column time">{dataInfo.time08}</div>
        <div className="column time">{dataInfo.time09}</div>
        <div className="column time">{dataInfo.time10}</div>
        <div className="column time">{dataInfo.time11}</div>
        {
          isUploadFilePopup &&
          <div className="column time lastIndex">
            {
              dataInfo.errorList && dataInfo.errorList.length > 0
                ? <img className='icon' src={ic_error} alt="" />
                : <img className='icon' src={ic_success} alt="" />
            }
          </div>
        }
      </RowContainer>
    )
  }

  useEffect(() => {
    setSelectedStatus(isSelected)
  }, [isSelected])

  return (
    isHeader
      ? renderHeader()
      : renderItem(index, dataInfo)
  )
}
