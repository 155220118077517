/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { css } from 'styled-components'

// component
import DropDownV2 from '../DropDownV2'

// styles
import { extendStylesDropdownOfficeV2 } from './index.styles'

// action
import {
    getAllOfficeV2
} from '../../../../../../../../actions/officeAction'

import {officeUtils} from '../../../../../../../Utils/OfficeUtils'

// hooks
import useDidMount from '../../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../../data/userRole'
import { MAIN_OFFICE_BRANCH_CODE } from '../../../../../../../../data/commonConstant'
import strToStrNoAccent from '../../../../../../../../helper/strToStrNoAccent'


const allOffice = {
    key: 'ALL',
    value: 'Tất cả chi nhánh',
}

const SelectOfficeV2 = ({ defaultValue, onChange, extendStyles }) => {
    const dispatch = useDispatch()

    const { userReducer, officeList, allOfficeList, officeManagementReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        officceList: state.filterOfficeReducer.officceList,
        allOfficeList: state.filterOfficeReducer.allOfficeList,
        officeManagementReducer: state.officeManagementReducer
    }))

    const [lstOffices, setLstOffices] = useState([])
    const [officeSelected, setOfficeSelected] = useState(defaultValue || [])

    const officeListRef = useRef([])

    const _handleOnChange = useCallback((arr) => {
        const isAllInArr = arr.some((item) => item.key === allOffice.key)
        if (isAllInArr) {
            const allOfficeSubmit = {
                key: [],
                value: 'Tất cả',
            }
            setOfficeSelected([allOfficeSubmit])
            onChange([allOfficeSubmit])
            return
        }

        setOfficeSelected(arr)
        onChange(arr)
    }, [onChange])

    const _mapOfficeOfUserNotAdminTSC = useCallback(() => {
        const { OfficeName, OfficeID, BranchCode } = userReducer.dataLogin
        if (!OfficeName || !OfficeID) return

        const office = {
            key: OfficeID,
            value: `${BranchCode} - ${OfficeName}`,
            BranchCode,
        }
        officeListRef.current = [office]
        //---
        setLstOffices([office])
        setOfficeSelected([office])
    }, [userReducer.dataLogin, setLstOffices, setOfficeSelected])

    const _mapOfficeOfUserAdminTSC = useCallback(() => {
        const officeManagementList = officeUtils.getOfficeList({
            dataLogin: userReducer.dataLogin,
            officeManagementList: officeManagementReducer.officeManagementList
        })
        //---
        let listOfficeSearch = officeManagementList.length > 0
                                ? officeManagementList
                                : officeList && officeList.length > 0 
                                    ? officeList 
                                    : allOfficeList
        const lstOffices = listOfficeSearch.map((item) => ({
            ...item,
            key: item.ID,
            value: `${item.BranchCode} - ${item.Name}`,
        }))
        //---
        officeListRef.current = [...lstOffices]
        //---
        setLstOffices([...lstOffices])
        setOfficeSelected(null)
    }, [allOfficeList, officeList, officeManagementReducer.officeManagementList, userReducer.dataLogin])

    const _handleSearch = (keySearch) => {
        // dispatch(filterOffice({ name: keySearch }, 1000000, 1))
        let newListOffice = []
        const value = strToStrNoAccent(keySearch).toLowerCase()
        if(value.length===0){
            setLstOffices(officeListRef.current)
        } else{
            newListOffice = officeListRef.current.filter(
                item=>(item.BranchCode.toLowerCase().indexOf(value) >= 0 || 
                strToStrNoAccent(item.Name).toLowerCase().indexOf(value) >= 0)
            )
            
            setLstOffices(newListOffice.map((item) => ({
                ...item,
                key: item.ID,
                value: `${item.BranchCode} - ${item.Name}`,
            })))
        }
        
    }

    useDidMount(() => {
        if (!userReducer.dataLogin) return false

        const { Roles, BranchCode } = userReducer.dataLogin
        if (!Roles.includes(ADMIN_ROLE) && BranchCode !== MAIN_OFFICE_BRANCH_CODE) return true

        dispatch(getAllOfficeV2())
    }, [userReducer.dataLogin])

    useEffect(() => {
        if (!userReducer.dataLogin) return
        //---
        updateListOffice()
       
    }, [
        userReducer.dataLogin,
        _mapOfficeOfUserNotAdminTSC,
        _mapOfficeOfUserAdminTSC,
    ])

    const updateListOffice = () => {
        const isBranchOfficeType = officeUtils.isBranchOfficeType({
            dataLogin: userReducer.dataLogin
        })
        //---
        if(isBranchOfficeType){
            _mapOfficeOfUserAdminTSC()
        } else {
            const { Roles, BranchCode } = userReducer.dataLogin
            if (!Roles.includes(ADMIN_ROLE) && BranchCode !== MAIN_OFFICE_BRANCH_CODE) {
                _mapOfficeOfUserNotAdminTSC()
                return
            }
            _mapOfficeOfUserAdminTSC()
        }
    }

    const isAdminTSC = useMemo(
        () => userReducer.dataLogin?.Roles?.includes(ADMIN_ROLE),
        [userReducer.dataLogin]
    )

    const isMainOffice = userReducer.dataLogin?.BranchCode === MAIN_OFFICE_BRANCH_CODE

    const isBranchOffice = officeUtils.isBranchOfficeType({
        dataLogin: userReducer.dataLogin
    })
    return (
        <DropDownV2
            lstItemFixed={isAdminTSC || isMainOffice ?  [allOffice] : []}
            lstItems={lstOffices}
            defaultValue={isAdminTSC || isMainOffice || isBranchOffice ? undefined : officeSelected}
            onChange={_handleOnChange}
            onSearch={_handleSearch}
            haveSearch={isAdminTSC || isMainOffice ? true : false}
            extendStyles={css`${extendStylesDropdownOfficeV2}${extendStyles}`}
            label="Chi nhánh"
            placeHolder="Nhập mã, tên chi nhánh"
            placeHolderSelect="Chọn chi nhánh"
            isSelectMultiple={isAdminTSC || isMainOffice || isBranchOffice}
            textMultipleSelect={(lstSelected) => 'Nhiều chi nhánh'}
        />
    )
}

export default SelectOfficeV2
