import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// image
import ic_left_arrow_blue from '../../../../../../../images/left-arrow-blue.svg'

// styles
import { WraperHeader } from './index.styles'
import { useDispatch } from 'react-redux'
import { clearFilterOffice, filterOffice } from '../../../../../../../actions/officeAction'

const Header = ({ title }) => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const _handleBack = useCallback(() => {
        console.log(history, location)
        history.push('/reports', location.state)
        dispatch(clearFilterOffice())
    }, [history, location])

    return (
        <WraperHeader>
            <img src={ic_left_arrow_blue} alt="back" onClick={_handleBack} />
            <div className='title'>{title}</div>
        </WraperHeader>
    )
}

export default Header
