import styled from 'styled-components'

const WrapperTools = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 25px;
    white-space: nowrap;
    position: relative;
    z-index: 10;
    /* max-width: min-content; */

    & > .tools-item {
        margin-left: 12px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;

        & > .label-tools-item {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 128%;
            color: #005993;
        }
    }
`

const WrapGridTools = styled.div`
    display: grid;
    grid-template-columns:
        repeat(
            ${(props) => (props.numOfCol ? props.numOfCol - 1 : 3)},
            minmax(200px, auto)
        )
        1fr;
    grid-gap: ${(props) => props.gap || '20px'};
    margin: 0 25px;
    position: relative;
`

const WrapLineTools = styled.div`
    height: 15px;
    position: relative;
    z-index: 10;
`

const WrapGridToolsCustom = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.templateCol};
    margin: 0 25px;
    position: relative;
    z-index: 10;
    grid-column-gap: ${(props) => props.gap || '20px'};

    ${(props) => props.extendStyles}
`

const GridColTools = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: ${(props) => props.templateRow || 'repeat(2, 1fr)'};
    align-items: center;
    grid-gap: 10px ${(props) => props.colGap || '10px'};

    & > .label-tools-item {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 128%;
        color: #005993;
        white-space: nowrap;
    }
`

const GridSplitHeadTools = styled.div`
    & > div {
        display: grid;
        grid-column-gap: ${(props) => props.colGap || '20px'};
        justify-items: end;
        align-items: center;

        &:first-child {
            grid-template-columns: ${(props) => props.template[0]};
        }

        &:last-child {
            grid-template-columns: ${(props) => props.template[1]};
            margin-top: 10px;
        }
    }

    .label-tools-item {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 128%;
        color: #005993;
        white-space: nowrap;
    }
`

const GridBtnTools = styled.div`
    justify-self: start;
    display: grid;
    grid-template-rows: repeat(${(props) => props.numRow || 2}, 1fr);
    align-items: center;
`

const GridBtnToolsEnd = styled(GridBtnTools)`
    justify-self: end;
`

export {
    WrapperTools,
    WrapGridToolsCustom,
    WrapGridTools,
    GridColTools,
    GridSplitHeadTools,
    GridBtnTools,
    GridBtnToolsEnd,
    WrapLineTools
}
