/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useDispatch } from 'react-redux'
import { WrapContainer } from './index.styles'
import { setGeneralConfig } from '../../../../../actions/confAppointmentScheduleAction'
import { useState } from 'react'
import ConfigureByGeneralControl from './ConfigureByGeneralControl'
import SubHeaderControlV2 from '../../Control/SubHeaderControlV2'
import { useEffect } from 'react'

export default function ConfigureByGeneral({ headerData, sysConfig, onHideShowErrPopup, onHideShowSuccessPopup, onUpdateData }) {
    const dispatch = useDispatch()
    //---
    const [dataConfig, setDataConfig] = useState(sysConfig)
    const [newDataConfig, setNewDataConfig] = useState(dataConfig.dataInfo)
    //---
    const [isError, setIsError] = useState(false)

    //let newDataConfig = {...sysConfig.dataInfo}
    let listErr = []
    //---
    useEffect(() => {
        setDataConfig(sysConfig)
    }, [sysConfig])
    //---
    const onSaveInfo = () => {
        if (isError) {
            return
        }
        //---
        dispatch(setGeneralConfig({
            generalConfig: newDataConfig,
            onCompleted: (dataResp) => {
                if (dataResp.code !== 0) {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Lưu lại thất bại",
                        note: "(*) Bạn vui lòng thực hiện lại"
                    })
                } else {
                    onHideShowSuccessPopup && onHideShowSuccessPopup({
                        isShowPopup: true,
                        mess: "Lưu lại thành công"
                    })
                    onUpdateData && onUpdateData(newDataConfig)
                }
            }
        }))
    }

    const updateDataConfig = (dataConfig) => {
        setNewDataConfig(dataConfig)
    }

    const onErrNotify = (isErr, configType) => {
        const index = listErr.indexOf(configType)
        if (isErr) {
            if (index === -1) {
                listErr.push(configType)
            }
        } else {
            if (index !== -1) {
                listErr.splice(index, 1)
            }
        }
        //---
        if (isError !== isErr) {
            if (!isErr && listErr.length !== 0) {
                return
            }
            setIsError(isErr)
        }
    }

    return (
        <>
            <WrapContainer>
                <SubHeaderControlV2
                    data={headerData}
                    onSaveInfo={onSaveInfo}
                    isDisableSaveInfoBt={isError}
                />
                <ConfigureByGeneralControl
                    dataInfo={newDataConfig}
                    defaultConfig={dataConfig.defaultConfig}
                    onChanged={updateDataConfig}
                    onErrNotify={onErrNotify}
                />
            </WrapContainer>
        </>
    )
}
