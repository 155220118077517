import React, { Component } from 'react'
import styled from 'styled-components';

const EmptyControlStyle = styled.div`
    display:flex;
    width:${props => props.widthInput ? props.widthInput : '375px'}; 
    margin: 15% auto;
    justify-content: center;
    /* transform:translate(0,200%); */
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .content{
        font-size: 32px;
        font-weight: bold;
        color: #222222;
        opacity: 0.15;            
        text-align:center;
        white-space: pre-line;
    }       
    .filter {
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        text-align:center;
        .filter_title{
            font-size: 20px;
            font-weight: bold;
            color: #787878;
        }
        .text{
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            color: #979797;
            margin-top:16px;
        }
    }
`
export default class EmptyControl extends Component {
    render() {
        return (
            <EmptyControlStyle widthInput={this.props.widthInput}>
                {
                    this.props.alertContent === '' 
                    ?   <div className='content'>{this.props.content}</div>
                    :   <div className='filter'>
                            <p className='filter_title'>{this.props.text_title ? this.props.text_title : "Không có kết quả tìm kiếm"}</p>
                            <p className='text'>{this.props.text ? this.props.text : "thay đổi để có kết quả tìm kiếm khác"}</p>
                        </div>
                }
            </EmptyControlStyle>
        )
    }
}
