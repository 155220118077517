import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// component
import ReportItem from './ReportItem'
import TableHeader from './TableHeader'

// styles
import { Grid3Col, HeaderStyles, WrapRowData } from './index.styles'

const TableReports = () => {
    const wrapRowDataRef = useRef()
    const location = useLocation()

    const { list } = useSelector(state => state.reports.listReports)

    const [reports, setReports] = useState([])

    useEffect(() => {
        if(!Object.values(list).length) return;
        const wrapRowData = wrapRowDataRef.current;
        if(!wrapRowData) return;
        const reportTarget = wrapRowData.querySelector(`.report-${location.state}`)
        if(!reportTarget) return;
        reportTarget.scrollIntoView({block: 'end'})
    }, [location, list])

    useEffect(() => {
        // if(!Object.values(list).length) return
        setReports(Object.values(list))
    }, [list])

    return (
        <div>
            <Grid3Col extendStyles={HeaderStyles}>
                <TableHeader />
            </Grid3Col>
            <WrapRowData ref={wrapRowDataRef}>
            {
                reports.map((item, key) => 
                    <ReportItem data={item} key={key} />
                )
            }
            </WrapRowData>
        </div>
    )
}

export default TableReports
