import React, { Component } from 'react';
import styled from "styled-components";

//Component
import PagingControl from '../Control/PagningControl'
import { color } from "../../../../data/store";
import TransactionCounterItem from './TransactionCounterItem';

const Bound = styled.div`
    display: flex;
    flex-direction:column;
    overflow-y: auto;
    flex:1;
    margin-top: 10px;
    font-family: "SVN-Gilroy";
    .transaction-pgd-title {
      display: flex;
      height:50px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: ${color.blue_005993};
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      :first-child {
          font-size: 11px;
          font-weight: bold;
          line-height: 1.26;
          text-transform: uppercase;
      } 
    }
    .block-transaction{
      overflow: auto;
      min-width: 950px;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  .scroll {
    display: grid;
    grid-template-columns: 26% 26% 26% 26%;
    grid-gap: 15px;
    /*justify-content: space-evenly;*/
    justify-content: space-between;
    overflow: auto;
    margin: 20px 0 10px 0;
    &::-webkit-scrollbar {
      width: 0px;
    }

  }
  @media screen and (min-width: 1024px) {
    .scroll {
      grid-template-columns: repeat(4, 280px);
    }
  }  
`
const limitPerPage = 8
class TransactionCounter extends Component {

  state = {
    datalistCounter: this.props.data.listCounter,
    currentPage: 1,
    startIndex: 0,
    endIndex: 10,
  }

  componentWillMount() {
    this.pagnationPage();
  }

  componentWillReceiveProps(n) {
    let datalistCounter = n.data.listCounter
    if (n && n.data) {
      let desktopKey = n.data.desktopKey ? n.data.desktopKey : null
      let tabletKey = n.data.tabletKey ? n.data.tabletKey : null
      let counterDesktop = datalistCounter.find(item => item.ID === desktopKey.CounterID)
      let counterTablet = datalistCounter.find(item => item.ID === tabletKey.CounterID)
      if (counterDesktop) {
        counterDesktop.desktopKey = desktopKey
      }
      if (counterTablet) {
        counterTablet.tabletKey = tabletKey
      }
    }
    this.setState({ datalistCounter })
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({
      startIndex,
      endIndex
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.total < this.props.data.total) {
      this.setState({ currentPage: 1 })
    }
  }

  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
    this.props.getCounterByOffice(this.props.officeID, 8, currentPage)
  }

  getNumberOfCounterServices  = () => {
    let numberOfService = 0
    const serviceList = this.props.data.listTypeCounterServices
    if(typeof(serviceList) === 'object' && Object.keys(serviceList).length === 2){
      const personCustomer = serviceList.personCustomer??[]
      personCustomer.forEach(service => {
        if(service.Children && service.Children.length > 0){
          numberOfService += service.Children.length
        }
      });
      //---
      const corpCustomer = serviceList.corpCustomer??[]
      corpCustomer.forEach(service => {
        if(service.Children && service.Children.length > 0){
          numberOfService += service.Children.length
        }
      });
    }
    return numberOfService
  }
  render() {
    const { datalistCounter, currentPage } = this.state
    let numberOfCounterServices = this.getNumberOfCounterServices()
    // console.log('currentPage',this.state.currentPage)
    // debugger;
    return (
      <Bound>
        <div className="transaction-pgd-title">
          <div>Danh sách quầy giao dịch</div>
          {
            (this.props.data.total > limitPerPage) ?
              <PagingControl
                currentPage={this.state.currentPage}
                totalItem={this.props.data.total}
                limitPerPage={limitPerPage}
                onChangePage={page => this.onPageChange(page)}
              />
              : null
          }
        </div>
        <div className="block-transaction">
          <div className='scroll'>
            {
              datalistCounter.map((item, index) => {
                return (
                    <TransactionCounterItem
                        key={index}
                        index={index}
                        dataInfo={item}
                        numberOfCounterServices={numberOfCounterServices}
                        currentPage={currentPage}
                        onShowPopupEdit={this.props.onShowPopupEdit}
                        onShowPopupDelete={this.props.onShowPopupDelete}
                        onShowErrPopup={this.props.onShowPopupErr}
                        onGenDesktopKey={this.props.genDesktopKey}
                        onGenTabletKey={this.props.genTabletKey}
                        onClearKeyGenCounter={this.props.clearKeyGenCounter}
                    />
                )
              })
            }
          </div>
        </div>
      </Bound>
    );
  }

}

export default TransactionCounter;