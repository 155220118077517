/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { WrapBodyContent, WrapHeaderContent, WrapTable } from './index.styles'
import AdviseServiceItem from './AdviseServiceItem'

export default function AdviseServiceTable({ limitPerPage, currentPage, dataConfig, dataList, itemSelectedBgColor, onItemSelected, onItemDoubleClick, onShowOptionPopup }) {
  const [indexSelected, setIndexSelected] = useState(-1)
  const isRowSelectedRef = useRef(false)
  //---
  const itemSelectedAction = (index, dataInfo) => {
    if (indexSelected !== index) {
      setIndexSelected(index)
      onItemSelected && onItemSelected(dataInfo)
    }
  }

  const itemDoubleClickAction = (dataInfo) => {
    onItemDoubleClick && onItemDoubleClick(dataInfo)
  }

  const showOptionPopupAction = (index, dataInfo) => {
    onShowOptionPopup && onShowOptionPopup(index, dataInfo)
  }

  const handleClickOutside = (event) => {
    setTimeout(() => {
      if (isRowSelectedRef.current) {
        isRowSelectedRef.current = false
        return
      }
      //---
      setIndexSelected(-1)
      onItemSelected && onItemSelected(null)
    }, 50)

  };

  const onTableSelected = () => {
    isRowSelectedRef.current = true
  }
  //---
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [])
  //---
  return (
    <WrapTable>
      <WrapHeaderContent>
        <AdviseServiceItem
          isHeader={true}
        />
      </WrapHeaderContent>

      <WrapBodyContent onClick={onTableSelected}>
        {
          dataList.map((item, index) => {
            const currentIndex = (index + 1) + ((currentPage - 1) * limitPerPage)
            return <AdviseServiceItem
              key={`adviseServiceItem${currentIndex}`}
              isHeader={false}
              index={currentIndex}
              isSelected={indexSelected === currentIndex}
              selectedBgColor={itemSelectedBgColor}
              dataConfig={dataConfig}
              dataInfo={item}
              onItemSelected={itemSelectedAction}
              onItemDoubleClick={itemDoubleClickAction}
              onShowOptionPopup={showOptionPopupAction}
            />
          })
        }
      </WrapBodyContent>
    </WrapTable>
  )
}

