/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

const useDidMount = (callback, dependencies) => {
    const [isFirstRender, setIsFirstRender] = useState(true)

    useEffect(() => {
        if (!isFirstRender) return
        const isCalled = callback()
        if (!isCalled) return
        setIsFirstRender(false)
    }, [isFirstRender, ...dependencies])
}

export default useDidMount
