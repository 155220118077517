import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// action
import { resetStore } from '../../../actions/userAction';

// image
import icCloseNotification from "../../../images/ic_notification_close.svg";

const Bound = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999999999999999999;
	background-color: rgba(0,0,0,0.6);
`;

const StylePopupAlertLogut = styled.div`
	width: 338px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	border-radius: 10px;
  	background-color: #fafafa;
  	padding: 0 29px;
  	box-sizing: border-box;
  	padding-bottom: 20px;

  	& > * {
  		font-family: "SVN-Gilroy";
  	}

  	& > img, & > .message, & > button {
		margin-top: 20px;
  	}

  	& > .message {
  		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.25;
  	}

  	& > .note {
  		margin-top: 16px;
  		font-size: 12px;
		font-weight: 500;
		font-style: normal;
		line-height: 1.3;
		color: var(--peacock-blue);
  	}

  	& > button {
  		text-transform: uppercase;
  		font-size: 13px;
		font-weight: bold;
		font-style: normal;
		color: #ffffff;
		outline: none;
		border: none;
		border-radius: 18px;
 		background-color: var(--vermillion);
 		width: 88px;
 		padding-top: 12px;
 		padding-bottom: 10px;
 		:hover {
 			cursor: pointer;
 		}
  	}

`;

class PopupAlertLogut extends Component {
	state = {
		time: 10
	}
	render() {
		return (
			<Bound>
				<StylePopupAlertLogut>
					<img src={icCloseNotification} alt="close-notify" />
					<div className="message">Tài khoản đang được đăng nhập từ thiết bị khác hoặc quá thời gian hoạt động, bạn vui lòng đăng nhập lại</div>
					<div className="note">Tự động thoát khỏi hệ thống sau: {this.state.time}s</div>
					<button onClick={this.props.resetStore}>Thoát</button>
				</StylePopupAlertLogut>
			</Bound>
		)
	}
	componentDidMount() {
		this.intervalTime = setInterval(() => {
			let newTime = this.state.time - 1;
			if(newTime < 0){
				clearInterval(this.intervalTime);
				this.props.resetStore();
				return;
			}
			
			this.setState({
				time: newTime
			})
		}, 1000);
	}
	componentWillUnmount() {
		clearInterval(this.intervalTime)
	}
}

const mapDispatchToProps = dispatch => ({
	resetStore: () => dispatch(resetStore())
})

export default connect(null, mapDispatchToProps)(PopupAlertLogut)