import styled from "styled-components";

const WrapContainer = styled.div`
    padding: 8px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1; 
    min-width: 1024px;
`

export {WrapContainer}