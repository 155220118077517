/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// component
import ReportList from './ReportList'
import ReportDetails from './ReportDetails'

// action
import { setConditionFilter, clearListReport } from '../../../../actions/reportsAction/listReports/listReports'
import { getOfficeManagementList } from '../../../../actions/officeManagementAction'

const Reports = () => {
    const dispatch = useDispatch()

    const reports = useSelector(state => state.reports)

    const [isFirstRender, setIsFirstRender] = useState(true)

    const { dataLogin, officeManagementList } = useSelector((state) => ({
        dataLogin: state.userReducer.dataLogin,
        officeManagementList: state.officeManagementReducer.officeManagementList
    }))

    useEffect(() => {
        const { listParents } = reports.listReports
        if(!Object.keys(listParents).length) return;
        if(!isFirstRender) return;
        setIsFirstRender(false)
        const firstGroupReportCode = Object.keys(listParents)[0]
        dispatch(setConditionFilter({
            ParentCode: firstGroupReportCode
        }))
    }, [reports.listReports.listParents, isFirstRender])

    useEffect(() => {
        if(dataLogin.OfficeType === 'BRANCH' && officeManagementList.length === 0){
            //call api get officeManagementList
            dispatch(getOfficeManagementList({
               uid: dataLogin.UID
            }))
        }

        return () => {
            dispatch(clearListReport())
        }
    }, [])

    return (
        <Switch>
            <Route exact path="/reports">
                <ReportList />
            </Route>
            <Route path="/reports/:codeReport">
                <ReportDetails />
            </Route>
            <Redirect to="/reports" />
        </Switch>
    )
}

export default Reports
