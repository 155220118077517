import ic_service_white from '../images/Menu/ic_service_white.svg'
import ic_location_white from '../images/Menu/ic_location_white.svg'
import ic_advertising_white from '../images/Menu/ic_advertising_white.svg'
import ic_employee_white from '../images/Menu/ic_employee_white.svg'
import ic_database from '../images/Menu/ic_database.svg'
import ic_setting_white from '../images/Menu/ic_setting_white.svg'
import ic_device_white from '../images/Menu/ic_device_white.svg'
import ic_ounter_white from '../images/Menu/ic_ounter_white.svg'
import ic_biometric from '../images/Menu/ic_biometric.svg'
import ic_checkbiometric from '../images/Menu/ic_checkbiometric.svg'
import ic_report from '../images/Menu/ic_report.svg'
import ic_ticket from '../images/Menu/ic_ticket.svg'
import ic_qr_code from '../images/Menu/ic_qr_code.svg'
import ic_advise from '../images/Menu/ic_advise.svg'

// import finger_white from '../images/finger_white.svg';
// import finger_delete_red from '../images/finger_delete_red.svg';
// import finger_register_green from '../images/finger_register_green.svg';
// import finger_update_blue from '../images/finger_update_blue.svg';

// import data
import * as userRole from './userRole'
import { color } from './store'

export const webVersion = '1.0'

export const dataLoginDefault = {
    ADMIN: {
        email: 'admin@vietinbank.vn',
        password: '1234',
    },
    OFFADMIN: {
        email: 'admin_off@vietinbank.vn',
        password: '1234',
    },
}

export const IFW_ROUTE_PATH = '/van-tin'
export const mainMenu = [
    {
        key: '/danh-sach-giao-dich',
        icon: ic_service_white,
        content: 'Danh sách GD',
        permissions: [userRole.ADMIN_ROLE],
    },
    {
        key: '/chi-nhanh-pgd',
        icon: ic_location_white,
        content: 'Chi nhánh/PGD',
        permissions: [userRole.ADMIN_ROLE],
    },
    {
        key: '/quang-cao',
        icon: ic_advertising_white,
        content: 'Quảng cáo',
        permissions: [userRole.ADMIN_ROLE],
    },
    {
        key: '/thiet-bi-kiosk',
        icon: ic_device_white,
        content: 'Thiết bị PGD',
        permissions: [userRole.ADMIN_OFFICE_ROLE],
    },
    {
        key: '/quay-giao-dich',
        icon: ic_ounter_white,
        content: 'Quầy giao dịch',
        permissions: [userRole.ADMIN_OFFICE_ROLE],
    },
    {
        key: '/quang-cao-pgd',
        icon: ic_advertising_white,
        content: 'Quảng cáo PGD',
        permissions: [userRole.ADMIN_OFFICE_ROLE],
    },
    {
        key: '/phan-quyen',
        icon: ic_employee_white,
        content: 'Phân quyền',
        permissions: [userRole.ADMIN_ROLE, userRole.ADMIN_OFFICE_ROLE],
    },
    {
        key: '/qr-diem-giao-dich',
        icon: ic_qr_code,
        content: 'QR Điểm giao dịch',
        permissions: [userRole.ADMIN_OFFICE_ROLE],
    },
    {
        key: '/biometric-data',
        icon: ic_biometric,
        // content: "Dữ liệu<br>Sinh trắc học",
        content: '<div>Dữ liệu</div><div>Sinh trắc học</div>',
        permissions: [userRole.OFFEXSUPERVISOR, userRole.OFFINSUPERVISOR],
    },
    {
        key: '/customer-vertify',
        icon: ic_checkbiometric,
        content: 'Xác thực KH',
        permissions: [userRole.OFFEXSUPERVISOR],
        // , userRole.OFFINSUPERVISOR]
    },
    {
        key: '/reports',
        icon: ic_report,
        content: 'Báo cáo',
        permissions: [userRole.RPW, userRole.ADMIN_ROLE],
    },
    {
        key: IFW_ROUTE_PATH,
        icon: ic_employee_white,
        content: 'Vấn tin',
        permissions: [userRole.ADMIN_ROLE, userRole.IFW],
    },
    {
        key: '/xy-ly-du-lieu',
        icon: ic_database,
        content: 'Xử lý dữ liệu',
        permissions:  [userRole.ADMIN_ROLE, userRole.OFFEXSUPERVISOR, userRole.OFFINSUPERVISOR],
    },
    {
        key: '/xy-ly-sth',
        icon: ic_database,
        content: 'Xử lý STH',
        permissions:  [userRole.ADMIN_ROLE],
    },
    {
        key: '/cau_hinh_lich_hen',
        icon: ic_ticket,
        content: 'Cấu hình lịch hẹn',
        permissions: [userRole.ADMIN_ROLE],
    },
    // {
    //     key: '/cau_hinh_vai_tro',
    //     icon: ic_setting_white,
    //     content: 'Cấu hình vai trò',
    //     permissions: [userRole.ADMIN_ROLE],
    // },
    {
        key: '/cau_hinh_dich_vu_tu_van',
        icon: ic_advise,
        content: 'Cấu hình dịch vụ tư vấn',
        permissions: [userRole.ADMIN_ROLE],
    },
]

export const settingObj = {
    key: '/setting',
    icon: ic_setting_white,
    content: 'Setting',
}

export const permission = {
    admin: {
        title: 'Administrator',
        role: 1,
    },
    mod: {
        title: 'Quản trị viên PGD',
        role: 2,
    },
    counter: {
        title: 'Giao dịch viên',
        role: 3,
    },
}

export const emptyData = {
    branch: 'Ấn vào nút “Thêm CN” để tạo chi nhánh',
    advertisement: 'Ấn vào nút “Thêm mới” để tạo quảng cáo',
    transaction: 'Ấn vào nút “Thêm mới” để tạo quầy',
    deviceKiosk: 'Ấn vào nút “Thêm thiết bị” để tạo thiết bị cấu hình',
    permission: 'Ấn vào nút “Thêm User” để tạo tài khoản',
    counter: 'Ấn vào nút “Thêm quầy” để tạo quầy',
    uploadFile: 'Ấn vào nút “Tải file” để cập nhật thông tin từ máy của bạn',
    role:  'Ấn vào nút “Thêm mới” để tạo vai trò',
}

export const popupType = {
    configAdvertiseKiosk: 2,
    configAdvertiseTable: 1,
    configTransaction: 3,
    configConfirm: 5,
    configAdvertiseNewImage: 7,
    configAddBranch: 4,
    configSuccess: 6,
    configAddPermission: 8,
    popupControlType: 9,
    configAdvertisementPopup: 10,
    configAccountPermission: 11,
    configDeletePermission: 12,
    configFlowCheckOffice: 13,
    configAddCounter: 14,
    configOutOfLimit: 15,
    configCheckBiometricCustomer: 16,
    configCheckCustomer: 17
}

export const subHeaderData = {
    transactionsOfPersonalCustomer: {
        mainTitle: 'Quản lý danh sách giao dịch của KHCN',
        subTitle: 'Giao dịch trong hệ thống Smart Digital Kiosk',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên GROUP/GD' },
            addMoreList: [{ title: 'THÊM GROUP' }, { title: 'THÊM GD' }],
        },
    },

    transactionsOfBusinessCustomer: {
        mainTitle: 'Quản lý danh sách giao dịch của KHDN',
        subTitle: 'Giao dịch trong hệ thống Smart Digital Kiosk',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên GROUP/GD' },
            addMoreList: [{ title: 'THÊM GROUP' }, { title: 'THÊM GD' }],
        },
    },

    branch: {
        mainTitle: 'Hệ thống chi nhánh/ PGD',
        subTitle: 'Quản trị hệ thống chi nhánh/PGD',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên Chi nhánh/PGD' },
            filter: {},
            addMore: { title: 'THÊM CN' },
        },
    },
    advertisementKiosk: {
        mainTitle: 'Thiết bị Kiosk',
        subTitle: 'Quảng cáo thiết bị Kiosk trong hệ thống',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên quảng cáo' },
            addMore: { title: 'THÊM QC' },
        },
    },
    advertisementTablet: {
        mainTitle: 'Thiết bị hỗ trợ GDV',
        subTitle: 'Quảng cáo thiết bị hỗ trợ giao dịch viên trong hệ thống',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên quảng cáo' },
            addMore: { title: 'THÊM QC' },
        },
    },
    advertisementTicket: {
        mainTitle: 'Bảng hiển thị vé',
        subTitle: 'Quảng cáo màn hình hiển thị vé trong hệ thống',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên quảng cáo' },
            addMore: { title: 'THÊM QC' },
        },
    },
    permission: {
        mainTitle: 'Phân quyền User',
        subTitle: 'Danh sách user được phân quyền trong hệ thống',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập user hoặc họ tên cán bộ' },
            filter: {},
            addMore: { title: 'THÊM USER' },
        },
    },
    deviceKioskPGD: {
        mainTitle: 'PGD Bến Thành',
        subTitle: '79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
        resetButton: 'RESET',
        buttonArr: {
            addMore: { title: 'THÊM THIẾT BỊ' },
        },
    },
    transactionRoomPGD: {
        mainTitle: 'PGD Bến Thành',
        subTitle: '79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
        resetButton: 'RESET',
        buttonArr: {
            addMore: { title: 'THÊM QUẦY' },
        },
    },
    Biometric: {
        mainTitle: 'PGD Bến Thành',
        subTitle: '79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
        // resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập mã số CIF, tên khách hàng' },
            filter: {},
            dataType: { content: 'Khách hàng nội bộ' },
        },
    },
    CustomerVertify: {
        mainTitle: 'PGD Bến Thành',
        subTitle: '79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập mã số CIF, tên khách hàng' },
            filter: {},
        },
    },
    qrTransPoint: {
        mainTitle: 'PGD Bến Thành',
        subTitle: '79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
        buttonArr: {},
    },
    confAppointmentScheduleByBranch: {
        mainTitle: 'Theo chi nhánh',
        subTitle: 'Thiết lập số lượng đặt lịch theo chi nhánh',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập mã ĐGĐ, Tên CN/PDG' },
            uploadFile: { title: 'TẢI FILE' }
        },
    },
    confAppointmentScheduleByGeneral: {
        mainTitle: 'Thiết lập chung',
        subTitle: 'Thiết lập chung toàn bộ chi nhánh/PGD sử dụng chung lượt đặt',
        buttonArr: {
            saveInfo: { title: 'LƯU LẠI' }
        },
    },
    confRole: {
        mainTitle: 'Quản lý vai trò',
        subTitle: 'Danh sách vai trò có thể được phân quyền trong hệ thống',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên vai trò' },
            addMore: { title: 'THÊM MỚI' },
            uploadFile: { title: 'TẢI FILE' }
        },
    },
    adviseService: {
        mainTitle: 'Quản lý dịch vụ tư vấn',
        subTitle: 'Danh sách dịch vụ tư vấn trong hệ thống',
        resetButton: 'RESET',
        buttonArr: {
            search: { placeHolder: 'Nhập tên dịch vụ tư vấn' },
            addMore: { title: 'THÊM MỚI' }
        },
    },
}

export const advertisementTabArr = [
    {
        key: 'KIOSK',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Thiết bị Kiosk',
        subHeaderData: subHeaderData.advertisementKiosk,
    },
    {
        key: 'GDV',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Thiết bị GDV',
        subHeaderData: subHeaderData.advertisementTablet,
    },
    {
        key: 'LCD',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Màn hình LCD',
        subHeaderData: subHeaderData.advertisementTicket,
    },
]

export const TrandingRoomTabArr = [
    {
        key: 'KIOSK',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Thiết bị Kiosk',
        title: 'danh sách thiết bị kiosk',
        subHeaderData: subHeaderData.deviceKioskPGD,
    },
    {
        key: 'LCD',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Màn hình LCD',
        title: 'danh sách màn hình LCD',
        subHeaderData: subHeaderData.deviceKioskPGD,
    },
]
export const BiometricTabArr = [
    {
        key: 'DS dữ liệu',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'DS dữ liệu',
        subHeaderData: subHeaderData.Biometric,
    },
    {
        key: 'Lịch sử xác nhận',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Lịch sử xác nhận',
        subHeaderData: subHeaderData.Biometric,
    },
]

export const ConfigureAppointmentScheduleTabArr = [
    {
        key: 'Theo chi nhánh',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Theo chi nhánh',
        subHeaderData: subHeaderData.confAppointmentScheduleByBranch,
    },
    {
        key: 'Thiết lập chung',
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'Thiết lập chung',
        subHeaderData: subHeaderData.confAppointmentScheduleByGeneral,
    },
]

export const TransactionsType = {
    KHCN: "KHCN",
    KHDN: "KHDN"
}

export const TransactionsTabArr = [
    {
        key: TransactionsType.KHCN,
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'KHCN',
        title: 'Tên giao dịch',
        subHeaderData: subHeaderData.transactionsOfPersonalCustomer,
    },
    {
        key: TransactionsType.KHDN,
        activeColor: '#191919',
        avtiveBorderBottom: `3px solid ${color.blue_005993}`,
        disActiveColor: '#c4c4c4',
        label: 'KHDN',
        title: 'Tên giao dịch',
        subHeaderData: subHeaderData.transactionsOfBusinessCustomer,
    },
]
export const dataFunction = [
    {
        type: 0,
        content: 'Đã có STH',
    },
    {
        type: 1,
        content: 'Cập nhật',
    },
    {
        type: 2,
        content: 'Xóa',
    },
    {
        type: 3,
        content: 'Đăng ký mới',
    },
]

export const biometrictFingerData = [
    // {
    //   typeFinger: "FACE",
    //   bottom: "82",
    //   left: "24"
    // },
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '82',
        left: '24',
    },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '205',
        left: '57',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '232',
        left: '91',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '231',
        left: '145',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '207',
        left: '200',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '83',
        right: '44',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '205',
        right: '73',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '232',
        right: '109',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '231',
        right: '163',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '207',
        right: '218',
    },
]

export const biometrictFingerDataUpdate = [
    // {
    //   typeFinger: "FACE",
    //   bottom: "82",
    //   left: "24"
    // },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '186',
        left: '229',
    },
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '73',
        left: '198',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '211',
        left: '262',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '210',
        left: '310',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '188',
        left: '361',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '186',
        right: '126',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '74',
        right: '96',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '211',
        right: '158',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '209',
        right: '207',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '188',
        right: '258',
    },
]

export const biometrictFingerDatas = {
    THUMB_LEFT: {
        typeFinger: 'FACE',
        bottom: '82',
        left: '24',
    },
    INDEX_LEFT: {
        typeFinger: 'INDEX_LEFT',
        bottom: '205',
        left: '57',
    },
    MIDDLE_LEFT: {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '232',
        left: '91',
    },
    RING_LEFT: {
        typeFinger: 'RING_LEFT',
        bottom: '231',
        left: '145',
    },
    PINKY_LEFT: {
        typeFinger: 'PINKY_LEFT',
        bottom: '207',
        left: '200',
    },
    THUMB_RIGHT: {
        typeFinger: 'THUMB_RIGHT',
        bottom: '83',
        right: '32',
    },
    INDEX_RIGHT: {
        typeFinger: 'INDEX_RIGHT',
        bottom: '205',
        right: '65',
    },
    MIDDLE_RIGHT: {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '232',
        right: '100',
    },
    RING_RIGHT: {
        typeFinger: 'RING_RIGHT',
        bottom: '231',
        right: '153',
    },
    PINKY_RIGHT: {
        typeFinger: 'PINKY_RIGHT',
        bottom: '207',
        right: '208',
    },
}

