/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { WrapBodyContent, WrapHeader, WrapHeaderContent, WrapNoteContent, WrapTable } from './index.styles'
import ConfigureByBranchItem from './ConfigureByBranchItem'
import ic_error from '../../../../../../images/ic_error_v3.svg'
import ic_success from '../../../../../../images/ic_success.svg'

export default function ConfigureByBranchTable({ limitPerPage, currentPage, dataList, isUploadFilePopup, itemSelectedBgColor, onItemSelected }) {
  const isWinOperatingSystem = window.navigator.userAgent.indexOf("Win") !== -1
  // console.log(`isWinOperatingSystem: ${isWinOperatingSystem}`);
  //---
  const [indexSelected, setIndexSelected] = useState(-1)
  const isRowSelectedRef = useRef(false)
  //---
  const dataListSort = dataList.sort(function (x, y) {    
    if ( x.errorList < y.errorList ){
      return 1;
    }
    if ( x.errorList > y.errorList ){
      return -1;
    }
    return 0;
  });
  //---
  const itemSelectedAction = (index, dataInfo) => {
    if (indexSelected !== index) {
      setIndexSelected(index)
      onItemSelected && onItemSelected(dataInfo)
    }
  }

  const handleClickOutside = (event) => {
    setTimeout(() => {
      if (isRowSelectedRef.current) {
        isRowSelectedRef.current = false
        return
      }
      //---
      setIndexSelected(-1)
      onItemSelected && onItemSelected(null)
    }, 50)

  };

  const onTableSelected = () => {
    isRowSelectedRef.current = true
  }
  //---
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [])
  //---
  return (
    <WrapTable>
      {
        isUploadFilePopup &&
        <WrapHeader>
          <div className="label">Danh sách tải lên</div>
          <WrapNoteContent isWinOperatingSystem={isWinOperatingSystem}>
            <div className="column">
              <img className='icon' src={ic_success} alt="" />
            </div>
            <div className="column">Dữ liệu không bị lỗi</div>

            <div className="column">
              <img className='icon' src={ic_error} alt="" />
            </div>
            <div className="column">Dữ liệu bị lỗi</div>
          </WrapNoteContent>
        </WrapHeader>
      }
      <WrapHeaderContent>
        <ConfigureByBranchItem
          isUploadFilePopup={isUploadFilePopup}
          isHeader={true}
        />
      </WrapHeaderContent>

      <WrapBodyContent onClick={onTableSelected}>
        {
          dataListSort.map((item, index) => {
            const currentIndex = (index + 1) + ((currentPage - 1) * limitPerPage)
            return <ConfigureByBranchItem
              isUploadFilePopup={isUploadFilePopup}
              key={`${isUploadFilePopup ? 'UploadFile_' : ''}ConfigureByBranchItem${currentIndex}`}
              isHeader={false}
              index={currentIndex}
              isSelected={indexSelected === currentIndex}
              selectedBgColor={itemSelectedBgColor}
              dataInfo={item}
              onItemSelected={itemSelectedAction}
            />
          })
        }
      </WrapBodyContent>
    </WrapTable>
  )
}

