/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, WrapOverlay } from './index.styles'
import { TransactionsTabArr } from '../../../../data/data'
import PopupError from '../../Popup/PopupError'
import TransactionInfo from './TransactionInfo'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCounterServices, getCounterServiceConfig } from '../../../../actions/counterServiceAction'
import PopupSuccess from '../../Popup/PopupSuccess'

export default function TransactionsContainer() {
    const dispatch = useDispatch()
    const { userId } = useSelector((state) => ({
        userId: state.userReducer.dataLogin.UID
    }))
    //---
    const { dataLogin } = useSelector(state => ({
        dataLogin: state.userReducer.dataLogin
    }))
    //---
    const tabList = TransactionsTabArr
    //---
    const [dataErr, setDataErr] = useState({
        isShowPopup: false,
        mess: "",
        note: ""
    })
    const [dataSuccess, setDataSuccess] = useState({
        isShowPopup: false,
        mess: "",
        note: ""
    })

    const [tabSelected, setTabSelected] = useState(tabList[0])
   
    const [dataInfo, setDataInfo] = useState({
        personCustomer: [],
        corpCustomer: [],
        dataConfig: {
            statusList: []
        }
    })

    const getTransacionData = (dataConfig) => {
        dispatch(getAllCounterServices({
            uid: userId,
            onCompletedFunc: (dataResp) => {
                if(dataResp.code === 0){
                    setDataInfo({
                        ...dataResp.result,
                        dataConfig: dataConfig??dataInfo.dataConfig
                    })
                } else {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Không lấy được danh sách giao dịch",
                        note: "(*) Hệ thống bị gián đoạn, Bạn vui lòng thực hiện lại"
                    })
                }
            }
        }))
    }

    const getTransacionConfigAction = ({onSuccess}) => {
        dispatch(getCounterServiceConfig({
            uid: dataLogin.UID,
            onCompleted: (dataResp) => {
                if (dataResp.code === 0) {
                    onSuccess && onSuccess(dataResp.result)
                } else {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Hệ thống bị gián đoạn",
                        note: "(*) Bạn vui lòng thực hiện lại"
                    })
                }
            }
        }))
    }

    const onTabSelected = (tabInfo) => {
        if (tabSelected.key !== tabInfo.key){
            setTabSelected(tabInfo)
        }
    }


    const onHideShowErrPopup = (data={isShowPopup: false, mess: "", note: ""}) => {
        setDataErr({
            isShowPopup: data.isShowPopup,
            mess: data.mess,
            note: data.note
        })
    }

    const onHideShowSuccessPopup = (data = { isShowPopup: false, mess: "", note: "", onAcceptAction: undefined}) => {
        setDataSuccess({
            isShowPopup: data.isShowPopup,
            mess: data.mess,
            note: data.note,
            onAcceptAction: data.onAcceptAction
        })
    }

    const renderTab = () => {
        return tabList.map((tab, i) => {
            return (
                <div
                    className="tab"
                    key={i}
                    style={
                        tabSelected.key === tab.key
                            ? {
                                    color: tab.activeColor,
                                    borderBottom: tab.avtiveBorderBottom,
                                }
                            : { color: tab.disActiveColor }
                    }
                    onClick={() => onTabSelected(tab)}
                >
                    {tab.label}
                </div>
            )
        })
    }

    useEffect(()=>{
        getTransacionConfigAction({
            onSuccess: (dataConfig) => {
                getTransacionData(dataConfig)
            }
        })
    }, [])

    return (
        <Container>
            <div className="title">
                {renderTab()}
            </div>
            <div className="body">
                {
                    <TransactionInfo
                        transType={tabSelected.key}
                        headerData={tabSelected.subHeaderData}
                        title={tabSelected.title}
                        transList={
                            tabSelected.key===tabList[0].key
                            ? dataInfo.personCustomer
                            : dataInfo.corpCustomer
                        }
                        dataConfig={dataInfo.dataConfig}
                        onReset={getTransacionData}
                        onHideShowErrPopup={onHideShowErrPopup}
                        onHideShowSuccessPopup={onHideShowSuccessPopup}
                    />
                }
            </div>
            {
                dataErr.isShowPopup &&
                <WrapOverlay>
                    <PopupError
                        width={'280px'}
                        content={dataErr.mess}
                        note={dataErr.note}
                        onClosed={()=>{
                            onHideShowErrPopup()
                        }}
                    />
                </WrapOverlay>
            }
             {
                dataSuccess.isShowPopup &&
                <WrapOverlay>
                    <PopupSuccess
                        backgroundColor='transparent'
                        titleHeader={dataSuccess.mess}
                        acceptAction={() => {
                            onHideShowSuccessPopup()
                        }}
                        outPopup={() => {
                            onHideShowSuccessPopup()
                        }}
                    />
                </WrapOverlay>
            }
        </Container>
    )
}
