import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

//Image
import logo_vietinbank from '../../../images/logo_vietinbank.svg';
import logo from '../../../images/logo.png';
import ic_logout from '../../../images/Icons_Logout.svg';
import ic_line from '../../../images/Pattern_VTB-03.png'
import PopupConfirm from '../Popup/PopupConfirm';
import { color } from '../../../data/store';

export const heightHeader = 78;

const Bound = styled.div`
    .main-head {
        display: flex;
        align-items: center;
        height: ${heightHeader}px;
        background-color: ${color.blue_7ed3f7};
        padding: 0 90px 0px 31px;

        .header-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;    
            flex: 1;
            font-family: SVN-Gilroy;

            .btn-logout {
                display: flex;
                align-items: center;
                cursor: pointer;
                span {
                    margin-left: 5px;
                    font-weight: bold;
                    line-height: 24px;
                    color: var(--lipstick);
                    font-size: 15px;
                }
            }

            .content-info{
                text-align: left;
                line-height: 1;
                p{
                    font-size: 16px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.13;
                    color: #222222;
                }
                span{
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1.42;
                    color: ${color.blue_005993};
                }
            }
            img.img-info{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin: 0 6px 0 50px;
            }
        }
        > img{
            width: 152px;
            height: 38px;
        }
    }
    .rowLine {
        width: 100%;
        height: 12px;
        background-image: url(${ic_line}) ;
        /* background-position:left center;
        background-repeat: no-repeat;
        background-size: 100% 100%;;  */
        background-position: center;
        background-size: cover;
    } 
`

class HeaderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            avatarImg: '',
            isShowAlert: false
        }
    }

    componentWillMount() {
        // let data = JSON.parse(localStorage.getItem('checkLogin'))
        // if(localStorage.getItem('checkLogin') !== null) {
        this.props.dataLogin && this.setState({
            fullName: this.props.dataLogin.FullName,
            email: this.props.dataLogin.Email,
            avatarImg: logo
        });
        // }
    }

    alertPopup = (content) => {
        this.setState({ isShowAlert: true })
    }
    closeAlertPopup = () => {
        this.setState({ isShowAlert: false })
    }

    logoutAdmin = () => {
        this.props.logoutUser(this.props.dataLogin.UID)
    }

    render() {
        return (
            <Bound>
                <div className='main-head'>
                    <div className='header-left'>
                        <div className="btn-logout" onClick={this.alertPopup}>
                            <img
                                className="ic-down"
                                src={ic_logout}
                                alt="ic-arrow-down"
                            />
                            <span>Đăng xuất</span>
                        </div>
                        <img src={this.state.avatarImg} alt='avatarImg' className='img-info' />
                        <div className='content-info'>
                            <p>{this.state.fullName}</p>
                            <span>{this.state.email}</span>
                        </div>
                    </div>
                    <img src={logo_vietinbank} alt='logo_vietinbank' />

                </div>
                <div className='rowLine'>
                    {/* <img src={ic_line} alt='line' /> */}
                </div>
                {
                    this.state.isShowAlert &&
                    <PopupConfirm
                        content='Xác nhận đăng xuất hệ thống?'
                        cancelAction={this.closeAlertPopup}
                        acceptAction={this.logoutAdmin}
                    />
                }
            </Bound>
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.commonReducer.isLoading,
    isLogout: state.commonReducer.isLogout,
    userReducer: state.userReducer
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(HeaderContainer)
