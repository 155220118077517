import styled from "styled-components";
import { color } from "../../../../../data/store";

const ContainerStyled = styled.div`
  display: flex;
  height: 80px;
  background: ${color.whiteFF};
  padding: 0 20px 0 20px;

  .left-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .title{
      font-size: 26px;
      font-weight: 700;
      color: #222222;
    }
    
    .time-content {
      display: flex;
      font-weight: 500;
      font-size: 14px;
      margin-top: 5px;

      .label{
        font-weight: 700;
        color: ${color.blue_005993};
      }

      .value{
        color: #222222;
        padding-left: 5px;
      }
    }
  }

  .right-content{
    display: flex;
    width: 300px;
    justify-content: end;
    align-items: center;
  }
`;

 export {ContainerStyled}