import React, { Component } from 'react';
import styled from 'styled-components';
import { color } from '../../../../data/store';

const BoundStyle = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: 13px;
    .title-text {        
        font-size: 11px;
        font-weight: bold;        
        color: ${color.blue_005993};
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    textarea {
        padding: 12px 10px;
        height: 100px;       
        font-size: 14px;
        font-weight: 500;
        font-family: 'SVN-Gilroy';
        line-height: 1.2;
        border-radius: 4px;
        border: solid 0.5px #c8c8c8;
        background-color: rgba(255,255,255,0.9);
        color: #222222;
        resize: none;
        &::placeholder{
            color: #bababa;
        }
    }    

`
export default class TextAreaControl extends Component {
    state = {
        text: this.props.defaultValue ? this.props.defaultValue:''
    }

    onChange = (e) => {
        this.setState(
            { text: e.target.value },
            () => { this.props.getText(this.state.text) }
        )
    }

    render() {
        return (
            <BoundStyle>
                <p className='title-text'>{this.props.title}</p>
                <textarea
                    type='text'
                    value={this.state.text}
                    placeholder={this.props.placeHolder}
                    onChange={this.onChange}
                >

                </textarea>

            </BoundStyle>
        )
    }
}
