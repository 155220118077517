import * as types from "../../actions/types";

const initialState = {
  listCounter: [],
  listTypeCounterServices: {},
  listCounterNumber: [],
  desktopKey: {},
  tabletKey: {},
  err: '',
}

const lstDeviceCounter = ['TAB', 'DESKTOP'];

export default function counterReducer(state = initialState, action) {
  switch (action.type) {
    case types.DEVICE_CONNECTED: {
      const { deviceType, mac, parentId } = action.payload;
      if(!lstDeviceCounter.includes(deviceType)) 
        return state;
      if(deviceType === 'TAB')
        return {
          ...state,
          listCounter: state.listCounter.map(item =>
            item.ID === parentId
              ? { ...item, TabletDevice: mac, tabletKey: undefined }
              : item
          ),
          tabletKey: state.tabletKey.CounterID === parentId ? {} : state.tabletKey,
        }
      return {
        ...state,
        listCounter: state.listCounter.map(item =>
          item.ID === parentId
            ? { ...item, DesktopDevice: mac, DeviceMac: mac, desktopKey: undefined }
            : item
        ),
        desktopKey: state.desktopKey.CounterID === parentId ? {} : state.desktopKey,
      }
    }
    case types.CLEAR_KEY_GEN_COUNTER: {
      const { counterId, key } = action.payload;
      return {
        ...state,
        listCounter: state.listCounter.map(item =>
          item.ID === counterId
            ? { ...item, [key]: undefined }
            : item
        ),
        [key]: state[key].CounterID === counterId ? {} : state[key],
      }
    }
    case types.CLEAR_KEY_GEN:
      return {
        ...state,
        listCounter: state.listCounter.map(item =>
          ({ ...item, tabletKey: undefined, desktopKey: undefined })
        ),
        desktopKey: {},
        tabletKey: {}
      }
    case types.REMOVE_COUNTER_LINK:
      return {
        ...state,
        listCounter: state.listCounter.map(item => 
          item.ID === action.counterId
          ? {...item, tabletKey: undefined, desktopKey: undefined}
          : item
        ),
        desktopKey: state.desktopKey.CounterID === action.counterId ? {} : state.desktopKey,
        tabletKey: state.tabletKey.CounterID === action.counterId ? {} : state.tabletKey
      }
    case types.CREATE_COUNTER:
      return {
        ...state,
        err: ''
      }
    case types.CREATE_COUNTER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.UPDATE_COUNTER:
      return {
        ...state,
        err: ''
      }
    case types.UPDATE_COUNTER_ERR:
      return {
        ...state,
        err: {
          type: action.type,
          code: action.payload.code
        }
      }
    case types.GET_COUNTER_BY_OFFICE:
      return {
        ...state,
        listCounter: action.listCounter,
        total: action.total,
        err: ''
      }
    case types.GET_COUNTER_BY_OFFICE_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.DELETE_COUNTER:
      return {
        ...state,
        err: ''
      }
    case types.DELETE_COUNTER_ERR:
      return {
        ...state,
        err: {
          type: action.type,
          code: action.payload.code
        }
      }
    case types.GEN_DESKTOP_KEY:
      return {
        ...state,
        listCounter: state.listCounter.map(item =>
          item.ID === action.data.CounterID ?
            {
              ...item,
              DesktopDevice: ''
            }
            :
            item
        ),
        desktopKey: action.data,
        err: ''
      }
    case types.GEN_DESKTOP_KEY_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GEN_TABLET_KEY:
      return {
        ...state,
        // listCounter: state.listCounter.map(item => 
        //   item.ID === action.data.CounterID?
        //   {
        //     ...item,
        //     TabletDevice: ''
        //   }
        //   :
        //   item
        // ),
        tabletKey: action.data,
        err: ''
      }
    case types.GEN_TABLET_KEY_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GET_ALL_COUNTER_SERVICES:
      return {
        ...state,
        listTypeCounterServices: action.listService,
        err: ''
      }
    case types.GET_ALL_COUNTER_SERVICES_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GET_LIST_COUNTER_NUMBER:
      return {
        ...state,
        listCounterNumber: action.listCounterNumber,
        err: ''
      }
    case types.GET_LIST_COUNTER_NUMBER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.DISCONNECT_COUNTER_TELLER:
      // console.log(action.counterID);

      return {
        ...state,
        // listCounter: updateListCounter
      }
    case types.DISCONNECT_COUNTER_TABLET:
      // console.log(action.counterID);

      return {
        ...state,
        // listCounter: updateListCounter
      }
    case types.DISCONNECT_COUNTER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.CLEAR_ERR:
      if (action.reducerTarget === "counterReducer") {
        if (!state.err) return state;
        return {
          ...state,
          err: ''
        }
      }
      return state;
    default:
      return state
  }
}