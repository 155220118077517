/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapOverlay, PopupConfirmResetWrapDiv } from './index.styles'

// component
import ButtonConfirmPopup from '../../Control/ButtonConfirmPopup'

// action
import { removeCounterLink, getCounterByOffice } from '../../../../../actions/counterAction'
import { officeUtils } from '../../../../Utils/OfficeUtils'

const PopupConfirmReset = ({
    counterInfos,
    setIsShowConfirmReset,
    currentPage,
    onShowErrPopup
}) => {
    const dispatch = useDispatch()

    const { dataLogin, generalOfficeSelected } = useSelector((state) => ({
        dataLogin: state.userReducer.dataLogin,
        generalOfficeSelected: state.officeManagementReducer.generalOfficeSelected
    }))

    useEffect(() => {
        document.body.style.overflowX = 'hidden'
        return () => {
            document.body.style.overflowX = 'visible'
        }
    }, [])


    const _callbackResetSuccess = useCallback(() => {
        let officeID = officeUtils.getOfficeID({
            dataLogin: dataLogin,
            generalOfficeSelected: generalOfficeSelected
        })
        //---
        dispatch(
            getCounterByOffice(officeID, 8, currentPage)
        )
        setIsShowConfirmReset(false)
    }, [])

    const _callbackResetError = useCallback((code) => {
        let officeID = officeUtils.getOfficeID({
            dataLogin: dataLogin,
            generalOfficeSelected: generalOfficeSelected
        })
        //---
        switch(code) {
            case 21:
                onShowErrPopup({
                    content: "Thoát quầy không thành công",
                    note: "(*) Quầy giao dịch đang ở trạng thái phục vụ khách hàng",
                })
                break;
            case 22:
                onShowErrPopup({
                    content: "Thoát quầy không thành công",
                    note: "(*) Quầy đang ở trạng thái giao dịch",
                })
                break;
            default:
                onShowErrPopup({
                    content: "Thoát quầy không thành công",
                    note: "(*) Quầy giao dịch không tồn tại hoặc đã bị xoá trước đó",
                })
                //---
                dispatch(
                    getCounterByOffice(officeID, 8, currentPage)
                )
                break;
        }
        setIsShowConfirmReset(false)
    }, [])

    const _handleResetCounter = () => {
        dispatch(
            removeCounterLink(
                counterInfos.ID,
                _callbackResetSuccess,
                _callbackResetError
            )
        )
    }

    return (
        <WrapOverlay onClick={()=>{return}}>
            {
                <PopupConfirmResetWrapDiv>
                    <div className="row_question">
                        <span className="img_question"></span>
                    </div>
                    <div className="row_title">
                        <p className="title">Xác nhận thoát quầy giao dịch</p>
                        <p className="contentAlert">
                            <span>Quầy sẽ ngắt kết nối với các thiết bị</span>
                            <span>đã liên kết</span>
                        </p>
                    </div>
                    <p></p>
                    <div className="row_btn_confirm">
                        <div className="btn_exit">
                            <ButtonConfirmPopup
                                titleConfirm="HỦY BỎ"
                                bg_color="rgb(215, 18, 73)"
                                onClick={() => setIsShowConfirmReset(false)}
                            />
                        </div>
                        <div className="btn_accept">
                            <ButtonConfirmPopup
                                titleConfirm="XÁC NHẬN"
                                bg_color="var(--peacock-blue)"
                                onClick={_handleResetCounter}
                            />
                        </div>
                    </div>
                </PopupConfirmResetWrapDiv>
            }
        </WrapOverlay>
    )
}

export default PopupConfirmReset
