/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { ContainerStyled } from './index.styles'
import ic_close from '../../../../images/ic_close_gray.svg'
import InputTextControl from '../../MainScreen/Control/InputTextControl'
import MultiChoiceDropdownControl from '../../MainScreen/Control/MultiChoiceDropDownControl'
import SingleChoiseDropDownControl from '../../MainScreen/Control/SingleChoiceDropDownControl'
import { useDispatch, useSelector } from 'react-redux'
import IpValidate from 'ip-validator'
import { getCounterByOffice } from '../../../../actions/counterAction'
import { allCounters } from '../../../../data/counterNumber'
import { getAllCounterServices } from '../../../../actions/counterServiceAction'
import {officeUtils} from '../../../Utils/OfficeUtils'
import { ADMIN_OFFICE_ROLE } from '../../../../data/userRole'

export default function PopupAddCounterV2({
    title, 
    counterData,
    onClosePopup,
    onShowPopupAlert,
    onSubmit
}) {
    const dispatch = useDispatch();
    //---
    const { 
        dataLogin, 
        listCounter, 
        totalNumberOfCounters, 
        storageCounterNumberList,
        generalOfficeSelected
    } = useSelector((state) => ({
        dataLogin: state.userReducer.dataLogin,
        listCounter: state.counterReducer.listCounter,
        storageCounterNumberList:  state.counterReducer.listCounterNumber,
        totalNumberOfCounters: state.counterReducer.total,
        generalOfficeSelected: state.officeManagementReducer.generalOfficeSelected
    }))

    let {listTypeCounterServices} = useSelector((state) => ({
        listTypeCounterServices: state.counterReducer.listTypeCounterServices,
    }))

    const [idShow, setIdShow] = useState('')
    const [numberCounterList, setNumberCounterList] = useState([])
    const [isEmptyTrans, setIsEmptyTrans] = useState(false)
    const [errorDesktopkey, setErrorDesktopkey] = useState('')
    const [transServiceList, setTransServiceList] = useState([
        {
            id: "1",
            selectedStatus: true,
            name: "Tất cả giao dịch"
        },
    ])
    const [counterInfo, setCounterInfo] = useState(counterData)
    const [desktopKey, setDesktopKey] = useState(
        counterData
        ? counterData.DeviceMac
        : ''
    )
    const [numberCounter, setNumberCounter] = useState(
        counterData
        ? counterData.CounterNumber
        : ''
    )
    const [nameCounter, setNameCounter] = useState(
        counterData
        ? counterData.CounterName
        : ''
    )
    const [priorityCounter, setPriorityCounter] = useState(
        counterData
        ? counterData.IsVIP
        : ''
    )

    const dataServiceChoicedRef = useRef(null)

    const onUpdateChoiceItemList = (dataInfo) => {
        dataServiceChoicedRef.current = dataInfo
        console.log("onUpdateChoiceItemList: ", dataServiceChoicedRef.current);
    }

    const onDesktopKeyChange = (value) => {
        setDesktopKey(value)
    }

    const onNameCounterChange = (value) => {
        setNameCounter(value)
    }

    const onIdShowChange = (value) => {
        setIdShow(value)
    }

    const onNumberCounterChange = (value) => {
        setNumberCounter(value)
    }

    const ontoggleCheckboxChange = () => {
        setPriorityCounter(!priorityCounter)
    }

    const onSubmitAction = (counterInfo) => {
        setIsEmptyTrans(false)
        setErrorDesktopkey('')
        //---
        const dataCounter = {
            OfficeID: officeUtils.getOfficeID({
                dataLogin: dataLogin,
                generalOfficeSelected: generalOfficeSelected
            })
        }
        if (counterInfo) {
            if (
                nameCounter &&
                listCounter.find(
                    (item) =>
                        item.ID !== counterInfo.ID &&
                        priorityCounter &&
                        item.CounterName.trim().replace(/\s+/g, ' ') ===
                            nameCounter.trim().replace(/\s+/g, ' ')
                )
            ) {
                onShowPopupAlert('Tên quầy giao dịch đã tồn tại.')
                return
            }
            dataCounter.CounterID = counterInfo.ID
            if (counterInfo.CounterNumber !== numberCounter)
                dataCounter.CounterNum = numberCounter
            dataCounter.IsVIP = priorityCounter
            if (counterInfo.CounterName !== nameCounter)
                dataCounter.CounterName = nameCounter.trim()
            //---
            dataCounter.ServAllService = true;
            if(!dataCounter.IsVIP){
                dataCounter.ServAllService = dataServiceChoicedRef.current.indexSelected === 0
            }
            //---
            if(!dataCounter.ServAllService){
                dataCounter.ServiceList = dataServiceChoicedRef.current.dataList.map((item)=>{
                    return item.id
                })
            } else {
                dataCounter.ServiceList = []
            }
           
            // debugger;
            if (Object.keys(dataCounter).length <= 2){
                return
            }
            if (!dataCounter.ServAllService && dataCounter.ServiceList.length === 0) {
                setIsEmptyTrans(true)
                return
            }

            if (counterInfo.DeviceMac && !desktopKey.trim()) {
                setErrorDesktopkey('PC NAME không được để trống.')
                return
            }

            if (
                counterInfo.DeviceMac &&
                /\s+/g.test(desktopKey)
            ) {
                setErrorDesktopkey('PC NAME không có khoảng trắng.')
                return
            }

            if (counterInfo.DeviceMac && (IpValidate.ipv4(desktopKey) || IpValidate.ipv6(desktopKey))) {
                setErrorDesktopkey('PC NAME không là địa chỉ IP.')
                return
            }

            dataCounter.DeviceMac = desktopKey.trim().toUpperCase()
    
            //---
            onSubmit(dataCounter)

            return
        } else {
            if (
                nameCounter &&
                listCounter.find(
                    (item) =>
                        item.CounterName.trim().replace(/\s+/g, ' ') ===
                        nameCounter.trim().replace(/\s+/g, ' ')
                )
            ) {
                onShowPopupAlert('Tên quầy giao dịch đã tồn tại.')
                return
            }
        }

        dataCounter.CounterNum = numberCounter
        dataCounter.CounterName = nameCounter
        if (priorityCounter) dataCounter.IsVIP = true
        //---
        dataCounter.ServAllService = true;
        if(!dataCounter.IsVIP){
            dataCounter.ServAllService = dataServiceChoicedRef.current.indexSelected === 0
        }
        //---
        if(!dataCounter.ServAllService){
            dataCounter.ServiceList = dataServiceChoicedRef.current.dataList.map((item)=>{
                return item.id
            })
        } else {
            dataCounter.ServiceList = []
        }
        //---
        if (!dataCounter.ServAllService && dataCounter.ServiceList.length === 0) {
            setIsEmptyTrans(true)
            return
        }
        //---
        onSubmit(dataCounter)
    }

    const onUpdateCounterInfo = () => {
        const counterID = counterInfo ? counterInfo.ID : ""
        dispatch(getCounterByOffice(
                    officeUtils.getOfficeID({
                        dataLogin: dataLogin,
                        generalOfficeSelected: generalOfficeSelected
                    }), 
                    totalNumberOfCounters, 
                    1, false, counterID, 
                    (listCounterNumber, counterInfo)=>{
                if (listCounterNumber === null || listCounterNumber.length === 0){
                    listCounterNumber = storageCounterNumberList
                }
                if(counterInfo === null){
                    counterInfo = counterData
                }
                //--- 
                const newNumberCounterList = allCounters.reduce(
                    (arr, item) =>
                        !listCounterNumber.find((i) => i === item)
                            ? [...arr, item]
                            : arr,
                    []
                )
                if (counterInfo){
                    setNumberCounterList([
                        counterInfo.CounterNumber,
                        ...newNumberCounterList,
                    ].sort((a, b) => a - b))
                    setPriorityCounter(counterInfo.IsVIP)
                    setNameCounter(counterInfo.CounterName)
                    setNumberCounter(counterInfo.CounterNumber)
                    setDesktopKey(counterInfo.DeviceMac)
                    setCounterInfo(counterInfo)
                } else {
                    setNumberCounterList(newNumberCounterList);
                }
            })
        )
    }

    const onUpdateTransServices = () => {
        if(typeof(listTypeCounterServices) !== 'object' ||
            Object.keys(listTypeCounterServices).length !== 2 ||
            (listTypeCounterServices.personCustomer.length === 0 && listTypeCounterServices.corpCustomer.length === 0)){
                dispatch(getAllCounterServices({
                    uid: dataLogin.UID,
                    status: dataLogin.Roles.includes(ADMIN_OFFICE_ROLE)?'A':'',
                    onCompletedFunc: (dataResp) => {
                        if(dataResp.code === 0){
                            listTypeCounterServices = dataResp.result
                            updateListTypeCounterServices(listTypeCounterServices)
                        } else {
                            onShowPopupAlert()
                        }
                    }
                }))
        } else {
            updateListTypeCounterServices(listTypeCounterServices)
        }
    }

    const updateListTypeCounterServices = (listTypeCounterServices) => {
        const newTransServiceList = getTransServiceList(
            counterInfo,
            listTypeCounterServices
        )
        //---
        setTransServiceList(newTransServiceList)
    }

    const getTransServiceList = (counterInfo, transServices) => {
        const personCustomerServices = makeServiceList(transServices.personCustomer)
        const corpCustomerServices = makeServiceList(transServices.corpCustomer)
        //---
        let indexSelected = 0
        if(counterInfo){
            indexSelected = counterInfo.ServAllService
                            ? 0
                            : counterInfo.ServiceList && counterInfo.ServiceList.length > 0
                                ? 1
                                : 0
        }
        return [
            {
                id: "1",
                selectedStatus: indexSelected === 0,
                name: "Tất cả giao dịch"
            },
            {
                id: "2",
                selectedStatus: indexSelected !== 0 && personCustomerServices.selectedStatus,
                name: "Danh sách giao dịch - KH Cá nhân",
                dataList: personCustomerServices.serviceList
            },
            {
                id: "3",
                selectedStatus: indexSelected !== 0 && corpCustomerServices.selectedStatus,
                name: "Danh sách giao dịch - KH Doanh nghiệp",
                dataList: corpCustomerServices.serviceList
            }
        ]
    }

    const makeServiceList = (serviceList) => {
        let selectedStatus = false
        let dataList = serviceList.map((service) => {
            let newDataList = []
            if(service.Children && service.Children.length){
                newDataList = service.Children.map((item) => {
                    let itemSelectedStatus = false
                    if(counterInfo){
                        itemSelectedStatus = counterInfo.ServAllService
                        const serviceChoicedList = counterInfo.ServiceList
                        //---
                        if(!itemSelectedStatus && serviceChoicedList && serviceChoicedList.length > 0){
                            const index = serviceChoicedList.indexOf(item.ID)
                            if(index !== -1){
                                itemSelectedStatus = true
                                if(!selectedStatus){
                                    selectedStatus = true
                                }
                            }
                        }
                    }
                    //----
                    return {
                        id: item.ID,
                        name: item.Name,
                        selectedStatus: itemSelectedStatus,
                        groupId: item.ParentID,
                    } 
                })
            }
            return {
                id: service.ID,
                name: service.Name,
                groupCode: service.GroupCode,
                dataList: newDataList
            }
        })
        //---
        return {
            selectedStatus: selectedStatus,
            serviceList: dataList
        }
    }
    

    const renderHeader = ()=>{
        return (
            <div className="row_header">
                <div className="titleHeader">{title}</div>
                <div>
                    <img
                        onClick={() => onClosePopup('')}
                        className="ic_close"
                        src={ic_close}
                        alt="abc"
                    />
                </div>
            </div>
        )
    }

    const renderNameCounter = () => {
        return (
            <>
                <h4>tên quầy giao dịch </h4>
                <div className="NameCounter">
                    <InputTextControl
                        name="nameCounter"
                        onChange={onNameCounterChange}
                        placeHolder="Nhập tên quầy giao dịch"
                        isDisabled={
                            !!counterInfo &&
                            counterInfo.DesktopDevice &&
                            counterInfo.TabletDevice
                        }
                        defaultValue={
                            counterInfo ? counterInfo.CounterName : ''
                        }
                        maxLength={50}
                    />
                </div>
            </>
        )
    }

    const renderTransServiceList = () => {
        return (
            <>
                <h4>Giao dịch thực hiện</h4>
                <div className="AllCounter" style={{ marginTop: '5px' }}>
                    <MultiChoiceDropdownControl
                        mainLabel='Danh sách giao dịch'
                        isDisabled={priorityCounter}
                        dataList={transServiceList}
                        onUpdateChoiceItemList={onUpdateChoiceItemList}
                    />
                </div>
                {isEmptyTrans && (
                    <div className="div-alert">
                        Vui lòng chọn ít nhất 1 giao dịch.
                    </div>
                )}
            </>
        )
    }

    const renderPCName = () => {
        return (
            <>
                <h4>PC NAME</h4>
                <div className="NameCounter">
                    <InputTextControl
                        name="desktopKey"
                        onChange={onDesktopKeyChange}
                        placeHolder="Nhập PC NAME"
                        defaultValue={counterInfo.DeviceMac}
                        maxLength={50}
                    />
                </div>
                {!!errorDesktopkey && (
                    <div className="div-alert">{errorDesktopkey}</div>
                )}
            </>
        )
    }

    const renderNumberCounterList = () => {
        return (
            <>
                <h4>Số quầy </h4>
                <div className="row_flex">
                    <div className="numberCounter">
                        <SingleChoiseDropDownControl
                            id="selectNumberCounter"
                            idShow={idShow}
                            setIdShow={onIdShowChange}
                            titleChoise="Chọn số quầy"
                            getText={onNumberCounterChange}
                            data={numberCounterList}
                            isDisable={
                                counterInfo &&
                                counterInfo.DesktopDevice &&
                                counterInfo.TabletDevice
                            }
                            defaultValue={
                                numberCounter
                            }
                        />
                    </div>
                    <div className="checkBox_priority">
                        <label className="radio-field">
                            <input
                                type="checkbox"
                                name="content"
                                value="abc"
                                checked={priorityCounter}
                                onChange={ontoggleCheckboxChange}
                            />
                            <div className="checkmark"></div>
                            <div className="text_label">Quầy ưu tiên</div>
                        </label>
                    </div>
                </div>
            </>
        )
    }

    const renderBody = () => {
        return (
            <div className="row_main">
                {renderNameCounter()}
                {renderTransServiceList()}
                {counterInfo && counterInfo.DesktopDeviceID !== null && 
                    renderPCName()
                }
                {renderNumberCounterList()}
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <div className="row_btn">
                <div className="guid-text">
                    (*) Hệ thống sẽ gọi tên quầy dựa trên “số quầy” được
                    cấu hình trong hệ thống.
                </div>
                <div className="btn-add">
                    <div
                        onClick={() => {
                            if (!numberCounter || !nameCounter.trim())
                                return
                            onSubmitAction(counterInfo)
                        }}
                        className="btn_success"
                        style={{
                            opacity:
                                !numberCounter ||
                                !nameCounter.trim()
                                    ? '0.5'
                                    : 'unset',
                            cursor:
                                !numberCounter ||
                                !nameCounter.trim()
                                    ? 'default'
                                    : 'pointer',
                        }}
                    >
                        {counterInfo ? 'XÁC NHẬN' : 'HOÀN TẤT'}
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        onUpdateTransServices()
        onUpdateCounterInfo()
        //---
        return () => {
            dataServiceChoicedRef.current = null
        }
    }, [])

    useEffect(()=>{
        if(!numberCounter && numberCounterList.length > 0){
            setNumberCounter(numberCounterList[0])
        }
    }, [numberCounterList])

    return (
        <ContainerStyled>
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
        </ContainerStyled>
    )
}
