// type of alert
export const ALERT_CONTENT = 'ALERT_CONTENT'

// type of ads
export const GET_ADS = 'GET_ADS'
export const GET_ADS_ERR = 'GET_ADS_ERR'
export const RESET_ADS_REDUCER = 'RESET_ADS_REDUCER'

export const CREATE_NEW_ADS = 'CREATE_NEW_ADS'
export const CREATE_NEW_ADS_ERR = 'CREATE_NEW_ADS_ERR'

export const UPDATE_ADS = 'UPDATE_ADS'
export const UPDATE_ADS_ERR = 'UPDATE_ADS_ERR'

export const DELETE_ADS = 'DELETE_ADS'
export const DELETE_ADS_ERR = 'DELETE_ADS_ERR'

export const SET_STATUS_ADS = 'SET_STATUS_ADS'
export const SET_STATUS_ADS_ERR = 'SET_STATUS_ADS_ERR'

export const GET_FILE_ADS = 'GET_FILE_ADS'
export const GET_FILE_ADS_ERR = 'GET_FILE_ADS_ERR'

export const SAVE_TEXT_ADS = 'SAVE_TEXT_ADS'
export const CLEAR_TEXT_ADS = 'CLEAR_TEXT_ADS'

// type of office
export const CREATE_NEW_OFFICE = 'CREATE_NEW_OFFICE'
export const CREATE_NEW_OFFICE_ERR = 'CREATE_NEW_OFFICE_ERR'

export const CLEAR_FILTER_OFFICE = 'CLEAR_FILTER_OFFICE'
export const FILTER_OFFICE = 'FILTER_OFFICE'
export const FILTER_OFFICE_ERR = 'FILTER_OFFICE_ERR'

export const GET_ALL_OFFICE = 'GET_ALL_OFFICE'
export const GET_ALL_OFFICE_ERR = 'GET_ALL_OFFICE_ERR'

export const GET_ALL_BRANCH = 'GET_ALL_BRANCH'
export const GET_ALL_BRANCH_ERR = 'GET_ALL_BRANCH_ERR'

export const UPDATE_OFFICE = 'UPDATE_OFFICE'
export const UPDATE_OFFICE_ERR = 'UPDATE_OFFICE_ERR'

export const DELETE_OFFICE = 'DELETE_OFFICE'
export const DELETE_OFFICE_ERR = 'DELETE_OFFICE_ERR'
export const CLEAR_ERR_DELETE_OFFICE = 'CLEAR_ERR_DELETE_OFFICE'

export const RESET_STATE_OFFICE = 'RESET_STATE_OFFICE'

export const SAVE_TEXT_OFFICE = 'SAVE_TEXT_OFFICE'
export const CLEAR_TEXT_OFFICE = 'CLEAR_TEXT_OFFICE'

export const GET_OFFICES_MANAGEMENT = 'GET_OFFICES_MANAGEMENT'
export const GET_OFFICES_MANAGEMENT_ERR = 'GET_OFFICES_MANAGEMENT_ERR'
export const UPDATE_GENARAL_OFFICE_SELECTED = 'UPDATE_GENARAL_OFFICE_SELECTED'

// type of user
export const ADD_USER = 'ADD_USER'
export const ADD_USER_ERR = 'ADD_USER_ERR'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_ERR = 'UPDATE_USER_ERR'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_ERR = 'DELETE_USER_ERR'

export const FORCE_SIGN_OUT_USER = 'FORCE_SIGN_OUT_USER'
export const FORCE_SIGN_OUT_USER_ERR = 'FORCE_SIGN_OUT_USER_ERR'

export const FILTER_USER = 'FILTER_USER'
export const FILTER_USER_ERR = 'FILTER_USER_ERR'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_ERR = 'LOGIN_USER_ERR'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_ERR = 'LOGOUT_USER_ERR'

export const SEARCH_USER_TO_ADD_BY_EMAIL = 'SEARCH_USER_TO_ADD_BY_EMAIL'
export const SEARCH_USER_TO_ADD_BY_EMAIL_ERR = 'SEARCH_USER_TO_ADD_BY_EMAIL_ERR'

export const SAVE_TEXT_SEARCH = 'SAVE_TEXT_SEARCH'
export const CLEAR_TEXT_SEARCH = 'CLEAR_TEXT_SEARCH'

//type of counter
export const CLEAR_KEY_GEN = 'CLEAR_KEY_GEN'
export const CLEAR_KEY_GEN_COUNTER = 'CLEAR_KEY_GEN_COUNTER'

export const DEVICE_CONNECTED = "DEVICE_CONNECTED";

export const GET_DATA_NOT_VIEW_YET = 'GET_DATA_NOT_VIEW_YET';
export const GET_DATA_NOT_VIEW_YET_ERR = 'GET_DATA_NOT_VIEW_YET_ERR';

export const GET_LIST_OF_USERNAME = 'GET_LIST_OF_USERNAME'
export const GET_LIST_OF_USERNAME_ERR = 'GET_LIST_OF_USERNAME_ERR'

export const GET_LIST_HEADSTAFF_USERNAME = 'GET_LIST_HEADSTAFF_USERNAME'
export const GET_LIST_HEADSTAFF_USERNAME_ERR = 'GET_LIST_HEADSTAFF_USERNAME_ERR'

export const REMOVE_COUNTER_LINK = "REMOVE_COUNTER_LINK";
export const REMOVE_COUNTER_LINK_ERR = "REMOVE_COUNTER_LINK_ERR";

export const CREATE_COUNTER = 'CREATE_COUNTER'
export const CREATE_COUNTER_ERR = 'CREATE_COUNTER_ERR'

export const UPDATE_COUNTER = 'UPDATE_COUNTER'
export const UPDATE_COUNTER_ERR = 'UPDATE_COUNTER_ERR'

export const GET_COUNTER_BY_OFFICE = 'GET_COUNTER_BY_OFFICE'
export const GET_COUNTER_BY_OFFICE_ERR = 'GET_COUNTER_BY_OFFICE_ERR'

export const DELETE_COUNTER = 'DELETE_COUNTER'
export const DELETE_COUNTER_ERR = 'DELETE_COUNTER_ERR'

export const GEN_DESKTOP_KEY = 'GEN_DESKTOP_KEY'
export const GEN_DESKTOP_KEY_ERR = 'GEN_DESKTOP_KEY_ERR'

export const GEN_TABLET_KEY = 'GEN_TABLET_KEY'
export const GEN_TABLET_KEY_ERR = 'GEN_TABLET_KEY_ERR'

export const GET_LIST_COUNTER_NUMBER = 'GET_LIST_COUNTER_NUMBER'
export const GET_LIST_COUNTER_NUMBER_ERR = 'GET_LIST_COUNTER_NUMBER_ERR'

export const DISCONNECT_COUNTER_TELLER = 'DISCONNECT_COUNTER_TELLER'
export const DISCONNECT_COUNTER_TABLET = 'DISCONNECT_COUNTER_TABLET'
export const DISCONNECT_COUNTER_ERR = 'DISCONNECT_COUNTER_ERR'

// type of kiosk
export const CLEAR_KEY_GEN_KIOSK = 'CLEAR_KEY_GEN_KIOSK'

export const CREATE_KIOSK = 'CREATE_KIOSK'
export const CREATE_KIOSK_ERR = 'CREATE_KIOSK_ERR'

export const UPDATE_KIOSK = 'UPDATE_KIOSK'
export const UPDATE_KIOSK_ERR = 'UPDATE_KIOSK_ERR'

export const GET_ALL_KIOSK = 'GET_ALL_KIOSK'
export const GET_ALL_KIOSK_ERR = 'GET_ALL_KIOSK_ERR'

export const CONNECT_KIOSK = 'CONNECT_KIOSK'
export const CONNECT_KIOSK_ERR = 'CONNECT_KIOSK_ERR'

export const DISCONNECT_KIOSK = 'DISCONNECT_KIOSK'
export const DISCONNECT_KIOSK_ERR = 'DISCONNECT_KIOSK_ERR'

export const GENERATE_KEY_KIOSK = 'GENERATE_KEY_KIOSK'
export const GENERATE_KEY_KIOSK_ERR = 'GENERATE_KEY_KIOSK_ERR'

export const DELETE_KIOSK = 'DELETE_KIOSK'
export const DELETE_KIOSK_ERR = 'DELETE_KIOSK_ERR'

// export const UPDATE_EFORM = 'UPDATE_EFORM'
// export const UPDATE_EFORM_ERR = 'UPDATE_EFORM_ERR'

// type of LCD
export const CREATE_LCD = 'CREATE_LCD'
export const CREATE_LCD_ERR = 'CREATE_LCD_ERR'

export const UPDATE_LCD = 'UPDATE_LCD'
export const UPDATE_LCD_ERR = 'UPDATE_LCD_ERR'

export const GET_ALL_LCD = 'GET_ALL_LCD'
export const GET_ALL_LCD_ERR = 'GET_ALL_LCD_ERR'

export const DELETE_LCD = 'DELETE_LCD'
export const DELETE_LCD_ERR = 'DELETE_LCD_ERR'

export const GENERATE_KEY_LCD = 'GENERATE_KEY_LCD'
export const GENERATE_KEY_LCD_ERR = 'GENERATE_KEY_LCD_ERR'

export const CLEAR_KEY_GEN_LCD = 'CLEAR_KEY_GEN_LCD'

// type of supervisor
export const GET_BIO_INFOS = 'GET_BIO_INFOS'
export const GET_BIO_INFOS_ERR = 'GET_BIO_INFOS_ERR'

export const UPDATE_BIO_INFOS_NOTE = 'UPDATE_BIO_INFOS_NOTE'
export const UPDATE_BIO_INFOS_NOTE_ERR = 'UPDATE_BIO_INFOS_NOTE_ERR'

export const ACCEPT_BIO_INFOS = 'ACCEPT_BIO_INFOS'
export const ACCEPT_BIO_INFOS_ERR = 'ACCEPT_BIO_INFOS_ERR'

export const REJECT_BIO_INFOS = 'REJECT_BIO_INFOS'
export const REJECT_BIO_INFOS_ERR = 'REJECT_BIO_INFOS_ERR'

export const CLEAR_ERR_APPROVE = 'CLEAR_ERR_APPROVE'

export const RUN_LOADING = 'RUN_LOADING'
export const REMOVE_LOADING = 'REMOVE_LOADING'

export const ALERT_LOGOUT = 'ALERT_LOGOUT'
export const ALERT_ERR = 'ALERT_ERR'

export const ERR_CONNECTION = 'ERR_CONNECTION'
export const REMOVE_ERR_CONNECTION = 'REMOVE_ERR_CONNECTION'

export const ERR_CONNECTION_TIMEOUT = 'ERR_CONNECTION_TIMEOUT'
export const REMOVE_ERR_CONNECTION_TIMEOUT = 'REMOVE_ERR_CONNECTION_TIMEOUT'

export const ERR_MATCH_BRANCHCODE = 'ERR_MATCH_BRANCHCODE'
export const REMOVE_ERR_MATCH_BRANCHCODE = 'REMOVE_ERR_MATCH_BRANCHCODE'

export const GET_VERIFY_FINGER_HISTORY = 'GET_VERIFY_FINGER_HISTORY'
export const GET_VERIFY_FINGER_HISTORY_ERR = 'GET_VERIFY_FINGER_HISTORY_ERR'

export const UPDATE_VERIFY_FINGER = 'UPDATE_VERIFY_FINGER'
export const UPDATE_VERIFY_FINGER_ERR = 'UPDATE_VERIFY_FINGER_ERR'

export const SAVE_TEXT_DATA_BIOMETRIC = 'SAVE_TEXT_DATA_BIOMETRIC'
export const CLEAR_TEXT_DATA_BIOMETRIC = 'CLEAR_TEXT_DATA_BIOMETRIC'

export const SAVE_TEXT_VERIFY_CUSTOMER = 'SAVE_TEXT_VERIFY_CUSTOMER'
export const CLEAR_TEXT_VERIFY_CUSTOMER = 'CLEAR_TEXT_VERIFY_CUSTOMER'
export const CLEAR_ERR = 'CLEAR_ERR'

export const SAVE_USER_PASS = 'SAVE_USER_PASS'
export const SAVE_USER_PASS_ERR = 'SAVE_USER_PASS_ERR'

export const SOCKET_GET_BIOS_INFO = 'SOCKET_GET_BIOS_INFO'
export const SOCKET_GET_VERIFY_CUSTOMER = 'SOCKET_GET_VERIFY_CUSTOMER'

export const GET_BIO_HISTORY_LIST = 'GET_BIO_HISTORY_LIST'
export const GET_BIO_HISTORY_LIST_ERR = 'GET_BIO_HISTORY_LIST_ERR'

export const GET_BIO_HISTORY_INFO = 'GET_BIO_HISTORY_INFO'
export const GET_BIO_HISTORY_INFO_ERR = 'GET_BIO_HISTORY_INFO_ERR'

export const GET_LIST_ERROR_ACCEPT_BIOS = 'GET_LIST_ERROR_ACCEPT_BIOS'
export const GET_LIST_ERROR_ACCEPT_BIOS_ERR = 'GET_LIST_ERROR_ACCEPT_BIOS_ERR'
export const CLEAR_LIST_ERROR_ACCEPT_BIOS = 'CLEAR_LIST_ERROR_ACCEPT_BIOS'

export const FIX_ERROR_ACCEPT_BIOS = 'FIX_ERROR_ACCEPT_BIOS'
export const FIX_ERROR_ACCEPT_BIOS_ERR = 'FIX_ERROR_ACCEPT_BIOS_ERR'

export const GET_LIST_CHANNEL = 'GET_LIST_CHANNEL'
export const GET_LIST_CHANNEL_ERR = 'GET_LIST_CHANNEL_ERR'

export const GET_LIST_CUSTOMER = 'GET_LIST_CUSTOMER'
export const GET_LIST_CUSTOMER_ERR = 'GET_LIST_CUSTOMER_ERR'
export const CLEAR_LIST_CUSTOMER = 'CLEAR_LIST_CUSTOMER'

export const DELETE_BIOMETRIC_OF_CUSTOMER = 'DELETE_BIOMETRIC_OF_CUSTOMER'
export const DELETE_BIOMETRIC_OF_CUSTOMER_ERR = 'DELETE_BIOMETRIC_OF_CUSTOMER_ERR'
// type of qrTransPoint
export const CREATE_QR_TRANS_POINT = 'CREATE_QR_TRANS_POINT'
export const CREATE_QR_TRANS_POINT_ERR = 'CREATE_QR_TRANS_POINT_ERR'
export const RESET_QR_TRANS_POINT = 'RESET_QR_TRANS_POINT'
export const GET_QR_TRANS_POINT_ERR = 'GET_QR_TRANS_POINT_ERR'

// type of confAppointmentSchedulle
export const GET_GENERAL_CONFIG_ERR = 'GET_GENERAL_CONFIG_ERR'
export const SET_GENERAL_CONFIG_ERR = 'SET_GENERAL_CONFIG_ERR'
export const GET_OFFICES_CONFIG_ERR = 'GET_OFFICES_CONFIG_ERR'
export const SET_OFFICES_CONFIG_ERR = 'SET_OFFICES_CONFIG_ERR'
export const FILTER_OFFICES_CONFIG_ERR = 'FILTER_OFFICES_CONFIG_ERR'

//type of Role
export const GET_ROLE_LIST_ERR = 'GET_ROLE_LIST_ERR'
export const GET_ROLE_CONFIG_ERR = 'GET_ROLE_CONFIG_ERR'
export const ADD_ROLE_ERR = 'ADD_ROLE_ERR'
export const REMOVE_ROLE_ERR = 'REMOVE_ROLE_ERR'
export const UPDATE_ROLE_ERR = 'UPDATE_ROLE_ERR'
export const ADD_ROLE_LIST_ERR = 'ADD_ROLE_LIST_ERR'

//type of AdviseService
export const GET_ADVISE_SERVICE_CONFIG_ERR = 'GET_ADVISE_SERVICE_CONFIG_ERR'
export const CREATE_ADVISE_SERVICE_ERR = 'CREATE_ADVISE_SERVICE_ERR'
export const REMOVE_ADVISE_SERVICE_ERR = 'REMOVE_ADVISE_SERVICE_ERR'
export const UPDATE_ADVISE_SERVICE_ERR = 'UPDATE_ADVISE_SERVICE_ERR'
export const GET_ADVISE_SERVICE_LIST_ERR = 'GET_ADVISE_SERVICE_LIST_ERR'

//type of CounterService
export const GET_ALL_COUNTER_SERVICES = 'GET_ALL_COUNTER_SERVICES'
export const GET_ALL_COUNTER_SERVICES_ERR = 'GET_ALL_COUNTER_SERVICES_ERR'
export const GET_COUNTER_SERVICE_CONFIG_ERR = 'GET_COUNTER_SERVICE_CONFIG_ERR'
export const CREATE_GROUP_COUNTER_SERVICE_ERR = 'CREATE_GROUP_COUNTER_SERVICE_ERR'
export const UPDATE_GROUP_COUNTER_SERVICE_ERR = 'UPDATE_GROUP_COUNTER_SERVICE_ERR'
export const CREATE_COUNTER_SERVICE_ERR = 'CREATE_COUNTER_SERVICE_ERR'
export const UPDATE_COUNTER_SERVICE_ERR = 'UPDATE_COUNTER_SERVICE_ERR'
