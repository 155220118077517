import React from 'react'
import * as styles from './index.styles'

const ButtonControl = ({isDisable, icon, content, widthButton, heightButton, fontSize, fontWeight, backgroundColor, onClick, ...props }) => {
    const onClickAction  = () => {
        if(isDisable){
            return
        }
        //---
        onClick && onClick()
    }
    return (
        <styles.Bound 
            isDisable={isDisable}
            widthButton={widthButton} 
            heightButton={heightButton} 
            backgroundColor={backgroundColor}
            fontSize={fontSize} 
            fontWeight={fontWeight}
            onClick={onClickAction}
            {...props}>
            {
                icon && <img src={icon} alt="icon" />
            }
            <p>{content}</p>
        </styles.Bound>
    )
}

export default ButtonControl
