import { css } from 'styled-components'

const lstPxs = [115, 290, 170, 194, 120, 120, 100, 130, 144]
const sumPx = lstPxs.reduce((total, curr) => total + curr)
const gridArr = lstPxs.map(
    (item) => `minmax(${item}px, ${Math.round((item / sumPx) * 100)}fr)`
)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`

const extendStyleTeller = css`
    width: 220px;
`

export { extendStylesTable, extendStyleTeller }
