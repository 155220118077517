/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { WrapOverlay, WrapContainer, WrapContent } from './index.styles'
import ic_close from "../../../../../../images/ic_close_gray.svg"
import ic_refresh from "../../../../../../images/ic_refresh.svg"
import ConfigureByBranchTable from '../ConfigureByBranchTable'
import ButtonControl from '../../../Control/ButtonControl'
import { useState } from 'react'

export default function UploadFilePopup({fileName, dataList, onChangeFile, onConfirm, onClose}) {
  const [itemSelected, setItemSelected] =  useState(null)
  //---
  const onConfirmBt = () => {
    onConfirm && onConfirm()
  }

  const onCloceBt = () => {
    onClose && onClose()
  }


  const renderHeaderContent = (fileName) => {
    const onChangeBt = () => {
      setItemSelected(null)
      onChangeFile && onChangeFile()
    }
    return (
      <div className="header">
        <div className="title-content">
          <div className="label">Tải file lên</div>
          <div className="btn-container">
            <img className="icon" src={ic_close} alt='ic_close' onClick={onCloceBt}/>
          </div>
        </div>
        <div className="body-content">
          <div className="file-content">
            <div className="label">File tải lên: </div>
            <div className="value">{fileName}</div>
          </div>
          <div className="change-button" onClick={onChangeBt}>
            <img className="icon" src={ic_refresh} alt='ic_close'/>
            <div className="label">Thay đổi</div>
          </div>
        </div>
      </div>
    )
  }

  const renderBodyContent = (dataList) => {
    const onItemSelected = (dataInfo) => {
      setItemSelected(dataInfo)
    }

    return (
      <div className="body">
        <ConfigureByBranchTable
          isUploadFilePopup={true}
          currentPage={1}
          limitPerPage={dataList.length}
          dataList={dataList}
          onItemSelected={onItemSelected}
          itemSelectedBgColor='#DEF5FF'
        />
      </div>
    )
  }

  const checkErr = () => {
    let result = false;
    for(var i = 0; i < dataList.length; i++){
      if(dataList[i].errorList.length > 0){
        result = true
        break
      }
    }
    return result
  }

  const getTotalNumberOfErrRows = () => {
    let result = 0;
    for(var i = 0; i < dataList.length; i++){
      if(dataList[i].errorList.length > 0){
        result++;
      }
    }
    return result
  }

  const rendFileErr = () => {
    let errContent = null
    const totalNumberOfErrRows = getTotalNumberOfErrRows()
    if(totalNumberOfErrRows > 0){
      errContent =  (
        <>
          <div>Đã tìm thấy {totalNumberOfErrRows} hàng có dữ liệu không đúng</div>
          <div>Vui lòng kiểm tra lại file</div>
        </>
      )
    }
    return errContent
  }

  return (
    <WrapOverlay>
      <WrapContainer>
        <WrapContent>
          {renderHeaderContent(fileName)}
          {renderBodyContent(dataList)}
          <div className="footer">
            <div className="err-content">
              {
                itemSelected !== null
                ? itemSelected.errorList.map((item, index)=>{
                  return (
                    <div key={`uploadFile_errItem_${index+1}`}>{item}</div>
                  )
                })
                : rendFileErr()
              }
            </div>
            <div className="btn-content">
              <ButtonControl
                isDisable={checkErr()}
                className="btn-content"
                content={"Xác nhận"}
                widthButton={"97px"}
                heightButton={"33px"}
                fontSize={"14px"}
                fontWeight={700}
                onClick={onConfirmBt}
              />
            </div>
          </div>
        </WrapContent>
      </WrapContainer>
    </WrapOverlay>
  )
}
