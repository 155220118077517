/* eslint-disable no-multi-str */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import readXlsxFile from 'read-excel-file'

// styles
import { Wrapper } from './index.styles'

// component
import Header from './Header';
import Footer from './Footer';
import SearchUser from './SearchUser';
import SetupUser from './SetupUser';
import TableListUser from './TableListUser';

// action
import { getAllOfficeV2 } from '../../../../actions/officeAction';
import { searchUserToAddByEmail, addUser, filterUser } from '../../../../actions/userAction';
import { clearError } from '../../../../actions/commonAction';

// api
import { apiGetListReports } from '../../../../actions/reportsAction/listReports/listReports';
import { apiAddUser, apiSearchUserToAddByEmail } from '../../../../actions/userAction'

// constant
import * as userRole from '../../../../data/userRole';
import { MAIN_OFFICE_BRANCH_CODE } from '../../../../data/commonConstant'

export const STEP_SEARCH_USER = 1;
export const STEP_SETUP_USER = 2;
export const STEP_CONFIRM_SEARCH_USERS = 3;
export const STEP_CONFIRM_ADD_USERS = 4;
export const STEP_RESULT_ADD_USERS = 5;
const GDV = 'GDV'
const KSV = 'KSV'
const DIENTOAN = 'DIENTOAN'
const BGD = 'BGD'
const BAOCAO = 'BAOCAO'
const VANTIN = 'VANTIN'
const DPKH = 'DPKH'
const ALL_BAOCAO = 'ALL'
const ALL_VANTIN = 'ALL'
const ALL_DPKH = 'ALL'
const mapExcelRoleToSystemRole = {
    ADMIN: userRole.ADMIN_OFFICE_ROLE,
    [GDV]: userRole.TELLER,
    [KSV]: userRole.OFFEXSUPERVISOR,
    [DIENTOAN]: userRole.INBIOCOLLECTOR,
    [BGD]: userRole.OFFINSUPERVISOR,
    [BAOCAO]: userRole.RPW,
    [VANTIN]: userRole.IFW,
    [DPKH]: userRole.CCD,
}

const objConflictRoles = {
	[GDV]: [KSV, DPKH, DIENTOAN],
	[BGD]: [DIENTOAN],
	[KSV]: [GDV],
	[DIENTOAN]: [GDV, BGD, DPKH]
  }
	
const listRoleExcelValid = Object.keys(mapExcelRoleToSystemRole)
const mapExcelQueryRoleForSystemQueryRole = {
    TRUNG_STH: userRole.IFW_DUP,
    VANTIN_CIF: userRole.IFW_SC,
    VANTIN_GTTT: userRole.IFW_SID,
    VANTIN_STH: userRole.IFW_SBB
}
const listQueryRoleExcelValid = Object.keys(mapExcelQueryRoleForSystemQueryRole)

const MAX_CHARACTERS = 100;
const BANK_ID = 'bank';
const ERR_NOT_FOUND = "UserAD không đúng hoặc chưa được phân quyền trên CoreBanking";
const ERR_NOT_FOUND_CODE = 0
const ERR_DATA_INCORRECT = 'Thông tin tài khoản không chính xác. Vui lòng kiểm tra lại.';
const ERR_DATA_INCORRECT_CODE = 2;
const ERR_ACCOUNT_EXISTED = 'Tài khoản đã tồn tại trong hệ thống. Vui lòng kiểm tra lại.';
const ERR_ACCOUNT_EXISTED_CODE = 4
const ERR_ADD_ACCOUNT_EXISTED_CODE = 3
const ERR_COMMON = 'Lỗi không xác định'
const ERR_OFFICE_NOT_FOUND = 'Không tìm thấy chi nhánh/PDG'
const ERR_USER_NOT_HAVE_PERMISSION = 'Vai trò phân quyền trên SDB không phù hợp với vai trò trên CoreBanking'
const ERR_USER_NOT_HAVE_PERMISSION_CODE = 11
const ERR_MISS_USERNAME = 'Lỗi thiếu UserAD'
const ERR_INVALID_MAIN_ROLE = 'Lỗi phân quyền sai định dạng'
const ERR_INVALID_REPORT_ROLE = "User có phân quyền báo cáo nhưng chưa có báo cáo nào được lựa chọn"
const ERR_INVALID_REPORT = 'Lỗi báo cáo sai định dạng'
const ERR_INVALID_QUERY_ROLE = "User có phân quyền vấn tin nhưng chưa có loại vấn tin được lựa chọn"
const ERR_INVALID_QUERY = 'Lỗi vấn tin sai định dạng'
const ERR_GROUP_ROLE_INVALID = "\
User không được phép phân cùng các quyền sau:\n \
- GDV: KSV - ĐPKH - Điện toán. \n \
- BGĐ: Điện toán. \n \
- KSV: GDV. \n \
- Điện toán: GDV - BGĐ - ĐPKH. \n "
const ERR_ROLE_MAIN_OFFICE = `User ở trụ sở chính chỉ có thể có các quyền sau: ${BAOCAO}, ${VANTIN}`
const ERR_OFFICE_EXCEL_NOT_MATCH_WITH_OFFICE_BANK = 'CN/PGD phân quyền trên SDB không trùng khớp với CN/PGD trên CoreBanking'
const ERR_NOT_FOUND_MAIN_ROLE = "Lỗi thiếu thông tin phân quyền"
const initialStateConfigs = {
    atOffice: '',
    lstRoles: [userRole.CCD],
    lstUserSelect: [],
    reportPermission: [],
    infosPermission: [],
    ccdPermission: [userRole.CCD_GQN, userRole.CCD_CCS]
}

const PopupAddPermissionV2 = ({
    outPopup,
    showPopupInBiometric
}) => {
    const dispatch = useDispatch()

    const { userReducer, commonReducer, filterOfficeReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        commonReducer: state.commonReducer,
        filterOfficeReducer: state.filterOfficeReducer
    }))

    const [step, setStep] = useState(STEP_SEARCH_USER)
    const [dataSetup, setDataSetup] = useState(null)
    const [runCallback, setRunCallback] = useState(false)
    const [runCallbackAddUser, setRunCallbackAddUser] = useState(false)
    const [isEnableBtn, setIsEnableBtn] = useState(false)
    const [listReports, setListReports] = useState([]);
    const [objChildReport, setObjChildReport] = useState({});
    const [listParentReports, setListParentReports] = useState([]);
    const [objListReports, setObjListReports] = useState({});
    const [stateSearch, setStateSearch] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            email: '',
            error: '',
            searchResult: null
        }
    )
    const [stateConfig, dispatchConfig] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            ...initialStateConfigs
        }
    )

    const [listUser, setListUser] = useState([])

    const _callbackSearch = useCallback(
        () => {
            const { err, lstUserFromBank } = userReducer;
            if (err && err.Code === ERR_NOT_FOUND_CODE && !err.UserInfos) {
                setStateSearch({
                    searchResult: null,
                    error: ERR_NOT_FOUND
                })
                return;
            }
            if (err && err.Code === ERR_DATA_INCORRECT_CODE) {
                setStateSearch({
                    searchResult: err.UserInfos,
                    error: ERR_DATA_INCORRECT
                })
                return;
            }
            if (err && err.Code === ERR_ACCOUNT_EXISTED_CODE) {
                setStateSearch({
                    searchResult: err.UserInfos,
                    error: ERR_ACCOUNT_EXISTED
                })
                return;
            }

            if (commonReducer.showErrorConnection) {
                return;
            }
            setStateSearch({
                // searchResult: userInfoFromBank,
                searchResult: lstUserFromBank,
                error: ''
            })
        },
        [userReducer, commonReducer],
    )

    const _callbackAddUser = () => {
        if (typeof (userReducer.err) === 'object') {
            const { ADD_USER_ERR } = userReducer.err;
            if (ADD_USER_ERR === ERR_USER_NOT_HAVE_PERMISSION_CODE) {
                // clear error
                dispatch(clearError('ADD_USER_ERR', 'userReducer'))
                // show error
                const PermissionError = "PermissionError"
                showPopupInBiometric({}, PermissionError);
                // debugger;
                return;
            }
        }

        if (commonReducer.showErrorConnection) {

            return;
        }

        outPopup()
        if (userReducer.err === "" || Object.keys(this.props.userReducer.err).length === 0) {
            // debugger;
            const PermissionSuccess = "PermissionSuccess"
            showPopupInBiometric({}, PermissionSuccess);
            dispatch(filterUser({}, 10, 1))
        }
    }

    const _handleOnSearch = useCallback(
        () => {
            const { email } = stateSearch;

            if (!email.trim()) {
                setStateSearch({
                    searchResult: null,
                    error: 'Vui lòng nhập tài khoản'
                })
                return;
            }

            if (email.trim().length > MAX_CHARACTERS) {
                setStateSearch({
                    searchResult: null,
                    error: 'Vui lòng nhập dưới 100 kí tự'
                })
                return;
            }

            dispatch(searchUserToAddByEmail(stateSearch.email, () => setRunCallback(true)))
        },
        [stateSearch, dispatch],
    )

    const _handleCreateUser = useCallback(
        () => {
            const { atOffice, lstRoles, reportPermission, infosPermission, ccdPermission } = stateConfig;

            const dataRequest = {
                Roles: lstRoles,
                AtOffice: atOffice.key,
                ReportPermission: reportPermission,
                InfosPermission: infosPermission,
                CoorLobbyPermission: ccdPermission,
                UserInfos: dataSetup.map(({ UserName, Name, Branch, Active }) => ({
                    Email: UserName,
                    FullName: Name,
                    BankID: BANK_ID,
                    Branch,
                    Active
                }))
            }

            dispatch(addUser(dataRequest, () => setRunCallbackAddUser(true)))
        },
        [dataSetup, stateConfig],
    )

    const _handleSearchUsers = async () => {

        const listUserNotError = listUser.filter(user => !user.error)
        const listRequestSearchUsers = listUserNotError.map(async user => {
            try {
                const dataRequest = JSON.stringify({
                    SearchText: user.username
                })
                const res = await Axios.post(apiSearchUserToAddByEmail, dataRequest)
                const { data } = res
                const isSuccess = data.Code === 0 && data.UserInfos
                const { Name, Active, Branch, BranchCode } = data.UserInfos
                if (isSuccess) {
                    return {
                        ...user,
                        name: Name,
                        active: Active,
                        branch: Branch,
                        branchCodeFromBank: BranchCode
                    }
                }
                else {
                    if (data.Code === ERR_NOT_FOUND_CODE) {
                        return {
                            ...user,
                            error: ERR_NOT_FOUND
                        }
                    }
                    return {
                        ...user,
                        error: `${ERR_COMMON}. Code ${data.Code}`
                    }
                }
            }
            catch (err) {
                const code = err?.response?.data?.Code
                if (code !== ERR_NOT_FOUND_CODE && !code) {
                    return {
                        ...user,
                        error: ERR_COMMON
                    }
                }
                if (code === ERR_NOT_FOUND_CODE) {
                    return {
                        ...user,
                        error: ERR_NOT_FOUND
                    }
                }
                if (code === ERR_DATA_INCORRECT_CODE) {
                    return {
                        ...user,
                        error: ERR_DATA_INCORRECT
                    }
                }
                if (code === ERR_ACCOUNT_EXISTED_CODE) {
                    return {
                        ...user,
                        error: ERR_ACCOUNT_EXISTED
                    }
                }
                return {
                    ...user,
                    error: `${ERR_COMMON}. Code ${code}`
                }
            }
        })
        const newListUser = await Promise.all(listRequestSearchUsers)
        console.log(newListUser)
        setListUser(newListUser)
    }

    const _handleAddUsers = async () => {
        const listUserNotError = listUser.filter(user => !user.error)
        const listRequestAddUser = listUserNotError.map(async user => {
            const {
                username,
                name,
                branchCode,
                lstRoles,
                reportPermission,
                infosPermission,
                ccdPermission,
                branch,
                branchCodeFromBank,
                active
            } = user
            if (branchCodeFromBank !== branchCode) {
                return {
                    ...user,
                    error: ERR_OFFICE_EXCEL_NOT_MATCH_WITH_OFFICE_BANK
                }
            }

            const office = filterOfficeReducer.allOfficeList.find(item => item.BranchCode === branchCode)
            if (!office) {
                return {
                    ...user,
                    error: ERR_OFFICE_NOT_FOUND
                }
            }
            const dataRequest = JSON.stringify({
                Roles: lstRoles,
                AtOffice: office.ID,
                ReportPermission: reportPermission,
                InfosPermission: infosPermission,
                CoorLobbyPermission: ccdPermission,
                UserInfos: [{
                    Email: username,
                    FullName: name,
                    BankID: BANK_ID,
                    Branch: branch,
                    Active: active
                }]
            })
            try {
                const res = await Axios.post(apiAddUser, dataRequest)
                const { data } = res;
                const isSuccess = data.Code === 0
                if (isSuccess) {
                    return user
                }
                else {
                    return {
                        ...user,
                        error: `${ERR_COMMON}. Code ${data.Code}`
                    }
                }
            }
            catch (err) {
                const code = err?.response?.data?.Code
                if (!code) {
                    return {
                        ...user,
                        error: ERR_COMMON
                    }
                }
                if (code === ERR_ADD_ACCOUNT_EXISTED_CODE) {
                    return {
                        ...user,
                        error: ERR_ACCOUNT_EXISTED
                    }
                }
                if (code === ERR_USER_NOT_HAVE_PERMISSION_CODE) {
                    return {
                        ...user,
                        error: ERR_USER_NOT_HAVE_PERMISSION
                    }
                }
                return {
                    ...user,
                    error: `${ERR_COMMON}. Code ${code}`
                }
            }
        })
        const newListUser = await Promise.all(listRequestAddUser)
        setListUser(newListUser)
    }

    const _handleClosePopup = () => {
        if (step === STEP_RESULT_ADD_USERS) {
            const someUserAddSuccess = listUser.some(user => !user.error)
            if (someUserAddSuccess) {
                dispatch(filterUser({}, 10, 1))
            }
        }
        outPopup()
    }

    const _handleSubmit = () => {
        if (step === STEP_SEARCH_USER) {
            setStep(STEP_SETUP_USER)
            return;
        }

        if (step === STEP_SETUP_USER) {
            _handleCreateUser()
            return;
        }

        if (step === STEP_CONFIRM_SEARCH_USERS) {
            dispatch(getAllOfficeV2())
            _handleSearchUsers()
            setStep(STEP_CONFIRM_ADD_USERS)
            return;
        }
        if (step === STEP_CONFIRM_ADD_USERS) {
            _handleAddUsers()
            setStep(STEP_RESULT_ADD_USERS)
            return;
        }
        _handleClosePopup()
    }

    const _structListUser = (lstUser) => {
        const listUserStructured = lstUser.map(user => {
            const listError = []

            const [
                username,
                name,
                branchCode,
                lstRolesSemiColon,
                lstReportSemiColon,
                lstInfosSemiColon,
                lstCoorLobbySemiColon
            ] = user

            if (!username) {
                listError.push(ERR_MISS_USERNAME)
            }

            const validExcelReportPermission = listReports.map(report => report.CodeName)

            const lstRoles = lstRolesSemiColon?.split(';') ?? []
            if(!lstRoles.length) {
                listError.push(ERR_NOT_FOUND_MAIN_ROLE)
            }
            const lstRolesValid = lstRoles
                .filter(role => {
                    if (role === BAOCAO && !lstReportSemiColon) {
                        listError.push(ERR_INVALID_REPORT_ROLE)
                        // return false
                    }
                    if (role === VANTIN && !lstInfosSemiColon) {
                        listError.push(ERR_INVALID_QUERY_ROLE)
                        // return false
                    }
                    return listRoleExcelValid.includes(role)
                })
            const lstRolesInvalid = lstRoles.filter(role => !lstRolesValid.includes(role))
            if (lstRolesInvalid.length) {
                listError.push(`${ERR_INVALID_MAIN_ROLE}: ${lstRolesInvalid.join(', ')}`)
            }
            const reportPermission = lstReportSemiColon?.split(';') ?? []
            const reportPermissionValid = lstRolesValid.includes(BAOCAO)
                ? lstReportSemiColon === ALL_BAOCAO
                    ? validExcelReportPermission
                    : reportPermission.filter(role => validExcelReportPermission.includes(role))
                : []
            const reportPermissionInvalid = lstRolesValid.includes(BAOCAO)
                ? lstReportSemiColon === ALL_BAOCAO
                    ? []
                    : reportPermission.filter(role => !validExcelReportPermission.includes(role))
                : reportPermission
            if (reportPermissionInvalid.length) {
                listError.push(`${ERR_INVALID_REPORT}: ${reportPermissionInvalid.join(', ')}`)
            }

            const infosPermission = lstInfosSemiColon?.split(';') ?? []
            const infosPermissionValid = lstRolesValid.includes(VANTIN)
                ? lstInfosSemiColon === ALL_VANTIN
                    ? listQueryRoleExcelValid
                    : infosPermission.filter(role => listQueryRoleExcelValid.includes(role))
                : []
            const infosPermissionInvalid = lstRolesValid.includes(VANTIN)
                ? lstInfosSemiColon === ALL_VANTIN
                    ? []
                    : infosPermission.filter(role => !listQueryRoleExcelValid.includes(role))
                : infosPermission
            if (infosPermissionInvalid.length) {
                listError.push(`${ERR_INVALID_QUERY}: ${infosPermissionInvalid.join(', ')}`)
            }

            const ccdPermission = lstCoorLobbySemiColon?.split(';') ?? []
            const ccdPermissionValid = lstRolesValid.includes(DPKH)
                ? lstCoorLobbySemiColon === ALL_DPKH
                    ? listQueryRoleExcelValid
                    : ccdPermission.filter(role => listQueryRoleExcelValid.includes(role))
                : []
            const ccdPermissionInvalid = lstRolesValid.includes(DPKH)
                ? lstInfosSemiColon === ALL_DPKH
                    ? []
                    : ccdPermission.filter(role => !listQueryRoleExcelValid.includes(role))
                : ccdPermission
            if (ccdPermissionInvalid.length) {
                listError.push(`${ERR_INVALID_QUERY}: ${ccdPermissionInvalid.join(', ')}`)
            }
			const someRoleConflict = lstRolesValid.some((roleValidItem)=>{
				const listConflictRole = objConflictRoles[roleValidItem]
				if(listConflictRole && listConflictRole.length > 0){
					const resultCheckConflict = listConflictRole.some((conflictRoleItem)=>{
						if(lstRolesValid.includes(conflictRoleItem)) return true
						return false
					})
					return resultCheckConflict
				}
			})
            if (someRoleConflict) {
                listError.push(ERR_GROUP_ROLE_INVALID)
            }

            if (
                String(branchCode) === MAIN_OFFICE_BRANCH_CODE
                && lstRolesValid.some(role => ![BAOCAO, VANTIN].includes(role))
            ) {
                listError.push(ERR_ROLE_MAIN_OFFICE)
            }

            return {
                username,
                name,
                branchCode: String(branchCode),
                lstRoles: lstRolesValid.map(role => mapExcelRoleToSystemRole[role]),
                reportPermission: reportPermissionValid,
                infosPermission: infosPermissionValid.map(role => mapExcelQueryRoleForSystemQueryRole[role]),
                ccdPermission: ccdPermissionValid,
                error: listError.join('.\n')
            }
        })

        setListUser(listUserStructured)
        setStep(STEP_CONFIRM_SEARCH_USERS)
    }

    const _handleUploadFile = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.xlsx'
        input.onchange = async (e) => {
            const files = input.files;
            const file = files[0]
            const rows = await readXlsxFile(file)
            // console.log(rows)
            const listUserNotColName = rows.slice(1)
            _structListUser(listUserNotColName)
        }
        input.click()
    }

    //#region get list report



    useEffect(() => {
        const getListReports = async () => {
            const dataRequest = JSON.stringify({
                ParentCode: null,
                CodeName: '',
                Searchtext: ''
            })
            try {
                const res = await Axios.post(apiGetListReports, dataRequest)
                const { data } = res
                //---
                const listReports = data.ListReports || []
                const objListReports = listReports.reduce((objList, currObj) => {
                    const parentCode = currObj.ParentCode
                    const objParent = objList[parentCode]
					//sth STH08_1 la bao dac biet chi hien thi cho TSC
					if(currObj && currObj.CodeName === "STH08_1"){
						currObj.OnlyTSC = true
					}
                    return {
                        ...objList,
                        [parentCode]: objParent ? [...objParent, currObj] : [currObj]
                    }
                }, {})
                const objChildReport = listReports.reduce((objList, currObj) => {
                    return {
                        ...objList,
                        [currObj.CodeName]: currObj
                    }
                }, {})
                setListReports(listReports)
                setObjChildReport(objChildReport)
                setObjListReports(objListReports)

            }
            catch (err) {
                console.log(err)
            }
        }
        getListReports()
    }, []);

    useEffect(() => {
        const getListParentReports = async () => {
            const dataRequest = JSON.stringify({
                ParentCode: '',
                CodeName: '',
                Searchtext: ''
            })
            try {
                const res = await Axios.post(apiGetListReports, dataRequest)
                const { data } = res
                //---
                const listParentReports = data.ListReports || []
                setListParentReports(listParentReports)
            }
            catch (err) {
                console.log(err)
            }
        }
        getListParentReports()
    }, []);

    useEffect(() => {
        dispatch(getAllOfficeV2())
    }, []);

    //#endregion

    useEffect(() => {
        if (step === STEP_SETUP_USER) {
            setDataSetup(stateSearch.searchResult)
            return;
        }
        setDataSetup(null)
    }, [step, stateSearch])

    useEffect(() => {
        if (runCallback) {
            setRunCallback(false)
            _callbackSearch()
        }
    }, [runCallback])

    useEffect(() => {
        if (runCallbackAddUser) {
            setRunCallbackAddUser(false)
            _callbackAddUser()
        }
    }, [runCallbackAddUser])

    useEffect(() => {
        if (step === STEP_SEARCH_USER) {
            const { error } = stateSearch
            const { lstUserSelect } = stateConfig
            setIsEnableBtn(!!lstUserSelect.length && !error)
            return;
        }

        if (step === STEP_SETUP_USER) {
            const { atOffice, lstRoles } = stateConfig
            setIsEnableBtn(!!dataSetup && !!atOffice && !!lstRoles.length)
            return;
        }

        if (step === STEP_CONFIRM_SEARCH_USERS || step === STEP_CONFIRM_ADD_USERS) {
            const isEveryItemError = listUser.every(user => user.error)
            setIsEnableBtn(!isEveryItemError)
            return
        }

        setIsEnableBtn(true)
    }, [step, stateConfig, stateSearch, dataSetup, listUser])


    return (
        <Wrapper>
            <Header
                dataSetup={dataSetup}
                step={step}
                outPopup={_handleClosePopup}
                handleUploadFile={_handleUploadFile}
            />
            {
                step === STEP_SEARCH_USER &&
                <SearchUser
                    handleOnSearch={_handleOnSearch}
                    initialStateConfigs={initialStateConfigs}
                    stateSearch={stateSearch}
                    setStateSearch={setStateSearch}
                    stateConfig={stateConfig}
                    dispatchConfig={dispatchConfig}
                    handleUploadFile={_handleUploadFile}
                />
            }
            {
                step === STEP_SETUP_USER &&
                <SetupUser
                    stateConfig={stateConfig}
                    dispatchConfig={dispatchConfig}
                    listReports={listReports}
                    objListReports={objListReports}
                    listParentReports={listParentReports}
                />
            }
            {
                step === STEP_CONFIRM_SEARCH_USERS &&
                <TableListUser
                    noteError="Upload thất bại"
                    noteSuccess="Upload thành công"
                    listUser={listUser}
                    objChildReport={objChildReport}
                />
            }
            {
                step === STEP_CONFIRM_ADD_USERS &&
                <TableListUser
                    noteError="Không thể thêm mới"
                    noteSuccess="Có thể thêm mới"
                    listUser={listUser}
                    objChildReport={objChildReport}
                />
            }
            {
                step === STEP_RESULT_ADD_USERS &&
                <TableListUser
                    noteError="Thất bại"
                    noteSuccess="Thành công"
                    listUser={listUser}
                    objChildReport={objChildReport}
                />
            }
            <Footer
                step={step}
                setStep={setStep}
                stateConfig={stateConfig}
                stateSearch={stateSearch}
                isEnableSubmit={isEnableBtn}
                handleSubmit={_handleSubmit}
            />
        </Wrapper>
    )
}

export default PopupAddPermissionV2
