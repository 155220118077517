import styled, { css } from "styled-components";
import ic_more_gray from '../../../../../images/ic_more_gray.svg'
import ic_star_yellow from '../../../../../images/starKHUT.svg'
import ic_refresh_blue from '../../../../../images/ic_refresh_blue.svg'

const TransCounterItemCountTimeStyle = css`
    color: #979797;
    font-size: 14px;
    font-weight: 500;
`

const TransCounterItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 253px;
    height: 230px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    background-color: white;
    padding: 12px;
    font-family: 'SVN-Gilroy';
`;

const TransCounterItemTitleContent = styled.div`
        display: flex;
        flex-direction: column;
        height: 59px;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 10px;

        .content {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .title_counter {
                font-size: 18px;
                font-weight: 700;
                color: #191919;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .ic_more_gray {
                width: 24px;
                height: 24px;
                background-image: url(${ic_more_gray});
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: -10px;
                margin-top: -2px;
                cursor: pointer;
            }
        }

        .vip_block {
            height: 38px;
            width: 100%;
            margin-top: 8px;
            .content {
                width: 70px;
                height: 20px;
                border-radius: 3px;
                background-color: #ffbb00;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
                .ic_star {
                    width: 18px;
                    height: 18px;
                    margin-left: -3px;
                    background-image: url(${ic_star_yellow});
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .label {
                    font-size: 12px;
                    font-weight: 700;
                    padding-left: 1px;
                    color: #ffffff;
                }
            }
        }
`

const TransCounterItemBodyContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .basic_row {
        display: flex;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        justify-content: space-between;
        .label {
            font-size: 12px;
            font-weight: 700;
            color: #005993;
        }
        .value {
            font-size: 14px;
            font-weight: 500;
            color: #191919;
        }
    }

    .detail_row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 5px;
        .label {
            font-size: 12px;
            font-weight: 700;
            color: #005993;
        }
        .basic_value {
            font-size: 14px;
            font-weight: 500;
            color: #191919;
        }
        
        .connect_bnt {
            display: flex;
            font-size: 14px;
            font-weight: 500;
            color: #0062d7;
            text-decoration: underline;
            text-align: right;
            cursor: pointer;

            &.active {
                display: flex;
                color: #0fb11f;
                text-decoration: none;
                cursor: default;

               
            }
        }
    }

    .sub_row {
        display: flex;
        width: 100%;
        height: 25px;
        justify-content: space-between;

        .value {
            font-size: 14px;
            font-weight: 500;
            color: #979797;
            margin: 5px 0;
            &.active {
                color: #222222;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .refresh_connect_bnt{
            display: flex;
            width: 70px;
            text-align: right;
            cursor: pointer;
            margin-right: 5px;
            align-items: center;

            .count_time_content {
                display: flex;
                width: 60px;
                flex-direction: row-reverse;
                margin-right: 5px;
            }

            .ic_refresh {
                width: 13px;
                height: 13px;
                margin-right: -5px;
                margin-top: -2px;
                cursor: pointer;
                background-image: url(${ic_refresh_blue});
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
    }
    
    .line {
        display: flex;
        width: 100%;
        background-color: #E6E6E6;
        height: 1px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
`

export {
    TransCounterItemContainer, 
    TransCounterItemTitleContent, 
    TransCounterItemBodyContent, 
    TransCounterItemCountTimeStyle
}