import styled from 'styled-components';
import { color } from '../../../../../data/store';

const Step = styled.div`
    background: ${props => props.isSelect ? color.blue_005993 : color.whiteFA};
    color: ${props => props.isSelect ? '#FFFFFF' : '#D6D6D6'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    user-select: none;

    :hover {
        cursor: pointer;
    }
`;

const Button = styled.div`
    text-transform: uppercase;
    padding: 8px 12px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 12.5px;
    line-height: 120%;
    color: #FFFFFF;
    background: ${color.blue_005993};
    border-radius: 18px;
    opacity: ${props => props.isEnable ? 'unset' : '0.7'};
    pointer-events: ${props => props.isEnable ? 'auto' : 'none'};

    :hover {
        cursor: pointer;
    }
`;

const WrapperFooter = styled.div`
    justify-content: space-between;
    padding: 30px 40px 22px;
    background: #FFFFFF;

    & > .row {
        padding: 3px 0;
    }

    ${Step}:first-child{
        margin-right: 18px;
    }
`;



export { WrapperFooter, Step, Button }