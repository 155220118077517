import React, { useState } from 'react'
import { Input } from './index.styles'

const FillCif = ({ defaultValue, onChange }) => {
    const [value, setValue] = useState(defaultValue || '')

    const _handleOnChange = (e) => {
        const { value } = e.target
        setValue(value)
        onChange(value)
    }

    return (
        <>
            <div className="label-tools-item">Số CIF</div>
            <Input type="text" onChange={_handleOnChange} value={value} />
        </>
    )
}

export default FillCif
