import styled from 'styled-components'
import { color } from "../../../../../../data/store";

const WrapperCheckBox = styled.div`
    display: flex;

    :hover {
        cursor: pointer;
    }

`;

const CheckBox = styled.div`
    flex-shrink: 0;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: ${props => props.isDisabled 
                            ? "#EFEFEF" 
                            : props.isChecked
                                ? color.blue_005993
                                : color.whiteF7};
    border: ${props => props.isDisabled 
                        ? "1px solid #BEBEBE" 
                        : props.isChecked
                            ? `1px solid ${color.blue_005993}`
                            : `1px solid ${color.grayE6}`};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        user-select: none;
    }

    .checked-some-child {
        width: 10px;
        height: 10px;
        background: ${color.blue_005993};
    }
`;

const Text = styled.div`
    margin-left: 8px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 128%;
    color: ${color.black22};

    ${props => props.extendStyles}
`;

export {
    WrapperCheckBox,
    CheckBox,
    Text
}