import styled from 'styled-components'
import { color } from '../../../../../../data/store';

const WrapCifInfos = styled.div`
    font-family: 'SVN-Gilroy';
    padding: 0 19px 20px;
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 130%;
        color: ${color.blue_005993};
        text-transform: uppercase;

        &.cust-type-title {
            margin-top: 16px;
        }

        &.cust-infos-title {
            margin-top: 27px;
        }
    }

    .wrap-cust-type {
        margin-top: 16px;
        display: flex;

        label {
            display: flex;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: #AAAAAA;

            &:not(:first-child) {
                margin-left: 20px;
            }

            input[type="radio"] {
                outline: none;
                appearance: none;
                width: 16px;
                height: 16px;
                border: 0.75px solid #D6D6D6;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;

                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #D71249;
                    border-radius: 50%;
                    display: none;
                }

                &:checked {
                    & + span {
                        color: #000000;
                    }

                    &::before {
                        display: block;
                    }
                }
            }
        }

        
    }

    .grid-wrap {
        margin-top: 12px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 42px;
    }

    .field {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #000000;

        span {
            color: #979797;
        }

        &.single-field {
            margin-top: 12px;
        }
    }
`;

export {
    WrapCifInfos
}