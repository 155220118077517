/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ContainerStyle, LabelContentStyle, MenuItemStyle } from './index.styles'
import { CheckBoxControlV2, CheckBoxStatus } from '../../../CheckBoxControlV2'

function MenuItem({index, value, checkBoxStatus = false, onUpdateStatus}){
    const [status, setStatus] = useState(checkBoxStatus)

    const handleClick = () => {
        const newStatus = !status
        //---
        onUpdateStatus && onUpdateStatus(index, newStatus)
        setStatus(newStatus)
    }

    useEffect(() => {
        if(status !== checkBoxStatus){
            setStatus(checkBoxStatus)
        }
    }, [checkBoxStatus])

    return (
        <MenuItemStyle isFirstItem={index === 0} onClick={handleClick}>
            <CheckBoxControlV2 status={status?CheckBoxStatus.ALL_SELECTED:CheckBoxControlV2.UNSELECTED}/>
            <div className="valueContent">{value}</div>
        </MenuItemStyle>
    )
}

export default function SubMenuItem({label, dataList, onChoiceStatusChanged, onUpdateAllSelectedStatus}) {
    const [isShowMenu, setIsShowMenu] = useState(false)
    const [labelStatus, setLabelStatus] = useState(CheckBoxStatus.UNSELECTED)
    const [subMenuList, setSubMenuList] = useState(dataList?dataList:[])
    //---
    let menuList = dataList?dataList:[]
    //---
    const onShowHideMenuList = () => {
        setIsShowMenu(!isShowMenu)
    }
    const onUpdateStatus = (index, newStatus) => {
        menuList[index].selectedStatus = newStatus
        //---
        const newLabelStatus = getLabeltatus()
        //---
        if(newLabelStatus !== labelStatus){
            setLabelStatus(newLabelStatus)
        }
        //---
        onChoiceStatusChanged && onChoiceStatusChanged(menuList[index], newStatus)
    }

    const updateAllSelectedStatus = () => {
        let newLabelStatus = labelStatus
        if(newLabelStatus === CheckBoxStatus.ALL_SELECTED){
            newLabelStatus = CheckBoxStatus.UNSELECTED
        }else{
            newLabelStatus = CheckBoxStatus.ALL_SELECTED
           
        }
        setLabelStatus(newLabelStatus)
        //---
        if(menuList && menuList.length>0){
            for(let i = 0; i < menuList.length; i++){
                menuList[i].selectedStatus = newLabelStatus === CheckBoxStatus.ALL_SELECTED
            }
        }
        setSubMenuList(menuList)
        onUpdateAllSelectedStatus&&onUpdateAllSelectedStatus(menuList, newLabelStatus)
        //---
        if(!isShowMenu){
            onShowHideMenuList()
        }
    }

    const getLabeltatus = () => {
        let numberOfUnselected = 0
        for (let i = 0; i < menuList.length; i++) {
            if(!menuList[i].selectedStatus){
                numberOfUnselected++
            }
        }
        //---
        let newLabelStatus = CheckBoxStatus.ALL_SELECTED;
        if(numberOfUnselected > 0){
            if(numberOfUnselected < menuList.length){
                newLabelStatus = CheckBoxStatus.SELECTED;
            } else {
                newLabelStatus = CheckBoxStatus.UNSELECTED;
            }
        } 
        //---
        return newLabelStatus
    }

    useEffect(() => {
        const newLabelStatus = getLabeltatus()
        if(newLabelStatus !== labelStatus){
            setLabelStatus(newLabelStatus)
        }
        setSubMenuList(dataList?dataList:[])
    }, dataList)

    return (
        <ContainerStyle isShowMenu={isShowMenu} numberOfItems={menuList.length}>
            <LabelContentStyle isShowMenu={isShowMenu} >
                <CheckBoxControlV2 status={labelStatus} onClick={updateAllSelectedStatus}/>
                <div className="value" onClick={onShowHideMenuList}>{label}</div>
                <div className="arrow" onClick={onShowHideMenuList}></div>
            </LabelContentStyle>
            {
                subMenuList &&
                <div className="menuContent">
                   {
                    subMenuList.map((menuItem, index) => {
                        return <MenuItem 
                            key={menuItem.id}
                            index={index}
                            value={menuItem.name}
                            checkBoxStatus={menuItem.selectedStatus}
                            onUpdateStatus={onUpdateStatus}
                        />
                    })
                   }
                </div>
            }
        </ContainerStyle>
    )
}
