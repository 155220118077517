import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
import {
    addLoading,
    removeLoading,
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';
import { isArray } from '../helper/checkValidity';

const apiGetOfficeManagementList = host + "admin/office/offices-management";

export const getOfficeManagementList = ({uid = '', isReset = false}) => async dispatch => {
    dispatch(addLoading())
    //---
    const queryData = JSON.stringify({
        UID: uid
    })
    try {
        const res = await Axios.post(apiGetOfficeManagementList, queryData);
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "OK",
        //         "ListOffice": [
        //             {
        //                 "ID": "63fc6b2eb25ba66efed09003",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "Chi nhánh Wee",
        //                 "Address": "11",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10198",
        //                 "Ward": "30337",
        //                 "NumOfKiosk": 14,
        //                 "NumOfCounter": 31,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "https://vtb-eticket.wee.vn/?officeID=63fc6b2eb25ba66efed09003\u0026token=8120c57e-ce29-4765-90fe-8858029c2de8",
        //                 "QrToken": "8120c57e-ce29-4765-90fe-8858029c2de8",
        //                 "CreatedAt": "2024-08-09T07:58:54.954Z",
        //                 "UpdatedAt": "2024-08-22T02:01:10.768Z"
        //             },
        //             {
        //                 "ID": "66b431f17a20ceeed53ab10a",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Huy",
        //                 "Address": "B20",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10132",
        //                 "Ward": "30337",
        //                 "NumOfKiosk": 8,
        //                 "NumOfCounter": 10,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "https://vtb-eticket.wee.vn/?officeID=66b431f17a20ceeed53ab10a\u0026token=a9753e49-8322-42d2-a407-9792fabd1334",
        //                 "QrToken": "a9753e49-8322-42d2-a407-9792fabd1334",
        //                 "CreatedAt": "2024-08-08T02:48:17.46Z",
        //                 "UpdatedAt": "2024-08-23T07:24:52.483Z"
        //             },
        //             {
        //                 "ID": "66d1a5bc1a73d6417dcc8cb1",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD 2",
        //                 "Address": "An Thịnh",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10107",
        //                 "Ward": "30316",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 3,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-08-30T10:58:04.203Z",
        //                 "UpdatedAt": "2024-09-04T08:55:27.317Z"
        //             },
        //             {
        //                 "ID": "66d82058db5f8cb007d54f14",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD 3",
        //                 "Address": "An Tịnh",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10154",
        //                 "Ward": "30340",
        //                 "NumOfKiosk": 1,
        //                 "NumOfCounter": 2,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "https://vtb-eticket.wee.vn/?officeID=66d82058db5f8cb007d54f14\u0026token=5b427518-67a2-4be8-8f2c-434408d535e3",
        //                 "QrToken": "5b427518-67a2-4be8-8f2c-434408d535e3",
        //                 "CreatedAt": "2024-09-04T08:54:48.739Z",
        //                 "UpdatedAt": "2024-09-06T02:32:42.13Z"
        //             },
        //             {
        //                 "ID": "66d9327b4517b6f7e4095350",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân CN xuống PGD",
        //                 "Address": "ad",
        //                 "Province": "77",
        //                 "District": "750",
        //                 "BranchCode": "10162",
        //                 "Ward": "26578",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 1,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-05T04:24:27.75Z",
        //                 "UpdatedAt": "2024-09-05T04:24:54.009Z"
        //             },
        //             {
        //                 "ID": "66d98b1d4b51e41c4c31d3d3",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Huy Dev",
        //                 "Address": "B20",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10188",
        //                 "Ward": "30337",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-05T10:42:37.51Z",
        //                 "UpdatedAt": "2024-09-05T10:44:24.796Z"
        //             },
        //             {
        //                 "ID": "66d9929e7203a7863e9f8393",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD9",
        //                 "Address": "sad",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10127",
        //                 "Ward": "30319",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-05T11:14:38.297Z",
        //                 "UpdatedAt": "2024-09-05T11:14:38.297Z"
        //             }
        //         ]
        //     }
        // }
        const { data } = res;
        //---
        let listOffice = []
        if(isArray(data.ListOffice) && data.ListOffice.length > 0){
            listOffice =  data.ListOffice
        }
        //---
        dispatch({
            type: types.GET_OFFICES_MANAGEMENT,
            success: true,
            dataList: listOffice,
            isReset: isReset
        })
    }
    catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_OFFICES_MANAGEMENT_ERR,
            () => false
        )
    }
    dispatch(removeLoading())
}

export const updateGeneralOfficeSelected = ({dataInfo}) => async dispatch => {
    dispatch({
        type: types.UPDATE_GENARAL_OFFICE_SELECTED,
        dataInfo: dataInfo
    })
}