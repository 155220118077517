import styled from "styled-components";
import { MinWidthContainer } from "../ConfigureByBranchTable/index.styles";
import { color } from "../../../../../../data/store";

const minWidth = MinWidthContainer + 10

const WrapOverlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
`

const WrapContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    min-width: ${minWidth}px;
`

const WrapContent=styled.div`
    display: flex;
    width: ${minWidth}px;
    height: 536px;
    background-color: white;
    flex-direction: column;
    margin-top: 100px;
    margin-left: calc((100% - ${minWidth}px)/2);
    border-radius: 12px;

    .header {
        display: flex;
        flex-direction: column;
        padding: 10px 20px 10px 20px;
        width: calc(100% - 40px);
        .title-content { 
            display: flex;
            width: 100%;
            justify-content: space-between;
            .label {
                font-size: 22px;
                font-weight: 700;
                color: #191919;
            }
            .btn-container {
                display: flex;
                justify-content: end;
                    
                .icon {
                    width: 30px;
                    height: 30px;
                    margin-right: -9px;
                    margin-top: -5px;
                    cursor: pointer;
                }
            }
            
        }

        .body-content {
            display: flex;
            width: 100%;
            height: 30px;
            align-items: center;
            .file-content {
                display: flex;
                font-size: 14px;
                font-weight: 500;
                flex-grow: 1;

                .label{
                    color: ${color.blue_005993};
                }

                .value{
                    color: #636060;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 5px;
                }
               
            }

            .change-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;
                cursor: pointer;
                .label{
                    font-size: 14px;
                    font-weight: 500;
                    color: ${color.blue_005993};
                    flex-grow: 1;
                }

                .icon{
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .body {
        display: flex;
        flex: 1;
        width: calc(100% - 20px);
        padding: 10px;
        overflow: hidden;
        background-color: #F7F7F7;
    }

    .footer {
        display: flex;
        width: calc(100% - 30px);
        height: 74px;
        align-items: center;
        border-top: 1px solid #E6E6E6;
        padding: 0px 15px;
        .err-content{
            font-size: 14px;
            font-weight: 500;
            color: #F31919;
            flex-grow: 1;
            div {
                padding: 2px 0px;
            }
        }
        .btn-content{
            width: 127px;
        }
    }
`



export {WrapOverlay, WrapContainer, WrapContent}