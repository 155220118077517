import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import MainContainer from './ui/MainContainer'
import PopupAddCounterV2 from './ui/Main/Popup/PopupAddCounterV2';
import MultiChoiceDropdownControl from './ui/Main/MainScreen/Control/MultiChoiceDropDownControl';
import MultiChoiceControl from './ui/Main/MainScreen/Control/MultiChoiceControl';
import { CheckBoxTheme } from './ui/Main/MainScreen/Control/CheckBoxControlV2';
import { color } from './data/store';
import { colors } from '@mui/material';
import styled from 'styled-components';

const dataList=[
  {
    id: "00000",
    selectedStatus: true,
    name: "Đăng ký STH cho cán bộ",
  },
  {
      id: "00001",
      selectedStatus: false,
      name: "Xem báo cáo",
      dataList: [
          {
              id: "00001-00001",
              groupID: "00001",
              selectedStatus: true,
              name: "Nhóm báo cáo 1",
              dataList: [
                {
                  id: "00001-00001-00001",
                  groupID: "00001-00001",
                  selectedStatus: true,
                  name: "STH01 - Báo cáo Tổng hợp số lượng đăng ký sinh trắc học",
                },
                {
                  id: "00001-00001-00002",
                  groupID: "00001-00001",
                  selectedStatus: false,
                  name: "STH02 - Báo cáo chi tiết khách hàng không xác thực vân tay khi vào quầy nhưng có phát sinh GD trên core 123455dfsdfdsfsdffsfdsf",
                },
                {
                  id: "00001-00001-00003",
                  groupID: "00001-00001",
                  selectedStatus: false,
                  name: "STH03 - Báo cáo Tổng hợp số lượng đăng ký sinh trắc học 03",
                }
              ]
          },
          {
              id: "00001-00002-00002",
              groupID: "00001-00002",
              selectedStatus: false,
              name: "Nhóm báo cáo 2",
              dataList: [
                {
                  id: "00001-00001-00004",
                  groupID: "00001-00001",
                  selectedStatus: false,
                  name: "STH04 - Báo cáo Tổng hợp số lượng đăng ký sinh trắc học 04",
                },
                {
                  id: "00001-00001-00005",
                  groupID: "00001-00001",
                  selectedStatus: false,
                  name: "STH05 - Báo cáo Tổng hợp số lượng đăng ký sinh trắc học 05",
                },
                {
                  id: "00001-00001-00006",
                  groupID: "00001-00001",
                  selectedStatus: false,
                  name: "STH06 - Báo cáo Tổng hợp số lượng đăng ký sinh trắc học 06",
                }
              ]
          }
      ]
  },
  {
    id: "00002",
    selectedStatus: false,
    name: "Vấn tin",
    dataList: [
        {
            id: "00002-00001",
            groupID: "00002",
            selectedStatus: false,
            name: "Vấn tin trùng STH"
        },
        {
            id: "00002-00002",
            groupID: "00002",
            selectedStatus: false,
            name: "Vấn tin theo giấy tờ tùy thân"
        },
        {
            id: "00002-00003",
            groupID: "00002",
            selectedStatus: false,
            name: "Vấn tin theo số CIF"
        },
        {
            id: "00002-00004",
            groupID: "00002",
            selectedStatus: false,
            name: "Vấn tin theo hình ảnh STH vân tay"
        }
    ]
  },
]

const Bound = styled.div`
    display:flex;
    width: 1000px;
    background-color: white;
    justify-content: space-between;
    .bodyContent{
      display:flex;
      flex: 1;
      padding: 10px;
    }
    .spaceLine{
      width:1px;
      background-color: gray;
    }
`
class App extends React.Component {
  render() {
    return (
      <Router>
        <MainContainer/>
        {/* <Bound>
          <div className="bodyContent">
            <MultiChoiceControl 
              // width='500px'
              dataList={dataList}
              isShowMenuList={false}
              groupLabelStyle={{
                fontSize: 11,
                fontWeight: 700,
                fontColor: color.blue_005993
              }}
              onUpdateChoiceItemList={(choiceData)=>{
                console.log('onChoiceStatusOfItemChanged');
                console.log(choiceData);
              }}
            />
          </div>
          
          <div className='spaceLine'/>
          <div className="bodyContent">
            <MultiChoiceControl 
              // width='500px'
              borderWidth={1}
              checkBoxTheme={CheckBoxTheme.BLUE}
              isShowNumberOfOptions={false}
              isShowHeader={true}
              headerLabel="Cấu hình chức năng WEB ADMIN"
              dataList={dataList}
              onUpdateChoiceItemList={(choiceData)=>{
                console.log('onChoiceStatusOfItemChanged');
                console.log(choiceData);
              }}
            />
          </div>
          
        </Bound> */}
        
      </Router>
    );
  }

}


export default App