import styled from 'styled-components'
import { color } from '../../../../../data/store';
const Bound = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
`
const WrapperSearch = styled.div`
    .input-container{
        display: flex;
        align-items: center;
        margin: 20px 40px 13px 40px;
        justify-content: space-between;
        
        .wrap-search-box {
            display: flex;
            align-items: center;
            .wrapper-input {
                background: #FFFFFF;
                border: 0.5px solid #D6D6D6;
                box-sizing: border-box;
                border-radius: 4px 0 0 4px;
                display: flex;
                align-items: center;
                padding: 1px 14px;
                width: 400px;
                img {
                    :hover {
                        cursor: pointer;
                    }
                }
        
                input {
                    border: none;
                    outline: none;
                    color: #222222;
                    padding: 11px 0;     
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 128%;    
                    flex-grow: 1;    
                    ::placeholder {
                        color: #BEBEBE;
                    }
                }

            }

            button {
                outline: none;
                border: none;
                background: ${color.blue_005993};
                width: 43px;
                height: 43px;
                border-radius: 0 4px 4px 0;

                :hover {
                    cursor: pointer;
                }
            }
        }

    }

    .error-search {
        font-family: SVN-Gilroy;
        font-size: 14px;
        line-height: 128%;
        color: #F31919;
        margin-left: 40px;
        height: 18px;
    }
`;

const WrapperResult = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .wrap-title {
        display: flex;
        justify-content: space-between;
        padding: 0 40px;
        .title {
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 120%;
            color: ${color.blue_005993};
            /* margin-left: 40px; */
        }

        .clear-data {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 150%;
            color: #F31919;
            text-decoration-line: underline;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .list-user-infos {
        display: flex;
        flex-direction: column;
        margin: 14px 40px 0px;
        overflow: auto;
        .row-result-title {
            display: grid;
            grid-template-columns: 170px 190px 220px 1fr;
            align-items: center;
            grid-gap: 10px;
            height: 48px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: var(--peacock-blue);
            background: #0059930d;
            box-shadow: inset 0px -0.5px 0px rgb(0 0 0 / 15%);

            & > *:first-child {
                padding-left: 20px;
            }
        }      
    }
`;

export { Bound, WrapperSearch, WrapperResult }