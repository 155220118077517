import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
// Images
import ic_close from '../../../images/ic_close_gray.svg';

//Component
import InputTextControl from '../MainScreen/Control/InputTextControl';
import TextAreaControl from '../MainScreen/Control/TextAreaControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import CheckOnOffControl from '../MainScreen/Control/CheckOnOffControl';
import ApplyLocation from './ApplyLocation';

// import action
import { getAllOfficeV2 } from '../../../actions/officeAction';

const Bound = styled.div`    
    background-color: #fafafa;
    width: 730px;
    height: 530px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    .popupHeader{
        padding: 15px 14px 24px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .menu { 
        background: #ffffff;
        font-family: 'SVN-Gilroy';
        font-size: 12px;
        font-weight: 500; 
        color:#222222;
        position: relative;
        padding-left: 20px; 
        width: 100vw;             
        height: 26px;            
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        border-bottom: 3px solid #e9e9e9;
        margin-bottom: 20px;
        .menu-choise-left {            
            display:flex;
            align-items:center;
            justify-content:center;
            width:90px;
            height: 30px;
            cursor: pointer;
            position: absolute;
        }
        .menu-choise-right {            
            display:flex;
            align-items:center;
            justify-content:center;
            width:90px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            left: 120px;
        }
    }     
    .popup-container{
        padding: 0px 25px 0 40px;
        .main {
            display: flex;
            height: 303px;
            .popup-aside{
                width: 168px;
                min-width: 168px;
                height: 299px;
                margin-right: 32px;
                background-image: ${props => "url(" + props.urlImg + ")"};
                background-repeat: no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                position: relative;
                &:hover{
                    .overlay{
                        background-color: rgba(0,0,0,0.5);
                        transition: all .5s ease;
                        /* opacity: 1; */
                    }
                    .textChange{
                        opacity: 1;
                    }
                }
                .overlay{
                    width: 100%;
                    height: 100%;
                    /* opacity: 0; */
                    background-color: transparent;
                }
                .textChange{
                    font-family: SVN-Gilroy;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.68;
                    letter-spacing: normal;
                    color: #ffffff;
                    position:absolute;
                    left: 50%;
                    top:50%;
                    transform:translate(-50%,-50%);
                    opacity: 0;
                }
            }
            .popup-main{
                width: 100%;
                height: 299px;
                .dropdown-control{
                    cursor: pointer;
                }
            }
        }
    }
    .bottom{
        width: calc(100%-40px);
        padding-left: 40px;
        font-family: SVN-Gilroy;
        .nameFile, .size, .ram{ 
            display: flex;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.42;
            color: #222222;
            p{
                font-weight: bold !important;;
            }


            span{
                padding-left: 2px;
            }      
        }
        button{
            float: right;
            margin: 3px 40px 24px 0;
        }
    }
    span.error {
        position: absolute;
        left: 38px;
        bottom: 15px;
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        color: #f31919;
    }
`
const AD_TITLE = "adTitle";
const AD_DESC = "adDesc";

const MAX_CHARACTERS = 200;

const maxSizeFollowType = {
    KIOSK: {
        MAX_WIDTH: 1080,
        MAX_HEIGHT: 1920,
    },
    GDV: {
        MAX_WIDTH: 800,
        MAX_HEIGHT: 1280,
    },
    LCD: {
        MAX_WIDTH: 1920,
        MAX_HEIGHT: 1080,
    },
}
class PopupAdvertiseRoot extends Component {
    state = {
        fileNotMatch: false,
        adTitle:
            this.props.dataNeedModify && this.props.dataNeedModify.Title
                ? this.props.dataNeedModify.Title
                : '',
        adDesc:
            this.props.dataNeedModify && this.props.dataNeedModify.Description
                ? this.props.dataNeedModify.Description
                : '',
        step: 1,
        active:
            this.props.dataNeedModify && this.props.dataNeedModify.IsActive
                ? this.props.dataNeedModify.IsActive
                : false,
        imgInfo: this.props.imgInfo ? this.props.imgInfo : {},
        typeSelected: 'Thông tin QC',
        lstbranchsChecked: null,
        fileData: '',
        isShowSubmit: false,
        maxSize: maxSizeFollowType[this.props.typeAds],
        keySearchOffice: ''
    }
    handleEventOnchangeInputFile = {}

    // handleUpdateAds = (dataNeedModify) => {
    //   let {ID,TypeAds} = dataNeedModify
    //   if (ID && TypeAds) {
    //     let id = ID;
    //     let dataChange = {
    //         title: this.state.adTitle,
    //         type: '',
    //         desc:this.state.adDesc,
    //         status:this.state.active
    //       }
    //       if (TypeAds === 'KIOSK') {
    //         dataChange.type = 'KIOSK'
    //       }else if(TypeAds === 'GDV') {
    //         dataChange.type = 'GDV'
    //       }else {
    //         dataChange.type = 'LCD'
    //       }
    //       this.props.updateKiosK(id, dataChange)
    //   }else {
    //     return;
    //   }
    // }

    /*
     * TODO: need update file field in update api
     * TODO: need select field was change to update
     */
    onClickBtn = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.outPopup()
        // this.refs.btn.setAttribute("disabled", "disabled");
        // console.log("abc")
        const { adTitle, adDesc, active, fileData } = this.state
        if (!adTitle || !adDesc) return
        const { dataNeedModify } = this.props
        const applyAtOffice = this.state.lstbranchsChecked
            ? this.state.lstbranchsChecked.map((item) => item.ID)
            : dataNeedModify
            ? dataNeedModify.ApplyAtOffice
            : []
        // debugger;
        // console.log(applyAtOffice)
        // console.log(this.state)
        if (dataNeedModify) {
            // console.log(dataNeedModify)
            // console.log("edit")
            const dataSubmit = {}
            dataSubmit['title'] = adTitle
            dataSubmit['type'] = dataNeedModify.TypeAds
            dataSubmit['desc'] = adDesc
            if (fileData) dataSubmit['upload'] = fileData
            dataSubmit['applyAtOffice'] = applyAtOffice
            dataSubmit['status'] = active

            // console.log(dataSubmit)
            await this.props.handleOnSubmit(dataNeedModify.ID, {
                ...dataSubmit,
            })
            await this.props.handleReLoadData(dataNeedModify.TypeAds, 8, 1)
        } else {
            // console.log("add new")
            const { fileData, typeAds } = this.props
            await this.props.handleOnSubmit(
                adTitle,
                adDesc,
                fileData,
                typeAds,
                active,
                applyAtOffice || []
            )
            await this.props.handleReLoadData(typeAds, 10, 1)
            let AdvertiseSuccess = 'AdvertiseSuccess'
            this.props.showPopupInBiometric({}, AdvertiseSuccess)
        }

        // Hàm update
        // this.handleUpdateAds(dataNeedModify);
    }
    getText = (type) => async (text) => {
        await this.setState({
            [type]: text,
        })
        if (this.state.adTitle.trim() === '' || this.state.adDesc.trim() === '')
            await this.setState({ isShowSubmit: false })
        else await this.setState({ isShowSubmit: true })
        if (this.state.adDesc.length > MAX_CHARACTERS)
            this.setState({
                isShowSubmit: false,
            })
    }

    setTypeSelected = (value) => {
        this.setState(
            {
                typeSelected: value,
            },
            () => {
                if (this.state.typeSelected === 'Vị trí áp dụng') {
                    // console.log("vi tri ap dun")
                    this.props.getAllOfficeV2()
                }
            }
        )
    }

    getStatus = (status) => {
        this.setState({ active: status })
    }

    getLstBranchChecked = (lst) => {
        // debugger;
        this.setState({ lstbranchsChecked: lst })
    }

    buildFileSelector = () => {
        const fileSelector = document.createElement('input')
        fileSelector.setAttribute('type', 'file')

        return fileSelector
    }
    componentDidMount() {
        this.fileSelector = this.buildFileSelector()
        const that = this
        this.fileSelector.onchange = function (e) {
            const file = this.files[0]
            if (!file) return

            const imageType = /image\/(png|jpg|jpeg)/
            if (!file.type.match(imageType)) {
                that.setState({ fileNotMatch: true })
                return
            }
            const ImgFormat = file.type.split('/')[1]
            var reader = new FileReader()
            reader.onload = function (e) {
                const img = new Image()
                img.src = e.target.result
                img.onload = function () {
                    // validate width and height image
                    if (
                        this.width > that.state.maxSize.MAX_WIDTH ||
                        this.height > that.state.maxSize.MAX_HEIGHT
                    ) {
                        that.setState({ fileNotMatch: true })
                        return
                    }

                    that.setState({
                        imgInfo: {
                            url: e.target.result,
                            name: file.name,
                            width: this.width,
                            height: this.height,
                            size: file.size,
                            format: ImgFormat,
                        },
                        fileData: file,
                        fileNotMatch: false,
                    })
                    document.querySelector(
                        '.popup-container .main .popup-aside'
                    ).style.backgroundImage = `url(${e.target.result})`
                }
            }
            reader.readAsDataURL(file)
        }

        if ((this.state.adDesc.trim() || this.state.adTitle.trim()) === '')
            this.setState({
                isShowSubmit: false,
            })
        else this.setState({ isShowSubmit: true })

        // get and set list office checked
        const { ApplyAtOffice } = this.props.dataNeedModify || {}
        if(!ApplyAtOffice) return;
        const lstbranchsChecked = this.props.filterOfficeReducer.allOfficeList?.filter(
            item => ApplyAtOffice.includes(item.ID)
        )
        this.setState({ lstbranchsChecked })
    }
    handleFileSelect = (e) => {
        e.preventDefault()
        this.fileSelector.click()
    }

    componentWillUnmount() {
    }

    render() {
        const { imgInfo } = this.state
        const onAdTitleChange = this.getText(AD_TITLE)
        const getTextAdDesc = this.getText(AD_DESC)
        return (
            <Bound urlImg={this.state.imgInfo.url}>
                <div className="popupHeader">
                    <h4 className="title">{this.props.title}</h4>
                    <span
                        onClick={() => this.props.outPopup()}
                        className="ic_close"
                    ></span>
                </div>
                <div className="menu">
                    <div
                        className="menu-choise-left"
                        style={
                            this.state.typeSelected === 'Thông tin QC'
                                ? {
                                      color: '#222222',
                                      borderBottom: '3px solid #005993',
                                      width: '100px',
                                  }
                                : {
                                      color: '#e9e9e9',
                                      borderBottom: '3px solid #e9e9e9',
                                  }
                        }
                        onClick={() => this.setTypeSelected('Thông tin QC')}
                    >
                        Thông tin QC
                    </div>
                    <div
                        className="menu-choise-right"
                        style={
                            this.state.typeSelected === 'Vị trí áp dụng'
                                ? {
                                      color: '#222222',
                                      borderBottom: '3px solid #005993',
                                      width: '100px',
                                  }
                                : {
                                      color: '#e9e9e9',
                                      borderBottom: '3px solid #e9e9e9',
                                  }
                        }
                        onClick={() => this.setTypeSelected('Vị trí áp dụng')}
                    >
                        Vị trí áp dụng
                    </div>
                </div>
                {this.state.typeSelected === 'Thông tin QC' ? (
                    <React.Fragment>
                        <div className="popup-container">
                            <div className="main">
                                <div
                                    className="popup-aside"
                                    onClick={this.handleFileSelect}
                                >
                                    <div className="overlay"></div>
                                    <p className="textChange">Thay đổi</p>
                                </div>
                                <div className="popup-main">
                                    <InputTextControl
                                        defaultValue={this.state.adTitle}
                                        title="tên quảng cáo"
                                        placeHolder="Nhập tên quảng cáo"
                                        onChange={onAdTitleChange}
                                    />
                                    <TextAreaControl
                                        title="mô tả"
                                        defaultValue={this.state.adDesc}
                                        placeHolder={
                                            this.props.descTitle
                                                ? this.props.descTitle
                                                : 'Tối đa 200 ký tự'
                                        }
                                        getText={getTextAdDesc}
                                    />
                                    <CheckOnOffControl
                                        defaulVal={this.state.active}
                                        getStatus={this.getStatus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="nameFile">
                                <p>Tên file: </p>
                                <span>{imgInfo.name}</span>
                            </div>
                            <div className="size">
                                <p>Kích thước: </p>
                                <span>{`${imgInfo.width} x ${imgInfo.height} px`}</span>
                            </div>
                            <div className="ram">
                                <p>Dung lượng: </p>
                                <span>{this.props.formatSize(imgInfo)}</span>
                            </div>
                            {this.state.isShowSubmit ? (
                                <ButtonConfirmPopup
                                    titleConfirm={this.props.titleConfirm}
                                    onClick={this.onClickBtn}
                                />
                            ) : (
                                <ButtonConfirmPopup
                                    titleConfirm={this.props.titleConfirm}
                                    onClick={() => {}}
                                    opacity="0.5"
                                    cursor="default"
                                />
                            )}
                        </div>
                        {this.state.fileNotMatch && (
                            <span className="error">
                                Vui lòng kiểm tra lại định dạng file hoặc kích
                                thước hình ảnh tiêu chuẩn
                            </span>
                        )}
                    </React.Fragment>
                ) : (
                        <ApplyLocation 
                            listOffice={this.props.filterOfficeReducer.allOfficeList}
                            getLstBranchChecked={this.getLstBranchChecked}
                            lstChecked={this.state.lstbranchsChecked}
                            isShowSubmit={this.state.isShowSubmit}
                            onHandleClick={this.onClickBtn}
                            titleConfirm={this.props.titleConfirm}
                            keySearchOffice={this.state.keySearchOffice}
                            setKeySearchOffice={(keySearchOffice) => this.setState({ keySearchOffice })}
                        />
                )}
            </Bound>
        )
    }
}


const mapStateToProps = (state) => ({
  filterOfficeReducer: state.filterOfficeReducer
})

const mapDispatchToProps = (dispatch) => ({
  getAllOfficeV2: () => dispatch(getAllOfficeV2())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PopupAdvertiseRoot);