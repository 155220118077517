import styled from "styled-components";
import { color } from "../../../../../../data/store";

const groupHeightItem = 42
const GroupContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0px 25px;

    .menuContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: ${props => props.isShowMenu?groupHeightItem*props.numberOfItems:0}px;
        transition: height 0.2s;
        overflow: ${props => props.isShowMenu?'visible':'hidden'};
        background-color: #fff;
    }
`

const GroupLabelContentStyle = styled.div`
    display: flex;
    height: 18px;
    min-height: 18px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 8px 10px;
    color: ${props => props.labelStyle?props.labelStyle.fontColor:'#000'};
    .value {
        font-family: 'SVN-Gilroy';
        font-weight: ${props => props.labelStyle?props.labelStyle.fontWeight:500};
        font-size: ${props => props.labelStyle?props.labelStyle.fontSize:14}px;
        width: calc(100% - 40px);
        /* margin-top: 3px; */
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
    }
    .rightContent {
        display: flex;
        align-items: end;
        .arrow {
            width: 0; 
            height: 0; 
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid ${color.blue_005993};
            transition-duration: 0.2s;
            transform: ${props => props.isShowMenu?'rotate(-180deg)':'rotate(0)'};
            -webkit-transform: ${props => props.isShowMenu?'rotate(-180deg)':'rotate(0)'};
        }
        .space {
            width: 8px;
        }
    }
    
`
export {GroupContainerStyle, GroupLabelContentStyle, groupHeightItem}