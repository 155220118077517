/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container } from "./index.styled";
import qrcode from "qrcode"
import icDowload from "../../../../images/ic_download.svg"
import icRefresh from "../../../../images/ic_refresh_02.svg"

const ShowResult = ({ officeName, qrCodeStr, onRefreshQRCode }) => {
    const [imageQR, setImageQR] = useState("")

    const generateQRCode = async (qrCodeStr) => {
        if (!qrCodeStr) return
        let image = await qrcode.toDataURL(qrCodeStr)
        setImageQR(image)
    }

    useEffect(() => {
        if (!qrCodeStr) return
        generateQRCode(qrCodeStr)
    }, [qrCodeStr])

    const buildButton = ({ icon, label, onSubmited }) => {

        return (
            <div
                className="button"
                onClick={() => {
                    onSubmited && onSubmited()
                }}
            >
                <img src={icon} alt="ic-button" className="icon" />
                <div className="label">
                    {label}
                </div>
            </div>
        )
    }

    return (
        <Container>
            <div className="result-content">
                <div className="title">
                    Ngân hàng TMCP Công thương Việt Nam
                </div>
                <div className="sub-title">
                    {officeName}
                </div>
                <img src={imageQR} alt="qr-code" className="qr-code" />
                <div className="bottom-content">
                    <a style={{ textDecoration: 'none' }} href={imageQR} download>
                        {
                            buildButton({
                                icon: icDowload,
                                label: "Tải xuống"
                            })
                        }
                    </a>
                    {
                        buildButton({
                            icon: icRefresh,
                            label: "Tạo lại",
                            onSubmited: () => {
                                onRefreshQRCode && onRefreshQRCode()
                            }
                        })
                    }

                </div>

            </div>
        </Container>
    );
}

export default ShowResult;