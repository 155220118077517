/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { Bound } from './index.styles'

export default function PopupOption({offsetPopup, dataInfo, onModify, onRemove, onClose}) {
  let popupRef = useRef(null)
  const [offset, setOffset] = useState(offsetPopup)
  const modifyAction = () => {
    onModify && onModify(dataInfo)
  }
  const removeAction = () => {
    onRemove && onRemove(dataInfo)
  }

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose && onClose()
    }
  };
  //---
  useEffect(()=>{
      if (popupRef.offsetTop + popupRef.offsetHeight > window.innerHeight - window.screenTop){
          setOffset({
            ...offset,
            top: popupRef.offsetTop - popupRef.offsetHeight
          })
      }
      //---
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, [])
  return (
    <Bound offset={offset} ref={popupRef}>
      <div className='modify'
          onClick={modifyAction}>Tùy chỉnh</div>
      {
        onRemove && 
        <div className='remove'
        onClick={removeAction}>Xóa</div>
      }
     
    </Bound>
  )
}
