import styled from "styled-components";
import { color } from "../../../../../data/store";

const WrapContainer = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: 10px;
    opacity: ${props => props.isDisabled?"0.5":"unset"};
    width: ${props => props.width?props.width:"100%"};;

    .title-content {
        display: flex;
        
        .title {        
            font-size: 13px;
            font-weight: 700;        
            color: ${color.blue_005993};
            text-transform: uppercase;
            background-color: transparent;
            padding: 0px;

            &.required {
                color: #F31919;
                padding-left: 2px;
            }
        }
    }
    

    .input-content {
        display: flex;
        height: 50px;     
        background-color: #F7F7F7;
        color: #191919;
        align-items: center;
        padding: 0px 15px;
        font-size: 17px;
        font-weight: 500;
        border-radius: 6px;
        border: ${props => props.isError?"1px solid #F31919":"1px solid transparent"};

        input {
            flex-grow: 1;
            height: 20px;  
            font-size: 17px;
            font-weight: 500;
            border: none;
            background-color: transparent;
        }

        input:focus{
            outline: none;
        }

        .unit {
            height: 20px;   
            padding-left: 10px;
        }
    }

    .error {
        font-size: 13px;
        font-weight: 500;
        color: #F31919;
        padding-top: 5px;
        height: 20px;
    }
`

export {WrapContainer}