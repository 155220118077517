// component
import ReportAtOfficeByTransaction from './ReportServiceTime/ReportAtOfficeByTransaction'
import ReportOfTellerByTicket from './ReportServiceTime/ReportOfTellerByTicket'
import ReportAtOfficeByTicket from './ReportServiceTime/ReportAtOfficeByTicket'
import ReportOfTellerByTransaction from './ReportServiceTime/ReportOfTellerByTransaction'
import ReportByOffice from './ReportQualityService/ReportByOffice'
import ReportByService from './ReportQualityService/ReportByService'
import ReportByTeller from './ReportQualityService/ReportByTeller'
import ReportDetailsOfSelfServeNotSuccess from './ReportNumOfTransaction/ReportDetailsOfSelfServeNotSuccess'
import ReportSelfServeByOfficeAndTrans from './ReportNumOfTransaction/ReportSelfServeByOfficeAndTrans'
import ReportNumOfSelfServeNotSuccess from './ReportNumOfTransaction/ReportNumOfSelfServeNotSuccess'
import ReportNumOfRegBio from './ReportBiometric/ReportNumOfRegBio'
import ReportNumOfNotRegBio from './ReportBiometric/ReportNumOfNotRegBio'
import ReportNumOfUpdateBio from './ReportBiometric/ReportNumOfUpdateBio'

import ReportChangeCustomerInfos from './ReportBiometric/ReportChangeCustomerInfos'

import ReportIncustNotRegFullFinger from './ReportBiometric/ReportIncustNotRegFullFinger'
import ReportStatusFingerAuth from './ReportBiometric/ReportStatusFingerAuth'
import ReportDetailsOfCusRegBio from './ReportBiometric/ReportDetailsOfCusRegBio'
import ReportDetailsOfCusNotRegBio from './ReportBiometric/ReportDetailsOfCusNotRegBio'
import ReportDetailsOfCusUpdateBio from './ReportBiometric/ReportDetailsOfCusUpdateBio'
import ReportDetailsOfCusRemoveBio from './ReportBiometric/ReportDetailsOfCusRemoveBio'
import ReportDetailsOfFingerAuth from './ReportBiometric/ReportDetailsOfFingerAuth'
import ReportDetailsOfBioNotApproval from './ReportBiometric/ReportDetailsOfBioNotApproval'
import ReportHistoryCusChangeRegBio from './ReportBiometric/ReportHistoryCusChangeRegBio'
import ReportRegcogBioInKiosk from './ReportBiometric/ReportRegcogBioInKiosk'
import ReportDuplicateFingers from './ReportBiometric/ReportDuplicateFingers'
import ReportDuplicateFaces from './ReportBiometric/ReportDuplicateFaces'
import ReportDetailsCustRegFaceInAppGoCounterFirst from './ReportBiometric/ReportDetailsCustRegFaceInAppGoCounterFirst'
import ReportDetailedCustomerJourney from './ReportCustomerJourney/ReportDetailedCustomerJourney'
import ReportSumariesCustomerJourney from './ReportCustomerJourney/ReportSumaries'
import ReportSumariesChooseOneServ from './ReportCustomerJourney/ReportSumariesChooseOneServ'
import ReportDeleteSTHByHeadStaff from './ReportBiometric/ReportDeleteSTHByHeadStaff'

const htkhPaths = [
    {
        path: '/HTKH01',
        codeName: 'HTKH01',
        title: 'HTKH01 - Báo cáo chi tiết hành trình khách hàng',
        component: ReportDetailedCustomerJourney,
    },
    {
        path: '/HTKH02',
        codeName: 'HTKH02',
        title: 'HTKH02 - BÁO CÁO TỔNG HỢP HÀNH TRÌNH KHÁCH HÀNG',
        component: ReportSumariesCustomerJourney,
    },
    {
        path: '/HTKH03',
        codeName: 'HTKH03',
        title: 'HTKH03 - BÁO CÁO TỔNG HỢP HÀNH TRÌNH KHÁCH HÀNG CHỌN 1 LOẠI GIAO DỊCH',
        component: ReportSumariesChooseOneServ,
    },
]

const tgpvPaths = [
    {
        path: '/TGPV01',
        codeName: 'TGPV01',
        title: 'TGPV01 - Báo cáo thời gian phục vụ tại CN theo vé',
        component: ReportAtOfficeByTicket,
    },
    {
        path: '/TGPV02',
        codeName: 'TGPV02',
        title: 'TGPV02 - Báo cáo thời gian phục vụ tại CN theo loại giao dịch',
        component: ReportAtOfficeByTransaction,
    },
    {
        path: '/TGPV05',
        codeName: 'TGPV05',
        title: 'TGPV05 - Báo cáo thời gian phục vụ của GDV theo vé',
        component: ReportOfTellerByTicket,
    },
    {
        path: '/TGPV06',
        codeName: 'TGPV06',
        title: 'TGPV06 - Báo cáo thời gian phục vụ của GDV theo loại giao dịch',
        component: ReportOfTellerByTransaction,
    },
]

const cldvPaths = [
    {
        path: '/CLDV01',
        codeName: 'CLDV01',
        title: 'CLDV01 - Báo cáo chất lượng dịch vụ theo CN',
        component: ReportByOffice,
    },
    {
        path: '/CLDV02',
        codeName: 'CLDV02',
        title: 'CLDV02 - Báo cáo chất lượng dịch vụ theo CN và loại giao dịch',
        component: ReportByService,
    },
    {
        path: '/CLDV04',
        codeName: 'CLDV04',
        title: 'CLDV04 - Báo cáo chất lượng dịch vụ theo GDV và loại giao dịch',
        component: ReportByTeller,
    },
]

const slgdPaths = [
    {
        path: '/SLGD01',
        codeName: 'SLGD01',
        title:
            'SLGD01 - Báo cáo số lượng giao dịch KH tự phục vụ theo CN và loại giao dịch',
        component: ReportSelfServeByOfficeAndTrans,
    },
    {
        path: '/SLGD02',
        codeName: 'SLGD02',
        title:
            'SLGD02 - Báo cáo số lượng giao dịch KH tự thực hiện - xác thực vân tay không thành công',
        component: ReportNumOfSelfServeNotSuccess,
    },
    {
        path: '/SLGD03',
        codeName: 'SLGD03',
        title:
            'SLGD03 - Báo cáo chi tiết giao dịch KH tự thực hiện - xác thực vân tay không thành công',
        component: ReportDetailsOfSelfServeNotSuccess,
    },
]


const sthPaths = [
    {
        path: '/STH01',
        codeName: 'STH01',
        title: 'STH01 - Báo cáo tổng hợp số lượng KH đăng ký đủ STH khuôn mặt và vân tay',
        component: ReportNumOfRegBio,
    },
    {
        path: '/STH02',
        codeName: 'STH02',
        title: 'STH02 - Báo cáo tổng hợp số lượng KH chỉ đăng ký STH khuôn mặt và KH không đăng ký STH',
        component: ReportNumOfNotRegBio,
    },
    {
        path: '/STH03',
        codeName: 'STH03',
        title:
            'STH03 - Báo cáo Tổng hợp số lượng KH thay đổi thông tin sinh trắc học',
        component: ReportNumOfUpdateBio,
    },
    {
        path: '/STH04',
        codeName: 'STH04',
        title: 'STH04 - Báo cáo Tổng hợp tình trạng xác thực vân tay tại quầy',
        component: ReportStatusFingerAuth,
    },
    {
        path: '/STH05',
        codeName: 'STH05',
        title: 'STH05 - Báo cáo chi tiết KH đăng ký đủ STH khuôn mặt và vân tay',
        component: ReportDetailsOfCusRegBio,
    },
    {
        path: '/STH06',
        codeName: 'STH06',
        title: 'STH06 - Báo cáo chi tiết KH chỉ đăng ký STH khuôn mặt và KH không đăng ký STH',
        component: ReportDetailsOfCusNotRegBio,
    },
    {
        path: '/STH07',
        codeName: 'STH07',
        title: 'STH07 - Báo cáo chi tiết KH thay đổi thông tin sinh trắc học',
        component: ReportDetailsOfCusUpdateBio,
    },
    {
        path: '/STH08',
        codeName: 'STH08',
        title:
            'STH08 - Báo cáo chi tiết khách hàng xóa thông tin sinh trắc học',
        component: ReportDetailsOfCusRemoveBio,
    },
    {
        path: '/STH08_1',
        codeName: 'STH08.1',
        title: 'STH08.1 - Báo cáo xóa STH của cán bộ hội sở',
        component: ReportDeleteSTHByHeadStaff,
    },
    {
        path: '/STH09',
        codeName: 'STH09',
        title: 'STH09 - Báo cáo lịch sử Đăng ký/ Thay đổi STH của khách hàng',
        component: ReportHistoryCusChangeRegBio,
    },
    {
        path: '/STH10',
        codeName: 'STH10',
        title:
            'STH10 - Báo cáo chi tiết xác thực thông tin sinh trắc học vân tay tại quầy',
        component: ReportDetailsOfFingerAuth,
    },
    {
        path: '/STH11',
        codeName: 'STH11',
        title:
            'STH11 - Báo cáo chi tiết khách hàng không được phê duyệt Đăng ký/Thay đổi thông tin STH',
        component: ReportDetailsOfBioNotApproval,
    },
    {
        path: '/STH12',
        codeName: 'STH12',
        title: 'STH12 - Báo cáo các trường hợp trùng STH vân tay',
        component: ReportDuplicateFingers,
    },
    {
        path: '/STH13',
        codeName: 'STH13',
        title:
            'STH13 - Báo cáo chi tiết các trường hợp thay đổi thông tin CIF của KH',
        component: ReportChangeCustomerInfos,
    },
    {
        path: '/STH14',
        codeName: 'STH14',
        title: 'STH14 - Báo cáo các trường hợp trùng STH Khuôn mặt',
        component: ReportDuplicateFaces,
    },
    {
        path: '/STH15',
        codeName: 'STH15',
        title:
            'STH15 - Báo cáo các trường hợp cán bộ không đăng ký đủ 10 dấu vân tay',
        component: ReportIncustNotRegFullFinger,
    },
    {
        path: '/STH16',
        codeName: 'STH16',
        title: 'STH16 - Báo cáo chi tiết KH đã đăng ký khuôn mặt trên Kênh ngoài quầy đến quầy giao dịch lần đầu',
        component: ReportDetailsCustRegFaceInAppGoCounterFirst,
    },
]

const ndPaths = [
    {
        path: '/ND01',
        codeName: 'ND01',
        title: 'ND01 - Báo cáo nhận diện STH tại Kiosk',
        component: ReportRegcogBioInKiosk,
    },
]

const reportPaths = [
    ...htkhPaths,
    ...tgpvPaths,
    ...cldvPaths,
    ...slgdPaths,
    ...sthPaths,
    ...ndPaths,
]

export default reportPaths
