/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'

// styles
import { extendStylesDropdownTrans } from './index.styles'

// component
import DropDown from '../DropDown'

const itemAllTrans = {
    key: undefined,
    value: 'Tất cả giao dịch',
}

const SelectTrans = ({
    defaultValue,
    onChange,
    isNotSelectAll,
    lstTrans = [],
}) => {
    const [lstServices, setLstServices] = useState([])
    const [servicesSelected, setServicesSelected] = useState(
        isNotSelectAll ? defaultValue || null : defaultValue || itemAllTrans
    )

    const _handleOnChange = useCallback((obj) => {
        setServicesSelected(obj)
        onChange(obj)
    }, [])

    useEffect(() => {
        if (isNotSelectAll) {
            setLstServices(lstTrans)
            if (!servicesSelected) {
                setServicesSelected(lstTrans[0])
            }
            return
        }
        setLstServices([itemAllTrans, ...lstTrans])
    }, [lstTrans])

    return (
        <DropDown
            lstItemFixed={[]}
            lstItems={lstServices}
            defaultValue={servicesSelected}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={extendStylesDropdownTrans}
            maxNumShowDropdown={9}
            label="Loại GD"
        />
    )
}

export default SelectTrans
