import React from 'react'
import { CheckBoxContainer } from './index.styles'
import whiteCheckIcon from "../../../../../images/check_white.svg"
import redCheckIcon from "../../../../../images/checked_red.svg"

const CheckBoxStatus = {
    UNSELECTED: 0,
    SELECTED: 1,
    ALL_SELECTED: 2
}

const CheckBoxTheme = {
    RED: 1,
    BLUE: 2
}

function CheckBoxControlV2({theme = CheckBoxTheme.BLUE, status = CheckBoxStatus.UNSELECTED, onClick}) {
    const onClickAction = ()=>{
        onClick && onClick()
    }
    return <CheckBoxContainer theme={theme} status={status} onClick={onClickAction}>
        {
            status !== CheckBoxStatus.UNSELECTED &&
            <div className="content">
                {
                    status === CheckBoxStatus.ALL_SELECTED
                    &&  <img 
                            src={
                                theme === CheckBoxTheme.BLUE
                                ? whiteCheckIcon
                                : redCheckIcon
                            } 
                            alt="" 
                        />
                    // :   theme === CheckBoxTheme.RED && 
                    //     <img 
                    //         src={redCheckIcon} 
                    //         alt="" 
                    //     />
                }
            </div>
        }
    </CheckBoxContainer>
}

export {CheckBoxStatus, CheckBoxTheme, CheckBoxControlV2}
