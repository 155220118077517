import styled from "styled-components";

const WrapContainer = styled.div`
    padding: 8px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
`
const WrapPagingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const WrapTable = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px 0 0 0;
    display: flex;
    flex: 1;
    flex-direction: column;
`

export  {WrapContainer, WrapPagingContainer, WrapTable}