/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import moment from 'moment'

// styles
import { WrapFaceQuery } from './index.styles'

// helper
import fileToBase64 from '../../../../../../helper/fileToBase64'

// images
import icDefaultUser from '../../../../../../images/default_user.svg'

// api
import { apiFindUserByFace } from '../../../../../../actions/queryAction/queryUserByImg'
import { apiGetCif } from '../../../../../../actions/queryAction/queryByCif'
import { apiFaceHistory } from '../../../../../../actions/supervisorAction/biometricAction'
// component
import LoadingControl from '../../../Control/LoadingControl'
import Modal from '../../../../../../helper/Modal'
import QueryResults from '../QueryResults'

// constant
import { FACE } from '../../../../../../data/infosType'

const STEP_INITIAL = "STEP_INITIAL"
const STEP_LOADING = "STEP_LOADING"
const STEP_SHOW_IMAGE = "STEP_SHOW_IMAGE"
const ERR_FACE_NOT_FOUND = 'Khuôn mặt đang tìm kiếm không tồn tại trong hệ thống'
const ERR_OVERLOAD_FILE = 'Hình ảnh tải lên vượt quá dung lượng cho phép'

const MAX_SIZE_OF_FILE = 1 * 1024 * 1024 //Bytes

const FaceQuery = ({ loadingQueryByFinger, setLoadingQueryByFace, setError }) => {


    const [step, setStep] = useState(STEP_INITIAL);
    const [dataUsers, setDataUsers] = useState(null);
    const [urlImg, setUrlImg] = useState(null);
    const [imgBase64, setImgBase64] = useState('');

    const _requestGetUserByFaceImage = async () => {
        setError('')
        setStep(STEP_LOADING)
        setLoadingQueryByFace(true)
        const dataRequest = JSON.stringify({
            Face: imgBase64
        })

        try {
            const res = await Axios.post(apiFindUserByFace, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0 && data.Cifs
            if (isSuccess) {
                const uniqueCifs = data.Cifs.reduce((cifs, cif) =>
                    cifs.some(item => item.Cifs?.CustomerNumber === cif.Cifs?.CustomerNumber)
                        ? cifs
                        : [...cifs, cif]
                    , [])
                const promiseLstUserFromTablet = uniqueCifs.map(async user => {
                    const { Cifs, History } = user
                    const { CustomerNumber } = Cifs
                    const dataRequest = JSON.stringify({
                        CifsNum: CustomerNumber,
                        Type: 0
                    })
                    try {
                        const res = await Axios.post(apiGetCif, dataRequest)
                        const { data } = res;
                        const isSuccess = data.Code === 0 && data.Cifs;
                        if (isSuccess) {
                            const cif = data.Cifs[0]
                            const { BioInfos: { BioDatas } } = data
                            const face = BioDatas.find(item => item.BioType === FACE)
                            return {
                                Cifs: {
                                    CustomerNumber: cif.CustomerNumber,
                                    Name: cif.Name,
                                    FullNameVi: cif.FullNameVi,
                                    CustomerType: cif.CustomerType,
                                    IdNumber: cif.IdNumber,
                                    IdIssueDate: cif.IdIssueDate,
                                    IdIssuePlace: cif.IdIssuePlace,
                                    MobileNumber: cif.MobileNumber,
                                    Address: cif.Address,
                                    SegmentDesc: cif.SegmentDesc,
                                    Gender: cif.Gender,
                                    Email: cif.Email,
                                    FaceID: cif.FaceID,
                                    faceFromChannel: face?.FromChannel
                                },
                                History
                            }
                        }
                        else {
                            return user
                        }
                    }
                    catch (err) {
                        return user
                    }
                })

                const lstUserFromTablet = await Promise.all(promiseLstUserFromTablet)
                setDataUsers(lstUserFromTablet)
            }
            else {
                console.log(data)
                // not found user
                setError(ERR_FACE_NOT_FOUND)
            }
        }
        catch (err) {
            console.dir(err)
            const code = err?.response?.data?.Code
            if (code === 1010) {
                // not found user
                setError(ERR_FACE_NOT_FOUND)
            }
        }
        setStep(STEP_SHOW_IMAGE)
        setLoadingQueryByFace(false)
    }

    const _handleUploadFace = () => {
        setError('')
        const input = document.createElement('input')
        input.type = "file"
        input.accept = '.png,.jpg,.jpeg'
        input.onchange = async () => {
            const files = input.files;
            const file = files[0]
            console.log(file)
            const { size } = file
            if(size > MAX_SIZE_OF_FILE) {
                setError(ERR_OVERLOAD_FILE)
                return;
            }
            const urlImg = URL.createObjectURL(file)
            setUrlImg(urlImg)
            try {
                const imgBase64 = await fileToBase64(file)
                console.log(imgBase64)
                //data:image/png;base64,
                const base64 = imgBase64.split(',')[1]
                setImgBase64(base64)
                setStep(STEP_SHOW_IMAGE)
                // requestGetUserByFaceImage(base64)
            }
            catch (err) {
                console.log(err)
            }
        }
        input.click()
    }

    const _handleFallback = () => {
        setError('')
        URL.revokeObjectURL(urlImg)
        setUrlImg(null)
        setImgBase64('')
        setStep(STEP_INITIAL)
    }

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(urlImg)
        }
    }, []);

    return (
        <WrapFaceQuery>
            {
                step === STEP_INITIAL &&
                <>
                    <img className="ic-user-default" src={icDefaultUser} alt="user" />
                    <div className="desc">
                        <div>Chụp hình khuôn mặt</div>
                        <div>từ thiết bị hoặc upload ảnh</div>
                        <div>(dung lượng tối đa 1MB)</div>
                    </div>
                    <div className="wrap-btn">
                        <button disabled={true}>Chụp hình</button>
                        <button onClick={_handleUploadFace} disabled={loadingQueryByFinger}>Tải lên</button>
                    </div>
                </>
            }
            {
                step === STEP_LOADING &&
                <div className="wrap-loading">
                    <LoadingControl size="32px" loadingPage={false} />
                    <div className="desc-loading">Vui lòng chờ...</div>
                </div>
            }
            {
                step === STEP_SHOW_IMAGE &&
                <>
                    <div className="wrap-face">
                        <img src={urlImg} alt="face" />
                    </div>
                    <div className="wrap-btn">
                        <button onClick={_requestGetUserByFaceImage} disabled={loadingQueryByFinger}>Kiểm tra</button>
                        <button onClick={_handleFallback} disabled={loadingQueryByFinger}>Lấy lại</button>
                    </div>
                </>
            }
            <Modal isShow={dataUsers !== null}>
                {
                    dataUsers !== null &&
                    <QueryResults
                        title='Kết quả vấn tin hình ảnh khuôn mặt'
                        handleClose={() => setDataUsers(null)}
                        dataUsers={dataUsers.map(({ Cifs: cif, History }) => ({
                            CustomerNumber: cif.CustomerNumber,
                            Name: cif.Name,
                            FullNameVi: cif.FullNameVi,
                            CustomerType: cif.CustomerType,
                            IDNumber: cif.IdNumber || cif.IDNumber,
                            IDIssueDate: cif.IdIssueDate || cif.IDIssueDate,
                            IDIssuePlace: cif.IdIssuePlace || cif.IDIssuePlace,
                            MobileNumber: cif.MobileNumber || cif.mobilenumber,
                            Address: cif.Address,
                            SegmentDesc: cif.SegmentDesc,
                            Gender: cif.Gender,
                            Email: cif.Email || cif.email,
                            urlFace: apiFaceHistory(cif.FaceID),
                            faceFromChannel: cif.faceFromChannel,
                            history: (() => {
                                if(!History) return {}
                                const {
                                    BranchCode,
                                    OfficeName,
                                    TellerInfos,
                                    RequestAt,
                                    SupervisorInfos,
                                    AcceptAt
                                } = History
                                return {
                                    office: `${BranchCode} - ${OfficeName}`,
                                    teller: TellerInfos?.Name,
                                    timeRequest: moment(RequestAt).format('DD/MM/YYYY HH:mm:ss'),
                                    supervisor: SupervisorInfos?.Name,
                                    timeAccept: moment(AcceptAt).format('DD/MM/YYYY HH:mm:ss'),
                                }
                            })() 
                        }))}
                    />
                }
            </Modal>
        </WrapFaceQuery>
    )
}

export default FaceQuery
