/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react'

// styles
import { useDispatch, useSelector } from 'react-redux'
import MultiChoiceDropdownControl from '../../../../../Control/MultiChoiceDropDownControl'
import { CustomerType } from '../../../../../../../../data/customerData'
import { getAllCounterServices } from '../../../../../../../../actions/counterServiceAction'
import { isArray } from '../../../../../../../../helper/checkValidity'

const SelectServiceType = ({customerTypeSelected, onChange }) => {
    const dispatch = useDispatch()

    const { dataLogin} = useSelector((state) => ({
        dataLogin: state.userReducer.dataLogin
    }))

    let {transServiceList} = useSelector((state) => ({
        transServiceList: state.counterReducer.listTypeCounterServices,
    }))

    const [serviceTypeList, setServiceTypeList] = useState([
        {
            id: "1",
            selectedStatus: true,
            name: "Tất cả"
        },
    ])

    const _onUpdateServiceTypeChoiced = (dataInfo) => {
        // console.log("onUpdateServiceTypeChoiced: ", dataInfo);
        let newServiceTypeList = []
        if(dataInfo.indexSelected !== 0){
            newServiceTypeList = dataInfo.dataList.map((item) => {
                return item.type;
            })
        }
        onChange && onChange(newServiceTypeList)
    }

    const _onUpdateTransServiceList = () => {
        if(typeof(transServiceList) !== 'object' ||
            Object.keys(transServiceList).length !== 2 ||
            (transServiceList.personCustomer.length === 0 && transServiceList.corpCustomer.length === 0)){
                dispatch(getAllCounterServices({
                    uid: dataLogin.UID,
                    onCompletedFunc: (resp) => {
                        if(resp.code === 0){
                            transServiceList = resp.result
                            _updateServiceTypeList()
                        }
                    }
                }))
        } else {
            _updateServiceTypeList()
        }
    }

    const _updateServiceTypeList = () => {
        const serviceList = _makeServiceList(
            customerTypeSelected === CustomerType.CORPCUST
            ? transServiceList.corpCustomer
            : transServiceList.personCustomer
        )
        //---
        const newServiceTypeList = [
            {
                id: "1",
                selectedStatus: true,
                name: "Tất cả"
            },
            {
                id: "2",
                selectedStatus: false,
                name: 'Danh sách giao dịch',
                dataList: serviceList.serviceList
            }
        ]
        //---
        setServiceTypeList(newServiceTypeList)
    }

    const _makeServiceList = (serviceList) => {
        let selectedStatus = false
        //---
        let dataList = []
        serviceList.forEach((service) => {
            let newDataList = []
            if(isArray(service.Children) && service.Children.length > 0){
                service.Children.forEach(item => {
                    newDataList.push({
                        id: item.ID,
                        name: item.Name,
                        selectedStatus: false,
                        groupId: item.ParentID,
                        type: item.ServiceType
                    })
                });
            }
            if(newDataList.length > 0){
                dataList.push({
                        id: service.ID,
                        name: service.Name,
                        groupCode: service.GroupCode,
                        dataList: newDataList
                })
            }
            
        })
        //---
        return {
            selectedStatus: selectedStatus,
            serviceList: dataList
        }
    }

    useEffect(() => {
        dispatch(getAllCounterServices({
            uid: dataLogin.UID,
            onCompletedFunc: (resp) => {
                if(resp.code === 0){
                    transServiceList = resp.result
                    _updateServiceTypeList()
                }
            }
        }))
    }, [])
    
    useEffect(() => {
        _onUpdateTransServiceList()
    }, [customerTypeSelected])

    return (
        <>
            <div className="label-tools-item">Loại hình giao dịch</div>
            <div className="tools-item" style={{width: '230px'}}>
                <MultiChoiceDropdownControl
                    labelStyle={{borderWidth: 0,paddingTopBottom: 10.5,paddingLeftRight: 10}}
                    menuStyle={{top: 40, borderWidth: 0}}
                    subMenuStyle={{borderWidth: 0}}
                    dataList={serviceTypeList}
                    onUpdateChoiceItemList={_onUpdateServiceTypeChoiced}/>
            </div>
            
        </>
    )
}

export default SelectServiceType
