import Axios from 'axios'
import { host } from '../host';
import * as types from './types';
import catchErrorApi from '../helper/catchErrorApi';
import { addLoading, removeLoading } from './commonAction';
import { formatDateTime, formatDateTimeToSpecificFormat } from '../helper/formatTime';
import { isArray } from "../helper/checkValidity";

const apiGetAdviseServiceConfig = host + "admin/advise-service/get-config-info";
const apiGetAdviseServiceList = host + "admin/advise-service/get-all";
const apiCreateAdviseService = host + "admin/advise-service/create";
const apiRemoveAdviseService = host + "admin/advise-service/remove";
const apiUpdateAdviseService = host + "admin/advise-service/update";

export const getAdviseServiceConfig = ({uid, onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        UID: uid
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiGetAdviseServiceConfig, queryData);
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message,
                result: []
            })
        } else {
            let dataSetting = {
                statusList: []
            }
            //---
            if(isArray(data.StatusList)){
                dataSetting.statusList = data.StatusList.map((item) => {
                    return {
                        code: item.Code,
                        value: item.Value
                    }
                })
            }
            //---
            if(dataSetting.statusList.length === 0){
                dataSetting.statusList = [
                    {
                        code: 'A',
                        value: 'Đang hoạt động'
                    },
                    {
                        code: 'INA',
                        value: 'Không hoạt động'
                    }
                    
                ]
            }
            //---
            onCompleted && onCompleted({
                code: 0,
                result: dataSetting
            })
        }
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_ADVISE_SERVICE_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const getAdviseServiceList = ({uid, onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        UID: uid
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiGetAdviseServiceList, queryData);
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message,
                result: []
            })
        } else {
            let dataList = []
            if(isArray(data.Advises) && data.Advises.length > 0){
                dataList = data.Advises.map((item) => {
                    return {
                        id: item.ID,
                        name: item.Name,
                        type: item.AdviseType,
                        descript: item.Description,
                        status: item.Status,
                        createAt: formatDateTimeToSpecificFormat(
                            item.CreateAt,
                            formatDateTime.DDMMYYYYHHmmss
                        )
                    }
                })
            }
            //---
            onCompleted && onCompleted({
                code: 0,
                result: dataList
            })
        }
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_ADVISE_SERVICE_LIST_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const createAdviseService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        Name: dataInfo.name,
        AdviseType: dataInfo.type,
        Description: dataInfo.descript,
        Status: dataInfo.status
    })
    try {
        const res = await Axios.post(apiCreateAdviseService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.CREATE_ADVISE_SERVICE_ERR,
            handleError400
        )
    }
}

export const removeAdviseService = ({id, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        ID: id
    })
    try {
        const res = await Axios.post(apiRemoveAdviseService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.REMOVE_ADVISE_SERVICE_ERR,
            handleError400
        )
    }
}

export const updateAdviseService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        ID: dataInfo.id,
        Name: dataInfo.name,
        AdviseType: dataInfo.type,
        Description: dataInfo.descript,
        Status: dataInfo.status
    })
    try {
        const res = await Axios.post(apiUpdateAdviseService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
        //---
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_ADVISE_SERVICE_ERR,
            handleError400
        )
    }
}
