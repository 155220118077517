import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

//Component
import TableControl from "./TableControl";
import SubHeaderControl from "../Control/SubHeaderControl";
import EmptyControl from "../Control/EmptyControl";

//Data
import { emptyData, subHeaderData } from "../../../../data/data";
import { filterUser, alertFillterUser, saveTextSearch, clearTextSearch } from "../../../../actions/userAction";
import { ADMIN_OFFICE_ROLE } from "../../../../data/userRole";

//Tool
import { formatDateTimeToSpecificFormat, formatDateTime } from "../../../../helper/formatTime";
import {officeUtils} from "../../../Utils/OfficeUtils";
// import { ADMIN_ROLE } from "../../../../data/userRole";

const Bound = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding: 0 40px;
  /*min-width: 1260px;*/
`;

class PermissionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {},
    };
  }

  setData = () => {
    // debugger;
    let titleArr = [
      "Stt",
      "họ và tên",
      "User AD",
      "Vai trò",
      "CN/PGD",
      "ngày cập nhật"
    ];
    let rowArr = this.props.userReducer.listUser.map(item => [
      item.ID,
      item.FullName,
      item.Email,
      item.Roles,
      `${item.BranchCode} - ${item.AtOfficeName}`,
      // formatDayAndTime(moment(item.UpdatedAt)).getFullDate
      formatDateTimeToSpecificFormat(item.UpdatedAt, formatDateTime.DDMMYYHHmm)
    ]);
    let users = {
      title: titleArr,
      content: rowArr
    };
    this.setState({ users });
  };

  clearTextSearch = () => {
    this.props.clearTextSearch();
  }

  searchFilter = data => {
    const officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })

    const dataFilter = {
      AtOffice: officeId,
      Search: data
    };
    
    this.props.filterUser(dataFilter, 10, 1);
    this.props.saveTextSearch(data)
    this.props.alertContent('')
  };

  onReset() {
    const officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })

    this.props.filterUser({
      AtOffice: officeId,
    }, 10, 1)
  }

  componentDidMount() {
    const officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    this.props.filterUser({
      AtOffice: officeId,
    }, 10, 1);
    this.setData();
  }

  componentDidUpdate(prevProps) {
    const prevOfficeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: prevProps.officeManagementReducer.generalOfficeSelected
    })

    const officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    if(officeId !== prevOfficeId){
      this.props.filterUser({
        AtOffice: officeId,
      }, 10, 1);
    }
    //-----
    if (
      JSON.stringify(prevProps.userReducer) !==
      JSON.stringify(this.props.userReducer)
    ) {
      this.setData();
    }
  }

  componentWillUnmount() {
    this.props.clearTextSearch();
  }

  onAddMoreSubmit = () => {
    this.props.showAddUserPopup();
    this.props.clearTextSearch();
  }

  render() {
    const { alert, dataLogin } = this.props.userReducer;
    let mainTitle = officeUtils.getOfficeName({
        dataLogin: this.props.userReducer.dataLogin, 
        generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    return (
      <Bound>
        {/* Header */}
        <SubHeaderControl
          textSearch={this.props.userReducer.textSearch}
          typeSearch='searchPermission'
          searchFilter={this.searchFilter}
          data={{...subHeaderData.permission, mainTitle: mainTitle}}
          toggleFilter={(ref) => {
            this.props.togglePermissionFilter(ref);
          }}
          onAddMoreSubmit={() => {
            this.onAddMoreSubmit()
          }}
          onReset={() => {
            this.onReset()
          }}
          clearTextSearch={() => this.clearTextSearch()}
          dataLogin={dataLogin}
          isAdd={dataLogin.Roles.includes(ADMIN_OFFICE_ROLE)}
          isShowFilter={!dataLogin.Roles.includes(ADMIN_OFFICE_ROLE)}
        />
        {/* Table User */}
        {this.state.users.content && this.state.users.content.length > 0 ? (
          <TableControl
            filterUserReducer={this.props.userReducer}
            data={this.state.users}
            onShowControl={this.props.onShowControl}
            onShowPopupModify={this.props.onShowPopupModify}
            filterUser={this.props.filterUser}
            dataLogin={dataLogin}
          />
        ) : (
          (() => {
            if (this.props.userReducer.listUser.length <= 0) {
              return <EmptyControl
                alertContent={alert}
                content={emptyData.permission}
              />
            }
            else if (alert === '') {
              return <EmptyControl
              />
            }
          })()
        )}
      </Bound>
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    officeManagementReducer: state.officeManagementReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    filterUser: (dataFilter, pageSize, pageNumber) =>
      dispatch(filterUser(dataFilter, pageSize, pageNumber)),
    alertContent: (data) => dispatch(alertFillterUser(data)),

    saveTextSearch: (text) => dispatch(saveTextSearch(text)),
    clearTextSearch: () => dispatch(clearTextSearch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PermissionContainer);
