import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { popupType } from '../../../data/data'
//component
import PopupAdvertiseRoot from './PopupAdvertiseRoot';
import PopupTransactionV2 from './PopupTransactionV2';
import PopupConfirm from './PopupConfirm';
import PopupNewImage from './PopupNewImage';
import PopupAddBranch from './PopupAddBranch';
import PopupSuccess from './PopupSuccess';
import PopupControl from './PopupControl';
import FlowAddAdvertisement from './FlowAddAdvertisement';
import PopupAlert from './PopupAlert';
import PopupCheckBiometricCustomer from './PopupCheckBiometric/PopupCheckBiometricCustomer';
import PopupCheckCustomer from './PopupCheckCustomer/PopupCheckCustomer';
import PopupAddPermissionV2 from './PopupAddPermissionV2';
import PopupAccountPermissionV2 from './PopupAccountPermissionV2';
import PopupAddCounterV2 from './PopupAddCounterV2/index';
import PopupDeletePermission from './PopupDeletePermission';
import FlowOfficeCheckNumber from './FlowOfficeCheckNumber';
/* import action */
import { createOffice, updateOffice, deleteOffice, filterOffice, clearErrorDeleteOffice } from '../../../actions/officeAction';
import { createAds, getAds, updateAds, deleteAds, setStatusAds } from '../../../actions/adsAction';
import { deleteUser, filterUser, addUser, updateUser } from '../../../actions/userAction';
import { formatSize } from '../../../tools';
import { fixErrorAcceptBios, getListErrorAcceptBios } from '../../../actions/bioErrorAction';
import { createKiosk, getAllKiosk, deleteKiosk, updateKiosk } from '../../../actions/kioskAction';
import { createCounter, updateCounter, deleteCounter, getCounterByOffice } from '../../../actions/counterAction';
import { getAllLCD, createLCD, updateLCD, deleteLCD } from '../../../actions/LCDAction';
import { clearError } from '../../../actions/commonAction';
import { acceptBioInfos, getBioInfos, getBioHistoryList } from '../../../actions/supervisorAction/biometricAction';
import {officeUtils} from '../../Utils/OfficeUtils';
import { ADMIN_OFFICE_ROLE } from '../../../data/userRole';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    background-color:${props => props.nonOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.6)'} ;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
`

//const dataBranch = {
//    ID: "63fc6b2eb25ba66efed09003",
//    ParentID: "000000000000000000000000",
//    OfficeType: "BRANCH",
//    Name: "Chi nhánh Wee",
//    Address: "b20 bạch đằng",
//    Province: "79",
//    District: "766",
//    BranchCode: "10198",
//    Ward: "26965",
//    NumOfKiosk: 11,
//    NumOfCounter: 30,
//    HasKioskDeviceConfig: true,
//    HasLcdDeviceConfig: true,
//    HasTabletDeviceConfig: true,
//    QrUrl: "https://vtb-eticket.wee.vn/?officeID=63fc6b2eb25ba66efed09003\u0026token=cf09bee3-365a-48de-acf4-68e18eb52e44",
//    QrToken: "cf09bee3-365a-48de-acf4-68e18eb52e44",
//    CreatedAt: "0001-01-01T00:00:00Z",
//    UpdatedAt: "2024-08-01T08:51:49.175Z"
//}
//const dataTrading = {
//    Address: "B20",
//    BranchCode: "10199",
//    CreatedAt: "2024-08-08T02:01:36.739Z",
//    District: "886",
//    HasKioskDeviceConfig: true,
//    HasLcdDeviceConfig: false,
//    HasTabletDeviceConfig: true,
//    ID: "66b431f17a20ceeed53ab10a",
//    Name: "Huy",
//    NumOfCounter: 0,
//    NumOfKiosk: 0,
//    OfficeType: "TRADING",
//    ParentID: "63fc6b2eb25ba66efed09003",
//    Province: "89",
//    QrToken: "",
//    QrUrl: "",
//    UpdatedAt: "2024-08-08T02:01:36.739Z",
//    Ward: "30337"
//}
//const dataHead = {
//    ID: "66b47c645ba692b6c46bb672",
//    ParentID: "000000000000000000000000",
//    OfficeType: "HEAD",
//    Name: "Tân TSC",
//    Address: "12321",
//    Province: "89",
//    District: "884",
//    BranchCode: "99998",
//    Ward: "30316",
//    NumOfKiosk: 0,
//    NumOfCounter: 0,
//    HasKioskDeviceConfig: false,
//    HasLcdDeviceConfig: false,
//    HasTabletDeviceConfig: false,
//    QrUrl: "",
//    QrToken: "",
//    CreatedAt: "2024-08-08T08:05:56.775Z",
//    UpdatedAt: "2024-08-08T08:05:56.775Z"
//}
class PopupContainer extends Component {
    constructor(props) {
        super(props)
        this.isMouseEnterPopup = false;
        this.state = {
            nonOverlay: false,
            content: null,
        }
    }
    onClosePopup = () => {
        this.props.closePopup()
    }
    componentDidMount() {
        this.getPopupContent(this.props.popupRef);
    }

    getData = data => {
        let Data = { data }
        Object.assign(this.props.popupRef.data, Data)
        this.props.getData(this.props.popupRef)
    }
    // ----------- User
    deleteUser = (id) => {
        this.props.deleteUser(id)
        this.props.filterUser({}, 10, 1)
        this.onClosePopup()
    }

    updateUser = async (dataUpdate, idUser, currentPage) => {
        await this.props.updateUser(dataUpdate, idUser)
        if (typeof this.props.userReducer.err === 'object') {
            const { UPDATE_USER_ERR } = this.props.userReducer.err
            if (UPDATE_USER_ERR === 11) {
                // clear error
                this.props.clearError('UPDATE_USER_ERR', 'userReducer')
                // show error
                this.props.onShowErrPopup({
                    content: "Cập nhật thông tin không thành công",
                    note: "(*) Không thể thực hiên được vì người dùng không có quyền trên hệ thống VietinBank",
                })
                return
            }
        }
        this.onClosePopup()
        this.props.filterUser(this.props.userReducer.lastConditionFilterUser, 10, currentPage)
    }
    // -----------
    getOfficeId = () => {
        let officeId = undefined
        const dataLogin = this.props.userReducer.dataLogin
        if(dataLogin.Roles.includes(ADMIN_OFFICE_ROLE)){
            officeId = officeUtils.getOfficeID({
                dataLogin: dataLogin,
                generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
            })
        }
        return officeId
    }

    getAds = (typeAds, pageSize, pageNumber) => {
        this.props.getAds(this.getOfficeId(), typeAds, pageSize, pageNumber);
    }
    deleteItem = async (dataNeedDelete) => {
        if (dataNeedDelete.ID && dataNeedDelete.TypeAds) {
            this.props.deleteAds(dataNeedDelete.ID);
            //---
            this.getAds(dataNeedDelete.TypeAds, 8, 1);
        }
        // delete office in admin
        else if (dataNeedDelete.ID && dataNeedDelete.OfficeType) {
            await this.props.deleteOffice(dataNeedDelete.ID);
            const { err } = this.props.deleteOfficeReducer;
            // debugger;
            if (err && err.Code === 5) {
                this.props.onShowRemoveBranchError({ ...err, Name: dataNeedDelete.Name })
                this.props.clearErrorDeleteOffice();
                return;
            }

            this.props.filterOffice({}, 10, dataNeedDelete.currentPage)
        }
        // delete kiosk device + lcd in branch
        else if (dataNeedDelete.ID && dataNeedDelete.type && dataNeedDelete.key === "deleteDevice") {
            if (dataNeedDelete.type === "DeviceLcd") {
                await this.props.deleteLCD(dataNeedDelete.ID)
                await this.props.getAllLCD(dataNeedDelete.AtOffice, 8, 1)
            } else if (dataNeedDelete.type === "DeviceKiosk") {
                await this.props.deleteKiosk(dataNeedDelete.ID)
                await this.props.getAllKiosk(dataNeedDelete.AtOffice, 8, 1)
            }
        }
        else if (dataNeedDelete.ID && dataNeedDelete.CounterName && dataNeedDelete.type === "deleteCounter") {
            await this.props.deleteCounter(dataNeedDelete.ID, (code) => {
                let officeId = officeUtils.getOfficeID({
                    dataLogin: this.props.userReducer.dataLogin,
                    generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
                })
                //---
                if (code !== 0) {
                    if (code === 21) {
                        this.props.onShowErrPopup({
                            content: "Xoá quầy không thành công",
                            note: "(*) Quầy giao dịch đang ở trạng thái phục vụ khách hàng",
                        })
                    } else if (code === 22) {
                        this.props.onShowErrPopup({
                            content: "Xoá quầy không thành công",
                            note: "(*) Quầy đang ở trạng thái giao dịch",
                        })
                    } else {
                        this.props.onShowErrPopup({
                            content: "Xoá quầy không thành công",
                            note: "(*) Quầy giao dịch không tồn tại hoặc đã bị xoá trước đó",
                        })
                        
                        this.props.getCounterByOffice(officeId, 8, dataNeedDelete.currentPage)
                    }
                } else {
                    this.props.showPopupInBiometric({}, 'DeleteTransactionCounterSuccess')
                    this.props.getCounterByOffice(officeId, 8, dataNeedDelete.currentPage)
                }
            })
        }
        //// Check disconnect Counter 
        else if (dataNeedDelete.ID && dataNeedDelete.TabletDevice && dataNeedDelete
            .DesktopDevice && dataNeedDelete.key === "disConnect") {
            const type = dataNeedDelete.type;
            switch (true) {
                case type === "disConnectCounterTeller":
                    break;
                case type === "disConnectCounterTablet":
                    break;

                default:
                    break;
            }
        }
        this.onClosePopup();
    }
    // updateAds kiosk
    updateKiosK = (id, dataChange) => {
        if (id && dataChange) {
            this.props.updateAds(id, dataChange)
            //---
            this.getAds(dataChange.TypeAds, 8, 1);
            //---
            this.onClosePopup();
        }
    }
    //--- Branch
    updateOffice = async (data, currentPage) => {
        let dataUpdate = {
            ID: data.ID,
            ParentID: data.parentID,
            Name: data.nameTransactionRoom,
            Address: data.addressTransactionRoom,
            Province: data.city,
            District: data.district,
            BranchCode: data.typeBranchCodeItem,
            Ward: data.ward,
            OfficeType: data.typeTransItem,
            HasKioskDeviceConfig: data.deviceConfig.hasKioskDeviceConfig,
            HasTabletDeviceConfig: data.deviceConfig.hasTabletDeviceConfig,
            hasLcdDeviceConfig: data.deviceConfig.hasLcdDeviceConfig
        }
        await this.props.updateOffice(dataUpdate, async (errCode) => {
            if (errCode !== 0) {
                if (errCode === 11 || errCode === 12 || errCode === 13) {
                    // show error
                    let contentValue = errCode === 11
                                        ? "Thiết bị Kiosk đang hoạt động"
                                        : errCode === 12
                                            ? "Thiết bị Tablet đang hoạt động"
                                            : "Thiết bị LCD đang hoạt động"

                    this.props.onShowErrPopup({
                        content: contentValue,
                        note: "(*) Bạn vui lòng liên hệ Admin chi nhánh để tạm ngưng các thiết bị",
                    })
                } else if(errCode === 101 || errCode === 102 || errCode === 103 || errCode === 104){
                    let contentValue = errCode === 101
                                        ? "Thông tin thêm mới CN/PGD không hợp lệ."
                                        : errCode === 102
                                            ? "Mã CN/PGD đã tồn tại trong hệ thống."
                                            : errCode === 103
                                                ? "Mã ID CN/PGD đã tồn tại trong hệ thống."
                                                : "Tên CN/PGD đã tồn tại trong hệ thống."
                    this.props.onShowErrPopup({
                        content: contentValue,
                        note: "(*) Bạn vui lòng kiểm tra lại.",
                    })
                } else {
                    this.props.onShowErrPopup({
                        content: "Cập nhật chi nhánh không thành công",
                        note: "(*) Lỗi không xác định, vui lòng thử lại",
                    })
                }
                return
            } else {
                let BranchSuccess = "BranchSuccess"
                this.props.showPopupInBiometric({}, BranchSuccess)
                await this.props.filterOffice({}, 10, currentPage)
            }
        });
    }

    createOffice = async (data) => {
        let dataCreate = {
            ID: data.ID,
            ParentID: data.parentID,
            Name: data.nameTransactionRoom,
            Address: data.addressTransactionRoom,
            Province: data.city,
            District: data.district,
            BranchCode: data.typeBranchCodeItem,
            Ward: data.ward,
            OfficeType: data.typeTransItem,
            HasKioskDeviceConfig: data.deviceConfig.hasKioskDeviceConfig,
            HasTabletDeviceConfig: data.deviceConfig.hasTabletDeviceConfig,
            hasLcdDeviceConfig: data.deviceConfig.hasLcdDeviceConfig
        }
        await this.props.createOffice(dataCreate, async (errCode) => {
            if (errCode !== 0) {
                if(errCode === 101 || errCode === 102 || errCode === 103 || errCode === 104){
                    let contentValue = errCode === 101
                                        ? "Thông tin thêm mới CN/PGD không hợp lệ."
                                        : errCode === 102
                                            ? "Mã CN/PGD đã tồn tại trong hệ thống."
                                            : errCode === 103
                                                ? "Mã ID CN/PGD đã tồn tại trong hệ thống."
                                                : "Tên CN/PGD đã tồn tại trong hệ thống."
                    this.props.onShowErrPopup({
                        content: contentValue,
                        note: "(*) Bạn vui lòng kiểm tra lại.",
                    })
                } else {
                    this.props.onShowErrPopup({
                        content: "Cập nhật chi nhánh không thành công",
                        note: "(*) Lỗi không xác định, vui lòng thử lại",
                    })
                }
                return
            } else {
                let BranchSuccess = "BranchSuccess"
                this.props.showPopupInBiometric({}, BranchSuccess)
                await this.props.filterOffice({}, 10, 1)
            }
        });
    }
    /// Counter
    acceptActionCounter = async (data, dataModify) => {
        let officeID = officeUtils.getOfficeID({
            dataLogin: this.props.userReducer.dataLogin,
            generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
        })
        //---
        if (dataModify) {
            await this.props.updateCounter(data, async (code) => {
                if (code !== 0) {
                    if ([3, 4, 16].includes(code)) {
                        if (code === 3) {
                            this.props.onShowErrPopup({
                                content: "Cập nhật quầy không thành công",
                                note: "(*) Số quầy đã được sử dụng, vui lòng chọn số quầy khác",
                            })
                        }
                        else if (code === 16) {
                            this.props.onShowErrPopup({
                                content: "Cập nhật quầy không thành công",
                                note: "(*) PC Name đã được khai báo trên hệ thống, vui lòng nhập tên khác",
                            })
                        }
                        else {
                            this.props.onShowErrPopup({
                                content: "Cập nhật quầy không thành công",
                                note: "(*) Số quầy đã được sử dụng, vui lòng chọn số quầy khác",
                            })
                        }
                        await this.props.getCounterByOffice(officeID, 8, dataModify.currentPage)
                    }
                    else if (code === 21) {
                        this.props.onShowErrPopup({
                            content: "Cập nhật quầy không thành công",
                            note: "(*) Quầy giao dịch đang ở trạng thái phục vụ khách hàng",
                        })
                    }
                    else {
                        this.props.onShowErrPopup({
                            content: "Cập nhật quầy không thành công",
                            note: "(*) Quầy đang ở trạng thái giao dịch",
                        })
                    }
                } else {
                    this.props.showPopupInBiometric({}, 'UpdateTransactionCounterSuccess')
                    await this.props.getCounterByOffice(officeID, 8, dataModify.currentPage)
                }
            })
        } else {
            await this.props.createCounter(data, async (dataResp) => {
                if (dataResp.code !== 0) {
                    if (dataResp.code === 3) {
                        this.props.onShowErrPopup({
                            content: "Thêm quầy mới không thành công",
                            note: "(*) Số quầy đã được sử dụng, vui lòng chọn số quầy khác",
                        })
                        await this.props.getCounterByOffice(officeID, 8, 1)
                    } else {
                        this.props.onShowErrPopup({
                            content: "Hệ thống bị gián đoạn",
                            note: "(*) Bạn vui lòng thực hiện lại",
                        })
                    }
                } else {
                    this.props.showPopupInBiometric({}, 'AddTransactionCounterSuccess')
                    await this.props.getCounterByOffice(officeID, 8, 1)
                }
            })
        }
        this.onClosePopup()
    }

    /// Create + update TradingRoom (Kiosk + LCD)
    acceptActionTradingRoom = async (id, name, type, timeoutAPI) => {
        let officeID = officeUtils.getOfficeID({
            dataLogin: this.props.userReducer.dataLogin,
            generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
        })
        //---
        if (id === officeID) {
            if (type === 'DeviceKiosk') {
                await this.props.createKiosk(id, name, timeoutAPI)
                await this.props.getAllKiosk(officeID, 8, 1)
            } else {
                await this.props.createLCD(id, name)
                await this.props.getAllLCD(officeID, 8, 1)
            }
        } else {
            if (type === 'DeviceKiosk') {
                const dataUpdate = {
                    Timeout: timeoutAPI
                }
                await this.props.updateKiosk(id, name, dataUpdate)
                await this.props.getAllKiosk(officeID, 8, 1)
            } else if (type === 'DeviceLcd') {
                await this.props.updateLCD(id, name)
                await this.props.getAllLCD(officeID, 8, 1)
            }
        }
    }

    getPopupContent(popupRef) {
        let content = null;
        let nameData, listDevicePGD

        switch (popupRef.data.type) {
            case 'DeviceKiosk':
                listDevicePGD = this.props.listKiosk
                break;
            case 'DeviceLcd':
                listDevicePGD = this.props.listLCD
                break;
            default:
                break;
        }
        if (popupRef.data) {
            if (popupRef.data.nameLCD) {
                nameData = popupRef.data.nameLCD
            } else if (popupRef.data.dataNeedDelete) {
                if (popupRef.data.dataNeedDelete.Name)
                    nameData = popupRef.data.dataNeedDelete.Name
                else if (popupRef.data.nameData)
                    nameData = popupRef.data.nameData
                else
                    nameData = popupRef.data.dataNeedDelete.CounterName

            }
            else {
                nameData = 'Chưa được đặt tên'
            }
        }
        // debugger
        switch (popupRef.type) {
            case popupType.configAdvertiseTable:
                {
                    content = <PopupAdvertiseRoot
                        descTitle={popupRef.data.descTitle}
                        title={popupRef.data.title}
                        titleHeader='Thêm mới quảng cáo'
                        outPopup={() => {
                            this.onClosePopup()
                        }} />
                    break;
                }
            case popupType.configTransaction:
                {
                    content = <PopupTransactionV2
                        alertPopup={this.props.alertPopup}
                        onShowErrPopup={this.props.onShowErrPopup}
                        titleHeader={popupRef.data.title}
                        dataNeedModify={popupRef.data.dataNeedModify}
                        //dataNeedModify={dataBranch}
                        //dataNeedModify={dataTrading}
                        //dataNeedModify={dataHead}
                        outPopup={() => {
                            this.onClosePopup()
                        }}
                        handleOnClick={
                            popupRef.data.dataNeedModify
                                ? this.updateOffice
                                : this.createOffice
                        }
                        showPopupInBiometric={this.props.showPopupInBiometric}
                    />
                    break;
                }
            case popupType.configAddBranch: {
                content = <PopupAddBranch
                    // typeAdvert={popupRef.data.typeAdvert}
                    listDevicePGD={listDevicePGD}
                    titleHeader={popupRef.data.titleHeader}
                    title={popupRef.data.title}
                    type={popupRef.data.type}
                    placeHolder={popupRef.data.placeHolder}
                    dataNeedModify={popupRef.data.dataNeedModify} outPopup={() => {
                        this.onClosePopup()
                    }}
                    showPopupInBiometric={this.props.showPopupInBiometric}
                    idOffice={officeUtils.getOfficeID({
                        dataLogin: this.props.userReducer.dataLogin,
                        generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
                    })}
                    alertPopup={this.props.alertPopup}
                    handleOnClick={this.acceptActionTradingRoom} />
                break;
            }
            case popupType.configSuccess: {
                content = <PopupSuccess
                    titleHeader='Tạo tài khoản thành công'
                    outPopup={() => {
                        this.onClosePopup()
                    }}
                    acceptAction={() => {
                        this.onClosePopup()
                    }}
                />
                break;
            }
            case popupType.configAddPermission: {
                content = <PopupAddPermissionV2
                    outPopup={this.onClosePopup}
                    showPopupInBiometric={this.props.showPopupInBiometric}
                />
                break;
            }
            case popupType.configConfirm:
                {
                    content = <PopupConfirm
                        title={popupRef.data.title}
                        dataNeedDelete={popupRef.data.dataNeedDelete}
                        nameData={nameData}
                        contentDelete={popupRef.data.contentDelete}
                        contentBlue={popupRef.data.contentBlue}
                        width={popupRef.data.widthPopup}
                        acceptAction={this.deleteItem}
                        cancelAction={() => { this.onClosePopup() }}
                        noOverlay={true}
                    />
                    break;
                }
            case popupType.configAdvertiseNewImage:
                {
                    content = <PopupNewImage
                        title='Thêm mới quảng cáo'
                        outPopup={() => {
                            this.onClosePopup()
                        }} />
                    break;
                }
            case popupType.popupControlType:
                {
                    content = <PopupControl
                        offsetPopup={popupRef.data.offsetPopup}
                        from={popupRef.data.from}
                        dataReceive={popupRef.data.dataReceive}
                        onModify={this.props.onModify}
                        onRemove={
                            this.props.onRemoveConfirm
                        }
                        closePopup={this.onClosePopup}
                    />
                    this.setState({ nonOverlay: true })
                    break;
                }
            case popupType.configAdvertisementPopup:
                {
                    content = <FlowAddAdvertisement
                        showPopupInBiometric={this.props.showPopupInBiometric}
                        typeAdvert={popupRef.data.typeAdvert}
                        outPopup={() => this.onClosePopup()}
                        handleOnSubmit={this.props.createAds}
                        handleReLoadData={this.getAds}
                    />
                    break;
                }
            case popupType.configFlowCheckOffice:
                {
                    content = <FlowOfficeCheckNumber
                        contentDelete={popupRef.data.contentDelete}
                        branchName={popupRef.data.dataShow.Name}
                        NumOfCounter={popupRef.data.dataShow.NumberOfUnDeletedCounters}
                        NumOfKiosk={popupRef.data.dataShow.NumberOfUnDeletedKiosks}
                        NumOfUser={popupRef.data.dataShow.NumberOfUnDeletedUsers}
                        NumOfLCD={popupRef.data.dataShow.NumberOfUnDeletedLCDs}
                        outPopup={() => this.onClosePopup()}
                    />
                    break;
                }
            case popupType.configAccountPermission:
                {
                    content = <PopupAccountPermissionV2
                        dataNeedModify={popupRef.data.dataNeedModify}
                        outPopup={this.onClosePopup}
                        alertPopup={this.props.alertPopup}
                    />
                    break;
                }
            case popupType.configDeletePermission:
                {
                    content = <PopupDeletePermission
                        from={popupRef.data.form}
                        title={popupRef.data.title}
                        dataNeedDelete={popupRef.data.dataNeedDelete}
                        cancelAction={() => this.onClosePopup()}
                        titleHeader='Thông tin tài khoản'
                        outPopup={() => this.onClosePopup()}
                        dataNeedModify={popupRef.data.dataNeedModify}
                        handleOnClick={
                            popupRef.data.dataNeedModify ? () => { } : this.props.createOffice
                        }
                        acceptAction={(id, role) => this.deleteUser(id, role)}
                    />
                    break;
                }
            case popupType.configAdvertiseKiosk:
                {
                    content = (
                        <PopupAdvertiseRoot
                            title={popupRef.data.dataNeedModify.titlePopup}
                            TitleAds={popupRef.data.dataNeedModify.titleAds}
                            descTitle={
                                popupRef.data.dataNeedModify.descTitle ||
                                popupRef.data.descTitle
                            }
                            titleConfirm={popupRef.data.titleConfirm}
                            formatSize={formatSize}
                            imgInfo={{
                                url: popupRef.data.dataNeedModify.fileSrc,
                                name: popupRef.data.dataNeedModify.FileName,
                                width: popupRef.data.dataNeedModify.width,
                                height: popupRef.data.dataNeedModify.height,
                                size: popupRef.data.dataNeedModify.fileSize,
                            }}
                            handleOnSubmit={this.props.updateAds}
                            handleReLoadData={this.getAds}
                            dataNeedModify={popupRef.data.dataNeedModify}
                            typeAds={popupRef.data.dataNeedModify.TypeAds}
                            outPopup={() => {
                                this.onClosePopup()
                            }}
                        />
                    )
                    break;
                }
            case popupType.configAddCounter:
                {
                    content = <PopupAddCounterV2
                        onShowPopupAlert={this.props.alertPopup}
                        onClosePopup={this.onClosePopup}
                        counterData={popupRef.data.dataNeedModify}
                        onSubmit={async (data) => await this.acceptActionCounter(data, popupRef.data.dataNeedModify)}
                        title={popupRef.data.titleHeader}

                    />
                    break;
                }
            case popupType.configOutOfLimit:
                {
                    content = <PopupAlert
                        title={popupRef.data.title}
                        info={popupRef.data.info}
                        note={popupRef.data.note}
                        width={popupRef.data.width}
                        isCenter={popupRef.data.isCenter}
                        bgColor='transparent'
                        outPopup={() => {
                            this.onClosePopup()
                        }}
                    />
                    break;
                }
            case popupType.configCheckBiometricCustomer:
                {
                    // debugger
                    content = <PopupCheckBiometricCustomer
                        showPopupInBiometric={this.props.showPopupInBiometric}
                        onClosePopupInBiometric={this.props.onClosePopupInBiometric}
                        dataUser={popupRef.data.dataUser}
                        dataCurrent={popupRef.data.dataCurrent}
                        isFlowBioErrorExecute={popupRef.data.isFlowBioErrorExecute}
                        outPopup={() => {
                            this.onClosePopup()
                        }}
                        lastConditionFilterUser={this.props.biometricReducer.lastConditionFilterUser}
                        roles={this.props.userReducer.dataLogin.Roles}
                        getBioHistoryList={this.props.getBioHistoryList}
                        fixErrorAcceptBios={this.props.fixErrorAcceptBios}
                        alertPopup={this.props.alertPopup}
                        getListErrorAcceptBios={this.props.getListErrorAcceptBios}
                    />
                    break;
                }
            case popupType.configCheckCustomer:
                {
                    content = <PopupCheckCustomer
                        showPopupCheckCustomer={this.props.showPopupCheckCustomer}
                        dataUser={popupRef.data.dataUser}
                        outPopup={() => {
                            this.onClosePopup()
                        }}
                        alertPopup={this.props.alertPopup}
                    />
                    break;
                }
            default:
                break;
        }
        this.setState({ content })
    }
    render() {
        // debugger
        return (
            <Bound nonOverlay={this.state.nonOverlay}
                onMouseDown={() => {
                    if (this.props.popupRef.type !== popupType.popupControlType)
                        return;
                    if (!this.isMouseEnterPopup) {
                        this.onClosePopup();
                    }
                }}
            >
                <div
                    onMouseEnter={() => {
                        if (this.props.popupRef.type !== popupType.popupControlType)
                            return;
                        return this.isMouseEnterPopup = true
                    }}
                    onMouseLeave={() => {
                        if (this.props.popupRef.type !== popupType.popupControlType)
                            return;
                        return this.isMouseEnterPopup = false
                    }}
                    onMouseOver={() => {
                        if (this.props.popupRef.type !== popupType.popupControlType)
                            return;
                        if (!this.isMouseEnterPopup)
                            this.isMouseEnterPopup = true
                    }}
                >
                    {this.state.content}
                </div>
            </Bound>
        )
    }
}
const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    userReducer: state.userReducer,
    updateOfficeReducer: state.updateOfficeReducer,
    biometricReducer: state.biometricReducer,
    deleteOfficeReducer: state.deleteOfficeReducer,
    listKiosk: state.kioskReducer.listKiosk,
    listLCD: state.LCDReducer.listLCD,
    officeManagementReducer: state.officeManagementReducer
})

const mapDispatchToProps = (dispatch) => ({
    //// office
    createOffice: (dataCreate, callbackFunc) => dispatch(createOffice(dataCreate, callbackFunc)),
    filterOffice: (datafilter, pageSize, pageNumber) => dispatch(filterOffice(datafilter, pageSize, pageNumber)),
    updateOffice: (dataUpdate, callbackFunc) => dispatch(updateOffice(dataUpdate, callbackFunc)),
    deleteOffice: (id) => dispatch(deleteOffice(id)),
    clearErrorDeleteOffice: () => dispatch(clearErrorDeleteOffice()),
    //// ads
    createAds: (title, desc, upload, typeAds, active, applyOffice = undefined) => dispatch(createAds(title, desc, upload, typeAds, active, applyOffice)),
    getAds: (officeID, typeAds, pageSize, pageNumber) => dispatch(getAds(officeID, typeAds, pageSize, pageNumber)),
    updateAds: (id, dataChange) => dispatch(updateAds(id, dataChange)),
    deleteAds: (id) => dispatch(deleteAds(id)),
    setStatusAds: (id, status) => dispatch(setStatusAds(id, status)),
    //// user
    deleteUser: (id) => dispatch(deleteUser(id)),
    filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber)),
    createUser: (listInfoUser) => dispatch(addUser(listInfoUser)),
    updateUser: (dataUpdate, idUser) => dispatch(updateUser(dataUpdate, idUser)),
    /// TrandingRoom
    createKiosk: (idOffice, nameKiosk, timeout) => dispatch(createKiosk(idOffice, nameKiosk, timeout)),
    updateKiosk: (idKiosk, nameKiosk, dataUpdate) => dispatch(updateKiosk(idKiosk, nameKiosk, dataUpdate)),
    getAllKiosk: (idOffice, pageSize, pageNumber) => dispatch(getAllKiosk(idOffice, pageSize, pageNumber)),
    deleteKiosk: (idKiosk) => dispatch(deleteKiosk(idKiosk)),
    createLCD: (idOffice, nameLCD) => dispatch(createLCD(idOffice, nameLCD)),
    updateLCD: (idLCD, nameLCD, macLCD) => dispatch(updateLCD(idLCD, nameLCD, macLCD)),
    deleteLCD: (idLCD) => dispatch(deleteLCD(idLCD)),
    getAllLCD: (idOffice, pageSize, pageNumber) => dispatch(getAllLCD(idOffice, pageSize, pageNumber)),
    //// counter
    createCounter: (dataCreate, onSuccessFunc) => dispatch(createCounter(dataCreate, onSuccessFunc)),
    updateCounter: (dataUpdate, onCompletedFunc) => dispatch(updateCounter(dataUpdate, onCompletedFunc)),
    deleteCounter: (counterId, onCompletedFunc) => dispatch(deleteCounter(counterId, onCompletedFunc)),
    getCounterByOffice: (officeId, pageSize, pageNumber) => dispatch(getCounterByOffice(officeId, pageSize, pageNumber)),
    // disConnectCounter: (counterID) => dispatch(disConnectCounter)
    //// supervisor
    acceptBioInfos: (idBio, noteBio) => dispatch(acceptBioInfos(idBio, noteBio)),
    getBioInfos: (dataQuery) => { dispatch(getBioInfos(dataQuery)) },
    getBioHistoryList: (customerNumber, pageSize, pageNumber, lessThanCreateAt) => dispatch(getBioHistoryList(customerNumber, pageSize, pageNumber, lessThanCreateAt)),
    clearError: (typeErr, reducerTarget) => dispatch(clearError(typeErr, reducerTarget)),
    fixErrorAcceptBios: (id, callbackSuccess) => dispatch(fixErrorAcceptBios(id, callbackSuccess)),
    getListErrorAcceptBios: (custNumber) => dispatch(getListErrorAcceptBios(custNumber))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupContainer);