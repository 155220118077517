import { css } from 'styled-components'

const lstPxs = [115, 290, 170, 170, 170, 170, 170, 115, 500]
const gridArr = lstPxs.map((item) =>
    typeof item === 'number' ? `${item}px` : item
)

const extendStylesTable = css`
    .header,
    .row {
        grid-template-columns: ${gridArr.join(' ')};
    }
`

const extendStylesTeller = css`
    width: 220px;
`

export { extendStylesTable, extendStylesTeller }
