export const ADMIN_ROLE = "ADMIN"; //Admin root
export const ADMIN_OFFICE_ROLE = "OFFADMIN"; // Quản trị HT tại CN
export const TELLER = "TELLER"; // GDV CN
export const OFFINSUPERVISOR = "OFFINSUPERVISOR"; // Ban giám đốc CN
export const OFFEXSUPERVISOR = "OFFEXSUPERVISOR"; // KSV CN
export const INBIOCOLLECTOR = "INBIOCOLLECTOR"; // Đăng ký STH cho cán bộ CN
export const CCD = "COORLOBBY"; // Customer Coordination - Điều phối khách hàng tại sảnh giao dịch
export const CCD_GQN = "TAKETICKET"; // Customer Coordination - Get the queue number - Sếp hàng lấy số
export const CCD_CCS = "ADVISETICKET"; // Customer Coordination - Customer Consulting - Tư vấn khách hàng
export const CCD_CCT = "MOVETICKET"; // Customer Coordination - Change Couter - Chuyển quầy
export const RPW = "RPW"; // Xem báo cáo
export const IFW = "IFW"; // Vấn tin
export const IFW_SID = "SID";//SearchID
export const IFW_SC = "SC";//SearchCif
export const IFW_DUP = "DUP";//DupInfos
export const IFW_SBB = "SBB";//SearchByBio
export const COUNSELOR = "COUNSELOR";//Tư vấn viên
export const COORDINATOR = "COORDINATOR";//Điều phối viên
