import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// import image-icon
import icMoreGray from '../../../../images/ic_more_gray.svg'
import icDeviceGreen from '../../../../images/ic_device_green.svg'
import icDeviceGrey from '../../../../images/ic_device_grey.svg'
import ic_refresh_gray from '../../../../images/ic_refresh_gray.svg'

import PopupConfirmUsePortal from '../Control/PopupPortal/PopupConfirmEForm'
import { connect } from 'react-redux'
import CountDownTimer from '../Control/countDownTimer'

const styleCountTime = css`
    color: #222222;
`

const StyledDeviceKiosItem = styled.div`
    width: 100%;
    height: 140px;
    position: relative;
    background: #ffffff;
    font-family: SVN-Gilroy;
    border-radius: 4px;
    &:last-child {
        margin-bottom: 40px;
    }
    cursor: ${(props) => props.cursor || 'default'};
    .kiosk-item__title {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-left: 12px;
        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .ic_more {
            margin: 0 6px 0 0;
        }
        div {
            flex-grow: 1;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin-left: 4px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    .kiosk-active__content {
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        margin-left: 17px;
        margin-top: 15px;
        height: 18px;

        .block-key {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 15px 0 0px;
            .sub-name {
                max-width: 21ch;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.3;
                color: #222222;
                display: flex;
                align-items: center;
                p {
                    max-width: 170px;
                    display: block;
                    margin: 0 auto;
                    font-size: 14px;
                    line-height: 1;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            img {
                margin: 0 0 3px 7px;
            }
        }

        .block-connected {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .text-content {
                color: #0fb11f;
                font-size: 14px;
                font-weight: 500;
                text-transform: none;
            }

            img {
                margin: 0 0 0 7px;
            }
        }

        .connect {
            width: fit-content;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            color: #1477ec;
            cursor: pointer;
        }
    }

    .kiosk-item__footer {
        display: flex;
        font-family: SVN-Gilroy;
        margin: 35px 0 0 17px;
        align-items: center;
        & > p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #222222;
            margin: 0 8px 0 0;
            text-transform: uppercase;
        }
    }
`

class DeviceKiosItem extends Component {
    _isMounted = false
    state = {
        data: this.props.data,
        isHover: false,
        dataGenkey: {},

        statusEform: true,
        showPopupConfirmEform: false,
    }

    onShowControl(index, data) {
        let moreBtn = document.getElementById('more_button_id_' + index)
        if (moreBtn) {
            let bound = moreBtn.getBoundingClientRect()
            let scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft
            let scrollTop =
                window.pageYOffset || document.documentElement.scrollTop
            let topBound = bound.top + scrollTop
            let leftBound = bound.left + scrollLeft
            let offsetPopup = {
                top: topBound + bound.height / 2,
                left: leftBound - 78 + bound.width / 2,
            }
            let dataNeed = {
                ...data,
                type: 'DeviceKiosk',
                currentPage: this.props.currentPage,
            }
            //console.log(dataNeed);
            this.props.onShowControl(offsetPopup, 'DeviceKioskPGD', dataNeed)
        }
    }

    async onConnect() {
        const { data } = this.props
        await this.props.generateKeyConnectKiosk(data.AtOffice, data.ID)
        // const test = {
        //     Code: 0,
        //     ConnectID: 'KIOSKc',
        //     MaxAge: 300,
        //     Message: '',
        //     Pwd: '7280624f',
        //     ID: "5e11567ee5be745514ee6a84"
        // }
        // this.setState({
        //     dataGenkey: { ...test},
        // })
        this.setState({
            dataGenkey: { ...this.props.dataGenkey, ID: this.props.data.ID },
        })
    }

    _handleSwitchEform = (idKiosk, status) => {
        this.setState({
            showPopupConfirmEform: true,
        })
    }

    _refreshKioskConnected = () => {
        console.log('_refreshKioskConnected')
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.kioskReducer.listKiosk !==
            this.props.kioskReducer.listKiosk
        ) {
            const { ID } = this.props.data
            const data = this.props.kioskReducer.listKiosk.find(
                (item) => item.ID === ID
            )
            // console.log(data.EForm)
            if (data.EForm === false || data.EForm === true) {
                this.setState({
                    statusEform: data.EForm,
                })
            }
        }

        if (
            JSON.stringify(this.props.dataGenkey) !==
            JSON.stringify(prevProps.dataGenkey)
        ) {
            if (!this.props.dataGenkey) {
                this.setState({
                    dataGenkey: {},
                })
            } else {
                this.setState({
                    dataGenkey: { ...this.props.dataGenkey, ID: this.props.data.ID },
                })
            }
        }
    }

    componentDidMount() {
        if (this.props.data.EForm === false || this.props.data.EForm === true) {
            this.setState({
                statusEform: this.props.data.EForm,
            })
        }
    }

    render() {
        const { IsConnected, Name, ID } = this.props.data
        const { keyValue } = this.props
        let { dataGenkey, showPopupConfirmEform } = this.state

        return (
            <StyledDeviceKiosItem
                onDoubleClick={() =>
                    this.props.onShowPopupEditKiosk({
                        ...this.props.data,
                        type: 'DeviceKiosk',
                        currentPage: this.props.currentPage,
                    })
                }
                cursor="pointer"
            >
                {/* title */}
                <div className="kiosk-item__title">
                    <img
                        src={!IsConnected ? icDeviceGrey : icDeviceGreen}
                        alt="status"
                    />
                    <div>{Name}</div>
                    <img
                        className="ic_more"
                        src={icMoreGray}
                        alt="more"
                        id={'more_button_id_' + this.props.keyValue}
                        onClick={() =>
                            this.onShowControl(keyValue, this.props.data)
                        }
                    />
                </div>

                {/* content */}
                <div className="kiosk-active__content">
                    {!IsConnected &&
                        Object.keys(this.state.dataGenkey).length > 0 &&
                        dataGenkey.ID === ID && (
                            <div className="block-key">
                                <div
                                    className="sub-name"
                                    title={`${this.state.dataGenkey.ConnectID}-${this.state.dataGenkey.Pwd}`}
                                >
                                    <p>
                                        {this.state.dataGenkey.ConnectID}-
                                        {this.state.dataGenkey.Pwd}
                                    </p>
                                    <img
                                        src={ic_refresh_gray}
                                        alt="refresh"
                                        onClick={this.onConnect.bind(this)}
                                    />
                                </div>
                                <div className="count-timer">
                                    <CountDownTimer
                                        key={1}
                                        condition={dataGenkey}
                                        timer={dataGenkey.MaxAge}
                                        extendStyles={styleCountTime}
                                        callBackTime={() => {
                                            this.props.clearKeyGenKiosk(
                                                ID,
                                                Name,
                                                'dataGenKey'
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                    {/* {false && Object.keys(this.state.dataGenkey).length === 0 && ( */}
                    {!IsConnected &&
                        Object.keys(this.state.dataGenkey).length === 0 && (
                            <div
                                className="connect"
                                onClick={this.onConnect.bind(this)}
                            >
                                Connect ID
                            </div>
                        )}

                    {/* {true && ( */}
                    {IsConnected &&
                        Object.keys(this.state.dataGenkey).length === 0 && (
                            <div className="block-connected">
                                <p className="text-content">Đã kết nối</p>
                                {/* <img
                                    src={ic_refresh_gray}
                                    alt="refresh"
                                    onClick={this.onConnect.bind(this)}s
                                /> */}
                            </div>
                        )}
                </div>

                {/* footer */}
                {/* <div className="kiosk-item__footer e-form">
                    <p>E-form</p>
                    <ButtonSwtich
                        status={EformStatus == 'E'}
                        callBackSwitch={(status) =>
                            this._handleSwitchEform(ID, status)
                        }
                    />
                </div> */}

                {showPopupConfirmEform && (
                    <PopupConfirmUsePortal
                        closePopup={() => {
                            this.setState({ showPopupConfirmEform: false })
                        }}
                        showPopup={showPopupConfirmEform}
                        // data={{
                        //     status: statusEform,
                        //     ID,
                        //     Name,
                        //     OnOffEform: statusEform ? false : true,
                        // }}
                        data={this.props.data}
                    />
                )}
            </StyledDeviceKiosItem>
        )
    }
}

const mapStateToProps = (state) => ({
    kioskReducer: state.kioskReducer,
})

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(DeviceKiosItem)
