import { combineReducers } from 'redux'

import listReports from './listReports'
import detailsReport from './detailsReport'

const reports = combineReducers({
    listReports,
    detailsReport
})

export default reports