import React from 'react'
import { WrapContainer } from './index.styles'
import InputNumberControl from '../../../Control/InputNumberControl'

export default function ConfigureByGeneralControl({dataInfo, defaultConfig, onChanged, onErrNotify}) {
  let newDataInfo = dataInfo

  const UnitType = {
    numberOfTimesType: 1,
    numberOfMinutesType: 2
  }

  const ConfigType = {
    bookingByPerSessions: 1,
    bookingLimit: 2,
    timeBookingPreset: 3,
    issueTicketTimeEarly: 4,
    issueTicketTimeLate: 5
  }
  const onErrNotifyAcion = (isErr, configType) => {
    onErrNotify && onErrNotify(isErr, configType)
  }

  const onChangedAction = () => {
    onChanged && onChanged(newDataInfo)
  }
  
  const renderItem = ({title, unitType, configType}) => {
    const getValue = () => {
      let value = ''
      switch (configType) {
        case 1:
          value = dataInfo.bookingByPerSessions
          break;
        case 2:
          value = dataInfo.bookingLimit
          break;
        case 3:
          value = dataInfo.timeBookingPreset
          break;
        case 4:
          value = dataInfo.issueTicketTimeEarly
          break;
        default:
          value = dataInfo.issueTicketTimeLate
          break;
      }
      return value
    }

    const getMinMaxValue = () => {
      let minmaxValue
      switch (configType) {
        case 1:
          minmaxValue = defaultConfig.bookingByPerSessions
          break;
        case 2:
          minmaxValue = defaultConfig.bookingLimit
          break;
        case 3:
          minmaxValue = defaultConfig.timeBookingPreset
          break;
        case 4:
          minmaxValue = defaultConfig.issueTicketTimeEarly
          break;
        default:
          minmaxValue = defaultConfig.issueTicketTimeEarly
          break;
      }
      return minmaxValue
    }

    const updateValue = (newValue) => {
      switch (configType) {
        case 1:
          newDataInfo.bookingByPerSessions = newValue
          break;
        case 2:
          newDataInfo.bookingLimit = newValue
          break;
        case 3:
          newDataInfo.timeBookingPreset = newValue
          break;
        case 4:
          newDataInfo.issueTicketTimeEarly = newValue
          break;
        default:
          newDataInfo.issueTicketTimeLate = newValue
          break;
      }
      onChangedAction()
      console.log(`${title}: ${newValue}`);
    }

    //---
    return (
      <div className="item">
        <InputNumberControl
          isRequire={true}
          title={title}
          unit={unitType===UnitType.numberOfTimesType?"Lượt":"Phút"}
          value={getValue()}
          onChanged={updateValue}
          minmaxValue={getMinMaxValue()}
          onErrNotify={(isErr)=>{
            onErrNotifyAcion(isErr, configType)
          }}
          errKey={unitType===UnitType.numberOfTimesType?"Số Lượt":"Số Phút"}
        />
      </div>
    )
  }

  return (
    <WrapContainer>
      <div className="row-content">
        {
          renderItem({
            title: "Số lịch hẹn tối đa theo từng KH/buổi (sáng/ chiểu)/PGD",
            configType: ConfigType.bookingByPerSessions,
            unitType: UnitType.numberOfTimesType
          })
        }
        {
          renderItem({
            title: "Số lịch hẹn tối đa theo từng KH",
            configType: ConfigType.bookingLimit,
            unitType: UnitType.numberOfTimesType
          })
        }
      </div>
      <div className="row-content">
        {
          renderItem({
            title: "Thời gian cho phép đặt lịch trước tối thiểu",
            configType: ConfigType.timeBookingPreset,
            unitType: UnitType.numberOfMinutesType
          })
        }
        {
          renderItem({
            title: "Thời gian cho phép xuất vé trước tối đa",
            configType: ConfigType.issueTicketTimeEarly,
            unitType: UnitType.numberOfMinutesType
          })
        }
      </div>
      <div className="row-content">
        {
          renderItem({
            title: "Thời gian hiệu lực của 1 lịch hẹn (tính từ thời gian lịch đã đặt)",
            configType: ConfigType.issueTicketTimeLate,
            unitType: UnitType.numberOfMinutesType
          })
        }
      </div>
    </WrapContainer>
  )
}
