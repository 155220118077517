import React, { Component } from 'react'
import { connect } from 'react-redux'

import logo_viettin from '../../images/logo_vietinbank.svg'
// import { loginUser, filterUser, addUser } from '../../actions/userAction';
// data
import { LOGIN_USER_ERR } from '../../actions/types'

// action
import { clearError } from '../../actions/commonAction'

// control
import LoadingControl from "../Main/MainScreen/Control/LoadingControl"

// styles
import { LoginPageStyles } from './index.styles'

const pjson = require('../../../package.json')
class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // userStore: user,
            userTmp: '',
            passTmp: '',
            err: '',
            isLoading: false
        }
        this.oldUser = ''
        this.oldPass = ''
    }
    onClear = () => {
        this.setState({ userTmp: '', passTmp: '' })
    }
    onEnter = (e) => {
        if (e.keyCode === 13) {
            this.onSubmit()
            // this.onClear();
        }
    }
    handleUserInput = (e) => {
        //check valid value input
        let regex = e.target.value.replace(/\s/g, '')
        //true =>
        this.oldUser = regex
        this.setState({ userTmp: this.oldUser, err: '' })
        //false =>
    }

    handlePassInput = (e) => {
        let regex = e.target.value.replace(/\s/g, '')
        this.oldPass = regex
        this.setState({ passTmp: this.oldPass, err: '' })
    }
    onSubmit = () => {
        let { userTmp, passTmp } = this.state
        if (!userTmp.trim() || !passTmp.trim()) {
            this.props.alertPopup('Tài khoản và mật khẩu không được để trống')
            return
        }
        this.setState(
            { 
                isLoading: true,
                err: ''
            },
            () => {
                this.props.loginUser(userTmp, passTmp)
            }
        )
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userReducer.err !== this.props.userReducer.err) {
            const { err } = this.props.userReducer
            if (typeof err === 'object' && err[LOGIN_USER_ERR]) {
                this.props.clearError(LOGIN_USER_ERR, 'userReducer')
                console.clear()
                // this.props.alertPopup("Thông tin đăng nhập không chính xác. Vui lòng kiểm tra lại");
                this.setState({
                    isLoading: false,
                    err:
                        'Tài khoản đăng nhập không đúng hoặc không tồn tại trong hệ thống.',
                })
            }
        }
    }

    render() {
        return (
            <LoginPageStyles>
                <div className="loginContainer">
                    <div className="rowHeader">
                        <div className="logoVietinBank">
                            <img src={logo_viettin} alt="logoViettinBank" />
                        </div>
                    </div>
                    <div className="rowLinear"></div>
                    <div className="rowMain">
                        <div className="col_login">
                            <div className="title">
                                <p>VietinBank SDB Admin</p>
                            </div>
                            <div className="content">Đăng nhập hệ thống</div>
                            <div className="form_login">
                                <label>TÀI KHOẢN</label>
                                <input
                                    name="userName"
                                    onChange={(e) => this.handleUserInput(e)}
                                    onKeyUp={(e) => this.onEnter(e)}
                                    className="userName"
                                    autoComplete="false"
                                    value={this.state.userTmp || ''}
                                    placeholder="Nhập tài khoản"
                                ></input>
                                <label>MẬT KHẨU</label>
                                <input
                                    type="password"
                                    onChange={(e) => this.handlePassInput(e)}
                                    onKeyUp={(e) => this.onEnter(e)}
                                    className="password"
                                    autoComplete="new-password"
                                    value={this.state.passTmp || ''}
                                    placeholder="Nhập mật khẩu"
                                ></input>
                                <p className="error">{this.state.err}</p>
                                <div className="blockBtn">
                                    {
                                        this.state.isLoading ?
                                        <div className="loading">
                                            <LoadingControl
                                                size={25}
                                                loadingPage={false}
                                            />
                                            Vui lòng chờ
                                        </div>
                                        :
                                        <button
                                            onClick={() => this.onSubmit()}
                                            className="btn_login"
                                        >
                                            ĐĂNG NHẬP
                                        </button>
                                    }
                                </div>
                                <div className="version">
                                    Phiên bản v{pjson.version}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoginPageStyles>
        )
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
})

const mapDispatchToProps = (dispatch) => ({
    clearError: (typeErr, reducerTarget) =>
        dispatch(clearError(clearError, reducerTarget)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
