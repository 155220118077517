import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'

// images
import icon_search from './../../../../images/ic_search_gray.png'

// Data
import { BiometricTabArr } from '../../../../data/data'
import { dataWard } from '../../../../data/VietNamData/DataWard'
import { dataDistrict } from '../../../../data/VietNamData/DataDistrict'
import { dataCity } from '../../../../data/VietNamData/DataCity'

// Component
// import SubHeaderControl from '../Control/SubHeaderControl'
import BiometricTable from './BiometricTable'
import EmptyControl from '../Control/EmptyControl'
import BiometricFilter from './BiometricFilter'

//Action
import {
    getBioInfos,
    saveTextSearchDataBiometric,
    clearTextSearchDataBiometric,
} from '../../../../actions/supervisorAction/biometricAction'
import { getListOfUsername } from '../../../../actions/commonAction'
import { color } from '../../../../data/store'

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;
    .ad-container {
        display: flex;
        flex: 1;
        width: 900px;
        overflow: hidden;
        padding: 0 0 0 40px;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: space-between;
            margin: 27px 16px 0 0;
            &-address {
                .main {
                    color: ${color.blue_005993};
                    font-size: 26px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 580px;
                    padding: 5px 0 0 0;
                    text-transform: uppercase;
                }
                .sub {
                    font-weight: 500;
                    font-size: 12px;
                    color: #222222;
                }
            }
            &-search {
                position: relative;
                input {
                    width: 300px;
                    height: 36px;
                    background: #ffffff;
                    border-radius: 4px;
                    border: none;
                    outline: none;
                    padding: 0 30px 0 10px;
                }
                img {
                    position: absolute;
                    top: 40%;
                    right: 10px;
                    transform: translate(0, -50%);
                    cursor: pointer;
                }
            }
        }
    }

    .block-filter {
        /* height: 100%; */
        width: 240px;
        /* overflow: auto; */
        background: #ffffff;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
    }
`

class BiometricDataContainer extends Component {
    _isMounted = false
    state = {
        dataFilter: {},
        subHeaderData: BiometricTabArr[0].subHeaderData,
        isAdd: false,
        // tab_layout: TAB_LAYOUT.PENDING_APPROVE.key,
        callBackSearch: 0,
        valueCustNumOrName: '',
    }

    componentWillMount() {
        let { subHeaderData } = this.state
        const { dataLogin } = this.props.userReducer
        subHeaderData = {
            ...subHeaderData,
            mainTitle: dataLogin.OfficeName,
            subTitle: [
                dataLogin.OfficeAddress,
                dataWard[dataLogin.OfficeWard].name,
                dataDistrict[dataLogin.OfficeDistrict].name,
                dataCity[dataLogin.OfficeProvince].name
            ].join(", "),
        }
        this.setState({ subHeaderData })
    }

    render() {
        const { valueCustNumOrName, callBackSearch } = this.state
        return (
            <>
                <Bound>
                    <div className="ad-container">
                        <div className="header">
                            <div className="header-address">
                                <p className="main">
                                    {this.state.subHeaderData.mainTitle}
                                </p>
                                <p className="sub">
                                    {this.state.subHeaderData.subTitle}
                                </p>
                            </div>

                            <div className="header-search">
                                <input
                                    type="text"
                                    placeholder="Nhập mã số CIF, GTTT, Tên khách hàng"
                                    value={valueCustNumOrName}
                                    onChange={(e) =>
                                        this.setState({
                                            valueCustNumOrName: e.target.value,
                                        })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.setState({
                                                callBackSearch:
                                                    this.state.callBackSearch +
                                                    1,
                                            })
                                        }
                                    }}
                                />
                                <img
                                    src={icon_search}
                                    alt="search"
                                    onClick={() =>
                                        this.setState({
                                            callBackSearch:
                                                this.state.callBackSearch + 1,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        {this.props.biometricReducer.dataBiometric &&
                        this.props.biometricReducer.dataBiometric.length > 0 ? (
                            <BiometricTable
                                showPopupCheckBiometric={
                                    this.props.showPopupCheckBiometric
                                }
                                roles={this.props.roles}
                            />
                        ) : (
                            <EmptyControl
                                text_title="Chưa có dữ liệu khách hàng"
                                text="Hệ thống sẽ tự động cập nhật khi có dữ liệu"
                            />
                        )}
                    </div>

                    {/* Filter */}
                    <div className="block-filter">
                        <BiometricFilter
                            callBackGetSearch={callBackSearch}
                            valueCustNumOrName={this.state.valueCustNumOrName}
                            callBackReset={() =>
                                this.setState({ valueCustNumOrName: '' })
                            }
                        />
                    </div>
                </Bound>
            </>
        )
    }
    componentDidMount() {
        // let m = moment().utcOffset(0)
        // m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        // m.toISOString()
        const timeStart = moment().startOf('day').toISOString()
        const timeEnd = moment().endOf('day').toISOString()
        this.props.getBioInfos({
            OfficeID: this.props.userReducer.dataLogin.OfficeID,
            Status: 'PENDING',
            PageSize: 10,
            PageNumber: 1,
            // FromCreateAt: m.format(),
            FromCreateAt: timeStart,
            ToCreateAt: timeEnd,
        })

        this.props.getListOfUsername([this.props.userReducer.dataLogin.OfficeID])
    }

    componentWillUnmount() {
        // this.props.clearTextSearchDataBiometric()
    }
}
const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    biometricReducer: state.biometricReducer,
})
const mapDispatchToProps = (dispatch) => {
    return {
        getBioInfos: (dataQuery) => {
            dispatch(getBioInfos(dataQuery))
        },
        saveTextSearch: (text) => dispatch(saveTextSearchDataBiometric(text)),
        clearTextSearchDataBiometric: () =>
            dispatch(clearTextSearchDataBiometric()),
        getListOfUsername: (lstOffice) =>
            dispatch(getListOfUsername(lstOffice))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwarRef: true,
})(BiometricDataContainer)
