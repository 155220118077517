import React from 'react'
import styled from 'styled-components'
import { color } from '../../../../data/store'
const Bound = styled.div`
    display: flex;
    /* flex: 1; */
    margin-top: 24px;
    margin-left: ${prop => prop.left??0}px;
    .core-name {
        h2 {
            font-family: 'SVN-Gilroy';
            font-size: 24px;
            font-weight: bold;
            line-height: 1.08;
            color: ${color.blue_005993};
        }
        p {
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            line-height: 1.42;
            color: #222222;
            margin-top: 5px;
            text-transform: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }
`


export default function BlockNameControl({left, mainname, subname}) {
  return (
    <Bound left={left}>
        <div className="core-name">
            <h2>{mainname}</h2>
            <p>{subname}</p>
        </div>
    </Bound>
  )
}

