import React, { Component } from 'react';
import styled from "styled-components";
// Images
import ic_notification_success from '../../../images/ic_notification_success.svg';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';

const Bound = styled.div`
    padding: 14.1px 14.2px 16px 12px;
    background-color: #fafafa;
    width: 300px;
    height: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
    text-align: center;
    z-index: 9999;
    box-shadow: 0px 0px 10px 0px #0000008a;
    animation: fadeInConfirm .6s;
    @keyframes fadeInConfirm {
        0%   { opacity:.7; top: 25% }
        100% { opacity:1; top: 40% }
    }
    .popupHeader{
        display: flex;
        align-items: end;
        margin-bottom: 20px;
        height: 58px;
        .img-content{
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }
    .content-text{
        font-size: 17px;
        color: var(--black);
        padding-bottom: 20px;
    }
    button{
        margin: 0 auto;
        margin-top: 25px;
    }
`

class PopupSuccess extends Component {
    render() {
        return (
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    background: this.props.backgroundColor??'rgba(0,0,0,0.6)',
                    width: "100%",
                    height: "100%",
                    zIndex: "100"
                }}
            >
                <Bound>
                    <div className="popupHeader">
                        <div className="img-content">
                            <img src={ic_notification_success} alt='ic_success' />
                        </div>
                    </div>
                    <div className="content-text">{this.props.titleHeader}</div>
                    <ButtonConfirmPopup
                        onClick={this.props.acceptAction}
                        titleConfirm='xác nhận' />
                </Bound>
            </div>
        );
    }
}

export default PopupSuccess;