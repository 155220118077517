import styled from "styled-components";
import { color } from "../../../../../data/store";

const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: 13px;
    /*opacity: ${props => props.isDisabled ? "0.5" : "unset"};*/   
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield
    }
    .title-text {        
        font-size: 11px;
        font-weight: bold;        
        color: ${color.blue_005993};
        text-transform: uppercase;
        margin-bottom: 5px;
        .title-notice{
            color: #F31919;
            margin-left: 3px;
        }
    }
    .wrap-input{
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%;
            padding: 10px;
            height: 22px;       
            font-size: 14px;
            font-weight: 500;
            font-family: 'SVN-Gilroy';
            line-height: 1.2;
            border-radius: 4px;
            border: solid 0.5px #c8c8c8;
            background-color: ${props => props.isDisabled ? "#E6E6E6" : "#FFFFFF"};
            color: ${props => props.isDisabled ? "rgba(25, 25, 25, 0.3)" : "#000000"};
            pointer-events: ${props => props.isDisabled ? "none" : "unset"};
            &::placeholder{
                color: #bababa;
            }      
        }
        .prefix{
            position: absolute;
            top: 15px;
            left: 13px;
            font-size: 14px;
            font-weight: 500;
            font-family: 'SVN-Gilroy';
            line-height: 1.2;
            text-align: left;
            color: #191919;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90px;
        }
        .suffix{
            position: absolute;
            top: 15px;
            right: 13px;
            font-size: 14px;
            font-weight: 500;
            font-family: 'SVN-Gilroy';
            line-height: 1.2;
            text-align: left;
            color: #191919;
        }
    }
`
export { Bound }