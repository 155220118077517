import styled from "styled-components";
import ic_question from '../../../../../../images/Icons_Notification_Question.svg'

const WrapPopupConfirmDelete = styled.div`
    padding: 20px 32px 15px 32px;
    background-color: #fafafa;
    width: 256px;
    height: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px #0000008a;
    border-radius: 10px;
    animation: fadeInConfirm 0.6s;
    @keyframes fadeInConfirm {
        0% {
            opacity: 0.7;
            top: 25%;
        }
        100% {
            opacity: 1;
            top: 40%;
        }
    }
    .row_question {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 53px;
        .img_question {
            background-image: url(${ic_question});
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 53px;
            height: 53px;
            object-fit: contain;
        }
    }
    .row_title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        flex-direction: column;
        font-size: 16px;
        .title {
            line-height: 1.13;
            color: #222222;
            text-align: center;
            font-weight: bold;
        }
    }
    .note{
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        line-height: 17.92px;
        text-align: center;
        color: #F31919;
        margin-top: 10px;
    }
    .row_btn_confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px 0 0 0;
        .btn_exit {
            margin: 0 20px 0 0;
        }
    }

`

export {
    WrapPopupConfirmDelete
}