/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { WrapContainer } from './index.styles'
import { useState } from 'react';
import { useEffect } from 'react';

export default function InputNumberControl({isRequire, width, title, unit, value, minmaxValue={min: 1, max: 99999}, errKey, onChanged, onErrNotify}) {
    const [inputValue, setInputValue] = useState(value); 
    const [errData, setErrData] = useState({
        isShowErr: false,
        messErr: ''
    }); 

    const handleChange = (e) => {
        let newValue = parseInt(e.target.value);
        if(isNaN(newValue)){
            newValue = 0
        }
        //---
        let isErr = false
        if(newValue < minmaxValue.min || newValue > minmaxValue.max){
            isErr = true;
            if(!errData.isShowErr){
                setErrData({
                    isShowErr: true,
                    messErr: `${errKey} không được nhỏ hơn ${minmaxValue.min} và lớn hơn ${minmaxValue.max}`
                })
                onErrNotify && onErrNotify(true)
            }
        } else {
            if(errData.isShowErr){
                setErrData({
                    isShowErr: false,
                    messErr: ''
                })
                onErrNotify && onErrNotify(false)
            }
        }
        if(newValue !== inputValue){
            setInputValue(newValue)
            //---
            if(!isErr){
                onChanged && onChanged(newValue)
            }
        }
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])
    //---
    return (
        <WrapContainer width={width} isError={errData.isShowErr}>
            <div className="title-content">
                <div className="title">{title}</div>
                {
                    isRequire &&
                    <div className="title required">*</div>
                }
            </div>
            <div className="input-content">
                <input 
                    value={inputValue}
                    onChange={handleChange} 
                    type="text" 
                    pattern="^[0-9]*$"
                    maxLength={6}
                />
                <div className="unit">{unit}</div>
            </div>
            <div className="error">{errData.messErr}</div>
            
        </WrapContainer>
    )
}
