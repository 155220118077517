import styled from "styled-components";
import { CheckBoxStatus, CheckBoxTheme } from ".";
import { color } from "../../../../../data/store";


const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid ${
        props => props.status === CheckBoxStatus.UNSELECTED
                    ? color.grayE6
                    : props.theme===CheckBoxTheme.RED
                        ? color.grayE6
                        : color.blue_005993
    };
    background-color: ${
        props => props.status === CheckBoxStatus.ALL_SELECTED
                    ? props.theme===CheckBoxTheme.RED
                        ? color.whiteFF
                        : color.blue_005993
                    :  props.theme===CheckBoxTheme.RED
                        ? color.whiteFF
                        : color.whiteF7
    };

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: ${
            props => props.theme===CheckBoxTheme.RED
                            ? props.status === CheckBoxStatus.SELECTED
                                ? color.red_D71249
                                : color.whiteFF
                            : color.blue_005993
        };
        img {
            width: 10px;
            height: 10px;
        }
    }
`

export {CheckBoxContainer}