import styled from "styled-components";
import { color } from "../../../../data/store";

const WrapBiometricExecution = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 180px);
    min-width: calc(1024px - 180px);

    .search-bio {
        width: 100%;
        height: 82px;
        background-color: #fff;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        padding: 15px 0 0 25px;
        box-sizing: border-box;

        .wrap-search {
            display: flex;
            align-items: center;
            .wrapper-input {
                width: 441px;
                height: 36px;
                box-sizing: border-box;
                border: 0.5px solid #D6D6D6;
                border-radius: 4px;
                display: flex;
                align-items: center;

                & > img {
                    margin-left: 9px;
                }

                & > input {
                    height: 100%;
                    padding: 0 10px 0 0;
                    margin: 0;
                    border: none;
                    flex-grow: 1;
                    box-sizing: border-box;
                    outline: none;
                    border-radius: 0 4px 4px 0;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 128%;
                    color: #222222;
                }
            }

            .btn-search {
                margin-left: 11px;
                padding: 8px 12px;
                background: ${color.blue_005993};
                border-radius: 18px;
                border: none;
                outline: none;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 120%;
                color: #FFFFFF;
                text-transform: uppercase;

                &.disabled {
                    opacity: 0.4;
                    &:hover {
                        cursor: default;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .error {
            margin-top: 10px;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 150%;
            color: #F31919;
        }

    }

    .wrap-table {
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        padding: 0 19px 0 19px;
        box-sizing: border-box;
    }

`

export {
    WrapBiometricExecution
}