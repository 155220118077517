import Axios from "axios";
import catchErrorApi from "../../../../helper/catchErrorApi";
import { GET_CUSTOMER_TYPE_LIST_ERR } from "../types";
import { addLoading, removeLoading } from "../../../commonAction";
import { host } from "../../../../host";

const apiGetCustomerType = host + "admin/exportJSON/get-customer-type";

export const getCustomerTypeList = ({onCompleted}) => async dispatch => {
	dispatch(addLoading())
	try {
		const res = await Axios.get(apiGetCustomerType);
        const { data } = res;
		onCompleted && onCompleted({
			code: data.Code,
			message: "",
			result: data.CustTypes.map((item) => {
                return {
                    key: item.Key,
                    value: item.Name
                }
            })
		})
	}
	catch (err) {
		const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
		//---
        console.log(err);
		catchErrorApi(
			err,
			dispatch,
			GET_CUSTOMER_TYPE_LIST_ERR,
			handleError400
		)
	}
	dispatch(removeLoading())
}