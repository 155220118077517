import styled from "styled-components";

const WrapContainer = styled.div`
    display:flex;
    margin-bottom:7px;
    height:70px;
    flex-direction:row;
    justify-content:space-between;
    .block-left{
        display:flex;
        align-items:center;
    }
    .counter-remain{
        margin-right: 30px;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        span{
            font-size: 14px;
            font-weight: bold;
            color: #d71249;
        }
    }
    .add{
        margin-right: 7px;
    }
`

export  {WrapContainer}