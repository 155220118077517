import styled from "styled-components";
import { color } from "../../../../../../data/store";

const heightItem = 40
const ContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: calc(100% - 2px);
    cursor: pointer;
    border: solid ${props => props.borderWidth??0}px ${color.grayE6};
    border-radius: 4px;
    margin-bottom: ${props => props.borderWidth?10:0}px;

    .menuContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 4px;
        height: ${props => props.isShowMenu
                            ? heightItem*props.numberOfItems
                            : 0
                 }px;
        transition: height 0.2s;
        overflow: ${props => props.isShowMenu?'visible':'hidden'};
        background-color: #fff;
    }
`

const LabelContentStyle = styled.div`
    display: flex;
    height: 18px;
    min-height: 18px;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.isShowMenu?'rgba(126, 211, 247, 0.1)':'#fff'};
    color: #000;
    padding: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: solid ${props => props.isShowMenu?props.borderWidth??0:0}px ${color.grayE6};
    .value {
        font-family: 'SVN-Gilroy';
        font-weight: 500;
        font-size: 14px;
        width: calc(100% - 40px);
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
        margin-left: 8px;
        flex:1;
    }
    .rightContent {
        display: flex;
        justify-content: end;
        align-items: center;
        .value {
            font-family: 'SVN-Gilroy';
            font-weight: 500;
            font-size: 14px;
            color: ${color.blue_005993};
            margin-right: 5px;
        }
        .arrow {
            width: 0; 
            height: 0; 
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid ${color.blue_005993};
            transition-duration: 0.2s;
            transform: ${props => props.isShowMenu?'rotate(-180deg)':'rotate(0)'};
            -webkit-transform: ${props => props.isShowMenu?'rotate(-180deg)':'rotate(0)'};
        }
        .space {
            width: 8px;
        }
    }
    
`

export {ContainerStyle, LabelContentStyle}