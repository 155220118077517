/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'

// styles
import { WrapperCheckBox, CheckBox, Text } from './index.styles'

// images
import checked_white from '../../../../../../images/check_white.svg'

const CheckBoxItem = ({
    children,
    onClick,
    isChecked,
    isCheckedSome,
    isDisabled,
    isReadOnly,
    extendStylesText
}) => {
    const _handleClick = useCallback(
        () => {
            if (isDisabled || isReadOnly) return;

            onClick()
        },
        [onClick, isChecked, isDisabled, isReadOnly],
    )

    const iconInCheckbox = () => {
        if (isChecked)
            return <img src={checked_white} alt="checked" />
        if (isCheckedSome)
            return <div className="checked-some-child"></div>
        return null
    }

    return (
        <WrapperCheckBox onClick={_handleClick}>
            <CheckBox isDisabled={isDisabled} isChecked={isChecked}>
                {iconInCheckbox()}
            </CheckBox>
            <Text isDisabled={isDisabled} extendStyles={extendStylesText}>{children}</Text>
        </WrapperCheckBox>
    )
}

export default CheckBoxItem
