import React from 'react'
import DropdownControl from '../DropdownControl'

/**
 * dataInfo struct
 * @param {string*} ID //id of group service
 * @param {string*} Name //name of group service 
 * @param {string*} GroupCode //group code
 * @param {[]transactionService} Children data list of group service
 */

function TransacionItem({id, index, dataInfo, onItemSelected, onSubItemSelected, onShowOptionPopup}) {
  const itemList = (dataInfo&&dataInfo.Children)?dataInfo.Children:[]
  return (
    <DropdownControl 
      id = {id}
      index={index}
      dataInfo={dataInfo} 
      menuList={itemList}
      onItemSelected={onItemSelected}
      onSubItemSelected={onSubItemSelected} 
      onShowOptionPopup={onShowOptionPopup}
    />
  )
}

export default React.memo(TransacionItem)
