import * as types from "../actions/types";

const initialState = {
    data: null,
    error: '',
    CifsNum: ""
}

export default function biometricExecutionReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLEAR_LIST_CUSTOMER:
            return initialState
        case types.GET_LIST_CUSTOMER: {
            return {
                ...state,
                data: action.payload.data,
                CifsNum: action.payload.CifsNum,
                error: ''
            }
        }
        case types.GET_LIST_CUSTOMER_ERR: {
            return {
                ...state,
                ...action.payload,
                data: null
            }
        }
        default:
            return state
    }
}