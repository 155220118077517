/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { css } from 'styled-components'

// component
import DropDown from '../DropDown'

// styles
import { extendStylesDropdownOffice } from './index.styles'
import { useDispatch } from 'react-redux'
import { getCustomerTypeList } from '../../../../../../../../actions/reportsAction/detailsReport/customerJourney/customer'

const itemAll = {
    key: '',
    value: 'Tất cả',
}


const SelectCustomerType = ({ onChange, extendStyles }) => {
    const dispatch = useDispatch()

    const [customerTypeList, setCustomerTypeList] = useState([])
    const [customerTypeSelected, setCustomerTypeSelected] = useState(itemAll)

    const _handleOnChange = useCallback((obj) => {
        setCustomerTypeSelected(obj)
        onChange(obj)
    }, [])

    useEffect(() => {
        dispatch(getCustomerTypeList({
            onCompleted: (dataResp)=>{
                if(dataResp.code===0){
                    setCustomerTypeList(dataResp.result)
                }
            }
        }))
    }, [])

    return (
        <DropDown
            lstItemFixed={[itemAll]}
            lstItems={customerTypeList}
            defaultValue={customerTypeSelected}
            onChange={_handleOnChange}
            haveSearch={false}
            extendStyles={css`${extendStylesDropdownOffice}${extendStyles}`}
            label="Loại khách hàng"
        />
    )
}

export default SelectCustomerType
