import React, { useState } from 'react'
import { BoundStyle } from './index.style'

export default function TextAreaControlV2({
    isRequired, maxLength, title, placeHolder, 
    defaultValue, backgroundColor, borderWidth, 
    titleFontSize, titleFontWeight,
    textFontSize, textFontWeight,
    onChange
}) {
    const [value, setValue] = useState(defaultValue??'')
    //---
    const onChangeAction = (e) => {
        setValue(e.target.value)
        //---
        onChange && onChange(e.target.value)
    }
    return (
        <BoundStyle 
            backgroundColor={backgroundColor} 
            borderWidth={borderWidth}
            titleFontSize={titleFontSize}
            titleFontWeight={titleFontWeight}
            textFontSize={textFontSize}
            textFontWeight={textFontWeight}
        >
            <p className='title-text'>
                {title}
                {isRequired && <span className='title-notice'>*</span>}
            </p>
            <textarea
                type='text'
                value={value}
                maxLength={maxLength}
                placeholder={placeHolder}
                onChange={onChangeAction}
            >

            </textarea>

        </BoundStyle>
    )
}

