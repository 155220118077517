import styled from "styled-components";
import { color } from "../../../../../data/store";

const BoundStyle = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: 13px;
    .title-text {        
        font-size: ${props => props.titleFontSize??11}px;
        font-weight: ${props => props.titleFontWeight??'bold'};       
        color: ${color.blue_005993};
        text-transform: uppercase;
        margin-bottom: 5px;
        .title-notice{
            color: #F31919;
            margin-left: 3px;
        }
    }
    textarea {
        padding: 12px 10px;
        height: ${props => props.height??100}px;       
        font-size: ${props => props.textFontSize??14}px;
        font-weight: ${props => props.textFontWeight??500}; 
        font-family: 'SVN-Gilroy';
        line-height: 1.2;
        border-radius: 4px;
        border: solid ${props => props.borderWidth??0.5}px #c8c8c8;
        background-color: ${props => props.backgroundColor??"rgba(255,255,255,0.9)"} ;
        color: #222222;
        resize: none;
        &::placeholder{
            color: #bababa;
        }
    }    
`
export {BoundStyle}