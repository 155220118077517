import React, { Component } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

//Image
// import ic_warning from '../../../../images/Icons_Ticket_Warning.svg';
import { VerifyCustomer, VERIFYUNSUCCESSFULLY, VERIFYSUCCESSFULLY, NOTVERIFY } from '../../../../data/dataVerifyCustomer';
import { apiGetBioFile } from '../../../../actions/supervisorAction/biometricAction';
import { THUMB_LEFT, dataFingerPrint, THUMB_RIGHT, INDEX_LEFT, INDEX_RIGHT, MIDDLE_LEFT, MIDDLE_RIGHT, RING_LEFT, RING_RIGHT, PINKY_LEFT, PINKY_RIGHT } from '../../../../data/infosType';
import { color } from '../../../../data/store';

const Bound = styled.div`
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  p.content {
    margin-top: 7px;
  }
   .title {
        font-size: 11px;
        font-weight: bold;
        line-height: 1.26;
        color: ${color.blue_005993};
        text-transform:uppercase;
        margin: 26px 0 14px 0;
    }
    .content {
        font-size: 14px;
        font-weight: 500;
        color: #979797;
        span, .text-gdv {
            color:#222222;
        }
        .type_reason{
          /* color: rgb(215, 18, 73); */
          color: #222222;
        }
        .type_status {
          /* color: rgb(215, 18, 73); */
          color: ${props => props.coliVerify};
        }
    }
    .bloc_warning {
      display: flex;
      align-items: center;
      margin: 14px 0 0 0;
      img {
        margin: 0 5px 0 0;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        color: var(--vermillion);
      }
    }
    .text-ellipst {
        /*width: 130px !important;*/
        width: 160px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${'' /* position: relative; */}
        pointer-events:none;
    }
    .text-ellipst:after {
        content: "";
        display: block;
        position: absolute;
        top: -15px;
        right: 0;
        width: 100%;
        height: 48px;
        z-index: 1;
        pointer-events: initial;
    }

    .text-ellipst:hover:after{
      cursor: pointer;
    }

    .cover{
      position: relative;
      width: 100%;
      display: flex;
    }
    #tooltip_ {
      position: absolute;
      top: -66%;
      left: 50%;
      z-index: 999;
      width: fit-content;
      visibility: hidden;
      background-color: #fff;
      padding: 11px;
      -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    .text-ellipst:hover + #tooltip_{
      visibility: visible;
      transition: opacity 0.5s ease;
      opacity: 1;
    }
 `

const DataAuth = styled.div`
    margin: 16px 0 0 0;
    .height{
      height: 80px;
    }
    .data_auth {
      margin: 0 40px 0 0;
    }
    .data_register {
      .block_img:not(:last-child) {
        margin: 0 5px 0 0;
      }
    }
    .block_img {
      width: 80px;
      height:80px;
      background-color: #ffffff;
      border-radius: 5px;
      border:solid 0.5px  ${props => props.color_border || '#c8c8c8' };
      img {
        width: 70px;
        height: 70px;
        object-fit:contain;
      }
    }
    .pb-14{
      padding: 14px 0;
    }
    .p-reason{
      display:block;
      /*width: 533px;*/
      width: 650px;
      word-wrap:break-word;
    }
    .text_biotype{
      display: flex;
      p{
        width: 170px;
        margin-right: 18px;
        color: ${color.blue_005993};
      }
    }
  `
// const CustomerDuplicate = styled.div`
//   .row .content:last-child {
//     margin: 16px 0 0 0;
//     height: 80px;
// }
// `

export default class DataInfoCheckCustomer extends Component {
  render() {
    const {dataUser} = this.props
    let elmVerify, coliVerify
    if (dataUser.VerifyStatus === VERIFYUNSUCCESSFULLY) {
        elmVerify = VerifyCustomer[VERIFYUNSUCCESSFULLY].data
        coliVerify = VerifyCustomer[VERIFYUNSUCCESSFULLY].color
    } else if(dataUser.VerifyStatus === VERIFYSUCCESSFULLY){
        elmVerify = VerifyCustomer[VERIFYSUCCESSFULLY].data
        coliVerify = VerifyCustomer[VERIFYSUCCESSFULLY].color
    } else{
      elmVerify = VerifyCustomer[NOTVERIFY].label
      coliVerify = VerifyCustomer[NOTVERIFY].color
    }

    // console.log('elem', elmVerify, this.props.dataUser)
    return (
      <Bound coliVerify={coliVerify}>
        <p className='title'>thông tin dữ liệu</p>
        <div className="block_status">
          <div className="row">
            <div className="col-7">
              <div className="row content">
                <p>Trạng thái:&nbsp;
                  <span className='type_status'>{elmVerify}</span>
                </p>
              </div>
            </div>
            <div className="col-5">
              <div className="row content">
                <div className = 'cover'>
                  <p>GDV yêu cầu:&nbsp;</p>
                  <div className = 'text-gdv text-ellipst'>{dataUser.TellerInfos.UserName}</div>
                  <span id = 'tooltip_'>{dataUser.TellerInfos.UserName}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataAuth>
          <div className="col-12">
            {
              (dataUser.VerifyStatus === NOTVERIFY) && 
                <div className="row content">
                  <div className = 'p-reason'>Lý do không xác thực:&nbsp;
                    <p className='type_reason'>{parse(`${dataUser.NotVerifyFingerReason}`)}</p>
                  </div>
                </div>
            }
            {
              (dataUser.VerifyStatus === VERIFYUNSUCCESSFULLY) &&
              <div className="row content">
                <div className='p-reason'>Lý do xác thực không thành công:&nbsp;
                  <p className='type_reason'>{parse(`${dataUser.Note}`)}</p>
                </div>
              </div>
            }
          </div>
          <div className="col-12">
            {
              dataUser.VerifyStatus !== NOTVERIFY &&
              <div className="row pb-14">
                <div className="data_auth">
                  <div className="block_img auth d-center">
                    <img src={apiGetBioFile(dataUser.FingerImageID)} alt="auth" />
                  </div>
                  <p className='content'>Xác thực</p>
                </div>
              
                <div className="data_register">
                    <div className="row height">
                      {
                        dataUser.ValidFingerIDs !== null ? dataUser.ValidFingerIDs.map((item, i) => {
                          return (
                            <div className="block_img d-center" key = {i} style={{margin: (i+1)%2===0 && '0 20px 0 0'}}>
                              <img src={apiGetBioFile(item)} alt={"register" + i} />
                            </div>
                          )
                          })
                          :
                          null
                      }
                    </div>
                    <div className = 'text_biotype'>
                      {
                        dataUser.ValidFingerType !== null ? dataUser.ValidFingerType.map((item,i) => {
                          if (item === THUMB_LEFT) {
                            item = dataFingerPrint[THUMB_LEFT].label
                          } else if (item === THUMB_RIGHT) {
                            item = dataFingerPrint[THUMB_RIGHT].label
                          } else if (item === INDEX_LEFT) {
                            item = dataFingerPrint[INDEX_LEFT].label
                          } else if (item === INDEX_RIGHT) {
                            item = dataFingerPrint[INDEX_RIGHT].label
                          } else if (item === MIDDLE_LEFT) {
                            item = dataFingerPrint[MIDDLE_LEFT].label
                          } else if (item === MIDDLE_RIGHT) {
                            item = dataFingerPrint[MIDDLE_RIGHT].label
                          } else if (item === RING_LEFT) {
                            item = dataFingerPrint[RING_LEFT].label
                          } else if (item === RING_RIGHT) {
                            item = dataFingerPrint[RING_RIGHT].label
                          } else if (item === PINKY_LEFT) {
                            item = dataFingerPrint[PINKY_LEFT].label
                          } else if (item === PINKY_RIGHT) {
                            item = dataFingerPrint[PINKY_RIGHT].label
                          }
                          return (
                            <p className='content' key={i}>{item}</p>
                          )
                        })
                        :
                        null
                      }
                    </div>
                    <p className='content' style = {{color: 'rgb(15, 177, 31)'}}>Vân tay đang hoạt động</p>
                  </div>
              </div>
            }
            
          </div>
        </DataAuth>
        {/* <div className="bloc_warning">
          <img src={ic_warning} alt="ic_warning" />
          <p>Hệ thống ghi nhận vân tay đang trùng khớp với dữ liệu trong hệ thống</p>
        </div>
        <CustomerDuplicate>
          <p className='title'>thông tin khách hàng trùng khớp</p>
          <div className="row">
            <div className="col-7">
              <div className="row content">
                <p>Tên khách hàng:&nbsp;
                  <span className='type_status'>Tran Quang Minh
                  </span>
                </p>
              </div>
              <div className="row content">
                <p>ID:&nbsp;
                  <span className='type_status'>78462146212
                  </span>
                </p>
              </div>
            </div>
            <div className="col-5">
              <div className="row content">
                <p>Số CIF: &nbsp;
                  <span>8402621
                  </span>
                </p>
              </div>
              <div className="row content">
                <p>SĐT: &nbsp;
                  <span>0912596321
                  </span>
                </p>
              </div>
            </div>
          </div>
        </CustomerDuplicate> */}
      </Bound>
    )
  }
}
