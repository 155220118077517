import styled from "styled-components";
import { color } from "../../../../../../data/store";

const TransBound = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    height: 36px;
    background-color: ${props => props.isRoot
        ? color.blue_005993
        : color.whiteFF
    };
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    cursor: pointer;
    margin-top: ${props => props.isRoot ? 0 : 5}px;
    color: ${props => props.isRoot ? color.whiteFF : color.black19};

    :hover{
        background-color: ${props => props.isRoot ? '' : color.blue_DEF5FF};
    }

    .left-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        .label {
            font-size: 14px;
            font-weight: 700;
        }
    }

    .right-content{
        display: flex;
        width: ${props => props.isRoot ? 120 : 30}px;
        justify-content: space-between;
        align-items: center;

        .value{
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
        }

        .arrow {
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #fff;
            transition-duration: 0.3s;
            transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
            -webkit-transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
        }

        .button-container {
            display: flex;
            width: 100%;
            justify-content: end;
            .moreButton {
                cursor: pointer;
                margin-right: -8px;
                
            }
        }
    }
`
const TransDetailContent = styled.div`
    display: grid;
    grid-template-columns: 180px 180px 250px minmax(250px, 1fr);

    grid-gap: 8px;
    min-width: 1050px;
    padding-top: ${props => props.isRoot ? 8 : 0}px;
    box-sizing: border-box;
    align-items: center;
    /* height: 20px; */
    /* background-color: gray; */

    .column {
        font-size: 12px;
        font-weight: 500;
        color: ${props => props.isRoot ? color.whiteFF : color.black19};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.status {
            display:flex;
            flex-direction: row;
            align-items: center;
        }

        .statusIcon {
            height: 8px;
            width: 8px;
            border-radius: 4px;
            margin-bottom: ${props => props.isRoot?2:0}px;
            /* margin-bottom: 2px; */
            margin-left: 8px;
            margin-right: 3px;
            background-color: ${props => props.status==='A' 
                                            ? color.green_0FB11F 
                                            : color.red_D71249};
        }
    }
`

export {TransBound, TransDetailContent }