import styled from "styled-components";
import { color } from "../../../../../data/store";

const Container = styled.div`
    display:flex;
    flex-direction: column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: 13px;
    margin-top: ${props => props.isHorizontal ? 13 : 0}px;
    .title-text {        
        font-size: 11px;
        font-weight: bold;        
        color: ${color.blue_005993};
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    .content {
        display: flex;
        margin-top:  5px;
        flex-direction: ${props => props.isHorizontal ? 'row' : 'column'}
    }
`

export {Container}