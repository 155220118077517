/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// styles
import { GoToDetails, Id, ReportName } from './index.styles'
import { Grid3Col, reportItemStyles} from '../index.styles'

// action
import { selectReport } from '../../../../../../../actions/reportsAction/detailsReport/detailsReport'

const ReportItem = ({ data, index }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const _handleGoToDetailsOfReport = useCallback(
        () => {
            dispatch(selectReport(data))
            history.push('/reports/' + data.CodeName, data.CodeName)
        },
        [history, data],
    )

    return (
        <Grid3Col 
            extendStyles={reportItemStyles} 
            className={`report-${data.CodeName}`}
            onDoubleClick={_handleGoToDetailsOfReport}
        >
            <Id title={data?.CodeName?.includes("_")?data.CodeName.replace("_", "."):data.CodeName}>{data?.CodeName?.includes("_")?data.CodeName.replace("_", "."):data.CodeName}</Id>
            <ReportName title={data.Name}>{data.Name}</ReportName>
            <GoToDetails 
                onClick={_handleGoToDetailsOfReport}
            >Xem báo cáo</GoToDetails>
        </Grid3Col>
    )
}

export default ReportItem
