import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as biometricRole from '../../../data/biometricRole'
// Img
import ic_close from '../../../images/ic_close_gray.svg'
// Component
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiceDropDownControl'
// Data
import { getBioInfos } from '../../../actions/supervisorAction/biometricAction'
import { setSupervisorSingleChoise } from '../../../data/singleChoiseData'
import LoadingControl from '../MainScreen/Control/LoadingControl'
import {
    /* BLOCK,*/ BioInfosType,
    REGISTER,
    /*UNBLOCK, */ UPDATE,
    /*DELETE,*/ NR,
} from '../../../data/infosType'
import { OFFEXSUPERVISOR } from '../../../data/userRole'
import moment from 'moment'

import DatePicker from 'react-date-picker'
import { alertFillterOffice } from '../../../actions/officeAction'

const Bound = styled.div`
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    font-family: 'SVN-Gilroy';
    .loading {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 108px 248px 0 0;
        p {
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #787878;
            padding-left: 6px;
        }
    }
    .popup-filter-bound {
        display: flex;
        flex-direction: column;
        width: 200px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 80px - 80px - 83px);
        background-color: white;
        position: absolute;
        bottom: 0;
        right: -240px;
        transition: right 0.5s;
        align-items: center;
        .div_check {
            width: 200px;
            margin: 0 0 20px 0;
            p {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 126%;
                text-transform: uppercase;
                color: #005993;
                margin: 0 0 5px 0;
            }
        }
        .div_header {
            width: 100%;
            margin: 0 0 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                width: 64px;
                height: 36px;
                border-radius: 18px;
                background-color: #005993;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                cursor: pointer;
            }
            .close-btn {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }

    .date-picker {
        width: 100%;
        .react-date-picker__wrapper {
            width: 100%;
            border: 0.5px solid #d6d6d6;
            border-radius: 4px;
            .react-date-picker__inputGroup {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 128%;
                display: flex;
                align-items: center;
                color: #222222;
                margin: 0 0 0 9px;
            }
        }
        .react-calendar {
            width: 224px;
            position: absolute;
            right: 0;
            top: 4px;
            border: 0.5px solid #d6d6d6;
            border-radius: 4px;

            .react-calendar__tile--active {
                background: #005993;
                border-radius: 50%;
            }
            .react-calendar__navigation {
                button:first-child,
                button:last-child {
                    display: none;
                }
                button:nth-child(3) {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 15px;
                    display: flex;
                    justify-content: center;
                    color: #222222;
                }
            }
            .react-calendar__viewContainer {
                .react-calendar__month-view__weekdays {
                    display: none !important;
                }
                .react-calendar__month-view__days {
                    font-family: SVN-Gilroy;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                }
            }
        }
    }
`
const dateFormat = 'dd-MM-y'
class PopupBiometricFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idShow: '',
            Status: '',
            Segment: '',
            BioTypes: '',
            data: setSupervisorSingleChoise,
            isLoading: false,
            date: new Date(),
        }
    }

    isMouseEnter = false

    getStatus = (type) => (text) => {
        this.setState({ [type]: text })
    }

    getSegment = (type) => (text) => {
        this.setState({ [type]: text })
    }

    getBioType = (type) => (text) => {
        this.setState({ [type]: text })
    }

    setIdShow = (id) => {
        this.setState({ idShow: id })
    }

    onSubmit = async () => {
        this.setState({
            isLoading: true,
        })
        const { Status, Segment, BioTypes, date } = this.state
        let dataFilter = {}
        if (date) {
            let m = moment(this.state.date)
            // let m = moment(this.state.date).utcOffset(0);
            m.set({ hour: 0, minute: 0, second: 0 })
            // m.toISOString()
            dataFilter['Dates'] = date
            dataFilter.Dates = m.format()
            // console.log(dataFilter.Dates)
        }
        if (Status) {
            dataFilter['Status'] = Status.name
            if (Status.name === biometricRole.APPROVED) {
                dataFilter.Status = 'ACCEPT'
            } else if (Status.name === biometricRole.PENDING_APPROVE) {
                dataFilter.Status = 'PENDING'
            } else {
                dataFilter.Status = 'REJECT'
            }
        }
        if (Segment) {
            dataFilter['Segment'] = Segment.name
            if (Segment.name === 'CN1') {
                dataFilter.Segment = 'CN1'
            } else if (Segment.name === 'CN2') {
                dataFilter.Segment = 'CN2'
            } else if (Segment.Segment === 'CN3') {
                dataFilter.Segment = 'CN3'
            } else if (Segment.name === 'CN4') {
                dataFilter.Segment = 'CN4'
            } else if (Segment.name === 'CN5') {
                dataFilter.Segment = 'CN5'
            } else if (Segment.name === 'CN6') {
                dataFilter.Segment = 'CN6'
            }
        }

        if (BioTypes) {
            dataFilter['BioTypes'] = BioTypes.name
            if (BioTypes.name === BioInfosType[REGISTER].label) {
                dataFilter.BioTypes = REGISTER
            } else if (BioTypes.name === BioInfosType[UPDATE].contentLabel) {
                dataFilter.BioTypes = UPDATE
            } else if (BioTypes.name === BioInfosType[NR].label) {
                dataFilter.BioTypes = NR
            }
            /*else if(BioTypes.name === BioInfosType[BLOCK].label){
                dataFilter.BioTypes = BLOCK
            }else if(BioTypes.name === BioInfosType[UNBLOCK].label){
                dataFilter.BioTypes = UNBLOCK
            }else if(BioTypes.name === BioInfosType[DELETE].label){
                dataFilter.BioTypes = DELETE
            }*/
        }
        // console.log(this.state.date, Object.keys(dataFilter).length, dataFilter)
        if (Object.keys(dataFilter).length !== 0) {
            // if(this.state.date) {
            this.props.getBioInfos({
                OfficeID: this.props.userReducer.dataLogin.OfficeID,
                // Status: dataFilter.Status,
                Status: this.props.biometricReducer.lastConditionFilterUser
                    .Status,
                Segment: dataFilter.Segment,
                BioType: dataFilter.BioTypes,
                PageSize: 10,
                PageNumber: 1,
                // FromCreateAt: moment(this.state.date).format()
                FromCreateAt: dataFilter.Dates,
            })
            this.props.onClose()
            this.props.alertContent('')
            // console.log(moment("2006-01-02T15:04:05Z07:00").format("DD/MM/YYYY"))
            // console.log(moment().format())
        } else {
            this.props.onClose()
            return
        }
    }

    getRangeDate = (date) => {
        this.setState({
            date,
        })
    }

    render() {
        const { data } = this.state

        let listBioInfosTypeEX = data.bioInfosType
        listBioInfosTypeEX = listBioInfosTypeEX.map((item) => ({
            name: item.name,
        }))
        let listBioInfosTypeIN = listBioInfosTypeEX.filter(
            (item) => item.name !== BioInfosType[NR].label
        )
        let listBioInfosType = []

        const { Roles } = this.props.userReducer.dataLogin
        switch(true){
            case Roles.includes(OFFEXSUPERVISOR):   
                listBioInfosType = listBioInfosTypeEX
                break
            default:
                listBioInfosType = listBioInfosTypeIN
                break
        }

        const BioTypes = this.getBioType('BioTypes')

        return (
            <Bound
                onMouseDown={() => {
                    if (!this.isMouseEnter) {
                        this.props.onClose()
                    }
                }}
            >
                {this.state.isLoading && (
                    <div className="loading">
                        <LoadingControl loadingPage={false} size="24px" />
                        <p>Đang tải...</p>
                    </div>
                )}
                <div
                    className="popup-filter-bound"
                    id="filter_biometric_id"
                    onMouseEnter={() => {
                        this.isMouseEnter = true
                    }}
                    onMouseLeave={() => {
                        this.isMouseEnter = false
                    }}
                >
                    <div className="div_header">
                        <img
                            src={ic_close}
                            alt="close"
                            className="close-btn"
                            onClick={() => {
                                this.props.onClose()
                            }}
                        />
                        <div onClick={() => this.onSubmit()} className="title">
                            FILTER
                        </div>
                    </div>
                    <div className="div_check">
                        <p>Ngày</p>
                        <DatePicker
                            className="date-picker"
                            onChange={this.getRangeDate}
                            value={this.state.date}
                            format={dateFormat}
                            clearIcon={false}
                        />
                    </div>
                    {/* <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectDataStatus"
                            getText={Status}
                            data={listStatus}
                            title='trạng thái dữ liệu'
                            titleChoise='Chọn trạng thái dữ liệu'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div> */}
                    {/* <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectCustomer"                            
                            getText={Segment}
                            data={listSegment}
                            title='loại khách hàng'
                            titleChoise='Tất cả khách hàng'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div>  */}
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectDataType"
                            getText={BioTypes}
                            data={listBioInfosType}
                            title="loại dữ liệu"
                            titleChoise="Chọn loại dữ liệu"
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}
                        />
                    </div>
                </div>
            </Bound>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        biometricReducer: state.biometricReducer,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getBioInfos: (dataQuery) => {
            dispatch(getBioInfos(dataQuery))
        },
        alertContent: () => dispatch(alertFillterOffice()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: null,
})(PopupBiometricFilter)
