import ReactDOM from 'react-dom'
import React, { Fragment } from 'react'
import * as style from './index.style'

export const withPopupConfirm = (Component) => ({
    showPopup,
    closePopup,
    data,
    ...props
}) => {
    return (
        <Popup>
            <style.Popup>
                <Component closePopup={closePopup} data={data} />
            </style.Popup>
        </Popup>
    )
}

export default function Popup({ children }) {
    return ReactDOM.createPortal(
        <Fragment>{children}</Fragment>,
        document.querySelector('body')
    )
}
