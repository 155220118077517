import styled from "styled-components";
import ic_close from '../../../../images/ic_close_gray.svg'
import { color } from "../../../../data/store";


const Bound = styled.div`
    padding: 0 20px;
    width: 580px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 12px;
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 58px;
        .title{
            font-family: 'SVN-Gilroy';
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            text-align: left;

        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .popupMainBody{
        /*padding: 20px 5px 0 5px;*/
        padding: 30px 0;
        .type-transaction {
          display: flex;
            .type-officeName{
              width: 70%;
              padding-right: 4.084%;
            }           
            .code-officeName{
              width: 30%;
              .input-suffix{
                input{
                    padding-right: 35px;
                }
              }
              .input-prefix{
                input{
                    padding-left: 100px;
                    /*text-align: end;*/
                }
              }
            }
            .titleTypeTrans{
                font-family: 'SVN-Gilroy';
                font-size: 11px;
                font-weight: bold;
                line-height: 1.26;
                color: ${color.blue_005993};
                text-transform: uppercase;
            }
            select.dropdown-control{
                width: 280px;
                min-width: 280px;
                background-position: top 20px right 17px;
            }
        }
        .type-branch{
            margin-bottom: 13px;
        }
        .info_Address{
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            img{
                position:absolute;
                /* right:0; */
            }
            .city{
                width: 32%;
                margin-right: 4%;
            }
            .district{
                width: 28%;
            }
            .ward{
                width: 32%;
                margin-left: 4%;
            }
        }
        
    }
    .popupFooter{
        height: 73px;
        width: 100%;
        border-top: 1px solid #E6E6E6;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`
export { Bound }