/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { WrapContainer, WrapPagingContainer, WrapTable } from './index.styles'
import SubHeaderControl from '../../Control/SubHeaderControl'
import PagingControl from '../../Control/PagningControl'
import EmptyControl from '../../Control/EmptyControl'
import { emptyData } from '../../../../../data/data'
import ConfigureRoleTable from './AdviseServiceTable'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import AdviseServicePopup, { AdviseServicePopupType } from './AdviseServicePopup'
import { heightHeader } from '../../../Header/HeaderContainer'
import { widthMenuLeft } from '../../../Menu/MenuContainer'
import PopupOption from '../../../Popup/PopupOption'
import PopupConfirm from '../../../Popup/PopupConfirm'
import { createAdviseService, getAdviseServiceConfig, getAdviseServiceList, removeAdviseService, updateAdviseService } from '../../../../../actions/adviseServiceAction'

export default function AdviseServiceInfo({headerData, onHideShowErrPopup, onHideShowSuccessPopup }) {
    const limitPerPage = 10
    const dispatch = useDispatch()
    //---
    const { dataLogin } = useSelector(state => ({
        dataLogin: state.userReducer.dataLogin
    }))
    //---
    const [dataInfo, setDataInfo] = useState({
        realDataList: [],
        dataList: [],
        currentDataList: [],
        currentPage: 1,
        dataConfig: {
            statusList: [],
            featureList: []
        }
    })

    const[rolePopupInfo, setRolePopupInfo] = useState({
        isShow: false,
        type: AdviseServicePopupType.ADD,
        dataInfo: null
    })

    const [optionPopupInfo, setOptionPopupInfo] = useState({
        isShow: false,
        dataInfo: null
    })
    const [deleteRoleInfo, setDeleteAdviseServiceInfo] = useState({
        isShowConfirmPopup: false,
        roleID: undefined
    })

    //----
    const onHideRolePopup = () => {
        setRolePopupInfo({
            isShow: false,
            type: AdviseServicePopupType.ADD,
            dataInfo: null
        })
    }

    const onShowRolePopup = (type, dataInfo) => {
        setRolePopupInfo({
            isShow: true,
            type: type,
            dataInfo: dataInfo
        })
    }

    const onHideOptionPopup = () => {
        setOptionPopupInfo({
            isShow: false,
            dataInfo: null
        })
    }

    const onShowOptionPopup = (index, dataInfo) => {
        let moreBtn = document.getElementById("more_button_id_" + index);
        if (moreBtn) {
            let bound = moreBtn.getBoundingClientRect();
            let scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let topBound = bound.top + scrollTop;
            let leftBound = bound.left + scrollLeft;
            let offsetPopup = {
                top: topBound + bound.height / 2 - heightHeader,
                left: leftBound - 78 + bound.width / 2 - widthMenuLeft
            };
            console.log(offsetPopup);
            //---
            setOptionPopupInfo({
                isShow: true,
                offsetPopup: offsetPopup,
                from: 'Role',
                dataInfo: dataInfo
            })
        }
    }


    const searchAdviseServiceWhenOffline = (key) => {
        const newDataList = []
        for (var i = 0; i < dataInfo.realDataList.length; i++) {
            const item = dataInfo.realDataList[i]
            const roleName = item.name.toLowerCase()
            if (roleName.includes(key.toLowerCase())) {
                newDataList.push(item)
            }
        }
        //----
        setDataInfo({
            ...dataInfo,
            dataList: newDataList,
            currentDataList: getCurrentDataList(1, newDataList),
            currentPage: 1
        })
    }

    const onReset = () => {
        getAdviseServiceListAction(dataInfo.dataConfig)
    }

    const getAdviseServiceListAction = (dataConfig) => {
        //call api get RoleList
        dispatch(getAdviseServiceList({
            uid: dataLogin.UID,
            onCompleted: (dataResp) => {
                if (dataResp.code === 0) {
                    setDataInfo({
                        realDataList: dataResp.result,
                        dataList: dataResp.result,
                        currentDataList: getCurrentDataList(1, dataResp.result),
                        currentPage: 1,
                        dataConfig: dataConfig
                    })
                } else {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Hệ thống bị gián đoạn",
                        note: "(*) Bạn vui lòng thực hiện lại"
                    })
                }
            }
        }))
    }

    const getAdviseServiceConfigAction = ({onSuccess}) => {
        dispatch(getAdviseServiceConfig({
            uid: dataLogin.UID,
            onCompleted: (dataResp) => {
                if (dataResp.code === 0) {
                    onSuccess && onSuccess(dataResp.result)
                } else {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Hệ thống bị gián đoạn",
                        note: "(*) Bạn vui lòng thực hiện lại"
                    })
                }
            }
        }))
    }

    const onAddAdviseService = (dataInfo) => {
        //call api add new role
        dispatch(createAdviseService({
            dataInfo: dataInfo,
            onCompleted: (dataResp) => {
                addOrUpdateAdviseServiceCompleted("ADD_ADVISE_SERVICE", dataResp)
            }
        }))
    }

    const onUpdateAdviseService = (dataInfo) => {
        //call api add new role
        dispatch(updateAdviseService({
            dataInfo: dataInfo,
            onCompleted: (dataResp) => {
                addOrUpdateAdviseServiceCompleted("UPDATE_ADVISE_SERVICE", dataResp)
            }
        }))
    }

    const addOrUpdateAdviseServiceCompleted = (typeAction, dataResp) => {
        if (dataResp.code === 0) {
            onHideRolePopup()
            //---
            getAdviseServiceListAction(dataInfo.dataConfig)
            //---
            onHideShowSuccessPopup({
                isShowPopup: true, 
                mess: typeAction === "ADD_ADVISE_SERVICE"
                        ?"Thêm dịch vụ thành công"
                        :"Cập nhật dịch vụ thành công",
            })
        } else {
            let messErr = ''
            let note = ''
            //---
            switch (dataResp.code) {
                case 100:
                    messErr = 'Mã dịch vụ tư vấn đã được sử dụng'
                    note = '(*) Bạn vui lòng kiểm tra lại'
                    break;
                case 101:
                    messErr = 'Tên dịch vụ tư vấn không được phép để trống'
                    note = '(*) Bạn vui lòng kiểm tra lại'
                    break;
                default:
                    messErr = 'Hệ thống bị gián đoạn'
                    note = '(*) Bạn vui lòng thực hiện lại'
                    break;
            }
            //--
            showErrPopup({
                mess: messErr,
                note: note
            })
        }
    }

    const onDeleteAdviseService = (roleID) => {
        setDeleteAdviseServiceInfo({
            isShowConfirmPopup: false
        })

        //call api remove roleID
        dispatch(removeAdviseService({
            id: roleID,
            onCompleted: (dataResp) => {
                if (dataResp.code === 0) {
                    getAdviseServiceListAction(dataInfo.dataConfig)
                    //---
                    onHideShowSuccessPopup && onHideShowSuccessPopup({
                        isShowPopup: true, 
                        mess: "Xoá dịch vụ thành công"
                    })
                } else {
                    let messErr = 'Hệ thống bị gián đoạ'
                    let note = '(*) Bạn vui lòng thực hiện lại'
                    showErrPopup({
                        mess: messErr,
                        note: note
                    })
                }
            }
        }))
    }

    const showErrPopup = ({mess, note}) => {
        onHideShowErrPopup && onHideShowErrPopup({
            isShowPopup: true,
            mess: mess,
            note: note
        })
    }

    const onPageChange = (e) => {
        setDataInfo({
            ...dataInfo,
            currentDataList: getCurrentDataList(e, dataInfo.dataList),
            currentPage: e
        })
    }

    const getCurrentDataList = (currentPage, dataList) => {
        let endIndex = currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        //---
        return dataList.slice(startIndex, endIndex)
    }

    const renderPagingControl = () => {
        return (
            <WrapPagingContainer>
                <PagingControl
                    currentPage={dataInfo.currentPage}
                    totalItem={dataInfo.dataList.length}
                    limitPerPage={limitPerPage}
                    onChangePage={(page) =>
                        onPageChange(page)
                    }
                />
            </WrapPagingContainer>
        )
    }

    const renderBodyContent = () => {
        return (
            <WrapTable>
                <ConfigureRoleTable
                    currentPage={dataInfo.currentPage}
                    limitPerPage={limitPerPage}
                    dataList={dataInfo.currentDataList}
                    dataConfig = {dataInfo.dataConfig}
                    onShowOptionPopup = {onShowOptionPopup}
                    onItemDoubleClick={(dataInfo) => {
                        onShowRolePopup(AdviseServicePopupType.UPDATE, dataInfo)
                    }}
                />
            </WrapTable>
        )
    }

    useEffect(() => {
        getAdviseServiceConfigAction({
            onSuccess: (dataConfig) => {
                getAdviseServiceListAction(dataConfig)
            }
        })
       
    }, [])

    return (
        <>
            <WrapContainer>
                <SubHeaderControl
                    data={headerData}
                    textSearch={""}
                    typeSearch='searchAdviseServiceConfig'
                    searchFilter={searchAdviseServiceWhenOffline}
                    onReset={onReset}
                    onAddMoreSubmit={()=>{
                        onShowRolePopup(AdviseServicePopupType.ADD, null)
                    }}
                />
                {
                    dataInfo.dataList.length > 0
                        ? renderPagingControl()
                        : null
                }
                {
                    dataInfo.dataList.length > 0
                        ? renderBodyContent()
                        : <EmptyControl
                            alertContent={''}
                            widthInput='400px'
                            content={emptyData.uploadFile}
                        />
                }
            </WrapContainer>
            {
                rolePopupInfo.isShow &&
                <AdviseServicePopup
                    type={rolePopupInfo.type}
                    dataInfo={rolePopupInfo.dataInfo}
                    dataConfig={dataInfo.dataConfig}
                    onAddAdviseService={onAddAdviseService}
                    onUpdateAdviseService={onUpdateAdviseService}
                    onClose={onHideRolePopup}
                />
            }
            {
                optionPopupInfo.isShow &&
                <PopupOption
                    offsetPopup={optionPopupInfo.offsetPopup}
                    dataInfo={optionPopupInfo.dataInfo}
                    onModify={(dataInfo) => {
                        onShowRolePopup(AdviseServicePopupType.UPDATE, dataInfo)
                    }}
                    onRemove={() => setDeleteAdviseServiceInfo({
                        isShowConfirmPopup: true,
                        roleID: optionPopupInfo.dataInfo.id
                    })}
                    onClose={onHideOptionPopup}
                />
            }
            {
                deleteRoleInfo.isShowConfirmPopup &&
                <PopupConfirm
                    width='230px'
                    content='Bạn có thực sự muốn xoá dịch vụ tư vấn hay không ?'
                    cancelAction={()=>{
                        setDeleteAdviseServiceInfo({
                            isShowConfirmPopup: false
                        })
                    }}
                    acceptAction={()=>onDeleteAdviseService(deleteRoleInfo.roleID)}
                />
            }
        </>

    )
}
