import React, { Component } from "react";
import styled from "styled-components";
//Data
import { color } from "../../../data/store";
import { mainMenu, } from "../../../data/data";
import * as userRole from '../../../data/userRole';
//Component
import MenuItem from "./MenuItem";
// import { Link } from "react-router-dom";

// image 
import icMenu from "../../../images/ic_menu.svg"

export const widthMenuLeft = 180;

const Bound = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.isShow ? 180 : 42}px;
  height:100%;
  background-color: ${color.blue_005993};
  overflow:hidden;
  transition:width 0.5s;
  .toggle-menu{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:40px;
  }
  .menu-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1;
    width:180px;
    margin-top: 21px;
    ul {
      width: 100%;
    }
    .menu-footer {
      margin-bottom: 20px;
      p {
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        color: #fafafa;
        font-weight: normal;
        font-style: normal;
        margin-left: 16px;
      }
      a {
        line-height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 25px;
        text-decoration: none;
        font-family: SVN-Gilroy;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.13;
        color: #fafafa;
        margin-bottom: 18px;
        img {
          padding: 0 12px;
        }
      }
    }
  }
  
`
const pjson = require('../../../../package.json')
export default class MenuContainer extends Component {
  constructor(props) {
    super(props);
    this.Active = false;
    this.state = {
      isShow: true,
      pathActive: window.location.pathname
    }
  }

  toggleMenu = () => {
    let { isShow } = this.state
    this.setState({ isShow: !isShow })
  }

  onActiveMenu(pathActive) {
    this.setState({
      pathActive
    });
  }

  checkMatchPermission = (keyCheck, propertyCheck) => {
    // return object contain key
    const checkIncludeKey = key => property => object =>
      object[property].includes(key);
    
    const key = keyCheck.match(/^\/[a-z]+/);
    if(!key) return false;

    const getKey = checkIncludeKey(key[0]);

    const getProperty = getKey(propertyCheck);
    const getMenuItem = mainMenu.find(getProperty);
    if(!getMenuItem) return false;

    const { Roles } = this.props.dataLogin;
    return Roles.some(item => getMenuItem.permissions.includes(item));
  };

  rendMenuItem(index, item){
    return (
      <MenuItem
        key={index}
        data={item}
        pathActive={this.state.pathActive}
        isShow={this.state.isShow}
        onActiveMenu={this.state.isShow ? this.onActiveMenu.bind(this) : () => { }}
      />
    )
  }

  renderMenu() {

    const getMenu = permissions => object =>
      permissions.some(item => object.permissions.includes(item));

    let menu = mainMenu.filter(getMenu(this.props.dataLogin.Roles));

    let newMenu = []
    menu.forEach((item, i) => {
      if(item.key === "/thiet-bi-kiosk"){
        if(this.props.dataLogin.HasKioskDeviceConfig || 
          this.props.dataLogin.HasLcdDeviceConfig){
            newMenu.push(
              this.rendMenuItem(i, item)
            )
        }
      } else {
        newMenu.push(
          this.rendMenuItem(i, item)
        );
      }
    });

    return newMenu
  }
  
  render() {
    return (
      <Bound isShow={this.state.isShow} isActive={this.Active}>
        {
          this.Active &&
          <div
            className='toggle-menu'
            onClick={this.toggleMenu}
            style={{ textAlign: "center" }}
            ref={ref => this.toggleDiv = ref}
          >
            <img
              src={icMenu}
              alt="menu"
              style={{
                width: "24px",
                height: "24px"
              }}
            />
          </div>
        }
        {
          !this.Active &&
          <div
            className="menu-icon-total"
            style={{
              textAlign: "end",
              marginTop: "20px"
            }}
          >
            <img
              src={icMenu}
              alt="menu"
              style={{
                paddingRight: "8px",
                width: "24px",
                height: "24px"
              }}
            />
          </div>
        }

        <div className='menu-container'>
          <ul>{this.renderMenu()}</ul>
          <div className="menu-footer">
            {
              this.state.isShow &&
              <p>Phiên bản v{pjson.version}</p>
            }
            {/*<Link to={settingObj.key}>
              <img src={settingObj.icon} alt={settingObj.content} />
              {settingObj.content}
            </Link>*/}
          </div>
        </div>
      </Bound>
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.isShow !== this.state.isShow && this.state.isShow) {
      this.toggleDiv.style.justifyContent = "flex-end";
      this.toggleDiv.style.paddingRight = "8px";
    }
    if (prevState.isShow !== this.state.isShow && !this.state.isShow) {
      this.toggleDiv.style.justifyContent = "center";
      this.toggleDiv.style.paddingRight = "";
    }
  }
  componentDidMount() {
    if (!this.checkMatchPermission(window.location.pathname, "key") || window.location.pathname === "/") {
      let pathActive = "/";
      const { Roles, HasKioskDeviceConfig, HasLcdDeviceConfig } = this.props.dataLogin
      switch (true) {
        case Roles.includes(userRole.ADMIN_ROLE):
          pathActive = '/danh-sach-giao-dich'
          break;
        case Roles.includes(userRole.ADMIN_OFFICE_ROLE):
          if(!HasKioskDeviceConfig && !HasLcdDeviceConfig){
            pathActive = '/quay-giao-dich'
          }else{
            pathActive = '/thiet-bi-kiosk'
          }
         
          break;
        case Roles.includes(userRole.OFFEXSUPERVISOR):
        case Roles.includes(userRole.OFFINSUPERVISOR):
          pathActive = '/biometric-data'
          break;
        case Roles.includes(userRole.RPW):
          pathActive= '/reports'
          break;
        default:
          break;
      }
      // console.log(pathActive)
      this.setState({
        pathActive
      })
    }
    if (this.Active) {
      this.setState({ isShow: false })
    }
  }
}
