import Axios from 'axios';
import { host } from "../host";
import * as types from './types';
import {
    addLoading,
    removeLoading,
} from './commonAction'
import catchErrorApi from "../helper/catchErrorApi";
const apiCreateQRTransPoint = host + "admin/office/generate-qr-url";
const apiGetQRTransPoint = host + "admin/office/get-qr-url";


export const createQRTransPoint = ({officeID, onCompletedFunc}) => async (dispatch) => {
    const queryData = JSON.stringify({
        OfficeID: officeID
    })
    console.log(`createQRTransPoint: ${queryData}`);
    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiCreateQRTransPoint, queryData);
        const { data } = res;
        //---
        onCompletedFunc && onCompletedFunc({
            errCode: data.code,
            qrCodeURL: data.url 
        })
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.code !== 0) {
                onCompletedFunc && onCompletedFunc({
                    errCode: err.response.data.code
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.CREATE_QR_TRANS_POINT_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const getQRTransPoint = ({officeID, onCompletedFunc}) => async (dispatch) => {
    const queryData = JSON.stringify({
        OfficeID: officeID
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiGetQRTransPoint, queryData);
        const { data } = res;
        //---
        onCompletedFunc && onCompletedFunc({
            errCode: data.code,
            qrCodeURL: data.url
        })
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.code !== 0) {
                onCompletedFunc && onCompletedFunc({
                    errCode: err.response.data.code
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_QR_TRANS_POINT_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}