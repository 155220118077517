import styled from "styled-components";

const MenuItemStyle = styled.div`
    display: flex;
    height: auto;
    min-height: ${props => props.minHeight?props.minHeight:25}px;
    border-radius: 4px;
    background-color: #fff;
    padding: 5px 35px;

    /* :hover {
        background-color: #DEF5FF;
    } */
    
    .valueContent {
        color: #000;
        font-family: 'SVN-Gilroy';
        font-weight: 500;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 3px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        flex: 1;
    }
`

export {MenuItemStyle}