import styled from 'styled-components'

// images
import linear from '../../images/Pattern_VTB-03.png'
import { color } from '../../data/store'

const LoginPageStyles = styled.div`
    background-color: ${color.blue_005993};
    width: 100%;
    height: 100vh;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .loginContainer {
        .rowHeader {
            width: 100%;
            height: 60px;
            background-color: ${color.blue_7ed3f7};
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .logoVietinBank {
                margin: 0 25px 0 0;
            }
        }
        .rowLinear {
            width: 100%;
            height: 12px;
            background-image: url(${linear});
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .rowMain {
            width: 100%;
            height: 100%;
            .col_login {
                padding: 32px 40px 24px 40px;
                width: 320px;
                /* height: 404px; */
                box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
                background-color: #ffffff;
                position: absolute;
                top: 150px;
                left: 50%;
                transform: translate(-50%, 0);
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.08;
                    color: ${color.blue_005993};
                    display: flex;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }
                .content {
                    margin: 28px 0 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.13;
                    color: #222222;
                }
                .form_login {
                    margin: 25px 0 0 0;
                    p {
                        color: #f31919;
                    }
                    label {
                        font-size: 11px;
                        font-weight: bold;
                        line-height: 1.26;
                        color: ${color.blue_005993};
                    }
                    input {
                        margin: 5px 0 20px 0;
                        width: 300px;
                        height: 24px;
                        border-radius: 4px;
                        background-color: #fafafa;
                        border: none;
                        outline: none;
                        padding: 10px;
                        &::placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 1.68;
                            color: #222222;
                            opacity: 0.25;
                        }
                    }

                    p.error {
                        text-align: center;
                    }

                    .blockBtn {
                        margin: 20px 0 0 0;
                        justify-content: center;
                        display: flex;
                        .loading {
                            display: flex;
                            align-items: center;
                        }
                        .btn_login {
                            width: 120px;
                            height: 36px;
                            border-radius: 18px;
                            background-color: ${color.blue_7ed3f7};
                            display: flex;
                            border: none;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            font-weight: bold;
                            color: ${color.blue_005993};
                            outline: none;
                        }
                    }
                    .version {
                        margin: 40px 0 0 0;
                        display: flex;
                        justify-content: center;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.42;
                        color: #222222;
                    }
                }
            }
        }
    }
`

export { LoginPageStyles }
