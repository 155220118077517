import React from 'react'

// styles
import { WrapFingerQuery } from './index.styles'

// image
import icFingerGray from '../../../../../../images/ic_finger_gray.svg'

// component
import LoadingControl from '../../../Control/LoadingControl'

const STEP_INITIAL = "STEP_INITIAL"
const STEP_LOADING = "STEP_LOADING"

const FingerQuery = ({ loadingQueryByFace, setLoadingQueryByFinger, setError }) => {

    // const [step, setStep] = useState(STEP_INITIAL);
    let step = STEP_INITIAL

    return (
        <WrapFingerQuery>
            {
                step === STEP_INITIAL &&
                <>
                    <img className="ic-finger-default" src={icFingerGray} alt="finger" />
                    <div className="desc">
                        <div>Quét hình ảnh vân tay</div>
                        <div>từ thiêt bị</div>
                    </div>
                    <button disabled={true}>Bắt đầu</button>
                </>
            }
            {
                step === STEP_LOADING &&
                <div className="wrap-loading">
                    <LoadingControl size="32px" loadingPage={false} />
                    <div className="desc-loading">Vui lòng chờ...</div>
                </div>
            }
        </WrapFingerQuery>
    )
}

export default FingerQuery
