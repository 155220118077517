import styled from "styled-components";
import { color } from "../../../../data/store";

const Container = styled.div`
    display:flex;
    flex:1;
    overflow:auto;
    flex-direction:column;

    .createQRContainer {
        display:flex;
        flex:1;
        flex-direction:column;
        padding: 0 20px;
        .content{
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .qr-code{
                display: flex;
                width: 166px;
                height: 166px;
                border-radius: 50%;
                background-color: #E6E6E6;
                .icon {
                    flex: 1;
                    margin: 34px;
                }
            }
            .text{
                margin-top: 18px;
                margin-bottom: 42px;
                color: var(--Gray, #636060);
                text-align: center;
                font-family: SVN-Gilroy;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 128%; 
            }

            button {
                display: inline-flex;
                padding: 10px 18px 6px 18px;
                justify-content: center;
                align-items: center;
                border-radius: 18px;
                border: none;
                cursor: pointer;
                outline: none;
                background: var(--primary-1, ${color.blue_005993});
                color: var(--White, #FFF);
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 16.8px */
                text-transform: uppercase;
            }
        }
    }

    .result-content{
        display:flex;
        width: calc(100% - 32px);
        height: auto;
        flex-direction:column;
        text-align: center;
        font-family: SVN-Gilroy;
        font-style: normal;
        align-items: center;
        margin: 16px;
        background-color: #FFF;
        .title {
            color: var(--primary-1, ${color.blue_005993});
            font-size: 24px;
            font-weight: 600;
            margin-top: 25px;
        }
        .sub-title{
            color: var(--Gray, #636060);
            font-size: 17px;
            font-weight: 500;
            margin-top: 7px;
        }
        .qr-code{
            width: 440px;
            height: 440px;
        }
        .bottom-content{
            display:flex;
            margin-bottom: 25px;
            .button{
                display:flex;
                margin-left: 12px;
                margin-right: 12px;
                cursor: pointer;
                .icon{
                    width: 14px;
                    height: 14px;
                }
                .label{
                    color: var(--Black, #191919);
                    font-size: 15px;
                    font-weight: 600;
                    margin-left: 5px;
                }
            }
        }
        
    }
`

export {Container}