/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { WrapContainer, WrapPagingContainer, WrapTable } from './index.styles'
import SubHeaderControl from '../../Control/SubHeaderControl'
import readXlsxFile from 'read-excel-file'
import PagingControl from '../../Control/PagningControl'
import EmptyControl from '../../Control/EmptyControl'
import { emptyData } from '../../../../../data/data'
import UploadFilePopup from './UploadFilePopup'
import ConfigureByBranchTable from './ConfigureByBranchTable'
import { useDispatch } from 'react-redux'
import { getOfficesConfig, setOfficesConfig } from '../../../../../actions/confAppointmentScheduleAction'
import PopupConfirm from '../../../Popup/PopupConfirm'
import { useEffect } from 'react'
import { addLoading, removeLoading } from '../../../../../actions/commonAction'

export default function ConfigureByBranch({ headerData, sysConfig, onHideShowErrPopup, onHideShowSuccessPopup }) {
    const limitPerPage = 10
    const timeFrameList = [
        "08:00 - 08:30", "08:30 - 09:00", "09:00 - 09:30",
        "09:30 - 10:00", "10:00 - 10:30", "10:30 - 11:00",
        "13:30 - 14:00", "14:00 - 14:30", "14:30 - 15:00",
        "15:00 - 15:30", "15:30 - 16:00"
    ]
    //---
    const dispatch = useDispatch()
    //---
    const [dataInfo, setDataInfo] = useState({
        realDataList: [],
        dataList: [],
        currentDataList: [],
        currentPage: 1
    })
    const [uploadFileInfo, setUploadFileInfo] = useState({
        isShowPopup: false,
        fileName: "",
        dataList: []
    })
    const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false)
    const [defaultConfig, setDefaultConfig] = useState(sysConfig)

    const onHideUploadFilePopup = () => {
        setUploadFileInfo({
            isShowPopup: false,
            fileName: "",
            dataList: []
        })
    }

    const searchDataConfigWhenOffline = (key) => {
        // console.log(`searchDataConfig: ${key}`)
        const newDataList = []
        for (var i = 0; i < dataInfo.realDataList.length; i++) {
            const item = dataInfo.realDataList[i]
            const branchCode = item.branchCode.toLowerCase()
            if (branchCode.includes(key.toLowerCase())) {
                newDataList.push(item)
            } else {
                const branchName = item.name.toLowerCase()
                if (branchName.includes(key.toLowerCase())) {
                    newDataList.push(item)
                }
            }
        }
        //----
        setDataInfo({
            ...dataInfo,
            dataList: newDataList,
            currentDataList: getCurrentDataList(1, newDataList),
            currentPage: 1
        })
    }

    const onReset = () => {
        setDataInfo({
            ...dataInfo,
            dataList: dataInfo.realDataList,
            currentDataList: getCurrentDataList(1, dataInfo.realDataList),
            currentPage: 1
        })
    }

    const getDataConfig = () => {
        dispatch(getOfficesConfig({
            onCompleted: (dataResp) => {
                if (dataResp.code === 0) {
                    setDataInfo({
                        realDataList: dataResp.result,
                        dataList: dataResp.result,
                        currentDataList: getCurrentDataList(1, dataResp.result),
                        currentPage: 1
                    })
                } else {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Hệ thống bị gián đoạn",
                        note: "(*) Bạn vui lòng thực hiện lại"
                    })
                }
            }
        }))
    }

    const onConfirm = () => {
        setIsShowPopupConfirm(false)
        //---
        dispatch(setOfficesConfig({
            dataConfig: uploadFileInfo.dataList,
            onCompleted: (dataResp) => {
                if (dataResp.code !== 0) {
                    let dataErr = {
                        isShowPopup: true,
                        mess: "File tải lên không thành công",
                        note: "(*) Bạn vui lòng tải lại file",
                        listBranchCodeInvalid: []
                    }
                    if (dataResp.code === 103 || dataResp.code === 104 || dataResp.code === 105) {
                        if (dataResp.code === 104) {
                            dataErr.listBranchCodeInvalid = dataResp.BranchCodeInvalid
                            dataErr.note = "(*) Mã CN/ĐGD không trùng khớp hoặc bị bỏ trống."
                        } else if (dataResp.code === 105) {
                            dataErr.listBranchCodeInvalid = dataResp.BranchCodeInvalid
                            dataErr.note = "(*) Khung giờ đặt lich hẹn có giá trị không đúng hoặc bị bỏ trống."
                        } else {
                            dataErr.note = "(*) Tên CN/PGD không đúng định dạng hoặc bị bỏ trống"
                        }
                        dataErr.note += " Bạn vui lòng kiểm tra lại"
                    }

                    //---
                    onHideShowErrPopup && onHideShowErrPopup(dataErr)
                } else {
                    onHideShowSuccessPopup && onHideShowSuccessPopup({
                        isShowPopup: true,
                        mess: "File tải lên thành công"
                    })
                    getDataConfig()
                    //---
                    setDataInfo({
                        realDataList: uploadFileInfo.dataList,
                        dataList: uploadFileInfo.dataList,
                        currentDataList: getCurrentDataList(1, uploadFileInfo.dataList),
                        currentPage: 1
                    })
                }
            }
        }))
    }

    const onShowHidePopupConfirm = () => {
        if (uploadFileInfo.isShowPopup) {
            setUploadFileInfo({
                ...uploadFileInfo,
                isShowPopup: false
            })
        }
        //---
        setIsShowPopupConfirm(!isShowPopupConfirm)
    }


    const onUploadSceduleByBrachFile = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.xlsx'
        input.onchange = async (e) => {
            dispatch(addLoading())
            const files = input.files;
            const file = files[0]
            const rows = await readXlsxFile(file)
            let errData = checkColumnName(rows[0], rows[1])
            if (errData !== null) {
                dispatch(removeLoading())
                //---
                onHideShowErrPopup({
                    isShowPopup: true,
                    mess: errData.mess,
                    note: errData.note
                })
            } else {
                const rowList = rows.slice(2)
                readDataOfSceduleByBrachFile(file.name, rowList)
                //---
                dispatch(removeLoading())
            }
        }
        input.click()
    }

    const checkColumnName = (row01, row02) => {
        let checkResult = checkFirstRow(row01)
        if (checkResult) {
            checkResult = checkSecondRow(row02)
        }
        //---
        if (!checkResult) {
            return {
                mess: 'File tải lên không thành công',
                note: '(*) File không đúng định dạng. Bạn vui lòng kiểm tra lại'
            }
        }
        return null
    }

    const checkFirstRow = (firstRow) => {
        if (firstRow.length !== 12) {
            return false
        } else {
            if (firstRow[0] !== 'Mã ĐGD') {
                return false
            } else
                // if (firstRow[1] !== 'Tên CN/PGD') {
                //     return false
                // } else
                if (firstRow[1] !== 'KHUNG GIỜ ĐẶT LỊCH HẸN') {
                    return false
                } else {
                    for (var i = 2; i < 12; i++) {
                        if (firstRow[i] !== null) {
                            return false
                        }
                    }
                }
        }
        return true
    }

    const checkSecondRow = (secondRow) => {
        if (secondRow.length !== 12) {
            return false
        } else {
            // if (secondRow[0] !== null || secondRow[1] !== null) {
            if (secondRow[0] !== null) {
                return false
            } else {
                for (var i = 1; i < 12; i++) {
                    if (secondRow[i] !== timeFrameList[i - 1]) {
                        return false
                    }
                }
            }
        }
        return true
    }

    const readDataOfSceduleByBrachFile = (fileName, rowList) => {
        const newDataList = rowList.map(row => {
            const listErr = []
            const [
                branchCode,
                // name,
                time01, time02, time03,
                time04, time05, time06,
                time07, time08, time09,
                time10, time11
            ] = row
            //---
            let messErr = ""
            let isTimeErr = false
            if (!branchCode || branchCode === "") {
                messErr = "Mã ĐGD"
            }
            // if (!name || name === "") {
            //     if (messErr !== "") {
            //         messErr += ", "
            //     }
            //     messErr += "Tên CN/PGD"
            // }

            if ((Number.isNaN(time01) || !Number.isInteger(time01)) ||
                (Number.isNaN(time02) || !Number.isInteger(time02)) ||
                (Number.isNaN(time03) || !Number.isInteger(time03)) ||
                (Number.isNaN(time04) || !Number.isInteger(time04)) ||
                (Number.isNaN(time05) || !Number.isInteger(time05)) ||
                (Number.isNaN(time06) || !Number.isInteger(time06)) ||
                (Number.isNaN(time07) || !Number.isInteger(time07)) ||
                (Number.isNaN(time08) || !Number.isInteger(time08)) ||
                (Number.isNaN(time09) || !Number.isInteger(time09)) ||
                (Number.isNaN(time10) || !Number.isInteger(time10)) ||
                (Number.isNaN(time11) || !Number.isInteger(time11))) {
                isTimeErr = true
                //---
                if (messErr !== "") {
                    messErr += ", "
                }
                messErr += "Khung giờ đặt lich hẹn"
            }
            if (messErr !== "") {
                messErr += " không đúng định dạng hoặc bị bỏ trống"
                listErr.push(messErr)
            }
            //---
            if (!isTimeErr && (
                time01 < defaultConfig.timeBlock8hTo8h30.min ||
                time01 > defaultConfig.timeBlock8hTo8h30.max ||
                time02 < defaultConfig.timeBlock8h30To9h.min ||
                time02 > defaultConfig.timeBlock8h30To9h.max ||
                time03 < defaultConfig.timeBlock9hTo9h30.min ||
                time03 > defaultConfig.timeBlock9hTo9h30.max ||
                time04 < defaultConfig.timeBlock9h30To10h.min ||
                time04 > defaultConfig.timeBlock9h30To10h.max ||
                time05 < defaultConfig.timeBlock10hTo10h30.min ||
                time05 > defaultConfig.timeBlock10hTo10h30.max ||
                time06 < defaultConfig.timeBlock10h30To11h.min ||
                time06 > defaultConfig.timeBlock10h30To11h.max ||
                time07 < defaultConfig.timeBlock13h30To14h.min ||
                time07 > defaultConfig.timeBlock13h30To14h.max ||
                time08 < defaultConfig.timeBlock14hTo14h30.min ||
                time08 > defaultConfig.timeBlock14hTo14h30.max ||
                time09 < defaultConfig.timeBlock14h30To15h.min ||
                time09 > defaultConfig.timeBlock14h30To15h.max ||
                time10 < defaultConfig.timeBlock15hTo15h30.min ||
                time10 > defaultConfig.timeBlock15hTo15h30.max ||
                time11 < defaultConfig.timeBlock15h30To16h.min ||
                time11 > defaultConfig.timeBlock15h30To16h.max
            )) {
                listErr.push("Khung giờ đặt lich hẹn có giá trị không đúng.")
            }
            //---
            if (listErr.length > 0) {
                listErr.push("Vui lòng kiểm tra lại thông tin.")
            }
            //---
            return {
                branchCode: branchCode ? branchCode.toString() : "",
                // name: name,
                time01: time01,
                time02: time02,
                time03: time03,
                time04: time04,
                time05: time05,
                time06: time06,
                time07: time07,
                time08: time08,
                time09: time09,
                time10: time10,
                time11: time11,
                errorList: listErr
            }
        })
        //---
        setUploadFileInfo({
            isShowPopup: true,
            fileName: fileName,
            dataList: newDataList
        })
    }

    const onPageChange = (e) => {
        setDataInfo({
            ...dataInfo,
            currentDataList: getCurrentDataList(e, dataInfo.dataList),
            currentPage: e
        })
    }

    const getCurrentDataList = (currentPage, dataList) => {
        let endIndex = currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        //---
        return dataList.slice(startIndex, endIndex)
    }

    const renderPagingControl = () => {
        return (
            <WrapPagingContainer>
                <PagingControl
                    currentPage={dataInfo.currentPage}
                    totalItem={dataInfo.dataList.length}
                    limitPerPage={limitPerPage}
                    onChangePage={(page) =>
                        onPageChange(page)
                    }
                />
            </WrapPagingContainer>
        )
    }

    const renderConfigureByBranchContent = () => {
        return (
            <WrapTable>
                <ConfigureByBranchTable
                    currentPage={dataInfo.currentPage}
                    limitPerPage={limitPerPage}
                    dataList={dataInfo.currentDataList}
                />
            </WrapTable>
        )
    }

    useEffect(() => {
        getDataConfig()
    }, [])

    useEffect(() => {
        setDefaultConfig(sysConfig)
    }, [sysConfig])

    return (
        <>
            <WrapContainer>
                <SubHeaderControl
                    data={headerData}
                    textSearch={""}
                    typeSearch='searchOfficesConfig'
                    searchFilter={searchDataConfigWhenOffline}
                    onUploadFile={onUploadSceduleByBrachFile}
                    onReset={onReset}
                />
                {
                    dataInfo.dataList.length > 0
                        ? renderPagingControl()
                        : null
                }
                {
                    dataInfo.dataList.length > 0
                        ? renderConfigureByBranchContent()
                        : <EmptyControl
                            alertContent={''}
                            widthInput='400px'
                            content={emptyData.uploadFile}
                        />
                }
            </WrapContainer>
            {
                uploadFileInfo.isShowPopup &&
                <UploadFilePopup
                    fileName={uploadFileInfo.fileName}
                    dataList={uploadFileInfo.dataList}
                    onChangeFile={onUploadSceduleByBrachFile}
                    onConfirm={onShowHidePopupConfirm}
                    onClose={onHideUploadFilePopup}
                />
            }
            {
                isShowPopupConfirm &&
                <PopupConfirm
                    width='280px'
                    content='Bạn vui lòng xác nhận việc thay đổi tuỳ chỉnh lịch hẹn'
                    contentDelete='(*) Dữ liệu cũ sẽ mất đi và được thay thế bằng dữ liệu mới. Bạn có chắc chắn muốn tiếp tục không?'
                    cancelAction={onShowHidePopupConfirm}
                    acceptAction={onConfirm}
                />
            }
        </>

    )
}
