/**
"OfficeType": "TRADING" : là phòng giao dịch => sẽ không có header chọn CN/PGD
"OfficeType": "BRANCH" : Sẽ có header chọn CN/PGD
"OfficeType": "HEAD" :  là admin trụ sở chính" hội sở" =>  sẽ không có header chọn CN/PGD
 */
const OfficeType = {
    TRADING: "TRADING",
    BRANCH: 'BRANCH',
    HEAD: 'HEAD'
}

const _getOfficeID = ({dataLogin, generalOfficeSelected}) => {
    let officeId = dataLogin.OfficeID
    if(generalOfficeSelected && dataLogin.OfficeType === OfficeType.BRANCH){
        officeId = generalOfficeSelected.ID
    }
    return officeId
}

const _getOfficeName = ({dataLogin, generalOfficeSelected}) => {
    let officeName = dataLogin.OfficeName
    if(generalOfficeSelected && dataLogin.OfficeType === OfficeType.BRANCH){
        officeName = generalOfficeSelected.Name
    }
    return officeName
}

const _getOfficeInfo = ({dataLogin, generalOfficeSelected}) => {
    let officeInfo = dataLogin
    if(generalOfficeSelected && dataLogin.OfficeType === OfficeType.BRANCH){
        officeInfo = generalOfficeSelected
    }
    return officeInfo
}

const _getOfficeList = ({dataLogin, officeManagementList}) => {
    let officeList = []
    if( dataLogin.OfficeType === OfficeType.BRANCH){
        if(officeManagementList.length > 0){
            officeList.push(officeManagementList[0])
            if(officeManagementList.length > 1){
                const dataList = officeManagementList.slice(1).sort((a, b) =>
                    Number(a.BranchCode) - Number(b.BranchCode)
                )
                officeList.push(...dataList)
            }
        }
        
        //---
       
    }
    
    return officeList
}

const _checkBranchOfficeType = ({dataLogin}) => {
    if( dataLogin.OfficeType === OfficeType.BRANCH){
       return true
    }
    return false
}

const officeUtils = {
    getOfficeID: _getOfficeID,
    getOfficeName: _getOfficeName,
    getOfficeInfo: _getOfficeInfo,
    getOfficeList: _getOfficeList,
    isBranchOfficeType: _checkBranchOfficeType
}

export  {
    officeUtils,
    OfficeType
}