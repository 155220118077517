import styled from 'styled-components'
import { color } from '../../../../../data/store'
const Bound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.heightButton ? props.heightButton : "31px"};
    background-color: ${props => props.isDisable ? color.grayC4: props.backgroundColor??color.blue_005993};
    border-radius: 18px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 12px 12px;
    p{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: ${props => props.fontWeight ? props.fontWeight : "bold"};
        font-size: ${props => props.fontSize ? props.fontSize : "12px"};;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-left: 5px;
    }
`
export { Bound }