import React, { Component } from 'react'
import styled from 'styled-components'
import icon from './../../../../images/ic_filter_blue.svg'
import { color } from '../../../../data/store'
const Bound = styled.div`
    display:flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px 0 10px;
    height:36px;
    cursor: pointer;
    button{
        border: none;
        background: transparent;
    }
    span{
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: bold;
        color: ${color.blue_005993};
    }
`

export default class SortControl extends Component {
    render() {
        return (
            <Bound onClick={()=>{this.props.toggleFilter()}}>
                <img src={icon} alt="sort" />
                <span>FILTER</span>
            </Bound>
        )
    }
}
