import React, { Component } from 'react';
import styled from 'styled-components';
import { color } from '../../../../data/store';

const BoundStyle = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    font-family: 'SVN-Gilroy';
    margin-bottom: ${props => props.marginBottom??13}px;
    /*opacity: ${props => props.isDisabled ? "0.5" : "unset"};*/
    .title-text {        
        font-size: ${props => props.titleFontSize??11}px;
        font-weight: ${props => props.titleFontWeight??'bold'};        
        color: ${color.blue_005993};
        text-transform: uppercase;
        margin-bottom: 5px;
        .title-notice{
            color: #F31919;
            margin-left: 3px;
        }
    }
    .wrap-input{
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%;
            padding: 10px;
            height: 22px;       
            font-size: ${props => props.textFontSize??14}px;;
            font-weight: ${props => props.textFontWeight??500};        ;
            font-family: 'SVN-Gilroy';
            line-height: 1.2;
            border-radius: 4px;
            border: solid ${props => props.borderWidth??0.5}px #c8c8c8;
            background-color: ${props => props.isDisabled 
                                            ? "#E6E6E6" 
                                            : props.backgroundColor??"rgba(255,255,255,0.9)"};
            color: ${props => props.isDisabled ? "rgba(25, 25, 25, 1)" : "#222222"};
            &::placeholder{
                color: #bababa;
            }      
        }
        .prefix{
            position: absolute;
            top: 15px;
            left: 13px;
            font-size: 14px;
            font-weight: 500;
            font-family: 'SVN-Gilroy';
            line-height: 1.2;
            text-align: left;
            color: #191919;
        }
        .suffix{
            position: absolute;
            top: 15px;
            right: 13px;
            font-size: 14px;
            font-weight: 500;
            font-family: 'SVN-Gilroy';
            line-height: 1.2;
            text-align: left;
            color: #191919;
        }
    }

`
export default class InputTextControlV2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            txtText: this.props.defaultValue ? this.props.defaultValue : '',
            maxLength: this.props.maxLength ? this.props.maxLength : null,
        }
    }
    onChange = e => {
        let value = e.target.value
        // debugger;
        this.setState({
            txtText: value
        }, () => { this.props.onChange(this.state.txtText) })
    }

    componentDidMount() {
        this.setState({
            txtText: this.props.defaultValue ? this.props.defaultValue : '',
            maxLength: this.props.maxLength ? this.props.maxLength : null
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue&&prevState.txtText !== this.props.defaultValue) {
            this.setState({
                txtText: ""
            })
        }
    }
    render() {
        return (
            <BoundStyle 
                isDisabled={this.props.isDisabled} 
                className={this.props.className} 
                marginBottom={this.props.marginBottom}
                backgroundColor={this.props.backgroundColor}
                borderWidth={this.props.borderWidth}
                titleFontSize={this.props.titleFontSize}
                titleFontWeight={this.props.titleFontWeight}
                textFontSize={this.props.textFontSize}
                textFontWeight={this.props.textFontWeight}
            >
                <p className='title-text'>
                    {this.props.title}
                    {this.props.isRequired && <span className='title-notice'>*</span>}
                </p>
                <div className='wrap-input'>
                    {
                        this.props.prefix &&
                        <p className='prefix'>{this.props.prefix}</p>
                    }
                    <input
                        type='text'
                        name='txtText'
                        value={this.state.txtText}
                        onChange={this.onChange}
                        disabled={this.props.isDisabled}
                        placeholder={this.props.placeHolder}
                        maxLength={this.state.maxLength}
                    />
                    {
                        this.props.suffix &&
                        <p className='suffix'>{this.props.suffix}</p>
                    }
                </div>

            </BoundStyle>
        )
    }
}
