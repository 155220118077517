import styled from "styled-components";
import { color } from "../../../../../../data/store";

const minWidth = 480

const WrapOverlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
`

const WrapContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    min-width: ${minWidth}px;
`

const WrapContent=styled.div`
    display: flex;
    width: ${minWidth}px;
    height: 550px;
    background-color: white;
    flex-direction: column;
    margin-top: calc((50% - 550px)/2);
    margin-left: calc((100% - ${minWidth}px)/2);
    border-radius: 12px;

    .header {
        display: flex;
        flex-direction: column;
        padding: 10px 20px 10px 20px;
        width: calc(100% - 40px);
        .title-content { 
            display: flex;
            width: 100%;
            justify-content: space-between;
            .label {
                font-size: 22px;
                font-weight: 700;
                color: #191919;
            }
            .btn-container {
                display: flex;
                justify-content: end;
                    
                .icon {
                    width: 30px;
                    height: 30px;
                    margin-right: -9px;
                    margin-top: -5px;
                    cursor: pointer;
                }
            }
            
        }
    }

    .body {
        display: flex;
        flex: 1;
        width: calc(100% - 40px);
        padding: 0 20px;
        overflow: auto;

        .row {
            display: flex;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            align-items: center;
            margin-top: 20px;
            
            &.first{
                /* margin-top: 0px; */
            }
        }
    }

    .footer {
        display: flex;
        width: calc(100% - 30px);
        height: 74px;
        align-items: center;
        justify-content: end;
        border-top: 1px solid #E6E6E6;
        padding: 0px 15px;
        .err-content{
            font-size: 14px;
            font-weight: 500;
            color: #F31919;
            flex-grow: 1;
            div {
                padding: 2px 0px;
            }
        }
        .btn-content{
            width: 127px;
        }
    }
`

const WrapLine = styled.div`
     display:flex;
    width: 1px;
    height: ${props => props.height??0}px;
    background-color: ${color.grayE6};
`



export {WrapOverlay, WrapContainer, WrapContent, WrapLine}