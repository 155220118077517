import * as types from "../../actions/types";

const initialState = {
  qrCode: '',
  err: ''
}

export default function createQRTransPointReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_QR_TRANS_POINT:
      return {
        ...state,
        qrCode: action.qrCode,
        err: ''
      }
    case types.CREATE_QR_TRANS_POINT_ERR:
      return {
        ...state,
        err: {
          ...action.err
        }
      }
    case types.RESET_QR_TRANS_POINT: 
      return initialState
    default:
      return state
  }
}