import Axios from 'axios'

import * as types from './types'
import { host } from '../host'
import setAuth from '../helper/setAuth'
import sortObjectByDateProperty from '../helper/sortObjectByDateProperty'

import {
    addLoading,
    removeLoading,
} from './commonAction'
import catchErrorApi from '../helper/catchErrorApi'

export const apiAddUser = host + 'admin/user/add'
const apiUpdateUser = host + 'admin/user/update'
const apiDeleteUser = host + 'admin/user/delete'
const apiFilterUser = host + 'admin/user/filter'
const apiLogin = host + 'admin/user/login'
const apiLogout = host + 'admin/user/logout'
const apiForceSignOutUser = host + "admin/user/force-logout"
export const apiSearchUserToAddByEmail = host + 'admin/user/search-email'
// const apiGetOfficeById = host + "admin/office/get";

/**
 * @param {{
 * Role*: "ADMIN" \| "TELLER" \| "OFFADMIN" \| "OFFINSUPERVISOR", \| "OFFEXSUPERVISOR"
 * AtOffice*: string - ID of office,
 * UserInfos: [{
 *  Email*: string,
 *  FullName*: string,
 *  BankID*: string,
 *  Branch*: string,
 *  Active*: string
 * }]
 * }} listInfoUser
 */
export const addUser = (listInfoUser, callback) => async (dispatch) => {
    dispatch(addLoading())

    const queryFormat = JSON.stringify({
        ...listInfoUser,
    })

    try {
        const res = await Axios.post(apiAddUser, queryFormat)
        const { data } = res.data
        dispatch({
            type: types.ADD_USER,
            success: Boolean(data && data.ID),
        })
    } catch (err) {
        const handleError400 = () => {
            const { Code } = err.response.data
            if (Code === 11) {
                dispatch({
                    type: types.ADD_USER_ERR,
                    err: {
                        [types.ADD_USER_ERR]: 11,
                    },
                })
                return true
            }
            return false
        }

        catchErrorApi(err, dispatch, types.ADD_USER_ERR, handleError400)
    }
    dispatch(removeLoading())
    callback()
}

/**
 *
 * @param {{
 * Role: string, - new role of user
 * AtOfficeID: string - new id of office apply
 * }} dataUpdate
 * @param {string*} idUser id of user will update
 */
export const updateUser = (dataUpdate, idUser, callback) => async (
    dispatch
) => {
    const queryDataFormat = JSON.stringify({
        UID: idUser,
        ...dataUpdate,
    })
    try {
        await Axios.post(apiUpdateUser, queryDataFormat)

        dispatch({
            type: types.UPDATE_USER,
        })
    } catch (err) {
        const handleError400 = () => {
            const { Code } = err.response.data
            if (Code === 9) {
                dispatch({
                    type: types.UPDATE_USER_ERR,
                    err: {
                        [types.UPDATE_USER_ERR]: 9,
                    },
                })
                return true
            }

            if (Code === 11) {
                dispatch({
                    type: types.UPDATE_USER_ERR,
                    err: {
                        [types.UPDATE_USER_ERR]: 11,
                    },
                })
                return true
            }
            return false
        }

        catchErrorApi(err, dispatch, types.UPDATE_USER_ERR, handleError400)
    }

    callback()
}

/**
 *
 * @param {string*} id: id of user need delete
 */
export const deleteUser = (id) => async (dispatch) => {
    const queryFormat = JSON.stringify({
        ID: id,
    })

    try {
        await Axios.post(apiDeleteUser, queryFormat)

        dispatch({
            type: types.DELETE_USER,
            success: true,
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.DELETE_USER_ERR, () => false)
    }
}

export const forceSignOutUser = (id) => async (dispatch) => {
    const queryFormat = JSON.stringify({
        UID: id,
    })

    try {
        await Axios.post(apiForceSignOutUser, queryFormat)

        dispatch({
            type: types.FORCE_SIGN_OUT_USER,
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.FORCE_SIGN_OUT_USER_ERR, () => false)
    }
}

/**
 *
 * @param {{
 * Search: string - email or username
 * AtOffice: string - id of office
 * Role: "ADMIN" \| "TELLER" \| "OFFADMIN" \| "OFFINSUPERVISOR", \| "OFFEXSUPERVISOR"
 * }} dataFilter
 * @param {number*} pageSize
 * @param {number*} pageNumber
 */

export const filterUser = (dataFilter, pageSize, pageNumber) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryFormat = JSON.stringify({
        ...dataFilter,
        PageSize: pageSize,
        PageNumber: pageNumber,
    })

    try {
        const res = await Axios.post(apiFilterUser, queryFormat)
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "",
        //         "ListUser": [
        //             {
        //                 "ID": "66e292faf8e5e05f7d761835",
        //                 "Email": "duyenofficee",
        //                 "UserName": "duyenofficee",
        //                 "FullName": "duyenofficee",
        //                 "Roles": [
        //                     "COORLOBBY",
        //                     "RPW",
        //                     "COORLOBBY",
        //                     "OFFADMIN"
        //                 ],
        //                 "ReportPermission": [
        //                     "CLDV01",
        //                     "CLDV02",
        //                     "CLDV04",
        //                     "HTKH01",
        //                     "HTKH02",
        //                     "HTKH03"
        //                 ],
        //                 "InfosPermission": [],
        //                 "CoorLobbyPermission": [
        //                     "TAKETICKET",
        //                     "ADVISETICKET"
        //                 ],
        //                 "AtOffice": "63fc6b2eb25ba66efed09003",
        //                 "AtOfficeName": "Chi nhánh Wee",
        //                 "BranchCode": "10198",
        //                 "CreatedAt": "2024-09-12T07:06:34.352Z",
        //                 "UpdatedAt": "2024-09-12T07:09:31.119Z"
        //             },
        //             {
        //                 "ID": "66d7dd711a73d6417dcc8ceb",
        //                 "Email": "huyksv",
        //                 "UserName": "huyksv",
        //                 "FullName": "huyksv",
        //                 "Roles": [
        //                     "OFFEXSUPERVISOR",
        //                     "COORLOBBY"
        //                 ],
        //                 "ReportPermission": [],
        //                 "InfosPermission": [],
        //                 "CoorLobbyPermission": [
        //                     "TAKETICKET",
        //                     "COUNSELOR",
        //                     "MOVETICKET"
        //                 ],
        //                 "AtOffice": "63fc6b2eb25ba66efed09003",
        //                 "AtOfficeName": "Chi nhánh Wee",
        //                 "BranchCode": "10198",
        //                 "CreatedAt": "2024-09-04T04:09:21.978Z",
        //                 "UpdatedAt": "2024-09-04T05:04:20.105Z"
        //             },
        //             {
        //                 "ID": "66d7e50c3ce044eed3a6658d",
        //                 "Email": "tanksv2",
        //                 "UserName": "tanksv2",
        //                 "FullName": "tanksv2",
        //                 "Roles": [
        //                     "OFFEXSUPERVISOR",
        //                     "COORLOBBY"
        //                 ],
        //                 "ReportPermission": [],
        //                 "InfosPermission": [],
        //                 "CoorLobbyPermission": [
        //                     "TAKETICKET",
        //                     "COUNSELOR",
        //                     "MOVETICKET"
        //                 ],
        //                 "AtOffice": "63fc6b2eb25ba66efed09003",
        //                 "AtOfficeName": "Chi nhánh Wee",
        //                 "BranchCode": "10198",
        //                 "CreatedAt": "2024-09-04T04:41:48.145Z",
        //                 "UpdatedAt": "2024-09-04T04:41:48.145Z"
        //             },
        //             {
        //                 "ID": "66d7e3e4fffa49f871f901da",
        //                 "Email": "duyen-test",
        //                 "UserName": "duyen-test",
        //                 "FullName": "duyen-test",
        //                 "Roles": [
        //                     "COORLOBBY"
        //                 ],
        //                 "ReportPermission": [],
        //                 "InfosPermission": [],
        //                 "CoorLobbyPermission": null,
        //                 "AtOffice": "63fc6b2eb25ba66efed09003",
        //                 "AtOfficeName": "Chi nhánh Wee",
        //                 "BranchCode": "10198",
        //                 "CreatedAt": "2024-09-04T04:36:52.371Z",
        //                 "UpdatedAt": "2024-09-04T04:36:52.371Z"
        //             },
        //             {
        //                 "ID": "66a9aea4ce611db71f6094ba",
        //                 "Email": "tantrusochinh",
        //                 "UserName": "tantrusochinh",
        //                 "FullName": "tantrusochinh",
        //                 "Roles": [
        //                     "RPW",
        //                     "IFW"
        //                 ],
        //                 "ReportPermission": [
        //                     "CLDV01",
        //                     "CLDV02",
        //                     "CLDV04",
        //                     "HTKH01",
        //                     "HTKH02",
        //                     "HTKH03",
        //                     "ND01",
        //                     "SLGD01",
        //                     "SLGD02",
        //                     "SLGD03",
        //                     "STH01",
        //                     "STH02",
        //                     "STH03",
        //                     "STH04",
        //                     "STH05",
        //                     "STH06",
        //                     "STH07",
        //                     "STH08",
        //                     "STH09",
        //                     "STH10",
        //                     "STH11",
        //                     "STH12",
        //                     "STH13",
        //                     "STH14",
        //                     "STH15",
        //                     "STH16",
        //                     "TGPV01",
        //                     "TGPV02",
        //                     "TGPV05",
        //                     "TGPV06"
        //                 ],
        //                 "InfosPermission": [
        //                     "DUP",
        //                     "SID",
        //                     "SC",
        //                     "SBB"
        //                 ],
        //                 "CoorLobbyPermission": null,
        //                 "AtOffice": "66d7c5951a73d6417dcc8cbe",
        //                 "AtOfficeName": "Tân TSC",
        //                 "BranchCode": "99998",
        //                 "CreatedAt": "2024-07-31T03:25:24.92Z",
        //                 "UpdatedAt": "2024-09-04T02:41:48.669Z"
        //             },
        //             {
        //                 "ID": "66c5666f94b9df0984707faf",
        //                 "Email": "tanadmin",
        //                 "UserName": "tanadmin",
        //                 "FullName": "tanadmin",
        //                 "Roles": [
        //                     "OFFADMIN",
        //                     "OFFINSUPERVISOR",
        //                     "OFFEXSUPERVISOR"
        //                 ],
        //                 "ReportPermission": [],
        //                 "InfosPermission": [],
        //                 "CoorLobbyPermission": null,
        //                 "AtOffice": "63fc6b2eb25ba66efed09003",
        //                 "AtOfficeName": "Chi nhánh Wee",
        //                 "BranchCode": "10198",
        //                 "CreatedAt": "2024-08-21T04:00:47.235Z",
        //                 "UpdatedAt": "2024-08-30T06:54:12.188Z"
        //             }
        //         ]
        //     }
        // }
        const { data } = res

        dispatch({
            type: types.FILTER_USER,
            listUser: data.ListUser
                ? data.ListUser.sort(sortObjectByDateProperty('UpdatedAt'))
                : [],
            total: data.Total,
            lastConditionFilterUser: {
                ...dataFilter,
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(err, dispatch, types.FILTER_USER_ERR, () => false)
    }
}

/**
 *
 * @param {string} email email of user account
 * @param {string} pwd password of user account
 */
export const loginUser = (email, pwd) => async (dispatch) => {
    const queryData = JSON.stringify({
        Email: email.toLocaleLowerCase(),
        Pwd: pwd,
    })
    try {
        Axios.defaults.withCredentials =
            process.env.NODE_ENV === 'production' ? false : true
        const res = await Axios.post(apiLogin, queryData)
        const { data } = res
        setAuth(data.Code === 0 && data)
        window.localStorage.setItem('dataLogin', JSON.stringify(data))
        dispatch(setCurrentUser(data))
    } catch (err) {
        dispatch({
            type: types.LOGIN_USER_ERR,
            err: {
                [types.LOGIN_USER_ERR]: true,
            },
        })
    }
}

export const setCurrentUser = (data) => ({
    type: types.LOGIN_USER,
    data,
})

/**
 *
 * @param {string} userId id of user signed in
 */
export const logoutUser = (userId) => async (dispatch) => {
    const queryData = JSON.stringify({
        UID: userId,
    })

    Axios.defaults.withCredentials = false
    setAuth(false)
    dispatch(resetStore())

    try {
        await Axios.post(apiLogout, queryData)
    } catch (err) {
        console.log(err)
    }
}

export const searchUserToAddByEmail = (emailUser, callbackSearch) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        SearchText: emailUser,
    })

    try {
        const res = await Axios.post(apiSearchUserToAddByEmail, queryDataFormat)
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "",
        //         "UserInfos": {
        //             "UserName": "duyen001",
        //             "Name": "duyen001",
        //             "Branch": "",
        //             "BranchCode": "",
        //             "Role": "",
        //             "IsExist": false,
        //             "IsNotFound": false,
        //             "Active": ""
        //         }
        //     }
        // }
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === 0 && data.UserInfos
        if (isSuccess) {
            dispatch({
                type: types.SEARCH_USER_TO_ADD_BY_EMAIL,
                userInfoFromBank: data.UserInfos,
            })
        } else
            dispatch({
                type: types.SEARCH_USER_TO_ADD_BY_EMAIL_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        const handleError400 = () => {
            if ([0, 2, 4].includes(err.response.data.Code)) {
                dispatch({
                    type: types.SEARCH_USER_TO_ADD_BY_EMAIL_ERR,
                    err: err.response.data,
                })
                return true
            }
            return false
        }

        catchErrorApi(
            err,
            dispatch,
            types.SEARCH_USER_TO_ADD_BY_EMAIL_ERR,
            handleError400
        )
    }

    callbackSearch()
}

export const resetStore = () => ({
    type: types.LOGOUT_USER,
})

export const alertFillterUser = () => {
    return {
        type: types.ALERT_CONTENT,
    }
}

export const saveTextSearch = (text) => ({
    type: types.SAVE_TEXT_SEARCH,
    textSearch: text,
})

export const clearTextSearch = () => ({
    type: types.CLEAR_TEXT_SEARCH,
})

export const saveUserPass = (dataSave) => (dispatch) => {
    dispatch({
        dataSave,
    })
}
