import styled from "styled-components";

const MinWidthContainer = 1200

const WrapTable = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    min-width: ${MinWidthContainer}px;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 10px;
`

const WrapHeaderContent = styled.div`
    display: flex;
    width: 100%;
`

const WrapBodyContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: 'scroll';
    overflow-x: hidden;
`

export {WrapTable, WrapHeaderContent, WrapBodyContent, MinWidthContainer}