import styled from "styled-components";
import { color } from "../../../../../../data/store";

const ContainerStyled = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;

    .label{
        font-weight: 700;
        color: ${color.blue_005993};
        }

    .value{
        color: #222222;
        padding-left: 5px;
    }
`
export {ContainerStyled}