import styled, { css } from "styled-components";

const MinWidthContainer = 1200

const WrapTable = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    min-width: ${MinWidthContainer}px;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 10px;
`

const WrapHeader = styled.div`
    display: flex;
    width: 100%;
    /* padding: 8px 0px; */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /* background-color: bisque; */

    .label{
        font-size: 14px;
        font-weight: 600;
        color: #191919;
    }
`

const WrapNoteContent = styled.div`
    display: grid;
    grid-template-columns: 17px 130px 17px 90px;
    width: auto;
    box-sizing: border-box;
    align-items: center;
    justify-content: end;

    .column {
        display:flex;
        font-size: 14px;
        font-weight: 500;
        color: #191919;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .icon{
            width:15px;
            height:15px;
            ${
                prop => prop.isWinOperatingSystem 
                ?css`margin-top:2px;`
                :css`margin-bottom: 2px`
            }
        }
    }
`

const WrapHeaderContent = styled.div`
    display: flex;
    width: 100%;
`

const WrapBodyContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: 'scroll';
    overflow-x: hidden;
`

export {WrapTable, WrapHeader, WrapHeaderContent, WrapBodyContent, WrapNoteContent, MinWidthContainer}