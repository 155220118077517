import styled from "styled-components";

const Bound=styled.div`
    display:flex;
    flex-direction:column;
    padding: 5px 0;
    min-width:78px;
    position:absolute;
    top:${props=>props.offset.top}px;
    left:${props=>props.offset.left}px;
    background-color:white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;

    div{
        width:calc(100% - 14px);
        height:22px;
        padding:0 7px;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.68;
        letter-spacing: normal;
        cursor:pointer;

        :hover{
            background-color: #DEF5FF;
        }
    }
    .modify{
        color:#222222;
    }
    .remove{
        color:#f31919;
    }
`
export {Bound}