import styled from "styled-components";

const heightItem = 40
const ContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    cursor: pointer;

    .menuContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: ${props => props.isShowMenu?heightItem*props.numberOfItems:0}px;
        transition: height 0.2s;
        overflow: ${props => props.isShowMenu?'visible':'hidden'};
        background-color: #fff;
    }
`

const LabelContentStyle = styled.div`
    display: flex;
    height: 18px;
    min-height: 18px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    padding: 8px 10px;
    .value {
        font-family: 'SVN-Gilroy';
        font-weight: 700;
        font-size: 14px;
        width: calc(100% - 40px);
        margin-top: 3px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
    }

    .arrow {
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
        transition-duration: 0.2s;
        transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
        -webkit-transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
    }
`

const MenuItemStyle = styled.div`
    display: flex;
    height: 25px;
    min-height: 25px;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;
    padding: 8px 20px;

    :hover {
        background-color: #DEF5FF;
    }
    
    .valueContent {
        color: #000;
        font-family: 'SVN-Gilroy';
        font-weight: 500;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 3px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
    }
`

export {ContainerStyle, LabelContentStyle, MenuItemStyle}