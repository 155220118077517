import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
// import { resetStore } from './userAction';
// import { getInfoImageOrVideo } from '../tools';
import {
  addLoading,
  removeLoading,
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';
import { isArray } from '../helper/checkValidity';

const apiGetAllCounterServices = host + "admin/counter-services/getAll"
const apiGetCounterServiceConfig = host + "admin/counter-services/get-config-info";
//---
const apiAddGroupCounterService = host + "admin/counter-services-group/create";
const apiUpdateGroupCounterService = host + "admin/counter-services-group/update";
//---
const apiAddCounterService = host + "admin/counter-services/create";
const apiUpdateCounterService = host + "admin/counter-services/update";

/**
 * 
 * @param {string*} userId id of user was loged in to web
 */
export const getAllCounterServices = ({uid, status = "", onCompletedFunc}) => async dispatch => {
    const queryDataFormat = JSON.stringify({
      UID: uid,
      Status: status
    })
    //---
    addLoading()
    //---
    try {
      const res = await Axios.post(apiGetAllCounterServices, queryDataFormat);
    //   const res = {
    //     data: {
    //         "Code": 0,
    //         "Message": "",
    //         "PersonCustomer": [
    //             {
    //                 "ID": "6661383d87ff9fec8f9b908e",
    //                 "Name": "Tài khoản và thẻ",
    //                 "GroupCode": "KHCN01",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.597Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.597Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66613e3387ff9fec8f9b90a6",
    //                         "Name": "Mở tài khoản thanh toán",
    //                         "ServiceType": "GDV_1941",
    //                         "Description": "Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán",
    //                         "Status": "A",
    //                         "ParentID": "6661383d87ff9fec8f9b908e",
    //                         "GroupCode": "KHCN01",
    //                         "CreateAt": "2024-09-04T07:09:30.686Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.687Z"
    //                     },
    //                     {
    //                         "ID": "66613ee687ff9fec8f9b90a7",
    //                         "Name": "Dịch vụ thẻ",
    //                         "ServiceType": "GDV_2621",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661383d87ff9fec8f9b908e",
    //                         "GroupCode": "KHCN01",
    //                         "CreateAt": "2024-09-04T07:09:30.688Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.688Z"
    //                     },
    //                     {
    //                         "ID": "66613fd087ff9fec8f9b90a8",
    //                         "Name": "Thay đổi thông tin KH",
    //                         "ServiceType": "GDV_1901",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661383d87ff9fec8f9b908e",
    //                         "GroupCode": "KHCN01",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661389c87ff9fec8f9b908f",
    //                 "Name": "Nộp/ rút tiền mặt",
    //                 "GroupCode": "KHCN02",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.598Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.598Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661424a87ff9fec8f9b90a9",
    //                         "Name": "Nộp tiền mặt vào tài khoản",
    //                         "ServiceType": "GDV_0941",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "666142fa87ff9fec8f9b90aa",
    //                         "Name": "Rút tiền mặt từ tài khoản",
    //                         "ServiceType": "GDV_1061",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "6661424a87ff9fec8f9b90a9",
    //                         "Name": "Nộp tiền mặt vào tài khoản",
    //                         "ServiceType": "GDV_0941",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "666142fa87ff9fec8f9b90aa",
    //                         "Name": "Rút tiền mặt từ tài khoản",
    //                         "ServiceType": "GDV_1061",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138ac87ff9fec8f9b9090",
    //                 "Name": "Thanh toán hóa đơn",
    //                 "GroupCode": "KHCN03",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661434f87ff9fec8f9b90ab",
    //                         "Name": "Thanh toán hóa đơn bằng tiền mặt",
    //                         "ServiceType": "GDV_0421",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138ac87ff9fec8f9b9090",
    //                         "GroupCode": "KHCN03",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "666143b287ff9fec8f9b90ac",
    //                         "Name": "Thanh toán hóa đơn bằng chuyển khoản",
    //                         "ServiceType": "GDV_0431",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138ac87ff9fec8f9b9090",
    //                         "GroupCode": "KHCN03",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138cd87ff9fec8f9b9091",
    //                 "Name": "Thu NSNN",
    //                 "GroupCode": "KHCN04",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "666143ed87ff9fec8f9b90ad",
    //                         "Name": "Nộp thuế tiền mặt",
    //                         "ServiceType": "GDV_0231",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138cd87ff9fec8f9b9091",
    //                         "GroupCode": "KHCN04",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "6661440e87ff9fec8f9b90ae",
    //                         "Name": "Nộp phạt tiền mặt",
    //                         "ServiceType": "GDV_0271",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138cd87ff9fec8f9b9091",
    //                         "GroupCode": "KHCN04",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "6661593587ff9fec8f9b90af",
    //                         "Name": "Nộp thuế từ tài khoản",
    //                         "ServiceType": "GDV_0241",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138cd87ff9fec8f9b9091",
    //                         "GroupCode": "KHCN04",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138db87ff9fec8f9b9092",
    //                 "Name": "Chuyển tiền",
    //                 "GroupCode": "KHCN05",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "666159e987ff9fec8f9b90b0",
    //                         "Name": "Chuyển khoản",
    //                         "ServiceType": "GDV_1131",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138db87ff9fec8f9b9092",
    //                         "GroupCode": "KHCN05",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "66615a4587ff9fec8f9b90b1",
    //                         "Name": "Chuyển tiền ngoài hệ thống từ TK",
    //                         "ServiceType": "GDV_0021",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138db87ff9fec8f9b9092",
    //                         "GroupCode": "KHCN05",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "66615af887ff9fec8f9b90b2",
    //                         "Name": "Chuyển tiền ngoài hệ thống đi từ tiền mặt",
    //                         "ServiceType": "GDV_0011",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138db87ff9fec8f9b9092",
    //                         "GroupCode": "KHCN05",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138e887ff9fec8f9b9093",
    //                 "Name": "Tiền gửi",
    //                 "GroupCode": "KHCN06",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615b9a87ff9fec8f9b90b3",
    //                         "Name": "Gửi tiết kiệm từ tiền mặt",
    //                         "ServiceType": "GDV_1971",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138e887ff9fec8f9b9093",
    //                         "GroupCode": "KHCN06",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     },
    //                     {
    //                         "ID": "66615bbd87ff9fec8f9b90b4",
    //                         "Name": "Gửi tiết kiệm từ tài khoản",
    //                         "ServiceType": "GDV_1141",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138e887ff9fec8f9b9093",
    //                         "GroupCode": "KHCN06",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     },
    //                     {
    //                         "ID": "66615bdd87ff9fec8f9b90b5",
    //                         "Name": "Rút tiết kiệm",
    //                         "ServiceType": "GDV_0761",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138e887ff9fec8f9b9093",
    //                         "GroupCode": "KHCN06",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138f687ff9fec8f9b9094",
    //                 "Name": "Tiền vay",
    //                 "GroupCode": "KHCN07",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615c3887ff9fec8f9b90b6",
    //                         "Name": "Trả nợ vay từ tiền mặt",
    //                         "ServiceType": "GDV_1021",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138f687ff9fec8f9b9094",
    //                         "GroupCode": "KHCN07",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     },
    //                     {
    //                         "ID": "66615c5b87ff9fec8f9b90b7",
    //                         "Name": "Trả nợ vay từ tài khoản",
    //                         "ServiceType": "GDV_1031",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138f687ff9fec8f9b9094",
    //                         "GroupCode": "KHCN07",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661390687ff9fec8f9b9095",
    //                 "Name": "Đăng ký dịch vụ",
    //                 "GroupCode": "KHCN08",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615c9287ff9fec8f9b90b8",
    //                         "Name": "Dịch vụ NHĐT",
    //                         "ServiceType": "GDV_2141",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661390687ff9fec8f9b9095",
    //                         "GroupCode": "KHCN08",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661391887ff9fec8f9b9096",
    //                 "Name": "Ngoại tệ",
    //                 "GroupCode": "KHCN09",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615cb287ff9fec8f9b90b9",
    //                         "Name": "Dịch vụ ngoại tệ",
    //                         "ServiceType": "GDV_0541",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661391887ff9fec8f9b9096",
    //                         "GroupCode": "KHCN09",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661393987ff9fec8f9b9097",
    //                 "Name": "Dịch vụ khác",
    //                 "GroupCode": "KHCN10",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615cd487ff9fec8f9b90ba",
    //                         "Name": "Dịch vụ khác",
    //                         "ServiceType": "GDV_0001",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661393987ff9fec8f9b9097",
    //                         "GroupCode": "KHCN10",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "66d7dcf38ff6a5b7ee405dd4",
    //                 "Name": "phuong tiep tục test",
    //                 "GroupCode": "KHCN11",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": null
    //             }
    //         ],
    //         "CorpCustomer": [
    //             {
    //                 "ID": "6661394b87ff9fec8f9b9098",
    //                 "Name": "Tài khoản và thẻ",
    //                 "GroupCode": "KHDN01",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615d9087ff9fec8f9b90bb",
    //                         "Name": "Mở tài khoản thanh toán",
    //                         "ServiceType": "GDV_1992",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661394b87ff9fec8f9b9098",
    //                         "GroupCode": "KHDN01",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     },
    //                     {
    //                         "ID": "66615d9087ff9fec8f9b90bc",
    //                         "Name": "Thay đổi thông tin KH",
    //                         "ServiceType": "GDV_1912",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661394b87ff9fec8f9b9098",
    //                         "GroupCode": "KHDN01",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661395887ff9fec8f9b9099",
    //                 "Name": "Nộp/ rút tiền mặt",
    //                 "GroupCode": "KHDN02",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615df087ff9fec8f9b90bd",
    //                         "Name": "Nộp tiền mặt vào tài khoản",
    //                         "ServiceType": "GDV_0942",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661395887ff9fec8f9b9099",
    //                         "GroupCode": "KHDN02",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     },
    //                     {
    //                         "ID": "66615df087ff9fec8f9b90be",
    //                         "Name": "Rút tiền mặt từ tài khoản",
    //                         "ServiceType": "GDV_1062",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661395887ff9fec8f9b9099",
    //                         "GroupCode": "KHDN02",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661396787ff9fec8f9b909a",
    //                 "Name": "Thu NSNN",
    //                 "GroupCode": "KHDN03",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615e6987ff9fec8f9b90bf",
    //                         "Name": "Nộp thuế từ tài khoản",
    //                         "ServiceType": "GDV_0242",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661396787ff9fec8f9b909a",
    //                         "GroupCode": "KHDN03",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661397387ff9fec8f9b909b",
    //                 "Name": "Chuyển tiền",
    //                 "GroupCode": "KHDN04",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615ee287ff9fec8f9b90c0",
    //                         "Name": "Chuyển khoản",
    //                         "ServiceType": "GDV_1132",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661397387ff9fec8f9b909b",
    //                         "GroupCode": "KHDN04",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     },
    //                     {
    //                         "ID": "66615f0c87ff9fec8f9b90c1",
    //                         "Name": "Chuyển tiền ngoài hệ thống từ TK",
    //                         "ServiceType": "GDV_0022",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661397387ff9fec8f9b909b",
    //                         "GroupCode": "KHDN04",
    //                         "CreateAt": "2024-09-04T07:09:30.694Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.694Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661398287ff9fec8f9b909c",
    //                 "Name": "Tiền vay",
    //                 "GroupCode": "KHDN05",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615f3f87ff9fec8f9b90c2",
    //                         "Name": "Trả nợ vay từ tài khoản",
    //                         "ServiceType": "GDV_1032",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661398287ff9fec8f9b909c",
    //                         "GroupCode": "KHDN05",
    //                         "CreateAt": "2024-09-04T07:09:30.694Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.694Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661398e87ff9fec8f9b909d",
    //                 "Name": "Đăng ký dịch vụ",
    //                 "GroupCode": "KHDN06",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615f8c87ff9fec8f9b90c3",
    //                         "Name": "Dịch vụ NHĐT",
    //                         "ServiceType": "GDV_2162",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661398e87ff9fec8f9b909d",
    //                         "GroupCode": "KHDN06",
    //                         "CreateAt": "2024-09-04T07:09:30.694Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.695Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "66613d9387ff9fec8f9b909e",
    //                 "Name": "Ngoại tệ",
    //                 "GroupCode": "KHDN07",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661616187ff9fec8f9b90c4",
    //                         "Name": "Chuyển tiền ngoại tệ",
    //                         "ServiceType": "GDV_0542",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "66613d9387ff9fec8f9b909e",
    //                         "GroupCode": "KHDN07",
    //                         "CreateAt": "2024-09-04T07:09:30.695Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.695Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "66613da687ff9fec8f9b909f",
    //                 "Name": "Dịch vụ khác",
    //                 "GroupCode": "KHDN08",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661617987ff9fec8f9b90c5",
    //                         "Name": "Dịch vụ khác",
    //                         "ServiceType": "GDV_0002",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "66613da687ff9fec8f9b909f",
    //                         "GroupCode": "KHDN08",
    //                         "CreateAt": "2024-09-04T07:09:30.695Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.695Z"
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    //   }

      const { data } = res;
      dispatch({
        type: types.GET_ALL_COUNTER_SERVICES,
        listService: {
          personCustomer: data.PersonCustomer ? data.PersonCustomer : [],
          corpCustomer: data.CorpCustomer ? data.CorpCustomer : []
        }
      })
      //---
      onCompletedFunc && onCompletedFunc({
        code: 0,
        result: {
          personCustomer: data.PersonCustomer ? data.PersonCustomer : [],
          corpCustomer: data.CorpCustomer ? data.CorpCustomer : []
        }
      })
    }
    catch (err) {
      catchErrorApi(
        err,
        dispatch,
        types.GET_ALL_COUNTER_SERVICES_ERR,
        () => false
      )
    }
    //---
    removeLoading()
}

export const getCounterServiceConfig = ({uid, onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        UID: uid
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiGetCounterServiceConfig, queryData);
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "OK",
        //         "StatusList": [
        //             {
        //                 "Code": 'A',
        //                 "Value": 'Đang hoạt động'
        //             },
        //             {
        //                 "Code": 'INA',
        //                 "Value": 'Đang tạm ngừng'
        //             }
        //         ],
        //         "FeaturesAdmin": [
        //             {
        //                 "ID": "66b2d35cfd58588644cbb05f",
        //                 "ParentCode": "",
        //                 "Name": "Danh Sách Giao Dịch",
        //                 "Code": "001",
        //                 "Child": [
        //                     {
        //                         "ID": "66b2d387fd58588644cbb060",
        //                         "ParentCode": "001",
        //                         "Name": "Xem danh sách GD",
        //                         "Code": "0010101",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2d3fefd58588644cbb061",
        //                 "ParentCode": "",
        //                 "Name": "Chi nhánh/PGD",
        //                 "Code": "002",
        //                 "Child": [
        //                     {
        //                         "ID": "66b2d424fd58588644cbb062",
        //                         "ParentCode": "002",
        //                         "Name": "Thêm quầy chi nhánh/PGD",
        //                         "Code": "0020101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d453fd58588644cbb063",
        //                         "ParentCode": "002",
        //                         "Name": "Tùy chỉnh quầy chi nhánh/PGD",
        //                         "Code": "0020102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d47dfd58588644cbb064",
        //                         "ParentCode": "002",
        //                         "Name": "Xem danh sách chi nhánh/PGD",
        //                         "Code": "0020103",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d48bfd58588644cbb065",
        //                         "ParentCode": "002",
        //                         "Name": "Xóa chi nhánh/PGD",
        //                         "Code": "0020104",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b1fb91485c7f295fcb81db",
        //                 "ParentCode": "",
        //                 "Name": "Quảng cáo PGD",
        //                 "Code": "003",
        //                 "Child": [
        //                     {
        //                         "ID": "66b1fecb485c7f295fcb81dc",
        //                         "ParentCode": "003",
        //                         "Name": "Xem danh sách thiết bị Kioks",
        //                         "Code": "00301",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b1ff0b485c7f295fcb81dd",
        //                                 "ParentCode": "00301",
        //                                 "Name": "Thêm thiết bị Kiosk",
        //                                 "Code": "0030101",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1ff21485c7f295fcb81de",
        //                                 "ParentCode": "00301",
        //                                 "Name": "Tùy chỉnh thiết bị Kiosk",
        //                                 "Code": "0030102",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1ff82485c7f295fcb81df",
        //                                 "ParentCode": "00301",
        //                                 "Name": "Xem danh sách thiết bị Kiosk",
        //                                 "Code": "0030103",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1ff96485c7f295fcb81e0",
        //                                 "ParentCode": "00301",
        //                                 "Name": "Vị trí áp dụng Kiosk",
        //                                 "Code": "0030104",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1ffa5485c7f295fcb81e1",
        //                                 "ParentCode": "00301",
        //                                 "Name": "Xóa thiết bị Kiosk",
        //                                 "Code": "0030105",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         "ID": "66b1fff0485c7f295fcb81e2",
        //                         "ParentCode": "003",
        //                         "Name": "Xem danh sách thiết bị GDV",
        //                         "Code": "00302",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b20009485c7f295fcb81e3",
        //                                 "ParentCode": "00302",
        //                                 "Name": "Thêm thiết bị GDV",
        //                                 "Code": "0030201",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b2002d485c7f295fcb81e4",
        //                                 "ParentCode": "00302",
        //                                 "Name": "Tùy chỉnh thiết bị GDV",
        //                                 "Code": "0030202",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b2004e485c7f295fcb81e5",
        //                                 "ParentCode": "00302",
        //                                 "Name": "Xem danh sách thiết bị GDV",
        //                                 "Code": "0030203",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b20069485c7f295fcb81e6",
        //                                 "ParentCode": "00302",
        //                                 "Name": "Vị trí áp dụng thiết bị GDV",
        //                                 "Code": "0030204",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b20076485c7f295fcb81e7",
        //                                 "ParentCode": "00302",
        //                                 "Name": "Xóa thiết bị GDV",
        //                                 "Code": "0030205",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         "ID": "66b2009a485c7f295fcb81e8",
        //                         "ParentCode": "003",
        //                         "Name": "Xem danh sách màn hình LCD",
        //                         "Code": "00303",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b200b4485c7f295fcb81e9",
        //                                 "ParentCode": "00303",
        //                                 "Name": "Thêm màn hình LCD",
        //                                 "Code": "0030301",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b200e4485c7f295fcb81ea",
        //                                 "ParentCode": "00303",
        //                                 "Name": "Tùy chỉnh màn hình LCD",
        //                                 "Code": "0030302",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b200f4485c7f295fcb81eb",
        //                                 "ParentCode": "00303",
        //                                 "Name": "Xem màn hình LCD",
        //                                 "Code": "0030303",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b20105485c7f295fcb81ec",
        //                                 "ParentCode": "00303",
        //                                 "Name": "Vị trí áp dụng màn hình LCD",
        //                                 "Code": "0030304",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b20113485c7f295fcb81ed",
        //                                 "ParentCode": "00303",
        //                                 "Name": "Xóa màn hình LCD",
        //                                 "Code": "0030305",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2019b485c7f295fcb81ee",
        //                 "ParentCode": "",
        //                 "Name": "Phân quyền User",
        //                 "Code": "004",
        //                 "Child": [
        //                     {
        //                         "ID": "66b201d8485c7f295fcb81ef",
        //                         "ParentCode": "004",
        //                         "Name": "Thêm user",
        //                         "Code": "0040101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b201f0485c7f295fcb81f0",
        //                         "ParentCode": "004",
        //                         "Name": "Tùy chỉnh user",
        //                         "Code": "0040102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2020e485c7f295fcb81f1",
        //                         "ParentCode": "004",
        //                         "Name": "Xem danh sách user",
        //                         "Code": "0040103",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b20226485c7f295fcb81f2",
        //                         "ParentCode": "004",
        //                         "Name": "Thoát phiên user",
        //                         "Code": "0040104",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2023a485c7f295fcb81f3",
        //                         "ParentCode": "004",
        //                         "Name": "Xóa user",
        //                         "Code": "0040105",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2035f485c7f295fcb81f4",
        //                 "ParentCode": "",
        //                 "Name": "Cấu hình lịch hẹn",
        //                 "Code": "005",
        //                 "Child": [
        //                     {
        //                         "ID": "66b203ac485c7f295fcb81f5",
        //                         "ParentCode": "005",
        //                         "Name": "Theo chi nhánh",
        //                         "Code": "00501",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b203f4485c7f295fcb81f7",
        //                                 "ParentCode": "00501",
        //                                 "Name": "Xem cấu hình lịch hẹn",
        //                                 "Code": "0050101",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b20407485c7f295fcb81f8",
        //                                 "ParentCode": "00501",
        //                                 "Name": "Thêm cấu hình lịch hẹn",
        //                                 "Code": "0050102",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         "ID": "66b203c9485c7f295fcb81f6",
        //                         "ParentCode": "005",
        //                         "Name": "Thiết lập chung",
        //                         "Code": "00502",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b20417485c7f295fcb81f9",
        //                                 "ParentCode": "00502",
        //                                 "Name": "Xem cấu hình thiết lập chung",
        //                                 "Code": "0050201",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b2042a485c7f295fcb81fa",
        //                                 "ParentCode": "00502",
        //                                 "Name": "Thêm cấu hình thiết lập chung",
        //                                 "Code": "0050202",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2d4cdfd58588644cbb066",
        //                 "ParentCode": "",
        //                 "Name": "Xử lý dữ liệu",
        //                 "Code": "006",
        //                 "Child": [
        //                     {
        //                         "ID": "66b2d4e3fd58588644cbb067",
        //                         "ParentCode": "006",
        //                         "Name": "Xem thông tin dữ liệu",
        //                         "Code": "0060101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b49d724efc7fe0226e9bd7",
        //                         "ParentCode": "006",
        //                         "Name": "Xác nhận dữ liệu",
        //                         "Code": "0060102",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b20495485c7f295fcb81fb",
        //                 "ParentCode": "",
        //                 "Name": "Xử lý STH",
        //                 "Code": "007",
        //                 "Child": [
        //                     {
        //                         "ID": "66b204e7485c7f295fcb81fc",
        //                         "ParentCode": "007",
        //                         "Name": "Tìm kiếm STH",
        //                         "Code": "0070101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b204f4485c7f295fcb81fd",
        //                         "ParentCode": "007",
        //                         "Name": "Xóa STH",
        //                         "Code": "0070102",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b1ed88485c7f295fcb81c4",
        //                 "ParentCode": "",
        //                 "Name": "Thiết bị PGD",
        //                 "Code": "008",
        //                 "Child": [
        //                     {
        //                         "ID": "66b1ee45485c7f295fcb81c5",
        //                         "ParentCode": "008",
        //                         "Name": "Thiết bị Kioks",
        //                         "Code": "00801",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b1f2a4485c7f295fcb81c7",
        //                                 "ParentCode": "00801",
        //                                 "Name": "Danh sách thiết bị Kiosk",
        //                                 "Code": "0080101",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f5ee485c7f295fcb81c8",
        //                                 "ParentCode": "00801",
        //                                 "Name": "Thêm thiết bị Kiosk",
        //                                 "Code": "0080102",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f61e485c7f295fcb81c9",
        //                                 "ParentCode": "00801",
        //                                 "Name": "Kết nối Kiosk",
        //                                 "Code": "0080103",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f637485c7f295fcb81ca",
        //                                 "ParentCode": "00801",
        //                                 "Name": "Tùy chỉnh thiết bị Kiosk",
        //                                 "Code": "0080104",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f641485c7f295fcb81cb",
        //                                 "ParentCode": "00801",
        //                                 "Name": "Xóa thiết bị Kiosk",
        //                                 "Code": "0080105",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         "ID": "66b1ef39485c7f295fcb81c6",
        //                         "ParentCode": "008",
        //                         "Name": "Thiết bị LCD",
        //                         "Code": "00802",
        //                         "Child": [
        //                             {
        //                                 "ID": "66b1f662485c7f295fcb81cc",
        //                                 "ParentCode": "00802",
        //                                 "Name": "Danh sách màn hình LCD",
        //                                 "Code": "0080201",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f849485c7f295fcb81d0",
        //                                 "ParentCode": "00802",
        //                                 "Name": "Thêm mới màn hình LCD",
        //                                 "Code": "0080202",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f85b485c7f295fcb81d1",
        //                                 "ParentCode": "00802",
        //                                 "Name": "Kết nối màn hình LCD",
        //                                 "Code": "0080203",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f874485c7f295fcb81d2",
        //                                 "ParentCode": "00802",
        //                                 "Name": "Tùy chỉnh màn hình LCD",
        //                                 "Code": "0080204",
        //                                 "Child": null
        //                             },
        //                             {
        //                                 "ID": "66b1f87d485c7f295fcb81d3",
        //                                 "ParentCode": "00802",
        //                                 "Name": "Xóa màn hình LCD",
        //                                 "Code": "0080205",
        //                                 "Child": null
        //                             }
        //                         ]
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b1fa42485c7f295fcb81d4",
        //                 "ParentCode": "",
        //                 "Name": "Quầy giao dịch",
        //                 "Code": "009",
        //                 "Child": [
        //                     {
        //                         "ID": "66b1fa9c485c7f295fcb81d5",
        //                         "ParentCode": "009",
        //                         "Name": "Kết nối Counter cho quầy giao dịch",
        //                         "Code": "0090101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b1facf485c7f295fcb81d6",
        //                         "ParentCode": "009",
        //                         "Name": "Kết nối Tablet cho quầy giao dịch",
        //                         "Code": "0090102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b1fade485c7f295fcb81d7",
        //                         "ParentCode": "009",
        //                         "Name": "Tùy chỉnh quầy giao dịch",
        //                         "Code": "0090103",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b1faef485c7f295fcb81d8",
        //                         "ParentCode": "009",
        //                         "Name": "Thêm quầy giao dịch",
        //                         "Code": "0090104",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b1fb02485c7f295fcb81d9",
        //                         "ParentCode": "009",
        //                         "Name": "Thoát quầy",
        //                         "Code": "0090105",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b1fb11485c7f295fcb81da",
        //                         "ParentCode": "009",
        //                         "Name": "Xóa quầy",
        //                         "Code": "0090106",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5d5ae3a7535aa13f49832",
        //                         "ParentCode": "009",
        //                         "Name": "Danh sách quầy",
        //                         "Code": "0090107",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2d107fd58588644cbb054",
        //                 "ParentCode": "",
        //                 "Name": "QR điểm giao dịch",
        //                 "Code": "010",
        //                 "Child": [
        //                     {
        //                         "ID": "66b2d137fd58588644cbb055",
        //                         "ParentCode": "010",
        //                         "Name": "Xem QR",
        //                         "Code": "0100101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d151fd58588644cbb056",
        //                         "ParentCode": "010",
        //                         "Name": "Tạo mã QR",
        //                         "Code": "0100102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d175fd58588644cbb057",
        //                         "ParentCode": "010",
        //                         "Name": "Tải xuống QR",
        //                         "Code": "0100103",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2d221fd58588644cbb058",
        //                 "ParentCode": "",
        //                 "Name": "Dữ liệu Sinh Trắc Học",
        //                 "Code": "011",
        //                 "Child": [
        //                     {
        //                         "ID": "66b2d249fd58588644cbb059",
        //                         "ParentCode": "011",
        //                         "Name": "Duyệt STH",
        //                         "Code": "0110101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d25bfd58588644cbb05a",
        //                         "ParentCode": "011",
        //                         "Name": "Từ chối STH",
        //                         "Code": "0110102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d26cfd58588644cbb05b",
        //                         "ParentCode": "011",
        //                         "Name": "Xem dữ liệu STH",
        //                         "Code": "0110103",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b2d28ffd58588644cbb05c",
        //                 "ParentCode": "",
        //                 "Name": "Xác thực KH",
        //                 "Code": "012",
        //                 "Child": [
        //                     {
        //                         "ID": "66b2d2affd58588644cbb05d",
        //                         "ParentCode": "012",
        //                         "Name": "Xem danh sách xác thực KH",
        //                         "Code": "0120101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b2d2cbfd58588644cbb05e",
        //                         "ParentCode": "012",
        //                         "Name": "Duyệt STH của khách hàng",
        //                         "Code": "0120102",
        //                         "Child": null
        //                     }
        //                 ]
        //             }
        //         ],
        //         "FeaturesCounter": [
        //             {
        //                 "ID": "66b5e30b3a7535aa13f49833",
        //                 "ParentCode": "",
        //                 "Name": "Xử lý giao dịch",
        //                 "Code": "050",
        //                 "Child": [
        //                     {
        //                         "ID": "66b5e3353a7535aa13f49834",
        //                         "ParentCode": "050",
        //                         "Name": "Danh sách hàng đợi/nhỡ",
        //                         "Code": "0500101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e3493a7535aa13f49835",
        //                         "ParentCode": "050",
        //                         "Name": "Thực hiện GD",
        //                         "Code": "0500102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e35f3a7535aa13f49836",
        //                         "ParentCode": "050",
        //                         "Name": "Gọi lại",
        //                         "Code": "0500103",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e36f3a7535aa13f49837",
        //                         "ParentCode": "050",
        //                         "Name": "Chuyển vào gọi nhỡ",
        //                         "Code": "0500104",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e37e3a7535aa13f49838",
        //                         "ParentCode": "050",
        //                         "Name": "Chuyển quầy",
        //                         "Code": "0500105",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b5e3a33a7535aa13f49839",
        //                 "ParentCode": "",
        //                 "Name": "Lấy vé",
        //                 "Code": "051",
        //                 "Child": [
        //                     {
        //                         "ID": "66b5e3c03a7535aa13f4983a",
        //                         "ParentCode": "051",
        //                         "Name": "Lấy số xếp hàng",
        //                         "Code": "0510101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e3ce3a7535aa13f4983b",
        //                         "ParentCode": "051",
        //                         "Name": "Lấy số KH online",
        //                         "Code": "0510102",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b5e47f3a7535aa13f4983c",
        //                 "ParentCode": "",
        //                 "Name": "Xử lý STH cho khách hàng",
        //                 "Code": "052",
        //                 "Child": [
        //                     {
        //                         "ID": "66b5e4953a7535aa13f4983d",
        //                         "ParentCode": "052",
        //                         "Name": "Thêm STH",
        //                         "Code": "0520101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e4a23a7535aa13f4983e",
        //                         "ParentCode": "052",
        //                         "Name": "Xóa STH",
        //                         "Code": "0520102",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e4af3a7535aa13f4983f",
        //                         "ParentCode": "052",
        //                         "Name": "Thay đổi STH",
        //                         "Code": "0520103",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e4bc3a7535aa13f49840",
        //                         "ParentCode": "052",
        //                         "Name": "Xem STH",
        //                         "Code": "0520104",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66b5e5c33a7535aa13f49841",
        //                 "ParentCode": "",
        //                 "Name": "Tư vấn KH",
        //                 "Code": "053",
        //                 "Child": [
        //                     {
        //                         "ID": "66b5e5ee3a7535aa13f49842",
        //                         "ParentCode": "053",
        //                         "Name": "Danh sách khách hàng hàng đợi/đặt lịch online",
        //                         "Code": "0530101",
        //                         "Child": null
        //                     },
        //                     {
        //                         "ID": "66b5e5fd3a7535aa13f49843",
        //                         "ParentCode": "053",
        //                         "Name": "Thực hiện tư vấn",
        //                         "Code": "0530102",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66bc8df7c6996eb6ac2ff4b2",
        //                 "ParentCode": "",
        //                 "Name": "Tìm kiếm KH mới",
        //                 "Code": "054",
        //                 "Child": [
        //                     {
        //                         "ID": "66b5e61f3a7535aa13f49844",
        //                         "ParentCode": "054",
        //                         "Name": "Tìm kiếm KH mới",
        //                         "Code": "0540101",
        //                         "Child": null
        //                     }
        //                 ]
        //             },
        //             {
        //                 "ID": "66bc8e0dc6996eb6ac2ff4b3",
        //                 "ParentCode": "",
        //                 "Name": "Quản lý Tablet",
        //                 "Code": "055",
        //                 "Child": [
        //                     {
        //                         "ID": "66b5e6643a7535aa13f49845",
        //                         "ParentCode": "055",
        //                         "Name": "Quản lý Tablet",
        //                         "Code": "0550101",
        //                         "Child": null
        //                     }
        //                 ]
        //             }
        //         ]
        //     }
        // }
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message,
                result: []
            })
        } else {
            let dataSetting = {
                statusList: []
            }
            //---
            if(isArray(data.StatusList)){
                dataSetting.statusList = data.StatusList.map((item) => {
                    return {
                        code: item.Code,
                        value: item.Value
                    }
                })
            }
            //---
            if(dataSetting.statusList.length === 0){
                dataSetting.statusList = [
                    {
                        code: 'A',
                        value: 'Đang hoạt động'
                    },
                    {
                        code: 'INA',
                        value: 'Không hoạt động'
                    }
                    
                ]
            }
            //---
            onCompleted && onCompleted({
                code: 0,
                result: dataSetting
            })
        }
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_COUNTER_SERVICE_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const addGroupCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        Name: dataInfo.name,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.serviceCode,
        Type: dataInfo.type
    })
    console.log(`addGroupCounterService: dataReq => ${dataReq}`);
    try {
        const res = await Axios.post(apiAddGroupCounterService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.CREATE_GROUP_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}

export const updateGroupCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        ID: dataInfo.id,
        Name: dataInfo.name,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.serviceCode
    })
    console.log(`updateGroupCounterService: dataReq => ${dataReq}`);
    
    try {
        const res = await Axios.post(apiUpdateGroupCounterService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
        //---
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_GROUP_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}

export const addCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        Name: dataInfo.name,
        ServiceCode: dataInfo.serviceCode,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.groupCode
    })
    console.log(`addCounterService: dataReq => ${dataReq}`);
    try {
        const res = await Axios.post(apiAddCounterService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.CREATE_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}

export const updateCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        ID: dataInfo.id,
        Name: dataInfo.name,
        ServiceCode: dataInfo.serviceCode,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.groupCode
    })
    console.log(`updateCounterService: dataReq => ${dataReq}`);
    try {
        const res = await Axios.post(apiUpdateCounterService, dataReq);
        // const res = {
        //     data: {
        //         Code: 0,
        //         Message: 'OK'
        //     }
        // }
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
        //---
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}


