import React from 'react'
import { PopupErrorContainer, PopupErrorContent } from "./index.styles";
import ic_error from '../../../../images/ic_error_v2.svg'

const PopupError = ({isShowOverlay=true, width, content, note, listBranchCodeInvalid, onClosed }) => {
    // console.log("listBranchCodeInvalid", listBranchCodeInvalid);
    const textArea = listBranchCodeInvalid?.filter(item => item)
    return (
        <PopupErrorContainer isShowOverlay={isShowOverlay}>
            <PopupErrorContent width={width}>
                <img src={ic_error} alt="ic_error" className="icon" />
                <div className="contentText">{content}</div>
                {
                    note !== null && note !== undefined && note !== "" &&
                    <div className="noteText">{note}</div>
                }
                {
                    (listBranchCodeInvalid?.length !== 0) && listBranchCodeInvalid &&
                    <div className='list-branchCode'>
                        <p className='title-err'>* Danh sách Mã CN/ĐGD không trùng khớp</p>
                        <textarea
                            className='content-err'
                            wrap="hard"
                            value={textArea}>
                        </textarea>
                    </div>
                }
                <button className='button' onClick={onClosed}>Đã hiểu</button>
            </PopupErrorContent >
        </PopupErrorContainer >
    );
}

export default PopupError;