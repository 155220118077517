/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import FillCif from '../../controls/Tools/FillCif'

// style
import { extendStylesTable } from './index.styles'
import {
    GridColTools,
    WrapGridTools,
} from '../../controls/Tools/index.styles'

// action
import {
    getHistoryCusChangeRegBio,
    exportHistoryCusChangeRegBio,
} from '../../../../../../../actions/reportsAction/detailsReport/biometrics/customerBioHistoriesDetails'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// helper
import getChannelName from '../../../../../../../helper/getChannelName'

const lstTitleCols = [
    'mã CN', //115
    'Tên CN', //290
    'số CIF', //170
    'tên khách hàng', //260
    'Loại KH', //170
    'ngày giao dịch', //170
    'kênh tác nghiệp', //170
    "GDV/Điện toán", //170
    "KSV/BGĐ", //170
    'loại tác nghiệp', //450
    'Lý do không đồng ý', //500
]
const INDEX_KENH_TAC_NGHIEP = 6
const INDEX_LOAI_TAC_NGHEP = 9
const INDEX_LY_DO_KO_DONG_Y = 10
const VALUE_BREAK_LINE = '<br>'

const ReportHistoryCusChangeRegBio = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            transaction: null,
            service: null,
            cif: '',
        }
    )

    const [dataTable, setDataTable] = useState([])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, cif } = stateFilter
        dispatch(
            getHistoryCusChangeRegBio({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                BranchName: 'Tất cả',
                BranchIDs: [],
                CustomerNumber: !!cif ? cif.trim() : undefined,
            })
        )
    }, [stateFilter])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, cif } = stateFilter
        dispatch(
            exportHistoryCusChangeRegBio({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                BranchName: 'Tất cả',
                BranchIDs: [],
                CustomerNumber: !!cif ? cif.trim() : undefined,
            })
        )
    }, [stateFilter])

    useDidMount(() => {
        dispatch(
            getHistoryCusChangeRegBio({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
                BranchName: 'Tất cả',
                BranchIDs: [],
            })
        )
        return true
    }, [stateFilter.office])

    useEffect(() => {
        const dataTable = detailsReport.listData.map((item) => {
            const channel = item[INDEX_KENH_TAC_NGHIEP]
            item[INDEX_KENH_TAC_NGHIEP] = getChannelName(channel)

            item[INDEX_LOAI_TAC_NGHEP] = (
                <div className="limit-length" title={item[INDEX_LOAI_TAC_NGHEP]}>
                    {item[INDEX_LOAI_TAC_NGHEP]}
                </div>
            )

            const transInfos = item[INDEX_LY_DO_KO_DONG_Y]
            item[INDEX_LY_DO_KO_DONG_Y] = (
                <>
                    {transInfos.split(VALUE_BREAK_LINE).map((line, key) => (
                        <div key={key} className="limit-length" title={line}>
                            {line}
                        </div>
                    ))}
                </>
            )
            return item
        })
        setDataTable(dataTable)
    }, [detailsReport.listData])

    return (
        <div>
            <Header title={title} />
            <WrapGridTools numOfCol={5}>
                <GridColTools templateRow="none">
                    <SelectStartDate
                        defaultValue={stateFilter.fromDate}
                        onChange={(fromDate) => dispatchFilter({ fromDate })}
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <FillCif
                        defaultValue={stateFilter.cif}
                        onChange={(cif) => dispatchFilter({ cif })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isEnableCheckRangeDate={false}
                    />
                    <ExportExcel onClick={_handleExportExcel} />
                </GridColTools>
            </WrapGridTools>
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
                numOfRow={9}
            />
        </div>
    )
}

export default ReportHistoryCusChangeRegBio
