import React, { useEffect, useState } from 'react'
import { RowContainer } from './index.styles'
import { Tooltip } from '@mui/material'
import ic_more_gray from "../../../../../../../images/ic_more_gray.svg";

export default function AdviseServiceItem({ isHeader, index, dataConfig, dataInfo, isSelected, selectedBgColor, onItemSelected, onItemDoubleClick, onShowOptionPopup}) {
  const [selectedStatus, setSelectedStatus] = useState(isSelected)
  //---
  const showOptionPopup = (index, dataInfo) => {
    onShowOptionPopup && onShowOptionPopup(index, dataInfo);
  }
  //---
  const renderHeader = () => {
    return (
      <RowContainer isHeader={true}>
        <div className="column">STT</div>
        <div className="column">TÊN DỊCH VỤ TƯ VẤN</div>
        <div className="column">MÃ DỊCH VỤ</div>
        <div className="column">MÔ TẢ DỊCH VỤ TƯ VẤN</div>
        <div className="column">TRẠNG THÁI HOẠT ĐỘNG</div>
        <div className="column">NGÀY TẠO</div>
        <div className="column"/>
      </RowContainer>
    )
  }

  /*
    name,
    type,
    descript,
    status,
    createAt
   */

  const renderItem = (index, dataInfo) => {
    const onClickAction = () => {
      onItemSelected && onItemSelected(index, dataInfo)
      //---
      setSelectedStatus(true)
    }
    const onDoubleClickAction = () => {
      onItemDoubleClick && onItemDoubleClick(dataInfo)
    }
    const indexValue = index < 10 ? `0${index}` : index
    return (
      <RowContainer selectedStatus={selectedStatus} selectedBgColor={selectedBgColor} isHeader={false} onClick={onClickAction} onDoubleClick={onDoubleClickAction}>
        <div className="column">{indexValue}</div>
        <div className="column">{dataInfo.name}</div>
        <div className="column">{dataInfo.type}</div>

        <Tooltip title={dataInfo.descript}>
          <div className="column">{dataInfo.descript}</div>
        </Tooltip>
        
        <div className="column">{getStatusDecription(dataInfo.status)}</div>
        <div className="column">{dataInfo.createAt}</div>
        <div className="column buttonControl">
          <img
            src={ic_more_gray}
            className="more-button"
            id={"more_button_id_" + index}
            alt="ic_more"
            onClick={() => {
              showOptionPopup(index, dataInfo);
            }}
          />
        </div>
      </RowContainer>
    )
  }

  const getStatusDecription = (statusCode) => {
    let statusInfo = dataConfig.statusList.find(data=>data.code===statusCode) 
    if(statusInfo){
      return statusInfo.value
    }
    return statusCode
  }
  useEffect(() => {
    setSelectedStatus(isSelected)
  }, [isSelected])

  return (
    isHeader
      ? renderHeader()
      : renderItem(index, dataInfo)
  )
}
