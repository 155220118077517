const isArray = (obj)=>{
    return !!obj && obj.constructor === Array;
}

const isObject = (obj)=>{
    return !!obj && Object.keys(obj).length > 0;
}

const isDate = (dateStr)=>{
    return !isNaN(new Date(dateStr).getTime());
  }

const isNumber = (value)=>{
    const numberCheck = /^[0-9]/;
    return numberCheck.test(value)
}

export {
    isArray,
    isObject,
    isDate,
    isNumber
}