import styled from "styled-components";
import { color } from "../../../../../../data/store";

const heightItem = 46
const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width?props.width:280}px;
  height: auto;
  padding: 4px 0px;
  border-radius: 6px;
  max-height:400px;
  background-color: #fff;
  border: solid ${props => props.contentStyle.borderWidth}px #c8c8c8;
  overflow: auto;
  z-index: 10;

  .footer {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 25px;
    margin-right: 12px;
    margin-bottom: 5px;

    .btn {
      cursor: pointer;
      font-family: 'SVN-Gilroy';  
      font-size: 12px;
      font-weight: 600;

      &.blue{
        color: ${color.blue_005993};
      }

      &.red{
        color: #D71249;
      }
    }
  }


`

const ContainerStyle = styled.div`
  position:absolute;
  top:${props => props.index?(props.index * heightItem):0}px;
  left:calc(100% + 5px);
  z-index: 10;
  width: ${props => props.width?props.width:280}px;
  height: auto;
`

export {ContainerStyle,  ContentStyle}