import * as types from "../../actions/types";
// import { ADMIN_OFFICE_ROLE, ADMIN_ROLE } from "../../data/userRole";

const initialState = {
  listUser: [],
  total: 0,
  success: false,
  // dataLogin: {
  //   "Code": 0,
  //   "UID": "65b1d97abe361cb2341ce5c5",
  //   "Message": "login successed",
  //   "FullName": "admin3",
  //   "Roles": [
  //       'ADMIN'
  //   ],
  //   "CsrfToken": "7aadc34180c33be9644bd65f3514a1a9f892e980066a68fe228c6b487976519bfe4916c562228215ddced49c29b17bc9fb7aa1d138da571c1b6ba4eebd8b8d29",
  //   "ExpireAt": "2024-09-30T02:13:39.428511756+07:00",
  //   "HasKioskDeviceConfig": false,
  //   "HasLcdDeviceConfig": false,
  //   "HasTabletkDeviceConfig": false
  // },
  // dataLogin: {
  //   "Code": 0,
  //   "Token": "ef8e4211aa3ab71a440bf20d9b3019898eee9cb7d04c7ebe",
  //   "UID": "63fc6da3b25ba66efed09007",
  //   "OfficeID": "63fc6b2eb25ba66efed09003",
  //   "Message": "login successed",
  //   "Email": "adminoffice1",
  //   "FullName": "Tenadminoffice1",
  //   "Roles": [
  //       "OFFADMIN",
  //       "RPW"
  //   ],
  //   "OfficeName": "Chi nhánh Wee",
  //   "OfficeAddress": "b20 bạch đằng",
  //   "OfficeType": "BRANCH",
  //   "OfficeProvince": "79",
  //   "OfficeDistrict": "766",
  //   "OfficeWard": "26965",
  //   "CsrfToken": "db5e50a48eb5bd146d58c61e4d4f76af5e48b87b7f27dc9d63d81c6e6ef45107a5b03da2e4d167648ef0cdc1973ae6391ae8b81a57aeed259d1b58fcb5847997",
  //   "BranchCode": "10198",
  //   "ExpireAt": "2024-09-30T01:05:02.572988778+07:00",
  //   "HasKioskDeviceConfig": true,
  //   "HasLcdDeviceConfig": true,
  //   "HasTabletkDeviceConfig": true
  // },
  dataLogin:{},
  err: '',
  userInfoFromBank: {},
  lstUserFromBank: [],
  alert: '',
  lastConditionFilterUser: {},
  textSearch: '',
  saveUser: {}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ERR: {
      if (action.reducerTarget === "userReducer") {
        if (!state.err) return state;
        return {
          ...state,
          err: ''
        }
      }
      return state;
    }
    case types.ALERT_CONTENT:
      return {
        ...state,
        alert: action.alert
      }
    case types.ADD_USER:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.ADD_USER_ERR:
      return {
        ...state,
        err: {
          ...action.err
        }
      }
    case types.UPDATE_USER:
      return {
        ...state,
        err: ''
      }
    case types.UPDATE_USER_ERR:
      return {
        ...state,
        err: {
          ...action.err
        }
      }
    case types.DELETE_USER:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.DELETE_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.FORCE_SIGN_OUT_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.FILTER_USER:
      return {
        ...state,
        listUser: action.listUser,
        total: action.total,
        lastConditionFilterUser: action.lastConditionFilterUser,
        err: ''
      }
    case types.FILTER_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.LOGIN_USER:
      return {
        ...state,
        dataLogin: action.data,
        err: ''
      }
    case types.LOGIN_USER_ERR:
      return {
        ...state,
        err: {
          ...state.err,
          ...action.err
        }
      }
    case types.LOGOUT_USER:
      return {
        ...state,
        dataLogin: {},
        err: ''
      }
    case types.LOGOUT_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.SEARCH_USER_TO_ADD_BY_EMAIL:
      return {
        ...state,
        userInfoFromBank: action.userInfoFromBank,
        lstUserFromBank: [action.userInfoFromBank],
        err: ''
      }
    case types.SEARCH_USER_TO_ADD_BY_EMAIL_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.SAVE_TEXT_SEARCH:
      return {
        ...state,
        textSearch: action.textSearch
      }
    case types.CLEAR_TEXT_SEARCH:
      return {
        ...state,
        textSearch: ''
      }
    case types.SAVE_USER_PASS:
      return {
        ...state,
        saveUser: action.dataSave
      }
    case types.SAVE_USER_PASS_ERR:
      return {
        ...state,
        saveUser: ''
      }

    default:
      return state
  }
}