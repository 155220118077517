import React, { Component } from 'react'
import styled from 'styled-components'

import icon_down from '../../../../images/ic_arrow_down_gray.svg'
// helper
import sortObjectByKey from '../../../../helper/sortObjectByKey'
import { color } from '../../../../data/store'
const BoundStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* margin-bottom: 20px; */
    position: relative;
    width: ${(props) => props.widthControl || 'unset'};
    opacity: ${(props) => (props.isDisable ? '0.5' : 'unset')};
    .title-text {
        font-family: 'SVN-Gilroy';
        font-size: ${props => props.titleFontSize??11}px;
        font-weight: ${props => props.titleFontWeight??'bold'};   
        color: ${color.blue_005993};
        text-transform: uppercase;
        margin-bottom: 5px;
        .title-notice{
            color: #F31919;
            margin-left: 3px;
        }
    }
    .dropdown-control {
        outline: none;
        font-family: 'SVN-Gilroy';
        padding: 0 35px 0 10px;
        height: ${props => props.heightControl??44}px;
        background-color: ${props => props.backgroundColor??color.whiteFF};
        border: solid ${props => props.borderWidth??0.5}px #c8c8c8;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-align: left;
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;
        white-space: nowrap;
        background-image: url(${icon_down});
        background-repeat: no-repeat;
        background-position: top 20px right 17px;
        position: relative;
        .titleChoise {
            cursor: default;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: ${props => props.textFontSize??14}px;
            font-weight: ${props => props.textFontWeight??500};  
        }
        .blockContent {
            display: flex;
            flex-direction: column;
        }
        .content {
            position: absolute;
            min-height: 40px;
            max-height: 160px;
            overflow: auto;
            top: 100%;
            width: 100%;
            margin-top: 2px;
            border-radius: 4px;
            border: solid 0.5px #c8c8c8;
            left: 0;
            border-radius: 4px;
            background-color: #ffffff;
            z-index: 999;
            box-shadow: 0px 0px 2px #fafafa;
            ::-webkit-scrollbar {
                width: 5px;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #c4c4c4;
                border-radius: 10px;
            }
            .item {
                padding: 10px;
                cursor: default;
                font-size: ${props => props.textFontSize??14}px;
                font-weight: ${props => props.textFontWeight??500};  
                &:hover {
                    background-color: ${color.blue_005993};
                    color: #fff;
                }
            }
        }
    }
    .dropdown-menu {
        font-family: 'SVN-Gilroy';
        display: flex;
        flex: 1;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #ffffff;
        width: 100%;
        padding: 10px 0;
        .menu {
            font-family: 'SVN-Gilroy';
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            font-size: ${props => props.textFontSize??14}px;
            font-weight: ${props => props.textFontWeight??500};   
            color: #000000;
            cursor: pointer;
        }
    }
    select:required:invalid {
        color: gray;
    }
    option[value=''][disabled] {
        display: none;
    }
`
export default class SingleChoiceDropDownControl extends Component {
    state = {
        data: this.props.data,
        item: this.props.defaultValue ? this.props.defaultValue : null,
    }

    componentWillReceiveProps(n) {
        if (n && n.defaultValue) {
            this.setState({ item: n.defaultValue })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            if (
                this.props.data &&
                this.state.data &&
                typeof this.props.data[0] === 'object' &&
                typeof this.state.data[0] === 'object'
            ){
                let propDataJSON = JSON.stringify(this.props.data)
                let stateDataJSON = JSON.stringify(this.state.data)
                if(!this.props.isNotSort){
                    propDataJSON = JSON.stringify(
                        this.props.data.sort(sortObjectByKey('name'))
                    )
                    stateDataJSON = JSON.stringify(
                        this.state.data.sort(sortObjectByKey('name'))
                    )
                }
                //---
                if(propDataJSON === stateDataJSON){
                    return
                }
            }
            if (prevProps.data && prevProps.data.length === 0) {
                const { data } = this.props
                this.setState({
                    data,
                })
                return
            }
            const { data } = this.props
            this.setState({
                data,
                item: '',
            })
        }
    }
    isMouseEnter = false
    handleChange = (e) => { }
    choiseText = (item) => {
        if (typeof item === 'object' && item !== null) {
            this.setState({ item: item.name })
        } else {
            // debugger
            this.setState({ item: item })
        }

        this.props.getText(item)
    }

    checkShow = () => this.props.idShow === this.props.id

    onClick = () => {
        if (this.props.isDisable) return

        if (this.checkShow()) {
            this.props.setIdShow('')
        } else {
            this.props.setIdShow(this.props.id)
        }
    }
    render() {
        const { notSort, isNotSort } = this.props
        let stateData = this.state.data
        if(!isNotSort){
            if(this.state.data && this.state.data.length > 0) {
                if(typeof this.state.data[0] === 'object'){
                    stateData = this.state.data.sort(
                                                        notSort
                                                            ? undefined
                                                            : sortObjectByKey(
                                                                'name'
                                                            )
                                                    )
                } else {
                    stateData = this.state.data.sort((a, b) => a - b)
                }
            }
        }

        return (
            <BoundStyle
                className="singlechoise"
                heightControl={this.props.heightControl}
                widthControl={this.props.setWidth}
                isDisable={this.props.isDisable}
                backgroundColor={this.props.backgroundColor}
                borderWidth={this.props.borderWidth}
                titleFontSize={this.props.titleFontSize}
                titleFontWeight={this.props.titleFontWeight}
                textFontSize={this.props.textFontSize}
                textFontWeight={this.props.textFontWeight}
            >
                <p className="title-text">
                    {this.props.title}
                    {this.props.isRequired && <span className='title-notice'>*</span>}
                </p>
                <React.Fragment>
                    <div
                        className="dropdown-control"
                        onClick={() => this.onClick()}
                        tabIndex="0"
                        onBlur={() => this.props.setIdShow('')}
                    >
                        <div className="titleChoise">
                            {this.state.item
                                ? this.state.item
                                : this.props.titleChoise}
                        </div>
                        {this.state.data && this.state.data.length > 0 && (
                            <div className="blockContent">
                                {this.props.idShow === this.props.id && (
                                    <div className="content">
                                        {this.state.data &&
                                            this.state.data.length > 0
                                            ? typeof this.state.data[0] ===
                                                'object'
                                                ? stateData.map((item, index) => (
                                                        <div
                                                            onClick={() =>
                                                                this.choiseText(
                                                                    item
                                                                )
                                                            }
                                                            className="item"
                                                            key={index}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    ))
                                                : stateData.map((item, index) => (
                                                        <div
                                                            onClick={() =>
                                                                this.choiseText(
                                                                    item
                                                                )
                                                            }
                                                            className="item"
                                                            key={index}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))
                                            : null}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            </BoundStyle>
        )
    }
}
