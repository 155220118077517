import styled from "styled-components";
import { color } from "../../../../../data/store";

const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 4px 0px;
  border-radius: 6px;
  /* max-height:${props => props.contentStyle.maxHeight}px; */
  background-color: transparent;
  border: solid ${props => props.contentStyle.borderWidth}px #c8c8c8;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 


`

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width?props.width:'100%'};
  height: auto;

  .headerContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18px;
    padding: 12px 0;

    .label{
        font-size: 14px;
        font-weight: 700;
        color: ${color.blue_005993};
        text-transform: uppercase;
    }

    .checkBoxContent{
      display: flex;
      height: 100%;
      justify-content: end;
      cursor: pointer;
      .value {
        color: #000;
        font-family: 'SVN-Gilroy';
        font-weight: 500;
        font-size: 14px;
        margin-right: 2px;
        margin-top: 3px;
        overflow: hidden;
        padding-left: 5px;
        align-items: end;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
`

export {ContainerStyle,  ContentStyle}