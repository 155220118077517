import Axios from 'axios';
import { host } from "../host";
import * as types from './types';
import {
    addLoading,
    removeLoading,
} from './commonAction'
import catchErrorApi from "../helper/catchErrorApi";
const apiGetConfig = host + "admin/office/booking-online/config-getting";
const apiSetGeneralConfig = host + "admin/office/booking-online/general-config-setting";
const apiGetOfficesConfig = host + "admin/office/booking-online/offices-config-getting";
const apiSetOfficesConfig = host + "admin/office/booking-online/offices-config-setting";
const apiFilterOfficesConfig = host + "admin/office/booking-online/offices-config/filter";

export const getConfig = ({onCompleted}) => async (dispatch) => {
    try {
        dispatch(addLoading())
        //---
        const res = await Axios.get(apiGetConfig);
        const { data } = res;
        const {GeneralConf: generalConf, TimeBlockConf: timeBlockConf} = data;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message,
            result: {
                generalConf: {
                    dataInfo: {
                        bookingByPerSessions: generalConf.BookingByPerSessions,
                        bookingLimit: generalConf.BookingLimit,
                        timeBookingPreset: generalConf.TimeBookingPreset,
                        issueTicketTimeEarly: generalConf.IssueTicketTimeEarly,
                        issueTicketTimeLate: generalConf.IssueTicketTimeLate,
                    },
                    defaultConfig: {
                        bookingByPerSessions: {
                            min: generalConf.BookingByPerSessionsMinValid,
                            max: generalConf.BookingByPerSessionsMaxValid
                        },
                        bookingLimit: {
                            min: generalConf.BookingLimitMinValid,
                            max: generalConf.BookingLimitMaxValid,
                        },
                        timeBookingPreset:  {
                            min: generalConf.TimeBookingPresetMinValid,
                            max: generalConf.TimeBookingPresetMaxValid,
                        },
                        issueTicketTimeEarly:  {
                            min: generalConf.IssueTicketTimeEarlyMinValid,
                            max: generalConf.IssueTicketTimeEarlyMaxValid,
                        },
                        issueTicketTimeLate:  {
                            min: generalConf.IssueTicketTimeLateMinValid,
                            max: generalConf.IssueTicketTimeLateMaxValid,
                        },
                    }
                },
                timeBlockConf: {
                    timeBlock8hTo8h30: {
                        min: timeBlockConf.TimeBlock8hToh8h30MinValid,
                        max: timeBlockConf.TimeBlock8hToh8h30MaxValid,
                    },
                    timeBlock8h30To9h: {
                        min: timeBlockConf.TimeBlock8h30To9hMinValid,
                        max: timeBlockConf.TimeBlock8h30To9hMaxValid,
                    },
                    timeBlock9hTo9h30: {
                        min: timeBlockConf.TimeBlock9hTo9h30MinValid,
                        max: timeBlockConf.TimeBlock9hTo9h30MaxValid,
                    },
                    timeBlock9h30To10h: {
                        min: timeBlockConf.TimeBlock9h30To10hMinValid,
                        max: timeBlockConf.TimeBlock9h30To10hMaxValid,
                    },
                    timeBlock10hTo10h30: {
                        min: timeBlockConf.TimeBlock10hTo10h30MinValid,
                        max: timeBlockConf.TimeBlock10hTo10h30MaxValid,
                    },
                    timeBlock10h30To11h: {
                        min: timeBlockConf.TimeBlock10h30To11hMinValid,
                        max: timeBlockConf.TimeBlock10h30To11hMaxValid,
                    },
                    timeBlock13h30To14h: {
                        min: timeBlockConf.TimeBlock13h30To14hMinValid,
                        max: timeBlockConf.TimeBlock13h30To14hMaxValid,
                    },
                    timeBlock14hTo14h30: {
                        min: timeBlockConf.TimeBlock14hTo14h30MinValid,
                        max: timeBlockConf.TimeBlock14hTo14h30MaxValid,
                    },
                    timeBlock14h30To15h: {
                        min: timeBlockConf.TimeBlock14h30To15hMinValid,
                        max: timeBlockConf.TimeBlock14h30To15hMaxValid,
                    },
                    timeBlock15hTo15h30: {
                        min: timeBlockConf.TimeBlock15hTo15h30MinValid,
                        max: timeBlockConf.TimeBlock15hTo15h30MaxValid,
                    },
                    timeBlock15h30To16h: {
                        min: timeBlockConf.TimeBlock15h30To16hMinValid,
                        max: timeBlockConf.TimeBlock15h30To16hMaxValid,
                    }
                }
            },
        })
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_GENERAL_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const setGeneralConfig = ({generalConfig, onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        MaxBookingByPerSessions: generalConfig.bookingByPerSessions,
        MaxBooking: generalConfig.bookingLimit,
        MinTimeBookingPreset: generalConfig.timeBookingPreset,
        IssueTicketTimeEarly: generalConfig.issueTicketTimeEarly,
        IssueTicketTimeLate: generalConfig.issueTicketTimeLate
    })
    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiSetGeneralConfig, queryData);
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message
            })
        } else {
            onCompleted && onCompleted({code: 0})
        }
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.SET_GENERAL_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const getOfficesConfig = ({onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        PageNumber: 0,
        PageSize: 0
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiGetOfficesConfig, queryData);
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message,
                result: []
            })
        } else {
            let officesConfigList = []
            if(data.Configs && data.Configs.length > 0){
                officesConfigList = data.Configs.map((item) => {
                    return {
                        branchCode: item.BranchCode,
                        name: item.Name,
                        time01: item.TimeBlock8hToh8h30,
                        time02: item.TimeBlock8h30To9h,
                        time03: item.TimeBlock9hTo9h30,
                        time04: item.TimeBlock9h30To10h,
                        time05: item.TimeBlock10hTo10h30,
                        time06: item.TimeBlock10h30To11h,
                        time07: item.TimeBlock13h30To14h,
                        time08: item.TimeBlock14hTo14h30,
                        time09: item.TimeBlock14h30To15h,
                        time10: item.TimeBlock15hTo15h30,
                        time11: item.TimeBlock15h30To16h,
                    }
                })
            }
            //---
            onCompleted && onCompleted({
                code: 0,
                result: officesConfigList
            })
        }
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_OFFICES_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const setOfficesConfig = ({dataConfig, onCompleted}) => async (dispatch) => {
    const newDataConfig = dataConfig.map((item) => {
        return {
            BranchCode: item.branchCode,
            Name: item.name,
            TimeBlock8hToh8h30: item.time01,
            TimeBlock8h30To9h: item.time02,
            TimeBlock9hTo9h30: item.time03,
            TimeBlock9h30To10h: item.time04,
            TimeBlock10hTo10h30: item.time05,
            TimeBlock10h30To11h: item.time06,
            TimeBlock13h30To14h: item.time07,
            TimeBlock14hTo14h30: item.time08,
            TimeBlock14h30To15h: item.time09,
            TimeBlock15hTo15h30: item.time10,
            TimeBlock15h30To16h: item.time11,
        }
    })
    const queryData = JSON.stringify({
        Configs: newDataConfig
    })
    //----
    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiSetOfficesConfig, queryData);
        const { data } = res;
        //---
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message,
            BranchCodeInvalid: data?.BranchCodeInvalid
        })
       
    } catch (err) {
        const handleError400 = () => {
            const errData = err.response.data
            if (errData.Code !== 0) {
                onCompleted && onCompleted({
                    code: errData.Code,
                    message: errData.Message,
                    BranchCodeInvalid: errData?.BranchCodeInvalid
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.SET_OFFICES_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const filterOfficesConfig = ({dataKey, onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        Key: dataKey,
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiFilterOfficesConfig, queryData);
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message,
                result: []
            })
        } else {
            let officesConfigList = []
            if(data.Configs && data.Configs.length > 0){
                officesConfigList = data.Configs.map((item) => {
                    return {
                        branchCode: item.BranchCode,
                        name: item.Name,
                        time01: item.TimeBlock8hToh8h30,
                        time02: item.TimeBlock8h30To9h,
                        time03: item.TimeBlock9hTo9h30,
                        time04: item.TimeBlock9h30To10h,
                        time05: item.TimeBlock10hTo10h30,
                        time06: item.TimeBlock10h30To11h,
                        time07: item.TimeBlock13h30To14h,
                        time08: item.TimeBlock14hTo14h30,
                        time09: item.TimeBlock14h30To15h,
                        time10: item.TimeBlock15hTo15h30,
                        time11: item.TimeBlock15h30To16h,
                    }
                })
            }
            //---
            onCompleted && onCompleted({
                code: 0,
                result: officesConfigList
            })
        }
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.FILTER_OFFICES_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}