/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container } from "./index.styled";
import { subHeaderData } from '../../../../data/data'
import { dataCity } from "../../../../data/VietNamData/DataCity";
import { dataDistrict } from "../../../../data/VietNamData/DataDistrict";
import { dataWard } from "../../../../data/VietNamData/DataWard";
import { useDispatch, useSelector } from "react-redux";
import CreateQRCode from "./CreateQRCode";
import ShowResult from "./ShowResult";

//---

import {createQRTransPoint, getQRTransPoint} from "../../../../actions/qrTransPointAction"

import PopupAlert from "../../Popup/PopupAlert";
import Modal from '../../../../helper/Modal';
import {officeUtils} from "../../../Utils/OfficeUtils";
import { isObject } from "../../../../helper/checkValidity";


export default function QRTransPointContainer () {
    const dispatch = useDispatch()

    const [headerData, setHeaderData] = useState(subHeaderData.qrTransPoint);

    const [alertData, setAlertData] = useState({
        isShow: false,
        mess: ""
    });

    const [qrCode, setQRCode] = useState("")

    const { generalOfficeSelected, dataLogin } = useSelector((state) => ({
        generalOfficeSelected: state.officeManagementReducer.generalOfficeSelected,
        dataLogin: state.userReducer.dataLogin
    }))

    useEffect(()=>{
        setHeaderData( {
            ...headerData,
            mainTitle: officeUtils.getOfficeName({
                dataLogin: dataLogin, 
                generalOfficeSelected: generalOfficeSelected
    
            }),
            subTitle: [
                dataLogin.OfficeAddress,
                dataWard[dataLogin.OfficeWard].name,
                dataDistrict[dataLogin.OfficeDistrict].name,
                dataCity[dataLogin.OfficeProvince].name
            ].join(", ")
        })
        //----
        if(isObject(generalOfficeSelected)){
            onGetQRCode()
        }
    },[generalOfficeSelected])

    const onCreateQRCode = async () => {
        console.log("onCreateQRCode: start");
        dispatch(createQRTransPoint({
            officeID: officeUtils.getOfficeID({
                dataLogin: dataLogin,
                generalOfficeSelected: generalOfficeSelected
            }),
            onCompletedFunc: (result)=>{
                if(result.errCode !== 0){
                    setAlertData({
                        isShow: true,
                        mess: "Tạo mã QR không thành công, hệ thống bị gián đoạn. Vui lòng thử lại!"
                    })
                } else {
                    setQRCode(result.qrCodeURL)
                }
            }
        }))
    }

    const onGetQRCode = async () => {
        console.log("onGetQRCode: start");
        dispatch(getQRTransPoint({
            officeID: officeUtils.getOfficeID({
                dataLogin: dataLogin,
                generalOfficeSelected: generalOfficeSelected
            }),
            onCompletedFunc: (result)=>{
                if(result.errCode !== 0){
                    setAlertData({
                        isShow: true,
                        mess: "Hệ thống bị gián đoạn. Vui lòng thử lại!"
                    })
                } else {
                    setQRCode(result.qrCodeURL)
                }
            }
        }))
    }

    return ( 
        <Container>
            {
                qrCode.trim() === ""
                ? <CreateQRCode
                    headerData={headerData}
                    onSubmited={onCreateQRCode}
                  />
                : <ShowResult
                    officeName={officeUtils.getOfficeName({
                        dataLogin: dataLogin, 
                        generalOfficeSelected: generalOfficeSelected
                    })}
                    qrCodeStr={qrCode.trim()}
                    onRefreshQRCode={onCreateQRCode}
                  />
            }
            {
                <Modal isShow={alertData.isShow}>
                    <PopupAlert
                        isCenter={true}
                        bgColor="rgba(0,0,0,0.6)"
                        title={alertData.mess}
                        outPopup={() => {
                            setAlertData({
                                isShow: false,
                                mess: ""
                            })
                        }}
                    />
                </Modal>
            }
        </Container>
     );
}