import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
// import { color } from '../../../data/store';
import parse from 'html-react-parser';
import { IFW_ROUTE_PATH } from '../../../data/data';
import { changeCountRoute } from '../../../actions/queryAction/common';
import { color } from '../../../data/store';

const Bound = styled.li`
    height: 60px;
    line-height: 60px;
    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        text-decoration: none;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.13;
        color: #fafafa;
        transition: opacity 0.5s;
        opacity: ${(props) => (props.isShow ? 1 : 0.7)};
        cursor: unset;
        .title-text {
            display: flex;
            align-items: center;
        }

        .num-data-not-view-yet {
            font-weight: 500;
            margin-right: 7px;
        }

        img {
            width: 24px;
            height: 24px;
            padding: 0 12px;
        }
        &:hover {
            background-color: ${(props) =>
                props.isShow ? color.blue_7ed3f7 : 'transparent'};
            transition: all 0.5s ease;
            cursor: ${(props) => (props.isShow ? 'pointer' : 'unset')};
        }
        &.menu-item.active {
            background-color: ${color.blue_7ed3f7};
            opacity: 1;
        }
    }
`

class MenuItem extends Component {
    state = {
        pathActive: this.props.pathActive,
        isShow:this.props.isShow,
        dataNotViewYet: 0
    }
    onActiveMenu() {
        this.props.onActiveMenu(this.props.data.key)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            pathActive:nextProps.pathActive,
            isShow:nextProps.isShow
        })
    }

    _handleClick = () => {
        const { isShow } = this.state
        const { key } = this.props.data
        if(!isShow)
            return null
        if(key === IFW_ROUTE_PATH) {
            this.props.changeCountRoute()
        }

        return this.onActiveMenu
    }

    render() {
        let {isShow} = this.state
        return (
            <Bound isShow={isShow}>
                <NavLink
                    to={
                        isShow
                            ? this.props.data.key
                            : document.location.pathname
                    }
                    onClick={this._handleClick}
                    // className={this.state.pathActive === this.props.data.key ? 'menu-item active' : 'menu-item'}
                    activeClassName="active"
                    className="menu-item"
                >
                    <div className="title-text">
                        <img
                            src={this.props.data.icon}
                            alt={this.props.data.content}
                        />
                        <div>{parse(`${this.props.data.content}`)}</div>
                    </div>
                    <span className="num-data-not-view-yet">{this.state.dataNotViewYet || ''}</span>
                </NavLink>
            </Bound>
        )
    }

    checkShowDataNotViewYet = () => {
        const { bioDataNotReview, verifyNotSeen } = this.props.commonReducer;
        
        if(this.props.data.key === '/biometric-data'){
            this.setState({ dataNotViewYet: bioDataNotReview })
            return;
        }
        
        if(this.props.data.key === '/customer-vertify') {
            this.setState({ dataNotViewYet: verifyNotSeen })
            return
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { bioDataNotReview, verifyNotSeen } = this.props.commonReducer
        const {
            bioDataNotReview: lastBioDataNotReview,
            verifyNotSeen: lastVerifyNotSeen,
        } = prevProps.commonReducer

        if (bioDataNotReview !== lastBioDataNotReview || verifyNotSeen !== lastVerifyNotSeen){
            this.checkShowDataNotViewYet()
        }
    }
    

    componentDidMount() {
        this.checkShowDataNotViewYet()
    }
    
}

const mapStateToProps = (state) => ({
    commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch) => ({
    changeCountRoute: () => dispatch(changeCountRoute())
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem)