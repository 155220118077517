import styled from "styled-components";

const PopupErrorContainer = styled.div`
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
    background-color: ${prop => prop.isShowOverlay?'rgba(0, 0, 0, 0.6)':'transparent'};
`

const PopupErrorContent = styled.div`
    width: ${prop => prop.width ? prop.width : '340px'};
	position: absolute;
	top: 40%;
	left: 50%;
	/* text-align: center; */
	border-radius: 10px;
  	background-color: #ffffff;
  	padding: 20px;
    font-family: "SVN-Gilroy";
    font-weight: 500;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    transform: translate(-40%, -50%);
    animation: fadeInConfirm .6s;
    @keyframes fadeInConfirm {
        0%   { opacity:.7; top: 25% }
        100% { opacity:1; top: 40% }
    }

    .icon {
        width: 52px;
        height: 52px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .contentText {
        font-size: 17px;
        color: var(--black);
        padding-bottom: 20px;
	    text-align: center;

    }

    .noteText {
        font-size: 14px;
        color: var(--vermillion);
        padding-bottom: 20px;
	    text-align: center;

    }
    .list-branchCode{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        padding-bottom: 20px;
        .title-err{
            font-size: 14px;
            color: var(--vermillion);
        }
        .content-err{
            padding: 10px;
            min-height: 70px;       
            font-size: 14px;
            font-weight: 500;
            font-family: 'SVN-Gilroy';
            line-height: 1.5;
            border-radius: 4px;
            border: solid 0.5px #c8c8c8;
            background-color: rgba(255,255,255,0.9);
            color: #222222;
            outline: unset;
            resize: none;
        }
    }

    .button {
  		text-transform: uppercase;
  		font-size: 13px;
		font-weight: bold;
		font-style: normal;
		color: #ffffff;
		outline: none;
		border: none;
		border-radius: 18px;
 		background-color: var(--peacock-blue);
 		width: 88px;
 		padding-top: 12px;
 		padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
 		:hover {
 			cursor: pointer;
 		}
  	} 
`

export { PopupErrorContainer, PopupErrorContent }

