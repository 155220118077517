/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef } from 'react'

// images
import ic_search_gray from '../../../../../images/ic_search_gray.png'
import ic_upload from '../../../../../images/ic_upload.svg'
import ic_search_white_outline from '../../../../../images/ic_search_white_outline.svg'

// styles
import * as styles from './index.styles'
import { WrapperSearch, WrapperResult } from './index.styles'

//component
import RowListResult from './RowListResult'
import ButtonControl from '../../../MainScreen/Control/ButtonControl'

const SearchUser = ({
    handleOnSearch,
    stateSearch,
    setStateSearch,
    initialStateConfigs,
    stateConfig,
    dispatchConfig,
    handleUploadFile
}) => {

    const inputSearchRef = useRef(null)

    const _handleOnChange = useCallback(
        (e) => {
            const { value } = e.target;

            setStateSearch({ email: value })
        },
        [],
    )

    const _handleClearData = () => {
        setStateSearch({ searchResult: null, email: '' })
        // dispatchConfig({ lstUserSelect: [] })
        dispatchConfig({ ...initialStateConfigs })
        const inputSearch = inputSearchRef.current;
        if (!inputSearch) return;
        inputSearch.value = ''
        inputSearch.focus()
    }

    const lst = stateSearch.searchResult || []

    return (
        <styles.Bound>
            <WrapperSearch>
                <div className="input-container">
                    <div className="wrap-search-box">
                        <div className="wrapper-input">
                            <img
                                src={ic_search_gray}
                                onClick={(e) => handleOnSearch(e)}
                                alt="search"
                            />
                            <input
                                onKeyPress={(e) => {
                                    if (e.charCode !== 13) return true
                                    handleOnSearch(e)
                                    return false
                                }}
                                value={stateSearch.email}
                                onChange={_handleOnChange}
                                ref={inputSearchRef}
                                placeholder="Nhập UserAD"
                                type="text"
                                autoFocus="autofocus"
                                disabled={!!stateSearch.searchResult}
                                tabIndex={1}
                                required
                            />
                        </div>
                        <button onClick={(e) => handleOnSearch(e)}>
                            <img src={ic_search_white_outline} alt="search" />
                        </button>
                    </div>
                    <ButtonControl
                        content="Tải file excel"
                        icon={ic_upload}
                        onClick={handleUploadFile}
                    />
                </div>
                <div className="error-search">{stateSearch.error}</div>
            </WrapperSearch>
            {
                !!stateSearch.searchResult &&
                <WrapperResult>
                    <div className="wrap-title">
                        <div className="title"></div>
                        <div className="clear-data" onClick={_handleClearData}>Xóa dữ liệu</div>
                    </div>
                    <div className="list-user-infos">
                        <div className="row-result-title">
                            <div>Họ tên</div>
                            <div>Vai trò trên CoreBanking</div>
                            <div>CN/PGD trên CoreBanking</div>
                        </div>
                        {
                            lst.map((item, i) => (
                                <RowListResult
                                    key={i}
                                    data={item}
                                    stateConfig={stateConfig}
                                    dispatchConfig={dispatchConfig}
                                />
                            ))
                        }
                    </div>
                </WrapperResult>
            }
        </styles.Bound>
    )
}

export default SearchUser
