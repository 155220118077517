import React, { Component } from 'react'
import styled from 'styled-components'
import { color } from '../../../data/store'
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup'
import ic_close from '../../../images/ic_close.svg'
const Bound = styled.div`
    padding: 20px 50px 15px 50px;
    background-color: #fafafa;
    width: 260px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .row_question {
        display: flex;
        align-items: center;
        justify-content: center;
        .img_question {
            background-image: url(${ic_close});
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 52px;
            height: 52px;
            object-fit: contain;
        }
    }
    .row_title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        flex-direction: column;
        .title {
            font-size: 16px;
            line-height: 1.13;
            color: #222222;
            text-align: center;
        }
        .branchName {
            width: 100%;
            font-weight: bold;
        }
        .numberOffice {
            margin: 16px 0 0 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;
            color: #005993;
            text-align: center;
        }
        .contentAlert {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;
            color: #f31919;
            text-align: center;
            margin: 16px 0 0 0;
        }
    }
    .row_btn_confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 0 0;
        .btn_exit {
            margin: 0 20px 0 0;
        }
    }
`

export default class FlowOfficeCheckNumber extends Component {
    render() {
        const { NumOfUser, NumOfCounter, NumOfKiosk, NumOfLCD } = this.props
        const lstInfosAlert = []
        if (NumOfUser) lstInfosAlert.push(`${NumOfUser} tài khoản`)
        if (NumOfCounter) lstInfosAlert.push(`${NumOfCounter} quầy`)
        if (NumOfKiosk + NumOfLCD)
            lstInfosAlert.push(`${NumOfKiosk + NumOfLCD} thiết bị`)
        // debugger;
        const strAlert =
            lstInfosAlert.length === 1
                ? lstInfosAlert[0]
                : lstInfosAlert.reduce((sum, item, i) =>
                      i === lstInfosAlert.length - 1
                          ? sum + ' và ' + item
                          : sum + ', ' + item
                  )
        // debugger;
        return (
            <Bound>
                <div className="row_question">
                    <span className="img_question"></span>
                </div>
                <div className="row_title">
                    <p className="title">
                        Bạn không thể xóa&nbsp;
                        <span className="branchName">
                            {this.props.branchName}
                        </span>
                    </p>
                    <p className="numberOffice">
                        ({`${strAlert} được liên kết`})
                    </p>
                    <p className="contentAlert">{this.props.contentDelete}</p>
                </div>
                <p></p>
                <div className="row_btn_confirm">
                    <div className="btn_accept">
                        <ButtonConfirmPopup
                            titleConfirm="XÁC NHẬN"
                            bg_color={color.blue_005993}
                            onClick={() => {
                                this.props.outPopup()
                            }}
                        />
                    </div>
                </div>
            </Bound>
        )
    }
}
