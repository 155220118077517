import styled from "styled-components";
import { color } from "../../../../../data/store";

const minSize = 5
const defaultSize = 18

const Container = styled.div`
    margin-left: ${props => props.marginLeft ?? 0 }px;
    margin-top: ${props => props.marginTop ?? 0 }px;
    .content {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &.disabled{
            pointer-events: none;
            .label{
                color: #C4C4C4;
            }
            .checkmark{
                /*background-color: #E6E6E6!important;*/
            }
        }
    }

    .label {
        padding-left: 25px;
        padding-top: 1px;
        color: var(--Black, #191919);
        font-family: SVN-Gilroy;
        font-size: ${props => props.labelFontSize ?? 17}px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }

    /* Hide the browser's default checkbox */
    .content input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: ${props => calcSizeCheckBox(props.sizeCheckBox) - 1}px;
        width: ${props => calcSizeCheckBox(props.sizeCheckBox) - 1}px;
        border-radius: 4px;
        border: 1px solid var(--Gray-3, #E6E6E6);
        background: var(--Background, #F7F7F7);
    }

    /* On mouse-over, add a grey background color */
    .content:hover input ~ .checkmark {
        border-radius: 4px;
        border: 1px solid var(--Gray-3, #E6E6E6);
        background: var(--Background, #F7F7F7);
    }

    /* When the checkbox is checked, add a blue background */
    .content input:checked ~ .checkmark {
        height: ${props => calcSizeCheckBox(props.sizeCheckBox)}px;
        width: ${props => calcSizeCheckBox(props.sizeCheckBox)}px;
        background-color: ${color.blue_005993};
        border: none;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .content input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .content .checkmark:after {
        left: ${props => calcSizeCheckmark(props.sizeCheckBox, 7)}px;
        top: ${props => calcSizeCheckmark(props.sizeCheckBox, 3)}px;;
        width: ${props => calcSizeCheckmark(props.sizeCheckBox, 3)}px;
        height: ${props => calcSizeCheckmark(props.sizeCheckBox, 7)}px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

`

const calcSizeCheckBox = (size) => {
    if(size === undefined || size === null || size < minSize){
        return defaultSize
    }
    return size;

}

const calcSizeCheckmark = (sizeCheckBox, sizeCheckmarkDefault) => {
    var sizeContent = calcSizeCheckBox(sizeCheckBox)
    return (sizeContent * sizeCheckmarkDefault)/defaultSize;

}

export {Container}