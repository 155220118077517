import Axios from 'axios'

import * as types from './types'
import { host } from '../host'
// import { resetStore } from './userAction';
import {
    addLoading,
    removeLoading,
} from './commonAction'
import catchErrorApi from '../helper/catchErrorApi'

// api
const apiCreateKiosk = host + 'admin/kiosk/create'
const apiUpdateKiosk = host + 'admin/kiosk/update'
const apiGetAllKiosk = host + 'admin/kiosk/getAll'
// const apiConnectKiosk = host + "admin/kiosk/connect";
// const apiDisconnectKiosk = host + "admin/kiosk/disconnect";
const apiGenerateKeyKiosk = host + 'admin/kiosk/generate-keys'
const apiDeleteKiosk = host + 'admin/kiosk/delete'

/**
 *
 * @param {string*} idOffice: if of office
 * @param {string*} nameKiosk: name of new kiosk
 * @param {number*} timeout: number from 15 to 90
 */
export const createKiosk = (idOffice, nameKiosk, timeout) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        AtOffice: idOffice,
        Name: nameKiosk,
        Timeout: timeout
    })

    try {
        const res = await Axios.post(apiCreateKiosk, queryDataFormat)
        const { data } = res

        dispatch({
            type: types.CREATE_KIOSK,
            data,
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.CREATE_KIOSK_ERR, () => false)
    }
}

/**
 *
 * @param {string*} idKiosk id of kiosk need update
 * @param {string*} nameKiosk new name of kiosk
 * @param {{
 *  EformStatus: 'E' \| 'D',
 *  Timeout: number from 15 to 90
 * }} dataUpdate new name of kiosk
 */
export const updateKiosk = (idKiosk, nameKiosk, dataUpdate) => async (dispatch) => {
    const objData = {
        ID: idKiosk,
        Name: nameKiosk,
        ...dataUpdate
    }
    
    const queryDataFormat = JSON.stringify(objData)

    try {
        const res = await Axios.post(apiUpdateKiosk, queryDataFormat)
        const { data } = res

        dispatch({
            type: types.UPDATE_KIOSK,
            data,
            infosUpdate: objData
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.UPDATE_ADS_ERR, () => false)
    }
}

/**
 *
 * @param {string} idOffice id of office
 * @param {number} pageSize size of a page
 * @param {number} pageNumber number of page
 */
export const getAllKiosk = (idOffice, pageSize, pageNumber) => async (
    dispatch
) => {
    dispatch(addLoading())
    const formatQueryData = JSON.stringify({
        AtOffice: idOffice,
        PageSize: pageSize,
        PageNumber: pageNumber,
    })

    try {
        const res = await Axios.post(apiGetAllKiosk, formatQueryData)
        const { data } = res
        dispatch({
            type: types.GET_ALL_KIOSK,
            listKiosk: data.ListKiosk ? data.ListKiosk : [],
            total: data.Total,
            pageNumber,
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(err, dispatch, types.GET_ALL_KIOSK_ERR, () => false)
    }
}
// /**
//  *
//  * @param {{
//  * Username: string,
//  * Password: string,
//  * Name: string, - name of kiosk need connect
//  * Identity: string - id of kiosk need connect
//  * }} dataConnect
//  */
// export const connectKiosk = (dataConnect) => async dispatch => {
//   const queryDataFormat = JSON.stringify({
//     ...dataConnect
//   })

//   try {
//     const res = await Axios.post(apiConnectKiosk, queryDataFormat);

//     console.log(res);
//     const {data}  = res;

//     dispatch({
//       type: types.CONNECT_KIOSK,
//       data
//     })
//   }
//   catch(err){
//     console.log(err);
//     dispatch({
//       type: types.CONNECT_KIOSK_ERR,
//       err
//     })
//   }
// }

// export const disconnectKiosk = (idKiosk) => async dispatch => {
//   const queryDataFormat = JSON.stringify({
//     ID: idKiosk
//   })
//   try{
//     const res = await Axios.post(apiDisconnectKiosk, queryDataFormat);

//     console.log(res);
//     dispatch({
//       type: types.DISCONNECT_KIOSK
//     })
//   }
//   catch(err){
//     console.log(err);
//     dispatch({
//       type: types.DISCONNECT_KIOSK_ERR,
//       err
//     })
//   }

// }

/**
 *
 * @param {string} idOffice id of office contain kiosk
 * @param {string} idKiosk id of kiosk need generate key
 */
export const generateKeyConnectKiosk = (idOffice, idKiosk) => async (
    dispatch
) => {
    const formatQueryData = JSON.stringify({
        OfficeID: idOffice,
        KioskID: idKiosk,
    })

    try {
        const res = await Axios.post(apiGenerateKeyKiosk, formatQueryData)
        const { data } = res

        dispatch({
            type: types.GENERATE_KEY_KIOSK,
            data,
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.GENERATE_KEY_KIOSK_ERR, () => false)
    }
}

export const deleteKiosk = (idKiosk) => async (dispatch) => {
    const formatQueryData = JSON.stringify({
        ID: idKiosk,
    })

    try {
        const res = await Axios.post(apiDeleteKiosk, formatQueryData)
        const { data } = res

        dispatch({
            type: types.DELETE_KIOSK,
            data,
        })
    } catch (err) {
        catchErrorApi(err, dispatch, types.DELETE_KIOSK_ERR, () => false)
    }
}

// export const updateEForm = (idKiosk, status) => async (dispatch, getState) => {
//     // const api = ''

//     // const formatQueryData = JSON.stringify({
//     //     status,
//     //     idKiosk,
//     // })

//     const listKiosk = getState().kioskReducer.listKiosk

//     let newListKiosk = []
//     for (let i = 0; i < listKiosk.length; i++) {
//         const element = listKiosk[i]
//         if (element.ID === idKiosk) {
//             element.EForm = !!status ? false : true
//         }
//         newListKiosk.push(element)
//     }

//     // console.log(newListKiosk)

//     try {
//         // const res = Axios.post(api, formatQueryData)
//         // const { data } = res

//         if (idKiosk && (status === true || status === false)) {
//             dispatch({
//                 type: types.UPDATE_EFORM,
//                 data: newListKiosk,
//             })
//         }
//     } catch (err) {
//         catchErrorApi(err, dispatch, types.UPDATE_EFORM_ERR, () => false)
//     }
// }

export const clearKeyGenKiosk = (kioskId, kioskName, key) => ({
    type: types.CLEAR_KEY_GEN_KIOSK,
    payload: {
        kioskId,
        kioskName,
        key,
    },
})
