import React, { useState } from 'react'
import { TransCounterItemBodyContent, TransCounterItemContainer, TransCounterItemTitleContent, TransCounterItemCountTimeStyle} from './index.styles'
import Modal from '../../../../../helper/Modal'
import PopupConfirmReset from '../PopupConfirmReset'
import PopupOptionListCounterItem from '../PopupOptionListCounterItem'
import CountDownTimer from '../../Control/countDownTimer'


export default function TransactionCounterItem({
    index, 
    dataInfo, 
    numberOfCounterServices, 
    currentPage, 
    onShowPopupEdit, 
    onShowPopupDelete, 
    onGenDesktopKey,
    onGenTabletKey,
    onClearKeyGenCounter,
    onShowErrPopup
}) {
    const [positionControl, setPositionControl] = useState({})
    const [isShowOptionControl, setIsShowOptionControl] = useState(false)
    const [isShowConfirmReset, setIsShowConfirmReset] = useState(false)

    const excutePositionPopup = () => {
        let moreBtn = document.getElementById('more_button_id_' + index)
        if (moreBtn) {
            let bound = moreBtn.getBoundingClientRect()
            let topBound = bound.top
            let leftBound = bound.left
            let offsetPopup = {
                top: topBound + bound.height / 2,
                left: leftBound - 89 + bound.width / 2,
            }
            //---
            setPositionControl(offsetPopup)
        }
    }

    const showHidePopupControl = () => {
        let isShow = !isShowOptionControl;
        //---
        setIsShowOptionControl(isShow);
        if(isShow){
            excutePositionPopup()
        }
    }

    const convertNumberToStr = (n) => {
        if (n > 0) {
            return n > 9 ? '' + n : '0' + n
        } else {
            return 0
        }
    }

    const getNumberOfCounterServices = (dataInfo) => {
        if(dataInfo.ServAllService){
            return numberOfCounterServices
        } else {
            return dataInfo.ServiceList
                    ? dataInfo.ServiceList.length
                    : 0
        }
    }
    
    const getDesktopKey =  (dataInfo) => {
        let desktopKey
        if (typeof dataInfo.desktopKey !== 'undefined') {
            if (Object.keys(dataInfo.desktopKey).length !== 0) {
                desktopKey = dataInfo.desktopKey
            }
        }
        return desktopKey
    }

    const getTabletKey =  (dataInfo) => {
        let tabletKey
        if (typeof dataInfo.tabletKey !== 'undefined') {
            if (Object.keys(dataInfo.tabletKey).length !== 0) {
                tabletKey = dataInfo.tabletKey
            }
        }
        return tabletKey
    }

    const renderTitleContent = (counterName, isVip) => {
        return (
            <TransCounterItemTitleContent>
                <div className="content">
                    <div className="title_counter">{counterName}</div>
                    <div
                        className="ic_more_gray"
                        id={'more_button_id_' + index}
                        onClick={showHidePopupControl}
                    >
                        <Modal isShow={isShowOptionControl}>
                            <PopupOptionListCounterItem
                                offsetPopup={positionControl}
                                counterInfos={dataInfo}
                                currentPage={currentPage}
                                funcCall={{
                                    onShowPopupEdit: onShowPopupEdit,
                                    onShowPopupDelete: onShowPopupDelete,
                                    onShowPopupReset: () => {
                                        setIsShowConfirmReset(true)
                                    },
                                }}
                            />
                        </Modal>
                    </div>
                </div>
                {   
                    isVip &&
                    <div className="vip_block">
                        <div className="content">
                            <div className="ic_star"/>
                            <div className="label">Ưu tiên</div>
                        </div>
                    </div>
                }

            </TransCounterItemTitleContent>
        )
    }

    const renderCounterContent = (desktopKey, dataInfo) => {
        return (
            <>
                <div className="detail_row">
                    <div className="label">COUNTER </div>
                    {
                        dataInfo.DeviceMac
                        ?   <div className="connect_bnt active">
                                Connected
                            </div>
                        :   <div 
                                className="connect_bnt"
                                onClick={() =>
                                    onGenDesktopKey(
                                        dataInfo.ID,
                                        dataInfo.AtOfficeID
                                    )
                                }
                            >
                                Connect ID
                            </div>
                    }
                    
                </div>
                <div className="sub_row">
                    {
                        dataInfo.DeviceMac 
                        ?   <div
                                className="value active"
                                title={dataInfo.DeviceMac}
                            >
                                {dataInfo.DeviceMac}
                            </div>
                        :   desktopKey 
                            ?   <div className="value">
                                    {desktopKey.ConnectID}-{desktopKey.Pwd}
                                </div>
                            : null
                    }
                    {
                        desktopKey
                        ?   <div
                                className="refresh_connect_bnt"
                                onClick={() => {
                                    onGenDesktopKey(
                                        dataInfo.ID,
                                        dataInfo.AtOfficeID
                                    )
                                }}
                            >
                                <div className="count_time_content">
                                    <CountDownTimer
                                        key={1}
                                        condition={desktopKey}
                                        timer={desktopKey.MaxAge}
                                        extendStyles={TransCounterItemCountTimeStyle}
                                        callBackTime={(_) =>
                                            onClearKeyGenCounter(
                                                dataInfo.ID,
                                                'desktopKey'
                                            )
                                        }
                                    />
                                </div>
                                <div className='ic_refresh'/>
                            </div>
                        :   null
                    }
                </div>
            </>
        )
    }

    const renderTabletContent = (tabletKey, dataInfo) => {
        return (
            <>
                <div className="detail_row">
                    <div className="label">TABLET </div>
                    {
                        dataInfo.VirtualTablet
                        ?   <div className="basic_value">
                                Virtual Tablet
                            </div>
                        :   dataInfo.TabletDevice
                            ?   <div className="connect_bnt active">
                                    Connected
                                </div>
                            :   <div 
                                    className="connect_bnt"
                                    onClick={() =>
                                        onGenTabletKey(
                                            dataInfo.ID,
                                            dataInfo.AtOfficeID
                                        )
                                    }
                                >
                                    Connect ID
                                </div>
                    }
                    
                </div>
                <div className="sub_row">
                    {
                        dataInfo.VirtualTablet
                        ?   null
                        :   dataInfo.TabletDevice 
                            ?   <div
                                    className="value active"
                                    title={dataInfo.TabletDevice}
                                >
                                    {dataInfo.TabletDevice}
                                </div>
                            :   tabletKey 
                                ?   <div className="value">
                                        {tabletKey.ConnectID}-{tabletKey.Pwd}
                                    </div>
                                : null
                    }
                    {
                        tabletKey
                        ?   <div
                                className="refresh_connect_bnt"
                                onClick={() => {
                                    onGenTabletKey(
                                        dataInfo.ID,
                                        dataInfo.AtOfficeID
                                    )
                                }}
                            >
                                <div className="count_time_content">
                                    <CountDownTimer
                                        key={1}
                                        condition={tabletKey}
                                        timer={tabletKey.MaxAge}
                                        extendStyles={TransCounterItemCountTimeStyle}
                                        callBackTime={(_) =>
                                            onClearKeyGenCounter(
                                                dataInfo.ID,
                                                'tabletKey'
                                            )
                                        }
                                    />
                                </div>
                                <div className='ic_refresh'/>
                            </div>
                        :   null
                    }
                </div>
            </>
        )
    }

    const renderBodyContent = (dataInfo) => {
        let desktopKey = getDesktopKey(dataInfo)
        let tabletKey = getTabletKey(dataInfo)
        let numberOfCounterServices = getNumberOfCounterServices(dataInfo)
        return(
            <TransCounterItemBodyContent>
                <div className="basic_row">
                    <div className="label">SỐ QUẦY: </div>
                    <div className="value">{` ${convertNumberToStr(dataInfo.CounterNumber)} `}</div>
                </div>
                <div className="basic_row">
                    <div className="label">SỐ LƯỢNG GIAO DỊCH: </div>
                    <div className="value">{convertNumberToStr(numberOfCounterServices)}</div>
                </div>
                <div className="line"/>
                {renderCounterContent(desktopKey, dataInfo)}
                <div className="line"/>
                {renderTabletContent(tabletKey, dataInfo)}
            </TransCounterItemBodyContent>
        )
    }

    return (
        <TransCounterItemContainer>
            {renderTitleContent(dataInfo.CounterName, dataInfo.IsVIP)}
            {renderBodyContent(dataInfo)}
            {
                isShowConfirmReset &&
                <PopupConfirmReset
                    counterInfos={dataInfo}
                    currentPage={currentPage}
                    setIsShowConfirmReset={(isShow) => {
                        setIsShowConfirmReset(isShow)
                    }}
                    onShowErrPopup={onShowErrPopup}
                />
            }
        </TransCounterItemContainer>
    )
}
