/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, WrapOverlay } from './index.styles'
import { ConfigureAppointmentScheduleTabArr } from '../../../../data/data'
import ConfigureByBranch from './ConfigureByBranch'
import PopupError from '../../Popup/PopupError'
import PopupSuccess from '../../Popup/PopupSuccess'
import ConfigureByGeneral from './ConfigureByGeneral'
import { useDispatch } from 'react-redux'
import { getConfig } from '../../../../actions/confAppointmentScheduleAction'

export default function ConfgureAppointmentScedule() {
    const dispatch = useDispatch()
    //---
    const tabList = ConfigureAppointmentScheduleTabArr
    //---
    const [tabSelected, setTabSelected] = useState(tabList[0])
    const [dataSuccess, setDataSuccess] = useState({
        isShowPopup: false,
        mess: "",
        note: ""
    })
    const [dataErr, setDataErr] = useState({
        isShowPopup: false,
        mess: "",
        note: "",
        listBranchCodeInvalid: [],
    })
    
    const [dataConfig, setDataConfig] = useState({
        generalConf: {
            dataInfo: {
                bookingByPerSessions: 0,
                bookingLimit: 0,
                timeBookingPreset: 0,
                issueTicketTimeEarly: 0,
                issueTicketTimeLate: 0,
            },
            defaultConfig: {
                bookingByPerSessions: {
                    min: 1,
                    max: 50
                },
                bookingLimit: {
                    min: 1,
                    max: 50
                },
                timeBookingPreset: {
                    min: 1,
                    max: 60
                },
                issueTicketTimeEarly: {
                    min: 1,
                    max: 30
                },
                issueTicketTimeLate: {
                    min: 1,
                    max: 30
                },
            }
        },
        timeBlockConf: {
            timeBlock8hTo8h30: {
                min: 0,
                max: 30
            },
            timeBlock8h30To9h: {
                min: 0,
                max: 30
            },
            timeBlock9hTo9h30: {
                min: 0,
                max: 30
            },
            timeBlock9h30To10h: {
                min: 0,
                max: 30
            },
            timeBlock10hTo10h30: {
                min: 0,
                max: 30
            },
            timeBlock10h30To11h: {
                min: 0,
                max: 30
            },
            timeBlock13h30To14h: {
                min: 0,
                max: 30
            },
            timeBlock14hTo14h30: {
                min: 0,
                max: 30
            },
            timeBlock14h30To15h: {
                min: 0,
                max: 30
            },
            timeBlock15hTo15h30: {
                min: 0,
                max: 30
            },
            timeBlock15h30To16h: {
                min: 0,
                max: 30
            }
        }
    })

    const getDataConfig = () => {
        dispatch(getConfig({
            onCompleted: (dataResp) => {
                if (dataResp.code === 0) {
                    setDataConfig(dataResp.result)
                } else {
                    onHideShowErrPopup && onHideShowErrPopup({
                        isShowPopup: true,
                        mess: "Hệ thống bị gián đoạn",
                        note: "(*) Bạn vui lòng thực hiện lại"
                    })
                }
            }
        }))
    }

    const onUpdateDataConfig = (newDataInfo) => {
        const newDataConfig = { ...dataConfig }
        newDataConfig.generalConf.dataInfo = newDataInfo
        //---
        setDataConfig(newDataConfig)
        getDataConfig()
    }

    const onTabSelected = (tabInfo) => {
        if (tabSelected.key !== tabInfo.key) {
            setTabSelected(tabInfo)
        }
    }

    const onHideShowErrPopup = (data = { isShowPopup: false, mess: "", note: "" }) => {
        setDataErr({
            isShowPopup: data.isShowPopup,
            mess: data.mess,
            note: data.note,
            listBranchCodeInvalid: data.listBranchCodeInvalid,
        })
    }

    const onHideShowSuccessPopup = (data = { isShowPopup: false, mess: "", note: "" }) => {
        setDataSuccess({
            isShowPopup: data.isShowPopup,
            mess: data.mess,
            note: data.note,
        })
    }

    const renderTab = () => {
        return tabList.map((tab, i) => {
            return (
                <div
                    className="tab"
                    key={i}
                    style={
                        tabSelected.key === tab.key
                            ? {
                                color: tab.activeColor,
                                borderBottom: tab.avtiveBorderBottom,
                            }
                            : { color: tab.disActiveColor }
                    }
                    onClick={() => onTabSelected(tab)}
                >
                    {tab.label}
                </div>
            )
        })
    }

    useEffect(() => {
        getDataConfig()
    }, [])

    return (
        <Container>
            <div className="title">
                {renderTab()}
            </div>
            <div className="body">
                {
                    tabSelected.key === tabList[0].key
                        ? <ConfigureByBranch
                            headerData={tabSelected.subHeaderData}
                            onHideShowErrPopup={onHideShowErrPopup}
                            onHideShowSuccessPopup={onHideShowSuccessPopup}
                            sysConfig={dataConfig.timeBlockConf}
                        />
                        : <ConfigureByGeneral
                            headerData={tabSelected.subHeaderData}
                            onHideShowErrPopup={onHideShowErrPopup}
                            onHideShowSuccessPopup={onHideShowSuccessPopup}
                            sysConfig={dataConfig.generalConf}
                            onUpdateData={onUpdateDataConfig}
                        />
                }
            </div>
            {
                dataErr.isShowPopup &&
                <WrapOverlay>
                    <PopupError
                        width={'280px'}
                        content={dataErr.mess}
                        note={dataErr.note}
                        listBranchCodeInvalid={dataErr.listBranchCodeInvalid}
                        onClosed={() => {
                            onHideShowErrPopup()
                        }}
                    />
                </WrapOverlay>
            }
            {
                dataSuccess.isShowPopup &&
                <WrapOverlay>
                    <PopupSuccess
                        titleHeader={dataSuccess.mess}
                        acceptAction={() => {
                            onHideShowSuccessPopup()
                        }}
                        outPopup={() => {
                            onHideShowSuccessPopup()
                        }}
                    />
                </WrapOverlay>
            }
        </Container>
    )
}
