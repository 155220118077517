import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
import {
    addLoading,
    removeLoading,
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';

const apiCreateOffice = host + "admin/office/create";
const apiFilterOffice = host + "admin/office/filter";
const apiUpdateOffice = host + "admin/office/update";
const apiDeleteOffice = host + "admin/office/delete";
const apiGetBranch = host + "admin/office/get-branch";

/**
 * 
 * @param {String} name name of office
 * @param {String} address full address of office
 * @param {String} province province of office
 * @param {String} branchCode type of office
 * @param {String} district district of office
 * @param {string} ward ward of office
 * @param {"HEAD" \| "TRADING" \| "BRANCH"} officeType type of office
 * @param {Object} deviceConfig: {
 *    @param {boolean} hasKioskDeviceConfig
 *    @param {boolean} hasTabletDeviceConfig
 *    @param {boolean} hasLcdDeviceConfig
 * }
 */
export const createOffice = (dataCreate, callbackFunc) => async dispatch => {
    const queryFormatData = JSON.stringify({
        ...dataCreate
    })
    try {
        const res = await Axios.post(apiCreateOffice, queryFormatData);
        const { data } = res;
        dispatch({
            type: types.CREATE_NEW_OFFICE,
            success: Boolean(data)
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code === 5) {
                dispatch({
                    type: types.CREATE_NEW_OFFICE_ERR,
                    err: err.response.data,
                })
                return true;
            }
            const { Code } = err.response.data
            callbackFunc(Code)
            return true;
        }
        catchErrorApi(err, dispatch, types.CREATE_NEW_OFFICE_ERR, handleError400)
    }
}

/**
 * 
 * @param {{
 *  name: string, 
 *  address: string, 
 *  province: string, 
 *  district: string, 
 *  ward: string,
 *  officeType: "HEAD" \| "TRADING" \| "BRANCH"
 *  deviceConfig: {
 *    hasKioskDeviceConfig: bool,
 *    hasTabletDeviceConfig: bool,
 *    hasLcdDeviceConfig: bool
 *  }
 *  hasFilterDevicesConfig: bool
 * }} dataFilter 
 * @param {number} pageSize 
 * @param {number} pageNumber 
 */

export const clearFilterOffice = () => ({
    type: types.CLEAR_FILTER_OFFICE
})

export const filterOffice = (dataFilter, pageSize, pageNumber) => async dispatch => {
    dispatch(addLoading())
    //---
    let hasFilterDevicesConfig = false;
    const formatDataFilter = {};
    if (dataFilter !== undefined && dataFilter !== null && Object.keys(dataFilter).length !== 0) {
        if (dataFilter.name) formatDataFilter["Name"] = dataFilter.name.trim();
        if (dataFilter.adress) formatDataFilter["Address"] = dataFilter.adress;
        if (dataFilter.province) formatDataFilter["Province"] = dataFilter.province;
        if (dataFilter.district) formatDataFilter["District"] = dataFilter.district;
        if (dataFilter.ward) formatDataFilter["Ward"] = dataFilter.ward;
        if (dataFilter.officeType) formatDataFilter["OfficeType"] = dataFilter.officeType;
        if (dataFilter.deviceConfig != null && Object.keys(dataFilter.deviceConfig).length !== 0) {
            formatDataFilter["HasKioskDeviceConfig"] = dataFilter.deviceConfig.hasKioskDeviceConfig ?? false
            formatDataFilter["HasLcdDeviceConfig"] = dataFilter.deviceConfig.hasLcdDeviceConfig ?? false
            formatDataFilter["HasTabletDeviceConfig"] = dataFilter.deviceConfig.hasTabletDeviceConfig ?? false
        }
        //---
        hasFilterDevicesConfig = dataFilter.hasFilterDevicesConfig ?? false;
    }

    const queryFormatData = JSON.stringify({
        ...formatDataFilter,
        PageSize: pageSize,
        PageNumber: pageNumber,
        HasFilterDevicesConfig: hasFilterDevicesConfig
    })

    try {
        const res = await Axios.post(apiFilterOffice, queryFormatData);
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "",
        //         "ListOffice": [
        //             {
        //                 "ID": "66e0fb1a2509b3b01e6c3953",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "TanCNN",
        //                 "Address": "An Ninh",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "1001298",
        //                 "Ward": "30316",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-11T02:06:18.532Z",
        //                 "UpdatedAt": "2024-09-13T02:45:15.08Z"
        //             },
        //             {
        //                 "ID": "66e2cd990cec16c162690c15",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tấnsad",
        //                 "Address": "dsaddsads",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10104",
        //                 "Ward": "30316",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-12T11:16:41.435Z",
        //                 "UpdatedAt": "2024-09-12T11:16:41.435Z"
        //             },
        //             {
        //                 "ID": "66d1a5bc1a73d6417dcc8cb1",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD 2",
        //                 "Address": "An Thịnh",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10107",
        //                 "Ward": "30316",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 3,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-08-30T10:58:04.203Z",
        //                 "UpdatedAt": "2024-09-04T08:55:27.317Z"
        //             },
        //             {
        //                 "ID": "66d9710c4b51e41c4c31d3ac",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân TEST CN",
        //                 "Address": "Tas",
        //                 "Province": "77",
        //                 "District": "748",
        //                 "BranchCode": "10121",
        //                 "Ward": "26566",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-05T08:51:24.455Z",
        //                 "UpdatedAt": "2024-09-12T11:33:51.117Z"
        //             },
        //             {
        //                 "ID": "66e26ca62ca5c9470fe88271",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "tân 12/09",
        //                 "Address": "An Thuy",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10123",
        //                 "Ward": "30334",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-12T04:23:02.162Z",
        //                 "UpdatedAt": "2024-09-12T04:23:02.162Z"
        //             },
        //             {
        //                 "ID": "66b431f17a20ceeed53ab10a",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Huy",
        //                 "Address": "B20",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10132",
        //                 "Ward": "30337",
        //                 "NumOfKiosk": 8,
        //                 "NumOfCounter": 10,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "https://vtb-eticket.wee.vn/?officeID=66b431f17a20ceeed53ab10a\u0026token=a9753e49-8322-42d2-a407-9792fabd1334",
        //                 "QrToken": "a9753e49-8322-42d2-a407-9792fabd1334",
        //                 "CreatedAt": "2024-08-08T02:48:17.46Z",
        //                 "UpdatedAt": "2024-08-23T07:24:52.483Z"
        //             },
        //             {
        //                 "ID": "66d9929e7203a7863e9f8393",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD9",
        //                 "Address": "sad",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10134",
        //                 "Ward": "30319",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-05T11:14:38.297Z",
        //                 "UpdatedAt": "2024-09-12T11:34:11.301Z"
        //             },
        //             {
        //                 "ID": "66e290d5f8e5e05f7d761834",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD 12.09",
        //                 "Address": "ads",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "10153",
        //                 "Ward": "30319",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-12T06:57:25.502Z",
        //                 "UpdatedAt": "2024-09-12T06:57:25.502Z"
        //             },
        //             {
        //                 "ID": "66d82058db5f8cb007d54f14",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân PGD 3",
        //                 "Address": "An Tịnh",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10154",
        //                 "Ward": "30340",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 2,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "https://vtb-eticket.wee.vn/?officeID=66d82058db5f8cb007d54f14\u0026token=5b427518-67a2-4be8-8f2c-434408d535e3",
        //                 "QrToken": "5b427518-67a2-4be8-8f2c-434408d535e3",
        //                 "CreatedAt": "2024-09-04T08:54:48.739Z",
        //                 "UpdatedAt": "2024-09-06T02:32:42.13Z"
        //             },
        //             {
        //                 "ID": "66d9327b4517b6f7e4095350",
        //                 "ParentID": "63fc6b2eb25ba66efed09003",
        //                 "OfficeType": "TRADING",
        //                 "Name": "Tân CN xuống PGD",
        //                 "Address": "ad",
        //                 "Province": "77",
        //                 "District": "750",
        //                 "BranchCode": "10162",
        //                 "Ward": "26578",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 1,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-05T04:24:27.75Z",
        //                 "UpdatedAt": "2024-09-05T04:24:54.009Z"
        //             }
        //         ],
        //         "Total": 19
        //     }
        // }
        const { data } = res;
        dispatch({
            type: types.FILTER_OFFICE,
            listOffice: data.ListOffice ? data.ListOffice : [],
            total: data.Total,
            dataFilter
        })
        dispatch(removeLoading())
    }
    catch (err) {
        dispatch(removeLoading())

        catchErrorApi(
            err,
            dispatch,
            types.FILTER_OFFICE_ERR,
            () => false
        )
    }
}

export const handerSearchByNameOrOfficeType = (dataFilter, pageSize, pageNumber) => async dispatch => {
    dispatch(addLoading())
    //---
    const formatDataFilter = {};
    if (dataFilter !== undefined && dataFilter !== null && Object.keys(dataFilter).length !== 0) {
        if (dataFilter.name) formatDataFilter["Name"] = dataFilter.name.trim();
        if (dataFilter.officeType) formatDataFilter["OfficeType"] = dataFilter.officeType;
    }

    const queryFormatData = JSON.stringify({
        ...formatDataFilter,
        PageSize: pageSize,
        PageNumber: pageNumber
    })

    try {
        const res = await Axios.post(apiFilterOffice, queryFormatData);

        const { data } = res;
        dispatch({
            type: types.FILTER_OFFICE,
            listOffice: data.ListOffice ? data.ListOffice : [],
            total: data.Total,
            dataFilter
        })
        dispatch(removeLoading())
    }
    catch (err) {
        dispatch(removeLoading())

        catchErrorApi(
            err,
            dispatch,
            types.FILTER_OFFICE_ERR,
            () => false
        )
    }
}

export const getAllOfficeV2 = () => async dispatch => {
    dispatch(addLoading())
    try {
        const all = await Axios.post(
            apiFilterOffice,
            JSON.stringify({
                PageSize: 1000000,
                PageNumber: 1
            })
        );
        dispatch({
            type: types.GET_ALL_OFFICE,
            allOfficeList: all.data.ListOffice ? all.data.ListOffice : [],
            total: all.data.Total,
            dataFilter: {}
        })
    }
    catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_ALL_OFFICE_ERR,
            () => false
        )
    }
    dispatch(removeLoading())
}

export const getAllBranch = (UID) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        UID: UID
    })
    try {
        const res = await Axios.post(apiGetBranch, queryDataFormat)
        // const res = {
        //     data: {
        //         "Code": 0,
        //         "Message": "OK",
        //         "ListBranch": [
        //             {
        //                 "ID": "63fc6b2eb25ba66efed09003",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "Chi nhánh Wee",
        //                 "Address": "11",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "10198",
        //                 "Ward": "30337",
        //                 "NumOfKiosk": 14,
        //                 "NumOfCounter": 32,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "https://vtb-eticket.wee.vn/?officeID=63fc6b2eb25ba66efed09003\u0026token=c4d0f335-7fd9-4f1c-9e60-25ad6e9b3aba",
        //                 "QrToken": "c4d0f335-7fd9-4f1c-9e60-25ad6e9b3aba",
        //                 "CreatedAt": "2024-08-09T07:58:54.954Z",
        //                 "UpdatedAt": "2024-08-22T02:01:10.768Z"
        //             },
        //             {
        //                 "ID": "66d7c973dfccc0ae6ca11537",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "PHUONG_TEST",
        //                 "Address": "B20 Bạch Đằng, HCM",
        //                 "Province": "79",
        //                 "District": "764",
        //                 "BranchCode": "23598",
        //                 "Ward": "26968",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-04T02:44:03.928Z",
        //                 "UpdatedAt": "2024-09-04T02:48:58.368Z"
        //             },
        //             {
        //                 "ID": "66e0fb1a2509b3b01e6c3953",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "TanCNN",
        //                 "Address": "An Ninh",
        //                 "Province": "89",
        //                 "District": "884",
        //                 "BranchCode": "1001298",
        //                 "Ward": "30316",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-11T02:06:18.532Z",
        //                 "UpdatedAt": "2024-09-13T02:45:15.08Z"
        //             },
        //             {
        //                 "ID": "66dea08ff922e2c97b966eb8",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "Tân 923d",
        //                 "Address": "Tân 2323",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "23221398",
        //                 "Ward": "30340",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 0,
        //                 "HasKioskDeviceConfig": true,
        //                 "HasLcdDeviceConfig": true,
        //                 "HasTabletDeviceConfig": true,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-09-09T07:15:27.943Z",
        //                 "UpdatedAt": "2024-09-12T11:20:28.016Z"
        //             },
        //             {
        //                 "ID": "66d1806bf62635e5aed0b174",
        //                 "ParentID": "000000000000000000000000",
        //                 "OfficeType": "BRANCH",
        //                 "Name": "Tân Chi nhánh",
        //                 "Address": "18 Tám Danh",
        //                 "Province": "89",
        //                 "District": "886",
        //                 "BranchCode": "56798",
        //                 "Ward": "30373",
        //                 "NumOfKiosk": 0,
        //                 "NumOfCounter": 1,
        //                 "HasKioskDeviceConfig": false,
        //                 "HasLcdDeviceConfig": false,
        //                 "HasTabletDeviceConfig": false,
        //                 "QrUrl": "",
        //                 "QrToken": "",
        //                 "CreatedAt": "2024-08-30T08:18:51.172Z",
        //                 "UpdatedAt": "2024-09-04T08:43:25.595Z"
        //             }
        //         ]
        //     }
        // }
        const { data } = res
        dispatch({
            type: types.GET_ALL_BRANCH,
            allBranchList: data.ListBranch ? data.ListBranch : [],
        })
    }
    catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_ALL_BRANCH_ERR,
            () => false
        )
    }
}
/**
 * 
 * @param {{
 * ID*: string,
 * Name:  string,
 * Address: string,
 * Province: string,
 * District: string,
 * Ward: string,
 * OfficeType: "HEAD" \| "TRADING" \| "BRANCH"
 * HasKioskDeviceConfig: bool,
 * HasTabletDeviceConfig: bool,
 * HasLcdDeviceConfig: bool
 * }} dataUpdate 
 */
export const updateOffice = (dataUpdate, callbackFunc) => async dispatch => {
    const queryFormatData = JSON.stringify({
        ...dataUpdate
    })

    try {
        const res = await Axios.post(apiUpdateOffice, queryFormatData);
        const { data } = res;
        //---
        callbackFunc(data.Code)
    }
    catch (err) {
        const handleError400 = () => {
            const { Code } = err.response.data
            callbackFunc(Code)
            return true
        }
        catchErrorApi(err, dispatch, types.UPDATE_OFFICE_ERR, handleError400)
    }
}

/**
 * 
 * @param {string} idOffice 
 */
export const deleteOffice = (idOffice) => async dispatch => {
    const dataQueryFormat = JSON.stringify({
        ID: idOffice
    });

    try {
        const res = await Axios.post(apiDeleteOffice, dataQueryFormat);
        const { data } = res;

        dispatch({
            type: types.DELETE_OFFICE,
            success: data.Code === 0
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code === 5) {
                dispatch({
                    type: types.DELETE_OFFICE_ERR,
                    err: err.response.data
                })
                return true;
            }
            return false;
        }

        catchErrorApi(
            err,
            dispatch,
            types.DELETE_OFFICE_ERR,
            handleError400
        )
    }
}

export const clearErrorDeleteOffice = () => ({
    type: types.CLEAR_ERR_DELETE_OFFICE
})

export const resetStateOffice = () => (
    {
        type: types.RESET_STATE_OFFICE
    }
)

export const alertFillterOffice = () => {
    return {
        type: types.ALERT_CONTENT
    }
}


export const saveTextSearchOffice = text => ({
    type: types.SAVE_TEXT_OFFICE,
    textSearch: text
})

export const clearTextSearchOffice = () => ({
    type: types.CLEAR_TEXT_OFFICE,
})