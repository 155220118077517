import styled from 'styled-components'
import { color } from '../../../../../data/store';

const WrapDuplicateBio = styled.div`
    width: 811px;
    /* min-height: 516px; */
    font-family: 'SVN-Gilroy';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #FAFAFA;
    box-sizing: border-box;
    transition: width 0.3s ease-in-out;

    .wrap-header {
        background: #ffffff;
        padding: 0 20px 0 20px;
        box-sizing: border-box;
        border-radius: 10px 10px 0 0;
        .title {
            padding-top: 18px;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 22px;
            color: #222222;

            :hover {
                cursor: move;
            }
        }
    
        .ic-close {
            position: absolute;
            top: 8px;
            right: 8px;

            &:hover {
                cursor: pointer;
            }
        }
    
        .wrap-tab {
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
    
            .wrap-inner-tab {
                display: flex;
                label {
                    display: block;
                    user-select: none;
                    input {
                        appearance: none;
    
                        &:checked + span {
                            color: #222222;
                            box-shadow: inset 0px -3px 0px ${color.blue_005993};
                        }
                    }
    
                    span {
                        padding: 10px 12px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 128%;
                        color: #AAAAAA;
                        display: inline-block;
                    }
    
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
    
            .query-code {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 128%;
                color: #222222;
                padding: 10px 12px;
                span {
                    font-weight: normal;
                    color: ${color.blue_005993};
                }
            }
        }
    }

    .wrap-content {
        overflow: auto;
        max-height: ${props => props.isDupFace ? 'calc((300px + 22px) * 2)': 'calc(480px + 22px)'};
        border-radius: 0 0 10px 10px;
        margin-bottom: 20px;

        ::-webkit-scrollbar {
            width: 15px;
        }
    }

`;

export {
    WrapDuplicateBio
}