/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'

// styles
import { Wrapper, Select, DropDown, Search } from './index.styles'

// images
import icon_down from '../../../../../images/ic_arrow_down_gray.svg'
import icon_search from '../../../../../images/ic_search_gray.png'
import check_blue from '../../../../../images/check_blue.svg'

// hooks
import useOutsideClick from '../../../../../helper/Hooks/useOutsideClick'
import useDebounce from '../../../../../helper/Hooks/useDebouce'

const DropDownItem = ({ children, isChecked, onClick }) => (
    <div className="item" title={children} onClick={onClick}>
        <div>{children}</div>
        {isChecked && <img src={check_blue} alt="checked" />}
    </div>
)

// item dropdown = {key: '', value: ''}

const DropDownSearchObjV2 = ({
    title,
    isRequired = false,
    lstItemFixed,
    lstItems,
    onChange,
    defaultValue, //array
    placeHolder,
    placeHolderSelect,
    onSearch,
    haveSearch = true,
    extendStylesSelect,
    extendStylesDropdown,
    maxNumShowDropdown,
    isSelectMultiple = false,
    timeDelayToSearch = 200,
    condIsChecked = (checked, itemCompare) => {
        return checked?.some((item) => item.key === itemCompare.key)
    },
    textMultipleSelect,
    readOnly
}) => {
    const ref = useRef(null)
    const fistRenderRef = useRef(true)
    const [isShow, setIsShow] = useState(false)
    const [checkedData, setCheckedData] = useState(defaultValue || [])
    const [lstItemFlex, setLstItemFlex] = useState(lstItems)
    const [keySearch, setKeySearch] = useState('')
    const keySearchDebounce = useDebounce(keySearch, timeDelayToSearch)

    useEffect(() => {
        if (!haveSearch) return
        if (fistRenderRef.current) {
            fistRenderRef.current = false;
            return;
        }
        onSearch(keySearchDebounce)
    }, [keySearchDebounce, haveSearch])

    useEffect(() => {
        if (!defaultValue) return
        setCheckedData(defaultValue)
    }, [JSON.stringify(defaultValue)])

    useEffect(() => {
        if (!isShow) {
            setLstItemFlex(lstItems)
        }
    }, [isShow])

    useEffect(() => {
        setLstItemFlex(lstItems)
    }, [JSON.stringify(lstItems)])

    useOutsideClick(ref, () => {
        setIsShow(false)
    })

    useEffect(() => {
        // if (!checkedData) return
        onChange(checkedData)
    }, [JSON.stringify(checkedData)])

    const _handleChecked = useCallback(
        (val) => {
            if (!isSelectMultiple) {
                setIsShow(!isShow)
                setCheckedData([val])
                return
            }

            const isValWasChecked = checkedData?.some(
                (item) => item.key === val.key
            )

            const isValInListFixed = lstItemFixed.some(
                (item) => item.key === val.key
            )
            if (isValInListFixed) {
                !isValWasChecked && setCheckedData([val])
                return
            }

            // multiple select if item not in list fixed
            if (isValWasChecked) {
                const newCheckedData = checkedData.filter(
                    (item) => item.key !== val.key
                )
                setCheckedData(newCheckedData)
                return
            }

            const isHaveItemFixedInCheckedData = checkedData.some((item) =>
                lstItemFixed.some((data) => data.key === item.key)
            )

            const newCheckedData =
                checkedData && !isHaveItemFixedInCheckedData
                    ? [...checkedData, val]
                    : [val]
            setCheckedData(newCheckedData)
        },
        [isShow, isSelectMultiple, checkedData, lstItemFixed]
    )

    const _handleSearch = useCallback(
        (e) => {
            const { value } = e.target
            if (!value.trim()) {
                setKeySearch('')
                return
            }
            setKeySearch(value.trimStart().replace(/\s+/, ' '))
        },
        [lstItems]
    )

    const textShow = useMemo(
        () =>
            checkedData.length > 1
                ? textMultipleSelect?.(checkedData)
                : checkedData[0]?.value ?? placeHolderSelect,
        [JSON.stringify(checkedData), textMultipleSelect, placeHolderSelect]
    )

    return (
        <Wrapper ref={ref}>
             {
                title &&
                <p className='title-text'>
                    {title}
                    {isRequired && <span className='title-notice'>*</span>}
                </p>
            }
            <Select
                onClick={() => !readOnly && setIsShow(!isShow)}
                extendStyles={extendStylesSelect}
            >
                <div title={textShow}>{textShow}</div>
                <img src={icon_down} alt="open-drop-down" />
            </Select>
            {isShow && (
                <DropDown
                    extendStyles={extendStylesDropdown}
                    maxNumShowDropdown={maxNumShowDropdown}
                >
                    {haveSearch && (
                        <Search>
                            <img src={icon_search} alt="search" />
                            <input
                                autoFocus="autofocus"
                                type="text"
                                placeholder={placeHolder}
                                value={keySearch}
                                onChange={_handleSearch}
                            />
                        </Search>
                    )}
                    <div className="lst-fixed-item">
                        {lstItemFixed.map((item, key) => (
                            <DropDownItem
                                key={key}
                                onClick={() => _handleChecked(item)}
                                isChecked={condIsChecked(checkedData, item)}
                            >
                                {item.value}
                            </DropDownItem>
                        ))}
                    </div>
                    <div className="lst-scroll-item">
                        {lstItemFlex.map((item, key) => (
                            <DropDownItem
                                key={key}
                                onClick={() => _handleChecked(item)}
                                isChecked={condIsChecked(checkedData, item)}
                            >
                                {item.value}
                            </DropDownItem>
                        ))}
                    </div>
                </DropDown>
            )}
        </Wrapper>
    )
}

export default DropDownSearchObjV2
