/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { css } from 'styled-components'

// styles
import { extendStylesDropdownHeadStaff } from './index.styles'

// component
import DropDownHeadStaff from './DropDownHeadStaff'

const labels = {
	CBHSC: 'CBHSC'
}

const labelsDesc = {
    [labels.CBHS]: {
        title: 'Cán bộ HSC'
    }
}

const ALL_USER = 'Tất cả'

const SelectHeadStaff = ({ defaultValue, onChange, label = labels.CBHSC, extendStyles }) => {
    const commonReducer = useSelector((state) => state.commonReducer)

    const [lstItems, setLstItems] = useState([])
    const [value, setValue] = useState(defaultValue || ALL_USER)

    const _handleOnChange = useCallback((val) => {
        setValue(val)
        if (ALL_USER === val) {
            onChange(undefined)
            return
        }
        onChange(val)
    }, [])

    useEffect(() => {
        switch (label) {
            case labels.CBHSC:
                setLstItems([ALL_USER, ...commonReducer.listHeadStaff])
                break;
            default:
                break;
        }
    }, [
        label,
        commonReducer.listHeadStaff
    ])

    useEffect(() => {
        setValue(ALL_USER)
    }, [
        commonReducer.listHeadStaff
    ])

    return (
        <DropDownHeadStaff
            extendStyles={css`${extendStylesDropdownHeadStaff}${extendStyles}`}
            label={labelsDesc[label]?.title}
            lstItemFixed={[]}
            lstItems={lstItems}
            onChange={_handleOnChange}
            defaultValue={value}
            placeHolder="Nhập User"
        />
    )
}

export default SelectHeadStaff
