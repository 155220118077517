import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

//Control
import PagningControl from '../Control/PagningControl'
import CountDownTimeControl from '../Control/CountDownTimeControl'
//Action
import { getBioInfos } from '../../../../actions/supervisorAction/biometricAction'
//Data
import {
    BioInfosType,
    Status,
    REGISTER,
    UPDATE,
    /*UNBLOCK,*/ /*BLOCK,*/ /* DELETE,*/ NR,
    ACCEPT,
    REJECT,
    PENDING,
    NR_FINGER,
} from '../../../../data/infosType'
//Tool
import {
    formatDateTimeToSpecificFormat,
    formatDateTime,
} from '../../../../helper/formatTime'
import { OFFINSUPERVISOR, OFFEXSUPERVISOR } from '../../../../data/userRole'
import { color } from '../../../../data/store'

const Bound = styled.div`
    height: 100%;
    margin: 15px 0 20px 0;
    /* overflow: auto; */
    overflow-x: auto;
    overflow-y: hidden;

    .block {
        display: grid;
        grid-template-columns: 112px 105px 186px 124px 105px 124px 186px 124px 81px 124px 124px 124px 108px;
        align-items: center;
        grid-column-gap: 5px;
        width: max-content;
    }
    .item-row {
        height: 48px;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 4px;
        cursor: pointer;
    }
    .date__color {
        color: #c8c8c8;
    }
    .bio-head {
        height: 48px;
        font-family: SVN-Gilroy;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.26;
        color: ${color.blue_005993};
    }
    .bio-body {
        /* height: 100%; */
        /* &::-webkit-scrollbar {
            width: 0px;
        } */
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: #222222;
        overflow-y: auto;
        overflow-x: hidden;
        width: max-content;
        height: calc(100% - 48px);

        /* ::-webkit-scrollbar {
            height: 8px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #c4c4c4;
            border-radius: 5px;
        } */
    }

    .first {
        padding-left: 20px;
    }
    .last {
        text-align: right;
    }
    .item-last {
        text-align: right;
        margin-right: 14px;
    }
    .name-custom {
        font-weight: bold;
    }

    .text-ellipst {
        ${'' /* width: 100px; */}
        ${'' /* width: 140px; */}
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${'' /* position: relative; */}
        pointer-events:none;
    }
    .w-100 {
        /* width: 103px;
        width: 132px;*/
        width: 124px;
    }
    .w-118 {
        width: 124px;
    }
    .w-147 {
        /* width: 147px;
        width: 189px;*/
        width: 186px;
    }
    .w-95 {
        width: 95px;
    }
    .w-72 {
        /* width: 72px; */
        width: 105px;
    }
    .w-50 {
        /* width: 52px; */
        width: 112px;
    }
    .text-ellipst:after {
        content: '';
        display: block;
        position: absolute;
        top: -15px;
        right: 0;
        width: 100%;
        height: 48px;
        z-index: 1;
        pointer-events: initial;
    }

    ${'' /* .text-ellipst:hover:after{
        cursor: pointer;
    } */}

    .cover {
        position: relative;
    }
    #tooltip_ {
        position: absolute;
        top: -66%;
        left: 50%;
        z-index: 999;
        width: max-content;
        visibility: hidden;
        background-color: #fff;
        padding: 11px;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .text-ellipst:hover + #tooltip_ {
        visibility: visible;
        transition: opacity 0.5s ease;
        opacity: 1;
    }

    .self-approval {
            color: rgb(15, 177, 31);
            font-weight: bold;
        }
`
const limitPerPage = 10

const CustomerType = {
    EXCUST: 'Thông thường',
    INCUST: 'Nội bộ',
}

class BiometricTable extends Component {
    state = {
        currentPage: 1,
    }

    onPageChange = (page) => {
        let currentPage = page
        this.setState({ currentPage })
        let dataFilter = this.props.biometricReducer.lastConditionFilterUser
        this.props.getBioInfos({
            ...dataFilter,
            PageNumber: currentPage,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(
                nextProps.biometricReducer.lastConditionFilterUser
            ) !==
            JSON.stringify(this.props.biometricReducer.lastConditionFilterUser)
        ) {
            this.setState({
                currentPage:
                    nextProps.biometricReducer.lastConditionFilterUser
                        .PageNumber,
            })
        }
    }

    showInfo = (item, dataCurrent) => {
        this.props.showPopupCheckBiometric(
            JSON.parse(JSON.stringify(item)),
            JSON.parse(JSON.stringify(dataCurrent))
        )
    }

    _genNameTitleByRole = () => {
        let nameTitle = ''
        const offInSupInRoles = this.props.roles.includes(OFFINSUPERVISOR)
        const offExSupInRoles = this.props.roles.includes(OFFEXSUPERVISOR)
        switch(true){
            case offInSupInRoles && offExSupInRoles:
                nameTitle = 'TÊN KH/CÁN BỘ'
                break;
            case offInSupInRoles:
                nameTitle = 'TÊN CÁN BỘ'
                break;
            default:
                nameTitle = 'TÊN KHÁCH HÀNG'
                break;
        }

        this.setState({ nameTitle })
    }

    render() {
        let { Total, lstCurrent } = this.props.biometricReducer
        let dataSlice = this.props.biometricReducer.dataBiometric
        let checkShowCount = dataSlice.find((item) => item.Status).Status
        return (
            <Bound checkShowCount={checkShowCount}>
                <div className="bio-head block">
                    <div className="cif-code first">
                        MÃ SỐ CIF
                    </div>
                    <div className="cif-code">GTTT</div>
                    <div className="name-head">{this.state.nameTitle}</div>
                    <div className="class-custom">
                        HẠNG KHÁCH HÀNG
                    </div>
                    <div className="type-custom">
                        LOẠI KHÁCH HÀNG
                    </div>
                    <div className="registor-gdv">
                        GDV ĐĂNG KÝ
                    </div>
                    <div className="type-data">
                        LOẠI DỮ LIỆU
                    </div>
                    <div className="type-status">
                        TRẠNG THÁI
                    </div>
                    <div className="time-waitting">
                        THỜI GIAN CHỜ
                    </div>
                    <div className="time-register">
                        THỜI GIAN ĐĂNG KÝ
                    </div>
                    <div className="user-approve">
                        NGƯỜI PHÊ DUYỆT
                    </div>
                    <div className="time-update">
                        THỜI GIAN PHÊ DUYỆT
                    </div>

                    <div className="control-page last">
                        {Total > limitPerPage ? (
                            <PagningControl
                                onChangePage={
                                    this.onPageChange
                                }
                                limitPerPage={limitPerPage}
                                totalItem={Total}
                                currentPage={
                                    this.state.currentPage
                                }
                            />
                        ) : null}
                    </div>
                </div>
                <div className="bio-body">
                    {dataSlice.map((item, index) => {
                        let dataCurrent = lstCurrent.find(
                            (it) => it.ID === item.ID
                        )
                        dataCurrent =
                            dataCurrent !== undefined &&
                            dataCurrent.CurrentBioData

                        let elmStatus,
                            elmBioInfosType,
                            colorsInfosType,
                            colorStatus,
                            valueStatus,
                            clsStatus
                        if (item.BioInfosType === REGISTER) {
                            elmBioInfosType = BioInfosType[REGISTER].label
                            colorsInfosType = BioInfosType[REGISTER].color
                        } else if (item.BioInfosType === UPDATE) {
                            elmBioInfosType = BioInfosType[UPDATE].contentLabel
                            colorsInfosType = BioInfosType[UPDATE].color
                        } else if (item.BioInfosType === NR) {
                            elmBioInfosType = BioInfosType[NR].label
                            colorsInfosType = BioInfosType[NR].color
                        } else if (item.BioInfosType === NR_FINGER) {
                            elmBioInfosType = BioInfosType[NR_FINGER].label
                            colorsInfosType = BioInfosType[NR_FINGER].color
                        }
                        /*else if(item.BioInfosType === UNBLOCK){
                            elmBioInfosType = BioInfosType[UNBLOCK].label
                            colorsInfosType = BioInfosType[UNBLOCK].color
                        }
                        else if(item.BioInfosType === BLOCK){
                            elmBioInfosType = BioInfosType[BLOCK].label
                            colorsInfosType = BioInfosType[BLOCK].color
                        }else if(item.BioInfosType === DELETE){
                            elmBioInfosType = BioInfosType[DELETE].label
                            colorsInfosType = BioInfosType[DELETE].color
                        }*/

                        if (item.Status === ACCEPT) {
                            elmStatus = Status[PENDING].label
                            colorStatus = Status[PENDING].color
                            clsStatus = Status[ACCEPT].colorA
                            valueStatus = Status[ACCEPT].label
                        } else if (item.Status === PENDING) {
                            elmStatus = Status[PENDING].label
                            colorStatus = Status[PENDING].color
                            clsStatus = Status[PENDING].colorP
                            valueStatus = Status[PENDING].value
                        } else {
                            elmStatus = Status[PENDING].label
                            colorStatus = Status[PENDING].color
                            clsStatus = Status[REJECT].colorR
                            valueStatus = Status[REJECT].label
                        }

                        return (
                            <React.Fragment key={index}>
                                <div
                                    className="item-row block"
                                    onClick={() =>
                                        this.showInfo(item, dataCurrent)
                                    }
                                >
                                    {/* <div className="cif-code first">{item.CustomerInfos.CustomerNumber}</div> */}
                                    <div className="cover">
                                        <div className="cif-code first text-ellipst w-50">
                                            {item.CustomerInfos.CustomerNumber}
                                        </div>
                                        {!!item.CustomerInfos
                                            .CustomerNumber && (
                                            <span id="tooltip_">
                                                {
                                                    item.CustomerInfos
                                                        .CustomerNumber
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div className="gttt-custom text-ellipst w-72">
                                            {item.CustomerInfos.IdNumber}
                                        </div>
                                        {!!item.CustomerInfos.IdNumber && (
                                            <span id="tooltip_">
                                                {item.CustomerInfos.IdNumber}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        {/* <div className="name-custom text-ellipst">{item.FullNameVi ?  item.FullNameVi : item.CustomerInfos.Name }</div> */}
                                        <div className="name-custom text-ellipst w-147">
                                            {item.CustomerInfos.Name}
                                        </div>
                                        {/* <span id="tooltip_">{item.FullNameVi ?  item.FullNameVi : item.CustomerInfos.Name }</span> */}
                                        {!!item.CustomerInfos.Name && (
                                            <span id="tooltip_">
                                                {item.CustomerInfos.Name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div className="class-custom text-ellipst w-100">
                                            {item.CustomerInfos.SegmentDesc}
                                        </div>
                                        {!!item.CustomerInfos.SegmentDesc && (
                                            <span id="tooltip_">
                                                {item.CustomerInfos.SegmentDesc}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div className="type-custom text-ellipst w-100">
                                            {CustomerType[item.CustomerType]}
                                        </div>
                                        {!!item.CustomerType && (
                                            <span id="tooltip_">
                                                {
                                                    CustomerType[
                                                        item.CustomerType
                                                    ]
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        <div className="registor-gdv text-ellipst w-100">
                                            {item.TellerInfos &&
                                                (!!item.TellerInfos.UserName
                                                    ? item.TellerInfos.UserName
                                                    : item.TellerInfos.Name)}
                                        </div>
                                        {!!item.TellerInfos.UserName && (
                                            <span id="tooltip_">
                                                {item.TellerInfos &&
                                                    (!!item.TellerInfos.UserName
                                                        ? item.TellerInfos
                                                              .UserName
                                                        : item.TellerInfos
                                                              .Name)}
                                            </span>
                                        )}
                                    </div>

                                    {/* <div className="satus-data" style = {{color: colorsInfosType}}>{elmBioInfosType}</div> */}

                                    <div className="cover">
                                        <div
                                            className="registor-gdv text-ellipst w-147"
                                            style={{
                                                cursor:
                                                    !!colorsInfosType &&
                                                    'pointer',
                                                color: colorsInfosType,
                                            }}
                                        >
                                            {elmBioInfosType}
                                        </div>
                                        {!!elmBioInfosType && (
                                            <span id="tooltip_">
                                                {colorsInfosType &&
                                                    elmBioInfosType}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cover">
                                        {/* <div className="registor-gdv text-ellipst w-100" style = {{fontWeight: 600, color: valueStatus === Status[PENDING].value && 'rgb(215, 18, 73)'}}>{valueStatus}</div> */}
                                        <div
                                            className="registor-gdv text-ellipst w-100"
                                            style={{
                                                fontWeight: 600,
                                                color: clsStatus,
                                            }}
                                        >
                                            {valueStatus}
                                        </div>
                                        {/* {
                                        valueStatus !== ''  && <span id="tooltip_">{valueStatus}</span>
                                    } */}
                                    </div>
                                    {/* <div className="item-status" style={{fontWeight: 600, color: valueStatus === Status[PENDING].value && 'rgb(215, 18, 73)'}}>{valueStatus}</div> */}
                                    {
                                        //item.Status === PENDING ? <div className="item-counttime"><CountDownTimeControl data = {item.CompleteAt}/></div> : <div></div>
                                        item.Status === PENDING ? (
                                            <div
                                                className="item-counttime" /* style={{ width: "8ch" }} */
                                            >
                                                <CountDownTimeControl
                                                    data={item.CreateAt}
                                                />
                                            </div>
                                        ) : (
                                            <div /* style={{ width: "8ch" }} */
                                            ></div>
                                        )
                                    }
                                    <div className="cover">
                                        <div className="time-registor-gdv text-ellipst w-118">
                                            {formatDateTimeToSpecificFormat(
                                                item.CreateAt,
                                                formatDateTime.HHmmDDMMYY
                                            )}
                                        </div>
                                        {!!item.CreateAt && (
                                            <span id="tooltip_">
                                                {formatDateTimeToSpecificFormat(
                                                    item.CreateAt,
                                                    formatDateTime.HHmmDDMMYY
                                                )}
                                            </span>
                                        )}
                                    </div>

                                    <div className="cover">
                                        <div
                                            className="user-approve-gdv text-ellipst w-118"
                                        >
                                            {!!item.SupervisorInfo &&
                                                item.SupervisorInfo.UserName}
                                        </div>

                                        <p className="self-approval">
                                            {!item.SupervisorInfo &&
                                                item.BioInfosType ===
                                                    'NOTREGISTER' &&
                                                item.Status === 'ACCEPT' &&
                                                'Auto'}
                                        </p>

                                        {!!item.SupervisorInfo && (
                                            <span id="tooltip_">
                                                {!!item.SupervisorInfo &&
                                                    item.SupervisorInfo.UserName}
                                            </span>
                                        )}
                                        {!item.SupervisorInfo &&
                                            item.BioInfosType ===
                                                'NOTREGISTER' &&
                                            item.Status === 'ACCEPT' && (
                                                <span id="tooltip_">
                                                    {!item.SupervisorInfo &&
                                                        item.BioInfosType ===
                                                            'NOTREGISTER' &&
                                                        item.Status ===
                                                            'ACCEPT' &&
                                                        'Auto'}
                                                </span>
                                            )}
                                    </div>

                                    <div className="cover">
                                        <div className="time-approve-gdv text-ellipst w-118">
                                            {!!item.CompleteAt &&
                                                item.Status !== 'PENDING' &&
                                                formatDateTimeToSpecificFormat(
                                                    item.CompleteAt,
                                                    formatDateTime.HHmmDDMMYY
                                                )}
                                        </div>
                                        {!!item.CompleteAt &&
                                            item.Status !== 'PENDING' && (
                                                <span id="tooltip_">
                                                    {formatDateTimeToSpecificFormat(
                                                        item.CompleteAt,
                                                        formatDateTime.HHmmDDMMYY
                                                    )}
                                                </span>
                                            )}
                                    </div>
                                    {/* <div className="time-update date__color">{formatDateTimeToSpecificFormat(item.CreateAt, formatDateTime.HHmmDDMMYY)}</div> */}
                                    {/* {
                                    item.Status === PENDING ? 
                                    <div className="item-last" style = {{color: colorStatus, textDecoration: 'underline'}}
                                    onClick = {() =>this.showInfo(item)}>{elmStatus}</div>
                                    :
                                    <div className="item-last" style = {{color: colorStatus, cursor: 'unset'}}>{elmStatus}</div>
                                } */}
                                    {/* <div className="item-last" style = {{color: colorStatus, textDecoration: 'underline'}} */}

                                    <div
                                        className="item-last"
                                        style={{
                                            color: colorStatus,
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() =>
                                            this.showInfo(item, dataCurrent)
                                        }
                                    >
                                        {elmStatus}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </Bound>
        )
    }

    componentDidMount() {
        this._genNameTitleByRole()
    }
    
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        biometricReducer: state.biometricReducer,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getBioInfos: (dataQuery) => {
            dispatch(getBioInfos(dataQuery))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(BiometricTable)
