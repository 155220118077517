import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import LoginPage from './Login/LoginPage'
import MainPage from './Main/MainPage'
// import PopupContainer from './Main/Popup/PopupContainer';
import PopupAlert from './Main/Popup/PopupAlert';

// helper
import setAuth from '../helper/setAuth';
// import DataVietNam from '../data/vietNamData/DataVietNam';

// action
import { logoutUser, loginUser, resetStore } from '../actions/userAction';
import { removeErrorConnection, removeErrorBranchCode, removeErrorConnectionTimeoutApi } from "../actions/commonAction";

const Bound = styled.div`
    width:100%;
    height:100%;
    display:flex;
    font-family:'SVN-Gilroy';
    font-weight:500;
`

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.popupAlert = {
            content: ''
        }
        this.state = {
            // isLogin: false,
            isShowAlert: false,
            isShowPopupError: false,
            isShow: false,
            err: ''
        }
    }

    alertPopup = (content) => {
        this.popupAlert.content = content
        this.setState({ isShowAlert: true })
    }
    onClosePopupAlert = () => {
        this.popupAlert = {}
        this.setState({ isShowAlert: false })
    }

    onClosePopupAlertS = () => {
        this.popupAlert = {}
        this.setState({ isShow: false })
    }
    render() {
        const { dataLogin, err } = this.props.userReducer;
        //---
        if (Object.keys(dataLogin).length === 0) {
            if(window.location.pathname !== "/"){
                window.location.pathname ="/"
            }
        }
        return (
            <Bound>
                {Object.keys(dataLogin).length > 0 ? (
                    <MainPage
                        logoutUser={this.props.logoutUser}
                        dataLogin={dataLogin}
                        resetStore={this.props.resetStore}
                    />
                ) : (
                    <LoginPage
                        alertPopup={this.alertPopup}
                        loginUser={this.props.loginUser}
                        error={err}
                    />
                )}
                {this.props.showErrConnection ? (
                    <PopupAlert
                        isCenter={true}
                        bgColor="rgba(0,0,0,0.6)"
                        title="Không thể kết nối được với hệ thống"
                        outPopup={this.props.removeErrorConnection}
                    />
                ) : (
                    this.state.isShowAlert && (
                        <PopupAlert
                            data={this.popupAlert}
                            bgColor="rgba(0,0,0,0.6)"
                            outPopup={() => {
                                this.onClosePopupAlert()
                            }}
                        />
                    )
                )}
                {this.props.errorConnectionTimeoutApi ? (
                    <PopupAlert
                        isCenter={true}
                        bgColor="rgba(0,0,0,0.6)"
                        width="323px"
                        title="Không nhận được phản hồi từ hệ thống. Vui lòng liên hệ quản trị viên để được hỗ trợ"
                        outPopup={this.props.removeErrorConnectionTimeoutApi}
                    />
                ) : null}
                {this.props.showErrorBranchCode && (
                    <PopupAlert
                        isCenter={true}
                        bgColor="rgba(0,0,0,0.6)"
                        alertPopup={this.alertPopup}
                        outPopup={this.props.removeErrorBranchCode}
                        title="Mã chi nhánh/PGD đã tồn tại."
                        // icon={ic_notification_success}
                    />
                )}
                {/* <DataVietNam/> */}
            </Bound>
        )
    }
}

const mapStateFromProps = (state) => ({
    userReducer: state.userReducer,
    showErrConnection: state.commonReducer.showErrorConnection,
    errorConnectionTimeoutApi: state.commonReducer.errorConnectionTimeoutApi,
    showErrorBranchCode: state.commonReducer.showErrorBranchCode,
})

const mapDispatchFromProps = (dispatch) => ({
    logoutUser: (id) => dispatch(logoutUser(id)),
    loginUser: (email, pass) => dispatch(loginUser(email, pass)),
    resetStore: () => {
        setAuth(false)
        dispatch(resetStore())
    },
    removeErrorConnection: () => dispatch(removeErrorConnection()),
    removeErrorConnectionTimeoutApi: () => dispatch(removeErrorConnectionTimeoutApi()),
    removeErrorBranchCode: () => dispatch(removeErrorBranchCode())
})

export default connect(mapStateFromProps, mapDispatchFromProps, null, { forwardRef: true })(MainContainer)
