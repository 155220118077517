import React, { useEffect, useRef, useState } from 'react'
import SubMenuItem from './SubMenuItem'
import { ContainerStyle, ContentStyle } from './index.styles'


export default function SubMenuListControl({contentStyle, index, dataList, onHideControl, onChoiceStatusOfItemChanged, onUpdateAllSelectedStatusOfItem, onSelectedAll, onUnSelectedAll}){
  const subMenuRef = useRef(null)
  const [subMenuList, setSubMenuList] = useState(dataList?dataList:[])
  const _handleClickOutside = (event) => {
    if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
      onHideControl && onHideControl()
    }
  };

  const _choiceStatusOfItemChanged = (item, status) => {
    onChoiceStatusOfItemChanged&&onChoiceStatusOfItemChanged(item, status)
  }

  const _updateAllSelectedStatusOfItem = (itemList, status) => {
    onUpdateAllSelectedStatusOfItem&&onUpdateAllSelectedStatusOfItem(itemList, status)
  }

  const _selectedAll = () => {
    const newSubMenuList = subMenuList.map((item) =>  {
      const newDataList = item.dataList.map((item)=>{
        return {
          ...item,
          selectedStatus: true
        }
      })
      //---
      return {
        ...item,
        dataList: newDataList
      }
    })
    setSubMenuList((newSubMenuList))
    //---
    onSelectedAll && onSelectedAll(newSubMenuList)
  }

  const _unSelectedAll = () => {
    const newSubMenuList = subMenuList.map((item) =>  {
      const newDataList = item.dataList.map((item)=>{
        return {
          ...item,
          selectedStatus: false
        }
      })
      //---
      return {
        ...item,
        selectedStatus: false,
        dataList: newDataList
      }
    })
    setSubMenuList((newSubMenuList))
    //---
    onUnSelectedAll && onUnSelectedAll(newSubMenuList)
  }

  const _checkAllSelectedStatus = () => {
    let result = true;
    for(let i = 0; i < subMenuList.length; i++){
      let subItem = subMenuList[i].dataList.find((item) => item.selectedStatus===false)
      if(subItem){
        result = false;
        break
      }
    }
    return result
  }

  useEffect(()=>{
    document.addEventListener('click', _handleClickOutside, true);
    return () => {
        document.removeEventListener('click', _handleClickOutside, true);
    };
  })

  useEffect(() => {
    setSubMenuList(dataList?dataList:[])
  }, [dataList])

  return (
    <ContainerStyle ref={subMenuRef} index={index}>
      <ContentStyle index={index} contentStyle={contentStyle??{borderWidth: 1}}>
        {
          subMenuList.map((menuItem) => {
            return (
                <SubMenuItem 
                    key={menuItem.id} 
                    label={menuItem.name}
                    dataList={menuItem.dataList}
                    onChoiceStatusChanged={_choiceStatusOfItemChanged}
                    onUpdateAllSelectedStatus={_updateAllSelectedStatusOfItem}
                />
            )
          })
        }
        <div className="footer">
          {
            _checkAllSelectedStatus()
            ? <div className="btn red" onClick={_unSelectedAll}>Bỏ chọn tất cả</div>
            : <div className="btn blue" onClick={_selectedAll}>Chọn tất cả</div>
          }
        </div>
      </ContentStyle>
    </ContainerStyle>
  )
}
