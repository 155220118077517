/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useReducer,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import SelectOfficeV2 from '../../controls/Tools/SelectOfficeV2'
import Table from './Table'
import SelectTeller from '../../controls/Tools/SelectTeller'
import SelectChannel from '../../controls/Tools/SelectChannel'

// style
import { extendStylesChannel, extendStylesOffice, extendStylesTeller } from './index.styles'
import {
    WrapGridTools,
    GridColTools,
    GridBtnToolsEnd,
} from '../../controls/Tools/index.styles'
// action
import {
    exportListDuplicatedFinger,
    getListDuplicatedFinger,
} from '../../../../../../../actions/reportsAction/detailsReport/biometrics/reportDuplicatedFinger'
import { getListOfUsername } from '../../../../../../../actions/commonAction'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'

// helper
import getChannelName from '../../../../../../../helper/getChannelName'

const INDEX_KENH_TAC_NGHIEP_TRUOC = 4
const INDEX_KENH_TAC_NGHIEP_SAU = 15

const ReportDuplicateFingers = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            transaction: null,
            service: null,
            beforeTeller: null,
            beforeSupervisor: null,
            beforeChannel: null,
            afterTeller: null,
            // afterSupervisor: null,
            afterChannel: null
        }
    )

    const [dataTable, setDataTable] = useState([])

    const [BranchIDs, BranchName, BranchCodes] = useMemo(() => {
        if (!stateFilter.office) return [null, null, null]
        const lenListOffice = stateFilter.office.length
        if (lenListOffice === 0) {
            return [null, null, null]
        }
        if (lenListOffice === 1) {
            const { key, value, BranchCode } = stateFilter.office[0]
            if (Array.isArray(key))
                return [key, value, BranchCode ? [BranchCode] : ['ALL']]
            return [[key], value, BranchCode ? [BranchCode] : ['ALL']]
        }

        const lstBranchId = [],
            lstBranchName = [],
            lstBranchCode = []

        const lstOfficeSort = [...stateFilter.office].sort((a, b) =>
            Number(a.BranchCode) - Number(b.BranchCode)
        )

        lstOfficeSort.forEach((element) => {
            lstBranchId.push(element.key)
            lstBranchName.push(element.value)
            lstBranchCode.push(element.BranchCode)
        })
        return [lstBranchId, lstBranchName.join(', '), lstBranchCode]
    }, [stateFilter.office])

    const _handleSubmit = useCallback(() => {
        const {
            fromDate,
            toDate,
            beforeChannel,
            afterTeller,
            afterChannel,
        } = stateFilter
        dispatch(
            getListDuplicatedFinger({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                ChannelCurrentCustomer: afterChannel?.key,
                ChannelDuplicatedCustomer: beforeChannel?.key,
                TellerCurrentCustomer: afterTeller,
                BranchName,
                BranchIDs,
            })
        )
    }, [stateFilter, BranchName, BranchIDs])

    const _handleExportExcel = useCallback(() => {
        const {
            fromDate,
            toDate,
            beforeChannel,
            afterTeller,
            afterChannel
        } = stateFilter
        dispatch(
            exportListDuplicatedFinger({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ReportCodeName: codeName,
                ChannelCurrentCustomer: afterChannel?.key,
                ChannelDuplicatedCustomer: beforeChannel?.key,
                TellerCurrentCustomer: afterTeller,
                BranchName,
                BranchIDs,
                BranchCodes,
            })
        )
    }, [stateFilter, BranchName, BranchIDs, BranchCodes])

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
        if (Roles.includes(ADMIN_ROLE)) return true

        if (!BranchName || !BranchIDs) return false
        dispatch(
            getListDuplicatedFinger({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
            })
        )
        return true
    }, [userReducer.dataLogin, BranchName, BranchIDs])

    useEffect(() => {
        const dataTable = detailsReport.listData.map((item) => {
            const channelBefore = item[INDEX_KENH_TAC_NGHIEP_TRUOC]
            const channelAfter = item[INDEX_KENH_TAC_NGHIEP_SAU]
            item[INDEX_KENH_TAC_NGHIEP_TRUOC] = getChannelName(channelBefore)
            item[INDEX_KENH_TAC_NGHIEP_SAU] = getChannelName(channelAfter)
            return item
        })
        setDataTable(dataTable)
    }, [detailsReport.listData])

    useEffect(() => {
        if(!BranchIDs){
            dispatch(getListOfUsername())
            return;
        }
        dispatch(getListOfUsername(BranchIDs))
    }, [BranchIDs])

    return (
        <div>
            <Header title={title} />

            <WrapGridTools numOfCol={4}>
                <GridColTools templateRow="repeat(2, 1fr)">
                    <SelectOfficeV2
                        defaultValue={stateFilter.office}
                        onChange={(office) => dispatchFilter({ office })}
                        extendStyles={extendStylesOffice}
                    />
                    <SelectChannel
                        defaultValue={stateFilter.beforeChannel}
                        onChange={(beforeChannel) => dispatchFilter({ beforeChannel })}
                        label="KENH_GIAO_DICH_TRUOC"
                        extendStyles={extendStylesChannel}
                    />
                    
                    
                </GridColTools>
                <GridColTools templateRow="repeat(2, 1fr)">
                    <SelectStartDate
                        defaultValue={stateFilter.fromDate}
                        onChange={(fromDate) => dispatchFilter({ fromDate })}
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                    <SelectChannel
                        defaultValue={stateFilter.afterChannel}
                        onChange={(afterChannel) => dispatchFilter({ afterChannel })}
                        label="KENH_GIAO_DICH_SAU"
                        extendStyles={extendStylesChannel}
                    />
                    
                    
                </GridColTools>
                <GridColTools templateRow="repeat(2, 1fr)">
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                    <SelectTeller
                        defaultValue={stateFilter.afterTeller}
                        onChange={(afterTeller) => dispatchFilter({ afterTeller })}
                        extendStyles={extendStylesTeller}
                        label="GDV_DIEN_TOAN_SAU"
                    />
                </GridColTools>
                <GridBtnToolsEnd numRow={2}>
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isDisable={!BranchIDs}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                        isDisable={!BranchIDs}
                    />
                </GridBtnToolsEnd>
            </WrapGridTools>
            <Table
                lstData={dataTable}
            />
        </div>
    )
}

export default ReportDuplicateFingers
