/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { DropDownContainer, DropDownMenuItem } from './index.styles'

export default function DropDownControl({
    isDisabled, 
    choicedData, 
    menuList, 
    showSubMenuList, 
    onSelectedItem, 
    getShowSubMenuListStatus,
    labelStyle,
    menuStyle
}) {
    const [isShowMenu, setIsShowMenu] = useState(false)
    //---
    const menuRef = useRef(null)
    const handleClickOutside = (event) => {
        setTimeout(()=>{
            const isShowSubMenuList = getShowSubMenuListStatus()
            if(isShowSubMenuList){
                return
            }
            //---
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsShowMenu(false)
            }
        }, 50)
       
    };
    //---
    const onShowHideMenuList = () => {
        if(isDisabled){
            return
        }
        if(menuList && menuList.length > 0){
            setIsShowMenu(!isShowMenu)
        }
    }
    const onShowSubMenuList = (index, menuItem) => {
        onSelectedItem && onSelectedItem(index, menuItem)
        if(menuItem.dataList&&menuItem.dataList.length>0){
            showSubMenuList && showSubMenuList(index, menuItem.dataList)
        }else{
            setIsShowMenu(false)
        }
    }

    const getNumberOfItems = (menuIndex)=>{
        let result = 0;
        if(menuList[menuIndex].dataList && menuList[menuIndex].dataList.length > 0){
            const subDataList = menuList[menuIndex].dataList
            if(subDataList && subDataList.length > 0){
                for(let i = 0; i < subDataList.length; i++){
                    const childSubDataList = subDataList[i].dataList
                    if(childSubDataList && childSubDataList.length > 0){
                        for(let j = 0; j < childSubDataList.length; j++){
                            const subItem = childSubDataList[j]
                            if(subItem.selectedStatus){
                                result += 1
                            }
                        }
                    }else {
                        result += 1
                    }
                }
            }
        }
        return result
    }

    const getLabelOfChoicedData = (choicedData) => {
        let labelValue = choicedData.label;
        if(labelValue==="" || choicedData.indexSelected === 0){
            labelValue = menuList[choicedData.indexSelected].name
        }
        labelValue += choicedData.indexSelected!==0?` (${choicedData.dataList.length})`:''
        return labelValue;
    }


    useEffect(()=>{
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [])
    

    return (
        <DropDownContainer 
            ref={menuRef}
            isShowMenu={isShowMenu} 
            isDisabled={isDisabled}
            numberOfMenuItem={menuList.length}
            labelStyle={labelStyle??{borderWidth: 1,paddingTopBottom: 15,paddingLeftRight: 13}}
            menuStyle={menuStyle??{top: 50, borderWidth: 1}}>
            <div className="labelContent" onClick={onShowHideMenuList}>
                <div className="value">{getLabelOfChoicedData(choicedData)}</div>
                {
                    menuList && menuList.length > 0 &&
                    <div className="arrow"/>
                }
            </div>
            {
                menuList && menuList.length  > 0 &&
                <div className="menuContent">
                   {
                        menuList.map((menuItem, index) => {
                            return (
                                <DropDownMenuItem 
                                    key={menuItem.id} 
                                    isFirstItem={index === 0}
                                    onClick={()=>{
                                        onShowSubMenuList(index, menuItem)
                                    }}
                                >   
                                    <div className="value">
                                        {
                                            getNumberOfItems(index) === 0
                                            ? menuItem.name
                                            : `${menuItem.name} (${getNumberOfItems(index)})`
                                        }
                                    </div>
                                    {
                                        menuItem.dataList && menuItem.dataList.length > 0 &&
                                        <div className="arrow"/>
                                    }
                                </DropDownMenuItem>
                            )
                        })
                   }
                </div>
            }
        </DropDownContainer>
    )
}
