import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import ic_more_gray from "../../../../../../images/ic_more_gray.svg";
import { TransBound, TransDetailContent } from './index.styles';
import { formatDateTime, formatDateTimeToSpecificFormat } from '../../../../../../helper/formatTime';
import { isDate } from '../../../../../../helper/checkValidity';

/**
 * dataInfo struct
 * @param {string*} ID //id of group service
 * @param {string*} Name //name of group service 
 * @param {string*} GroupCode //group code
 * @param {string*} Status //status
 * @param {string*} Description //description
 * @param {string*} UpdateAt //updateAt
 * @param {[]transactionService} Children data list of group service
 */

/**
 * transactionService struct
 *  @param {string*} ID //id of service
 *  @param {string*} Name //name of service
 *  @param {string*} Status //status
 *  @param {string*} Description //description
 *  @param {string*} ParentID //id of group service
 *  @param {string*} GroupCode //group code
 */
export default function DropdownItem({isRoot, index, dataInfo, onClick, onDoubleClick, onShowTransPopup, onShowOptionPopup}) {
  const isWinOperatingSystem = window.navigator.userAgent.indexOf("Win") !== -1

  const [isShowMenu, setIsShowMenu] = useState(false)

  let isShowTransPoup = false

  const onShowHideMenuList = () => {
    if(isRoot){
        setIsShowMenu(!isShowMenu)
    }
  }

  const renderItem = (index, dataInfo) => {
    const onClickAction = () => {
      if(isShowTransPoup){
        return
      }
      onShowHideMenuList()
      //---
      onClick && onClick(index, dataInfo)
    }

    const onDoubleClickAction = () => {
      onDoubleClick && onDoubleClick(index, dataInfo)
    }

    const onShowTransPopupAction = () => {
      isShowTransPoup = true;
      onShowTransPopup && onShowTransPopup(index, dataInfo)
      setTimeout(()=>{
        isShowTransPoup = false
      }, 300)
    }

    const showOptionPopup = (index, dataInfo) => {
      onShowOptionPopup && onShowOptionPopup(index, dataInfo);
    }

    return (
      <TransBound 
        isRoot={isRoot} 
        isShowMenu={isShowMenu}  
        onClick={onClickAction} 
        onDoubleClick={onDoubleClickAction}
      >
        <div className="left-content">
          <div className="label">{dataInfo.Name}</div>
          <TransDetailContent isRoot={isRoot} status={dataInfo.Status} isWinOperatingSystem={isWinOperatingSystem}>
            <div className="column">
              {
                isRoot
                ? `Mã GROUP: ${dataInfo.GroupCode}`
                : `Mã giao dịch: ${dataInfo.ServiceType}`
              }
            </div>
            <div className="column status">
              Trạng thái: 
              {
                dataInfo.Status!=='' &&
                <div className="statusIcon"/>
              }
              
              {
                dataInfo.Status===''
                ? ' -'
                : dataInfo.Status==='A'
                  ?'Đang hoạt động'
                  :'Không hoạt động'
              }
            </div>
            <div className="column">
              Lần cập nhật gần nhất: 
              { 
                isDate(dataInfo.UpdateAt)
                ? ` ${formatDateTimeToSpecificFormat(
                            dataInfo.UpdateAt,
                            formatDateTime.DDMMYYYYHHmmss
                  )}`
                : ' -'
              }
            </div>
            <Tooltip title={dataInfo.Description}>
              <div className="column">Mô tả: {dataInfo.Description===''?' -':dataInfo.Description}</div>
            </Tooltip>
          </TransDetailContent>
        </div>
        <div className="right-content">
          {
            isRoot
            ? <>
                <div className="value"
                  onClick={onShowTransPopupAction}>TUỲ CHỈNH</div>
                <div className="arrow"/>
              </>
            : <div className="button-container">
                <img
                  src={ic_more_gray}
                  className="moreButton"
                  id={"more_button_id_" + index}
                  alt="ic_more"
                  onClick={() => {
                    showOptionPopup(index, dataInfo);
                  }}
                />
            </div>
          }
        </div>
        
        
      </TransBound>
    )
  }

  useEffect(() => {
  }, [])

  return (
    renderItem(index, dataInfo)
  )
}
