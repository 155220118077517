/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react'
import moment from 'moment'

// styles
import { Button } from './index.styles'

// image
import ic_close from '../../../../../../../../images/ic_close.svg'

// component
import Modal from '../../../../../../../../helper/Modal'
import PopupAlert from '../../../../../../Popup/PopupAlert'

const ButtonFilter = ({
    onClick,
    className,
    data,
    isDisable = false,
    isEnableCheckRangeDate = true,
    ...props
}) => {
    const [error, setError] = useState(null)

    const _handleClick = useCallback(() => {
        const { fromDate, toDate } = data
        if (!fromDate || !toDate) return

        const checkMatchRangeDate = moment(toDate).diff(fromDate)
        if(checkMatchRangeDate < 0){
            setError({
                content: '"Từ ngày" phải nhỏ hơn "đến ngày"',
                iconData: ic_close,
                width: '264px'
            })
            return;
        }

        const range = Math.abs(moment(toDate).diff(fromDate, 'days'))
        // console.log(range)
        if (isEnableCheckRangeDate && range > 30) {
            setError({
                content: 'Vui lòng chọn khoảng thời gian từ 30 ngày trở xuống',
                iconData: ic_close,
            })
            return
        }
        onClick()
    }, [data])

    return (
        <>
            <Button
                className={`tools-item ${className}`}
                isDisable={isDisable}
                onClick={_handleClick}
                {...props}
            >
                Xem dữ liệu
            </Button>
            <Modal isShow={error}>
                <PopupAlert
                    width={error?.width}
                    data={error}
                    bgColor="rgba(0,0,0,0.6)"
                    outPopup={() => setError(null)}
                />
            </Modal>
        </>
    )
}

export default ButtonFilter
