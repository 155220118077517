/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import DropDownControl from './DropDownControl'
import SubMenuListControl from './SubMenuControl'
import { CheckBoxStatus } from '../CheckBoxControlV2'

export default function MultiChoiceDropdownControl({labelStyle, menuStyle, subMenuStyle, isDisabled, mainLabel = "", dataList, onUpdateChoiceItemList}) {
  const [isShowSubMenuList, setIsShowSubMenuList] = useState(false)
  const [choicedData, setChoicedData] = useState({
    indexSelected: 0,
    dataList: []
  })
  const menuListRef = useRef(dataList)
  const subMenuListRef =useRef(null)

  const onShowSubMenuList = (index, dataList) => {
    if(subMenuListRef.current && index === subMenuListRef.current.index){
        return
    }
    //---
    if(dataList && dataList.length > 0){
        subMenuListRef.current = {
            index: index,
            dataList: dataList
        }
        setIsShowSubMenuList(true)
    }
  }

  const onHideSubMenuList = () => {
    subMenuListRef.current = null
    //---
    if(choicedData.dataList.length===0){
        const newDataList = allOptionsSelected()
        //---
        const newChoicedData = {
            indexSelected: 0,
            label: mainLabel,
            dataList: newDataList
        } 
        setChoicedData(newChoicedData)
        //---
        onUpdateChoiceItemList(newChoicedData)
    }
    //---
    setIsShowSubMenuList(false)
  }

  const allOptionsSelected = () => {
    let selectedList = []
    const newMenuList = menuListRef.current.map((menuItem)=>{
        const groupMenuList = menuItem.dataList
        if(groupMenuList && groupMenuList.length > 0){
            groupMenuList.forEach((groupMenuItem) => {
                const subMenuList = groupMenuItem.dataList
                if(subMenuList && subMenuList.length > 0){
                    subMenuList.forEach((subMenuItem) => {
                        subMenuItem.selectedStatus = true
                        selectedList.push(subMenuItem)
                    })
                }
            });
        }
        return menuItem       
    })
    menuListRef.current = newMenuList
    //---
    return selectedList
  }

  const onChoiceStatusOfItemChanged = (menuItem, status) => {
    const newChoicedData = {
        indexSelected: choicedData.indexSelected,
        label: mainLabel,
        dataList: choicedData.dataList
    } 
    //---
    if(status){
        newChoicedData.dataList.push(menuItem)
        //---
        updateMenuList(newChoicedData.indexSelected, menuItem.id, true)
    } else {
        const index = newChoicedData.dataList.findIndex((item)=>item.id === menuItem.id)
        if(index !== -1){
            newChoicedData.dataList.splice(index, 1)
            //---
            updateMenuList(newChoicedData.indexSelected, menuItem.id, false)
        }
    }
    //---
    if(newChoicedData.dataList.length > 0 &&
        newChoicedData.indexSelected !== subMenuListRef.current.index
        ){
            newChoicedData.indexSelected = subMenuListRef.current.index
        }
    //---
    onUpdateChoiceItemList(newChoicedData)
    //---
    setChoicedData(newChoicedData)
  }

  const onAllSelectedStatusOfItemChanged = (itemList, status) => {
    const newChoicedData = {
        indexSelected: choicedData.indexSelected,
        label: mainLabel,
        dataList: choicedData.dataList
    } 
    //----
    for(let i = 0; i < itemList.length; i++){
        const subItem = itemList[i]
        //---
        const index = newChoicedData.dataList.findIndex((item)=>item.id === subItem.id)
        if(status === CheckBoxStatus.ALL_SELECTED){
            if(index === -1){
                newChoicedData.dataList.push(subItem)
                //---
                updateMenuList(newChoicedData.indexSelected, subItem.id, true)
            }
        } else {
            if(index !== -1){
                newChoicedData.dataList.splice(index, 1)
                //---
                updateMenuList(newChoicedData.indexSelected, subItem.id, false)
            }
        }
    }
    
    onUpdateChoiceItemList(newChoicedData)
    //---
    setChoicedData(newChoicedData)
  }

  const updateMenuList = (menuIndexSelected, subItemIndex, selectedStatus) => {
    const groupMenuList = menuListRef.current[menuIndexSelected].dataList
    if(groupMenuList && groupMenuList.length > 0){
        for(let i = 0; i < groupMenuList.length; i++){
            const subMenuItem = groupMenuList[i].dataList.find((item)=>item.id === subItemIndex)
            if(subMenuItem){
                subMenuItem.selectedStatus = selectedStatus
                break
            }

        }
    }
  }

  const onSelectedAllOfSubMenuList = (subMenuList) => {
    const newChoicedData = {
        indexSelected: choicedData.indexSelected,
        label: mainLabel,
        dataList: choicedData.dataList
    } 
    //----
    for(let i = 0; i < subMenuList.length; i++){
        const menuSubItem = subMenuList[i]
        for(let j = 0; j < menuSubItem.dataList.length; j++){
            const subItem = menuSubItem.dataList[j];
            const index = newChoicedData.dataList.findIndex((item)=>item.id === subItem.id)
            if(index === -1){
                newChoicedData.dataList.push(subItem)
                //---
                updateMenuList(newChoicedData.indexSelected, subItem.id, true)
            }
        }
       
    }
    
    onUpdateChoiceItemList(newChoicedData)
    //---
    setChoicedData(newChoicedData)
  }

  const onUnSelectedAllOfSubMenuList = (subMenuList) => {
    const newChoicedData = {
        indexSelected: choicedData.indexSelected,
        label: mainLabel,
        dataList: choicedData.dataList
    } 
    //----
    for(let i = 0; i < subMenuList.length; i++){
        const menuSubItem = subMenuList[i]
        for(let j = 0; j < menuSubItem.dataList.length; j++){
            const subItem = menuSubItem.dataList[j];
            const index = newChoicedData.dataList.findIndex((item)=>item.id === subItem.id)
            if(index >= 0){
                newChoicedData.dataList.splice(index, 1)
                //---
                updateMenuList(newChoicedData.indexSelected, subItem.id, false)
            }
        }
       
    }
    
    onUpdateChoiceItemList(newChoicedData)
    //---
    setChoicedData(newChoicedData)
  }

  const onSelectedMenuItem = (index, menuItem) => {
    if(index !== choicedData.indexSelected){
        const newChoicedData = {
            indexSelected: index,
            label: mainLabel,
            dataList: mainLabel!==""?choicedData.dataList:[]
        }
        if(index === 0){
            const newDataList  = allOptionsSelected()
            newChoicedData.dataList = newDataList
        } else {
            if(mainLabel===""){
                newChoicedData.dataList = getSubChoicedList(menuItem)
            }
        }
        //---
        onUpdateChoiceItemList(newChoicedData)
        //---
        setChoicedData(newChoicedData)
    }
  }

  const getSubChoicedList = (menuItem)=>{
    let subChoicedList = []
    const groupMenuList = menuItem.dataList
    if(groupMenuList && groupMenuList.length > 0){
        groupMenuList.forEach((groupMenuItem) => {
            const subMenuList = groupMenuItem.dataList
            if(subMenuList && subMenuList.length > 0){
                subMenuList.forEach((subMenuItem) => {
                    if(subMenuItem.selectedStatus){
                        subChoicedList.push(subMenuItem)
                    }
                })
            }
        });
    }
    return subChoicedList;
  }

  const getChoicedData = (menuList)=>{
    const newChoicedData = {
        indexSelected: 0,
        label: mainLabel,
        dataList: []
    } 
    if(menuList && menuList.length > 0){
        for(let i=0; i < menuList.length; i++){
            if(menuList[i].selectedStatus){
                newChoicedData.dataList = [...newChoicedData.dataList, ...getSubChoicedList(menuList[i])];
                if(newChoicedData.dataList.length>0){
                    newChoicedData.indexSelected = i;
                }
                //---
                if(newChoicedData.label === ""){
                    break
                }
            }
        }
    }
    return newChoicedData;
  }

  const getShowSubMenuListStatus = () => {
    return subMenuListRef.current !== null;
  }

  const updateChoicedData = () => {
    const newChoicedData = getChoicedData(menuListRef.current)
    //---
    if(isDisabled){
        newChoicedData.indexSelected = 0
    } else {
        if(newChoicedData.indexSelected === 0){
            const newDataList = allOptionsSelected()
            newChoicedData.dataList = newDataList
        }
    }
    //---
    setChoicedData(newChoicedData)
    //---
    onUpdateChoiceItemList(newChoicedData)
    //---
    console.log("choicedData-control: ", newChoicedData);
  }

  useEffect(() => {
    menuListRef.current = dataList
    //---
    updateChoicedData()
  }, [dataList])

  useEffect(() => {
    return () => {
        menuListRef.current = null;
        subMenuListRef.current = null;
    }
  }, [])

  return (
    <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
        <DropDownControl 
            isDisabled={isDisabled}
            choicedData={choicedData} 
            menuList={menuListRef.current}
            showSubMenuList={onShowSubMenuList}
            onSelectedItem={onSelectedMenuItem}
            getShowSubMenuListStatus={getShowSubMenuListStatus}
            labelStyle={labelStyle}
            menuStyle={menuStyle}
        />
        {
            isShowSubMenuList &&
            <div style={{position:"relative", zIndex:'10', width: "100%"}}>
            <SubMenuListControl 
                index={subMenuListRef.current.index} 
                dataList={subMenuListRef.current.dataList}
                onHideControl={onHideSubMenuList}
                onChoiceStatusOfItemChanged={onChoiceStatusOfItemChanged}
                onUpdateAllSelectedStatusOfItem={onAllSelectedStatusOfItemChanged}
                onSelectedAll={onSelectedAllOfSubMenuList}
                onUnSelectedAll={onUnSelectedAllOfSubMenuList}
                contentStyle={subMenuStyle}
            />
            </div>
        }
    </div>
  )
}
