import styled from "styled-components";

const WrapContainer = styled.div`
    display:flex;
    flex-direction:column;
    width: calc(100% - 40px);
    height: 300px;
    background-color: white;
    border: 1px solid #E6E6E6;
    margin-top: 10px;
    padding: 20px;

    .row-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .item {
            width: calc((100% - 40px)/2);
        }
    }
`

export {WrapContainer}