import React, { Component } from "react";
import styled from "styled-components";

//Data
import { formatDayAndTime } from "../../../../tools";
import { color } from "../../../../data/store";


const StyledHeader = styled.div`
    height: 80px;
    background: #ffffff;
    padding: 0 40px 0 20px;
    flex-shrink: 0;
    &,
    & > .right-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    * {
        font-family: SVN-Gilroy;
        font-weight: bold;
    }

    .title {
        font-size: 32px;
        line-height: 1.29;
        color: #222222;
        margin-left: ${props => props.isShrink?'0':'20px'};
    }

    .main-flex{
        display: flex;
        .right-header__title {
            font-size: 12px;
            line-height: 1.42;
            color: ${color.blue_005993};
        }

        .time {
            font-weight: 500;
            width: 57px;
            padding-left: 15px;
        }
    }
`;

export default class HeaderControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: "",
            title: this.props.title,
            isLoading: false
        };

        // get time
        this.getTimeNow = {};
    }

    componentWillMount() {
        this.getTimeNow = setInterval(() => {
            this.setState({ time: formatDayAndTime().getFullTime });
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({ title: nextProps.title });
        }
    }

    render() {
        return (
            <StyledHeader className='header' isShrink={this.props.isShrink}>
                <div className="title">{this.state.title}</div>
                <div className="right-header">
                    <div className='main-flex'>
                        <div className="right-header__title">Thời gian hệ thống:</div>
                        <div className="time">{this.state.time}</div>
                    </div>
                </div>
            </StyledHeader>
        );
    }

    componentWillUnmount() {
        clearInterval(this.getTimeNow);
    }
}
