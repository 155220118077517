import * as types from "../../actions/types";

const initialState = {
    listOffice: [],
    allOfficeList: [],
    allBranchList: [],
    total: 3,
    err: '',
    dataFilter: {},
    alert: '',
    textSearch: ''
}

export default function filterOfficeReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLEAR_FILTER_OFFICE:
            return initialState
        case types.FILTER_OFFICE:
            return {
                ...state,
                listOffice: action.listOffice,
                total: action.total,
                dataFilter: action.dataFilter,
            }
        case types.FILTER_OFFICE_ERR:
            return {
                ...state,
                err: action.err
            }
        case types.GET_ALL_OFFICE:
            return {
                ...state,
                allOfficeList: action.allOfficeList,
                total: action.total,
                dataFilter: action.dataFilter,
            }
        case types.GET_ALL_OFFICE_ERR:
            return {
                ...state,
                err: action.err
            }
        case types.ALERT_CONTENT:
            return {
                ...state,
                alert: action.alert
            }
        case types.SAVE_TEXT_OFFICE:
            return {
                ...state,
                textSearch: action.textSearch
            }
        case types.CLEAR_TEXT_OFFICE:
            return {
                ...state,
                textSearch: ''
            }
        case types.GET_ALL_BRANCH:
            return {
                ...state,
                allBranchList: action.allBranchList,
            }
        case types.GET_ALL_BRANCH_ERR:
            return {
                ...state,
                err: action.err
            }
        default:
            return state
    }
}