import React, { useEffect, useRef, useState } from 'react'
import { ContainerStyled } from './index.styles'
import { formatDayAndTime } from '../../../../../../tools';

export default function RealTimeSystem() {
    const [timeNow, setTimeNow] = useState('')
    let timeNowInterval = useRef(null)

    useEffect(()=>{
        timeNowInterval.current = setInterval(() => {
            setTimeNow(formatDayAndTime().getFullTime)
        }, 1000);
        //---
        return ()=>{
            clearInterval(timeNowInterval.current)
        }
    }, [])

  return (
    <ContainerStyled>
        <div className="label">Thời gian hệ thống:</div>
        <div className="value">{timeNow}</div>
    </ContainerStyled>
  )
}
