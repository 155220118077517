/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import icQRCode from "../../../../images/Menu/ic_qr_code.svg"
import SubHeaderControlV2 from "../Control/SubHeaderControlV2";
import { useSelector } from "react-redux";
import {officeUtils} from "../../../Utils/OfficeUtils";


const CreateQRCode = ({headerData, onSubmited}) => {
    const { dataLogin, generalOfficeSelected } = useSelector((state) => ({
        dataLogin: state.userReducer.dataLogin,
        generalOfficeSelected: state.officeManagementReducer.generalOfficeSelected
    }))
    //--
    let mainTitle = officeUtils.getOfficeName({
        dataLogin: dataLogin,
        generalOfficeSelected: generalOfficeSelected
    })
    //--
    return ( 
        <div className="createQRContainer">
            <SubHeaderControlV2
                data={{...headerData, mainTitle: mainTitle}}
                isShowOfficeDropdown={true}
            />
            <div className="content">
                <div className="qr-code">
                    <img src={icQRCode} alt="ic_qr_code" className="icon" />
                </div>
                <div className="text">
                    Bấm “Tạo mã QR” để bắt đầu
                </div>
                <button
                    onClick={() => {
                        onSubmited && onSubmited()
                    }}
                >
                    Tạo mã QR
                </button>
            </div>
        </div>
     );
}
 
export default CreateQRCode;