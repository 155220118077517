import createOfficeReducer from "./createOfficeReducer";
import filterOfficeReducer from './filterOfficeReducer';
import updateOfficeReducer from './updateOfficeReducer';
import deleteOfficeReducer from './deleteOfficeReducer';
import officeManagementReducer from "./officeManagementReducer";

export const officeReducer = {
  createOfficeReducer,
  filterOfficeReducer,
  updateOfficeReducer,
  deleteOfficeReducer,
  officeManagementReducer
}
