/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useEffect,
    useState,
    useCallback,
    useReducer,
    useMemo,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

// styles
import { WrapReport, extendStylesTable } from './index.styles'
import {
    GridColTools,
    WrapGridTools,
    WrapLineTools,
} from '../../controls/Tools/index.styles'

// component
import Header from '../../controls/Header'
import ExportExcel from '../../controls/Tools/ExportExcel'
import ButtonFilter from '../../controls/Tools/ButtonFilter'
import Table from '../../controls/Table'
import SelectEndDate from '../../controls/Tools/SelectEndDate'
import SelectStartDate from '../../controls/Tools/SelectStartDate'
import SelectOfficeV2 from '../../controls/Tools/SelectOfficeV2'

// hooks
import useDidMount from '../../../../../../../helper/Hooks/useDidMount'

// constant
import { ADMIN_ROLE } from '../../../../../../../data/userRole'
import SelectCustomerType from '../../controls/Tools/SelectCustomerType'
import SelectServiceType from '../../controls/Tools/SelectServiceType'

// action
import { 
    exportReportSumariesChooseOneServ, 
    getReportSumariesChooseOneServ 
} from '../../../../../../../actions/reportsAction/detailsReport/customerJourney/sumariesChooseOneServ'

const lstTitleCols = [
    "STT",
    "Mã CN",
    "Mã ĐGD",
    "Tên ĐGD",
    "Loại KH",
    "Mã loại GD",
    "Loại hình giao dịch",
    "Số lượng giao dịch",
    "Tổng thời gian xử lý giao dịch",
    "Tổng thời gian chờ",
    "Tổng thời gian tư vấn, bán hàng",
    "Tổng thời gian thực hiện GD",
    "Thời gian chờ bình quân",
    "Thời gian thực hiện GD bình quân"
]

const ReportSumariesChooseOneServ = ({ title, codeName, ...props }) => {
    const DEFAULT_FROM_DATE = useMemo(() => moment().startOf('days'), [])
    const DEFAULT_TO_DATE = useMemo(() => moment().endOf('days'), [])

    const dispatch = useDispatch()
    const { detailsReport, userReducer } = useSelector((state) => ({
        detailsReport: state.reports.detailsReport,
        userReducer: state.userReducer,
    }))

    const [stateFilter, dispatchFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fromDate: DEFAULT_FROM_DATE.toDate(),
            toDate: DEFAULT_TO_DATE.toDate(),
            office: null,
            transaction: null,
            customerType: '',
            serviceTypeList: []
        }
    )

    const [dataTable, setDataTable] = useState([])

    const [BranchIDs, BranchName, BranchCodes] = useMemo(() => {
        if (!stateFilter.office) return [null, null, null]
        const lenListOffice = stateFilter.office.length
        if (lenListOffice === 0) {
            return [null, null, null]
        }
        if (lenListOffice === 1) {
            const { key, value, BranchCode } = stateFilter.office[0]
            if (Array.isArray(key))
                return [key, value, BranchCode ? [BranchCode] : ['ALL']]
            return [[key], value, BranchCode ? [BranchCode] : ['ALL']]
        }

        const lstBranchId = [],
            lstBranchName = [],
            lstBranchCode = []

        const lstOfficeSort = [...stateFilter.office].sort((a, b) =>
            Number(a.BranchCode) - Number(b.BranchCode)
        )

        lstOfficeSort.forEach((element) => {
            lstBranchId.push(element.key)
            lstBranchName.push(element.value)
            lstBranchCode.push(element.BranchCode)
        })
        return [lstBranchId, lstBranchName.join(', '), lstBranchCode]
    }, [stateFilter.office])

    const _handleSubmit = useCallback(() => {
        const { fromDate, toDate, transaction, customerType, serviceTypeList } = stateFilter
        dispatch(
            getReportSumariesChooseOneServ({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode: transaction?.key,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
                CustomerType: customerType,
                ServiceTypes: serviceTypeList
            })
        )
    }, [stateFilter, BranchName, BranchIDs])

    const _handleExportExcel = useCallback(() => {
        const { fromDate, toDate, transaction, customerType, serviceTypeList } = stateFilter
        dispatch(
            exportReportSumariesChooseOneServ({
                FromDate: fromDate.toISOString(),
                ToDate: toDate.toISOString(),
                ServiceCode: transaction?.key,
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
                BranchCodes,
                CustomerType: customerType,
                ServiceTypes: serviceTypeList
            })
        )
    }, [stateFilter, BranchName, BranchIDs, BranchCodes])

    const _onUpdateCustomerType = (customerTypeSelected) => {
        dispatchFilter({ customerType: customerTypeSelected.key })
        // console.log("_onUpdateCustomerType: ", customerTypeSelected);
    }

    const _onUpdateServiceTypeChoiced = (dataList) => {
        dispatchFilter({ serviceTypeList: dataList })
        // console.log("onUpdateServiceTypeChoiced: ", dataList);
    }

    useDidMount(() => {
        if (!userReducer.dataLogin) return false
        const { Roles } = userReducer.dataLogin
        if (Roles.includes(ADMIN_ROLE)) return true

        if (!BranchName || !BranchIDs) return false
        dispatch(
            getReportSumariesChooseOneServ({
                FromDate: DEFAULT_FROM_DATE.toISOString(),
                ToDate: DEFAULT_TO_DATE.toISOString(),
                ReportCodeName: codeName,
                BranchName,
                BranchIDs,
                CustomerType: '',
                ServiceTypes: []
            })
        )
        return true
    }, [userReducer.dataLogin, BranchName, BranchIDs])

    useEffect(() => {
        setDataTable(detailsReport.listData)
    }, [detailsReport.listData])

    return (
        <WrapReport>
            <Header title={title} />
            <WrapGridTools numOfCol={5}>
                <GridColTools templateRow="none">
                    <SelectOfficeV2
                        defaultValue={stateFilter.office}
                        onChange={(office) => dispatchFilter({ office })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectStartDate
                        defaultValue={stateFilter.fromDate}
                        onChange={(fromDate) => dispatchFilter({ fromDate })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <SelectEndDate
                        defaultValue={stateFilter.toDate}
                        onChange={(toDate) => dispatchFilter({ toDate })}
                    />
                </GridColTools>
                <GridColTools templateRow="none">
                    <ButtonFilter
                        data={stateFilter}
                        onClick={_handleSubmit}
                        isDisable={!BranchIDs}
                    />
                    <ExportExcel
                        onClick={_handleExportExcel}
                        isDisable={!BranchIDs}
                    />
                </GridColTools>
            </WrapGridTools>
            <WrapLineTools/>
            <WrapGridTools numOfCol={2}>
                <GridColTools templateRow="none">
                    <SelectCustomerType
                        onChange={_onUpdateCustomerType}
                    />
                </GridColTools>

                <GridColTools templateRow="none">
                    <SelectServiceType
                        customerTypeSelected={stateFilter.customerType}
                        onChange={_onUpdateServiceTypeChoiced}
                    />
                </GridColTools>
               
            </WrapGridTools>
            <Table
                lstTitleCols={lstTitleCols}
                lstData={dataTable}
                extendStyles={extendStylesTable}
                headerHeight={390}
            />
        </WrapReport>
    )
}

export default ReportSumariesChooseOneServ
