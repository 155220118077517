import styled from "styled-components";
import { color } from "../../../../../data/store";

const DropDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    
    .labelContent {
        display: flex;
        height: 30px;
        min-height: 30px;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background-color: ${color.blue_005993};
        color: #fff;
        padding: 8px 12px;
        box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
        .value {
            font-family: 'SVN-Gilroy';
            font-weight: 700;
            font-size: 14px;
        }

        .arrow {
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #fff;
            transition-duration: 0.3s;
            transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
            -webkit-transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
        }
    }

    .menuContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: ${props => props.isShowMenu?65*props.numberOfItems:0}px;
        transition: height 0.3s ease-out;
        overflow: ${props => props.isShowMenu?'visible':'hidden'};
    }
`

const DropDownMenuItem = styled.div`
    display: flex;
    width: 100%;
    height: 30px;
    min-height: 30px;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;
    color: #191919;
    padding: 8px 12px;
    margin-bottom: 5px;
    margin-top: ${props => props.isFirstItem?5:0}px;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
    font-family: 'SVN-Gilroy';
    font-weight: 500;
    font-size: 14px;
`

export {DropDownContainer, DropDownMenuItem}