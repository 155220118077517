import React, { Component } from 'react';
import parse from 'html-react-parser';

import * as types from '../../../../data/infosType'
import { BioInfosType, BioType } from '../../../../data/infosType';
import { formatDateTimeToSpecificFormat, formatDateTime } from '../../../../helper/formatTime';

// styles
import { StyleBiometric } from './DataInfoCheckBiometric.styles';
export default class DataInfoCheckBiometric extends Component {

  funcMergeData = (bioDatas, currentBioDatas, dataStatus) => {
      currentBioDatas = currentBioDatas ? currentBioDatas : []
      bioDatas = bioDatas ? bioDatas : []

      const bioDatasTypes = bioDatas.map(item => item.BioType);
      const currentBioDatasTypes = currentBioDatas.map(item => item.BioType);

      const sameArr = bioDatasTypes.filter(item => currentBioDatasTypes.includes(item));//["FACE"]

      const diffArrBioDatas = bioDatasTypes.filter(item => !currentBioDatasTypes.includes(item))
      let diffArrCurrentBioDatas = currentBioDatasTypes.filter(item => !bioDatasTypes.includes(item))
      let isSame = 0, isMatch = 0
      const sameArrGetData = sameArr.map(item => {
        let itemSameInBioDatas = bioDatas.find(data => data.BioType === item)//{ID: , BioType: "FACE"}
        let itemSameInCurrentBioDatas = currentBioDatas.find(data => data.BioType === item)//{ID: , BioType: "FACE"}

        if((itemSameInBioDatas.BioActionType === types.UPDATE || itemSameInBioDatas.BioActionType === types.DELETE) && (itemSameInCurrentBioDatas.BioActionType === "" || itemSameInCurrentBioDatas.BioActionType === types.UPDATE || itemSameInCurrentBioDatas.BioActionType === types.REGISTER)){
          isSame = 3
        }
        if(isSame === 3) {
          if((!itemSameInBioDatas.IsConfirmFinger && !itemSameInCurrentBioDatas.IsConfirmFinger) || (itemSameInBioDatas.IsConfirmFinger && itemSameInCurrentBioDatas.IsConfirmFinger)){
            isSame = 1;
          }

          if(((!itemSameInBioDatas.IsActive && !itemSameInCurrentBioDatas.IsActive) || (itemSameInBioDatas.IsActive && itemSameInCurrentBioDatas.IsActive)) && isSame === 1) {
            isSame = 2;
          }
          if(isSame === 2) {
            if((itemSameInBioDatas.BioActionType === types.UPDATE && (itemSameInCurrentBioDatas.BioActionType === "" || itemSameInCurrentBioDatas.BioActionType === types.UPDATE || itemSameInCurrentBioDatas.BioActionType === types.REGISTER)) &&
            ((((itemSameInBioDatas.EnrollData === null || itemSameInBioDatas.EnrollData !== null) && itemSameInCurrentBioDatas.EnrollData !== null) || (itemSameInBioDatas.EnrollData === itemSameInCurrentBioDatas.EnrollData)))){
              if(itemSameInBioDatas.BioType === types.FACE && (itemSameInBioDatas.EnrollData === itemSameInCurrentBioDatas.EnrollData)){
                isMatch = 1
              }else if(itemSameInBioDatas.BioType === types.FACE && (itemSameInBioDatas.EnrollData !== itemSameInCurrentBioDatas.EnrollData) && itemSameInBioDatas.EnrollData !== null){
                isMatch = 2
              }else{
                isMatch = 1
              }
              itemSameInBioDatas = bioDatas.find(data => data.BioType === item)
            }else{
              itemSameInBioDatas = bioDatas.find(data => data.BioType === item)
            }
            if(isMatch === 1) {
              isMatch = 0
              itemSameInBioDatas.matchDataType = false
            }
          }
        }
        return {
          itemSameInBioDatas,
          itemSameInCurrentBioDatas,
        }
      })

      // console.log('same', sameArrGetData, isMatch)
      const diffArrBioDatasGetData = diffArrBioDatas.map(item => {
        const itemDiffInBioDatas = bioDatas.filter(items=>items.BioType === item)
        return {
          itemDiffInBioDatas
        }
      })

      const diffArrCurrentBioDatasGetData = diffArrCurrentBioDatas.map(item => {
        const itemDiffInCurrentDatas = currentBioDatas.filter(items=>items.BioType === item)
        return {
          itemDiffInCurrentDatas
        }
      })

      const obj = {
          sameArr,
          diffArrBioDatas,
          sameArrGetData,
          diffArrBioDatasGetData,
          diffArrCurrentBioDatas,
          diffArrCurrentBioDatasGetData
      }
      return obj
    }
    
    render() {
      let { dataUser } = this.props
      dataUser  = JSON.parse(JSON.stringify(dataUser))

      const { sameArr, diffArrBioDatas, sameArrGetData, diffArrBioDatasGetData, diffArrCurrentBioDatasGetData } = this.funcMergeData(dataUser.BioDatas, dataUser.CurrentBioData, dataUser.Status)                 
      let elmBioInfosType, colorsInfosType, elmTextDays, typeData
      let index = 0, indexStatus = 0, indexDiffBio = 0, isSame = 0, isMissCase = 0

      let indexx = 0 
      if([types.NR, types.NR_FINGER].includes(dataUser.BioInfosType)){
        if(dataUser.MobileNumber !== '' ){
          elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].label
          colorsInfosType = BioInfosType[types.REGISTER].color
          typeData = types.mobileFone
          indexx = 1
          indexDiffBio = 10
          isMissCase = 1
        }
        if(dataUser.FullNameVi !== ''){
          if(indexx === 1){
            indexx = 2 
          }else{
            elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].label
            colorsInfosType = BioInfosType[types.REGISTER].color
            typeData = types.fullNameVi
            indexx = 3
            indexDiffBio = 11
            isMissCase = 2
          }
        }
        if(indexx === 2){
          elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].label
          colorsInfosType = BioInfosType[types.REGISTER].color
          typeData = types.fullName_mobie
          indexDiffBio = 7
          isMissCase = 3
        }
      }
      // indexx = 6: finger, indexx = 7: face, indexx = 8: face_finger
      if(sameArr.includes(types.FACE) || sameArr.some(item => item !== types.FACE)){
        let findFace = sameArr.find(item => item === types.FACE)
        let findFinger = sameArr.find(item => item !== types.FACE)

        if(findFace){
          index = 1
          sameArrGetData.forEach(item => {
            if(item.itemSameInBioDatas.BioActionType === types.UPDATE && item.itemSameInBioDatas.BioType === types.FACE){
                elmBioInfosType = BioInfosType[types.UPDATE].vlType
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = BioType[types.FACE].label
                indexStatus = 1
                indexx = 7 
                if(indexx === 7){
                  if(indexDiffBio === 10) {
                    elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = types.mobie_face
                  }else if(indexDiffBio === 11) {
                    elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = types.fullName_face
                  }
                } 
              if(indexStatus === 1){
                if(!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined){
                  elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].namelabel
                  colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].color
                  typeData = ""
                  isSame = 1
                }
              }
            }
            diffArrBioDatasGetData.forEach(item => {
              item.itemDiffInBioDatas.forEach(items => {
                if(indexStatus === 1){
                  if(items.BioActionType === types.REGISTER){
                    elmBioInfosType = BioInfosType[types.UPDATE].vlType
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FACE_FINGER].label
                    indexx = 8
                    indexDiffBio = 9
                  }
                }
              })
            })

            if(item.itemSameInBioDatas.BioActionType === types.REGISTER && item.itemSameInBioDatas.BioType === types.FACE){
              elmBioInfosType = BioInfosType[types.REGISTER].vlType
              colorsInfosType = BioInfosType[types.REGISTER].color
              typeData = BioType[types.FACE].label
              indexStatus = 8
              indexx = 7
            }
            if(indexStatus === 8){
              if(!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].namelabel
                colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].color
                typeData = ""
              }
            }

            if(item.itemSameInBioDatas.BioActionType === types.DELETE && item.itemSameInBioDatas.BioType === types.FACE){
              elmBioInfosType = BioInfosType[types.UPDATE].vlType
              colorsInfosType = BioInfosType[types.REGISTER].color
              typeData = BioType[types.FACE].label
              indexx = 7
            }
            if(indexx === 7){
              if(indexDiffBio === 10){
                  elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = types.mobie_face
              }else if(indexDiffBio === 11){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_face
              }
            }
            if(indexDiffBio === 7 || isMissCase === 3){
              if(indexx === 7){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_face
              }else if(indexx === 8){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_face_finger
              }
            }
          })
        }
        if(findFinger){
          if(index === 1 || indexStatus === 1){
            sameArrGetData.forEach(item => {
              if(item.itemSameInBioDatas.BioActionType === types.UPDATE){
                  elmBioInfosType = BioInfosType[types.UPDATE].vlType
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = BioType[types.FACE_FINGER].label
                  indexx = 8
                  if(indexx === 8){
                    if(indexDiffBio === 7  || isMissCase === 3){
                      if(indexx === 7){
                        elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                        colorsInfosType = BioInfosType[types.REGISTER].color
                        typeData = types.fullName_mobie_face
                      }else if(indexx === 8){
                        elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                        colorsInfosType = BioInfosType[types.REGISTER].color
                        typeData = types.fullName_mobie_face_finger
                      }
                    } else if(indexDiffBio === 10 || isMissCase === 1) {
                      elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                      colorsInfosType = BioInfosType[types.REGISTER].color
                      typeData = types.mobie_face_finger
                    } else if(indexDiffBio === 11 || isMissCase === 2) {
                      elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                      colorsInfosType = BioInfosType[types.REGISTER].color
                      typeData = types.fullName_face_finger
                    }
                  } 
                if(!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined){
                  if(isSame === 1){
                    if(indexStatus === 10){
                      if(item.itemSameInBioDatas.BioType === types.FACE && !item.itemSameInBioDatas.matchDataType) {
                        elmBioInfosType = BioInfosType[types.UPDATE].vlType
                        colorsInfosType = BioInfosType[types.REGISTER].color
                        typeData = BioType[types.FINGER].label
                        indexx = 6
                      }else if(item.itemSameInBioDatas.BioType !== types.FACE && !item.itemSameInBioDatas.matchDataType === undefined) {
                        elmBioInfosType = BioInfosType[types.UPDATE].vlType
                        colorsInfosType = BioInfosType[types.REGISTER].color
                        typeData = BioType[types.FACE].label
                        indexx = 7
                      }
                    }else{
                      elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].namelabel
                      colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].color
                      typeData = ""
                      indexStatus = 11
                    }
                    diffArrBioDatasGetData.forEach(diff => {
                      diff.itemDiffInBioDatas.forEach(check =>{
                        if(check.BioActionType === types.REGISTER){
                          if(isSame === 1 || indexStatus === 11) {
                            elmBioInfosType = BioInfosType[types.UPDATE].content
                            colorsInfosType = BioInfosType[types.REGISTER].color
                            typeData = BioType[types.FINGER].label
                            indexx = 6
                          }
                        }
                      })
                    })
                  }else{
                    elmBioInfosType = BioInfosType[types.UPDATE].vlType
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FACE_FINGER].label
                    indexStatus = 9
                    if(item.itemSameInBioDatas.BioType !== types.FACE && !item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined) {
                      elmBioInfosType = BioInfosType[types.UPDATE].vlType
                      colorsInfosType = BioInfosType[types.REGISTER].color
                      typeData = BioType[types.FACE].label
                      indexx = 7
                    }
                    if(indexStatus === 1){
                      if(item.itemSameInBioDatas.BioType !== types.FACE && indexStatus === 11){
                        elmBioInfosType = BioInfosType[types.UPDATE].vlType
                        colorsInfosType = BioInfosType[types.REGISTER].color
                        typeData = BioType[types.FINGER].label
                        indexx = 6
                      }
                    }
                  } 
                }else{
                  if(isSame === 1){
                    if(item.itemSameInBioDatas.BioType !== types.FACE && item.itemSameInBioDatas.matchDataType === undefined) {
                      elmBioInfosType = BioInfosType[types.UPDATE].vlType
                      colorsInfosType = BioInfosType[types.REGISTER].color
                      typeData = BioType[types.FINGER].label
                      indexStatus = 10
                      indexx = 6
                    }
                  }
                }

                if(indexStatus === 9 && item.itemSameInBioDatas.BioType === types.FACE ) {
                  elmBioInfosType = BioInfosType[types.UPDATE].vlType
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = BioType[types.FACE].label
                  indexx  = 7
                }
              }
              else if(item.itemSameInBioDatas.BioActionType === types.REGISTER){
                if(index === 3){
                  elmBioInfosType = BioInfosType[types.UPDATE].vlType
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = BioType[types.FACE_FINGER].label
                  indexx  = 8
                }else{
                  elmBioInfosType = BioInfosType[types.REGISTER].vlType
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = BioType[types.FACE_FINGER].label
                  index = 7
                  indexx  = 8
                }
              }else if(item.itemSameInBioDatas.BioActionType === types.DELETE){
                elmBioInfosType = BioInfosType[types.UPDATE].vlType
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = BioType[types.FACE_FINGER].label
                indexx  = 8
                if(indexx === 8){
                  if(indexDiffBio === 10 || isMissCase === 1) {
                    elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = types.mobie_face_finger
                  }else if(indexDiffBio === 11 || isMissCase === 2) {
                    elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = types.fullName_face_finger
                  }
                } 
                indexStatus = 2
                if(indexStatus === 2) {
                  if(isSame === 1){
                    elmBioInfosType = BioInfosType[types.UPDATE].vlType
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FACE_FINGER].label
                    indexx  = 8
                  }
                }
              }
            })

            if(indexDiffBio === 7  || isMissCase === 3){
              if(indexx === 6){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_finger
              }else if(indexx === 7){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_face
              }else if(indexx === 8){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_face_finger
              }
            }
          }else {
            index = 2
            sameArrGetData.forEach(item => {
             if(item.itemSameInBioDatas.BioActionType === types.REGISTER) {
              elmBioInfosType = BioInfosType[types.REGISTER].vlType
              colorsInfosType = BioInfosType[types.REGISTER].color
              typeData = BioType[types.FINGER].label
              indexx = 6
            }
            if(item.itemSameInBioDatas.BioActionType === types.UPDATE) {
              elmBioInfosType = BioInfosType[types.UPDATE].vlType
              colorsInfosType = BioInfosType[types.REGISTER].color
              typeData = BioType[types.FINGER].label
              indexStatus = 7
              indexx = 6
              if(indexStatus === 7) {
                if(!item.itemSameInBioDatas.matchDataType && item.itemSameInBioDatas.matchDataType !== undefined){
                  if(index === 3){
                    elmBioInfosType = BioInfosType[types.UPDATE].vlType
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FINGER].label
                  }else{
                    elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].namelabel
                    colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].color
                    typeData = ""
                  }
                }
              }
            }

            if(item.itemSameInBioDatas.BioActionType === types.DELETE) {
              elmBioInfosType = BioInfosType[types.UPDATE].vlType
              colorsInfosType = BioInfosType[types.REGISTER].color
              typeData = BioType[types.FINGER].label
              index = 3
              indexx = 6
            }
            diffArrBioDatasGetData.forEach(diff => {
              diff.itemDiffInBioDatas.forEach(check =>{
                if(check.BioActionType === types.REGISTER){
                  if(index === 7 || indexStatus === 7) {
                    elmBioInfosType = BioInfosType[types.UPDATE].content
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FINGER].label
                    indexx = 6
                  }
                }
              })
            })
            // if(indexDiffBio === 7 && indexx === 6){
            //   elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
            //   colorsInfosType = BioInfosType[types.REGISTER].color
            //   typeData = types.fullName_mobie_finger
            // }

            if(indexx === 6){
              if(indexDiffBio === 7){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_finger
              } else if(indexDiffBio === 10 || isMissCase === 1) {
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.mobie_finger
              }else if(indexDiffBio === 11 || isMissCase === 2) {
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_finger
              }
            }
          })
        }
        }
      }else if(!sameArr.includes(types.FACE)){
        const haveFingerInSameArr = sameArr.find(item => item !== types.FACE)
        const haveFingerInDiffArrBioDatas = diffArrBioDatasGetData.find(item => item !== types.FACE)
        const haveFingerInDiffArrCurrentBioDatas = diffArrCurrentBioDatasGetData && diffArrCurrentBioDatasGetData.find(item => item && item.itemDiffInCurrentDatas[0].BioType === types.FACE)
        
        if(haveFingerInSameArr && haveFingerInDiffArrBioDatas){
            //cập nhật và đăng ký bổ sung vân tay
        }else if (haveFingerInSameArr){
            // cập nhật vân tay
        }else if(haveFingerInDiffArrBioDatas && haveFingerInDiffArrCurrentBioDatas !== undefined){
            // đăng ký bổ sung vân tay
            if(haveFingerInDiffArrCurrentBioDatas.itemDiffInCurrentDatas[0].BioType === types.FACE){
              elmBioInfosType = BioInfosType[types.UPDATE].vlType
              colorsInfosType = BioInfosType[types.UPDATE].color
              typeData = BioType[types.FINGER].label
              indexx = 6
              if(indexx === 6){
                if(indexDiffBio === 7){
                  elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = types.fullName_mobie_finger
                } else if(indexDiffBio === 10 || isMissCase === 1) {
                  elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = types.mobie_finger
                }else if(indexDiffBio === 11 || isMissCase === 2) {
                  elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = types.fullName_finger
                }
              }
            }
        }else if(haveFingerInDiffArrBioDatas && haveFingerInDiffArrCurrentBioDatas === undefined){
        }
      }

      if(diffArrBioDatas.includes(types.FACE) && diffArrBioDatas.some(item => item === types.FACE)){
        let findFace = diffArrBioDatas.find(item => item === types.FACE)
        let findFinger = diffArrBioDatas.find(item => item !== types.FACE)
        if(findFace){
          indexDiffBio = 1
          diffArrBioDatasGetData.forEach(item => {
            item.itemDiffInBioDatas.forEach(items => {
              if(items.BioActionType === types.REGISTER){
                elmBioInfosType = BioInfosType[types.REGISTER].vlType
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = BioType[types.FACE].label
              }
            })
          })
        }
        if(findFinger){
          if(indexDiffBio === 1) {
            diffArrBioDatasGetData.forEach(item => {
              item.itemDiffInBioDatas.forEach(items => {
                if(items.BioActionType === types.REGISTER){
                    indexDiffBio = 3
                    elmBioInfosType = BioInfosType[types.REGISTER].vlType
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FACE_FINGER].label
                }else if(items.BioActionType === types.DELETE){
                  elmBioInfosType = BioInfosType[types.UPDATE].vlType
                  colorsInfosType = BioInfosType[types.UPDATE].color
                  typeData = BioType[types.FACE_FINGER].label
                }
              })
            })
          }else{
            indexDiffBio = 2
            diffArrBioDatasGetData.forEach(item => {
              // console.log(item.itemDiffInBioDatas)
              item.itemDiffInBioDatas.forEach(items => {
                if(items.BioActionType === types.REGISTER){
                    elmBioInfosType = BioInfosType[types.REGISTER].vlType
                    colorsInfosType = BioInfosType[types.REGISTER].color
                    typeData = BioType[types.FINGER].label
                }
              })
            })
          }
        }
      }else if(!diffArrBioDatas.includes(types.FACE)){
      }

      // if(dataUser.Status === types.REJECT) {
      //   if(dataUser.CurrentBioData){
      //     if(diffArrCurrentBioDatas.includes(types.FACE) && diffArrCurrentBioDatas.some(item => item === types.FACE)){
      //       let findFace = diffArrCurrentBioDatas.find(item => item === types.FACE)
      //       let findFinger = diffArrCurrentBioDatas.find(item => item !== types.FACE)
      //       if(findFace){
      //         indexDiffBio = 4
      //         elmBioInfosType = BioInfosType[types.UPDATE].vlType
      //         colorsInfosType = BioInfosType[types.UPDATE].color
      //         typeData = BioType[types.FACE].label
      //         indexx = 7
      //       }
      //       if(findFinger){
      //         if(indexDiffBio === 4) {
      //           elmBioInfosType = BioInfosType[types.UPDATE].vlType
      //           colorsInfosType = BioInfosType[types.UPDATE].color
      //           typeData = BioType[types.FACE_FINGER].label
      //           indexDiffBio = 5
      //           indexx = 8
      //         }else{
      //           elmBioInfosType = BioInfosType[types.UPDATE].vlType
      //           colorsInfosType = BioInfosType[types.UPDATE].color
      //           typeData = BioType[types.FINGER].label
      //           indexDiffBio = 6
      //           indexx = 6
      //         }
      //       }
      //       if(indexDiffBio === 7  || isMissCase === 3){
      //         if(indexx === 6){
      //           elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
      //           colorsInfosType = BioInfosType[types.REGISTER].color
      //           typeData = types.fullName_mobie_finger
      //         }else if(indexx === 7){
      //           elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
      //           colorsInfosType = BioInfosType[types.REGISTER].color
      //           typeData = types.fullName_mobie_face
      //         }else if(indexx === 8){
      //           elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
      //           colorsInfosType = BioInfosType[types.REGISTER].color
      //           typeData = types.fullName_mobie_face_finger
      //         }
      //       }
      //     }else if(!diffArrCurrentBioDatas.includes(types.FACE)){
      //     }
      //   }else{
      //   }
      // }

      if(dataUser.BioInfosType === types.NR){
        elmBioInfosType = BioType[types.NOT_REGISTER_BIOMETRIC].namelabel
        colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].colorNR
      }

      if(dataUser.BioInfosType === types.NR_FINGER){
        elmBioInfosType = BioType[types.NOT_REGISTER_FINGER].namelabel
        colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].colorNR
      }

      if(dataUser.CurrentBioData === null && ![types.NR, types.NR_FINGER].includes(dataUser.BioInfosType)){
        const bioDatasRMU = dataUser.BioDatas.map(item => item.BioType);
        if(bioDatasRMU.includes(types.FACE) && bioDatasRMU.some(item => item === types.FACE)){
          let findFace = bioDatasRMU.find(item => item === types.FACE)
          let findFinger = bioDatasRMU.find(item => item !== types.FACE)
          if(findFace){
            dataUser.BioDatas.forEach(item => {
              indexDiffBio = 8
              if(item.BioActionType === types.REGISTER && item.BioType === types.FACE){
                elmBioInfosType = BioInfosType[types.REGISTER].vlType
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = BioType[types.FACE].label
                indexx = 7
                indexStatus = 1
                if(indexStatus === 1){
                  if(!item.matchDataType && item.matchDataType !== undefined){
                    elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].namelabel
                    colorsInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].color
                    typeData = ""
                    isSame = 1
                  }
                }
              }
              if(indexDiffBio === 7){
                if(indexx === 7){
                  elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = types.fullName_mobie_face
                }
              }
            })
          }
          if(findFinger){
            if(indexDiffBio === 8) {
              dataUser.BioDatas.forEach(item => {
                if(item.BioActionType === types.REGISTER && item.BioType !== types.FACE){
                  elmBioInfosType = BioInfosType[types.REGISTER].vlType
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = BioType[types.FACE_FINGER].label
                  indexx = 9
                }
              })
            }else{
              dataUser.BioDatas.forEach(item => {
                if(item.BioActionType === types.REGISTER && item.BioType !== types.FACE){
                  elmBioInfosType = BioInfosType[types.REGISTER].vlType
                  colorsInfosType = BioInfosType[types.REGISTER].color
                  typeData = BioType[types.FINGER].label
                  indexx = 6
                }
              })
            }
            if(indexDiffBio === 7 || isMissCase === 3){
              if(indexx === 6 || isMissCase === 2){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_finger
              }else if(indexx === 8){
                elmBioInfosType = BioType[types.NOT_CHANGE_BIOMETRIC].labelC_B
                colorsInfosType = BioInfosType[types.REGISTER].color
                typeData = types.fullName_mobie_face_finger
              }
            }
          }
        }
      }
      let textReason
      switch (dataUser.BioInfosType ) {
        case types.REGISTER:
          elmTextDays = 'Ngày đăng ký:'
          break;
        case types.UPDATE:
          elmTextDays = 'Ngày cập nhật:'
          break;
        default:
          elmTextDays = 'Ngày ghi nhận:'
          break;
      }

      if(dataUser.ReasonNR  !== ''){
        textReason = dataUser.ReasonNR
        indexx = 4
      }
      if(dataUser.ReasonNRFinger !== ''){
        if(indexx === 4) {
          indexx = 5
        }else{
          textReason = dataUser.ReasonNRFinger
        }
      }
      if(indexx === 5){
        textReason = dataUser.ReasonNR + '. ' + dataUser.ReasonNRFinger
      }
      textReason = parse(`${textReason}`)
    return (
      <StyleBiometric>
          <p className='title'>thông tin dữ liệu</p>
          <div className="container">
              <div className="row">
                  <div className="col-7">
                      <div className="row content dataType">
                          <p>Loại dữ liệu:&nbsp;
                              <span 
                                style={{ 
                                  color: [types.NR, types.NR_FINGER].includes(dataUser.BioInfosType)
                                    ? BioInfosType[dataUser.BioInfosType].color
                                    : colorsInfosType 
                                }}>
                                  {
                                    [types.NR, types.NR_FINGER].includes(dataUser.BioInfosType)
                                    ? BioInfosType[dataUser.BioInfosType].value
                                    : elmBioInfosType
                                  }
                              </span>
                          </p>
                      </div>
                      <div className={![types.NR, types.NR_FINGER].includes(dataUser.BioInfosType) ? "row content dataNeed" : "row content"}>
                      {
                          ![types.NR, types.NR_FINGER].includes(dataUser.BioInfosType) &&
                          <p>Dữ liệu yêu cầu:&nbsp;
                              <span>
                                  {typeData}
                              </span>
                          </p>
                      }
                      </div>
                      <div className = 'col-12 mt-14'>
                      {
                          [types.NR, types.NR_FINGER].includes(dataUser.BioInfosType) && 
                          <div className="row content">
                              <p style = {{paddingRight: '10px'}}>Nguyên nhân không đăng ký:&nbsp;
                              {/* <span className='type_reason'>{dataUser.ReasonNR + '. ' + dataUser.ReasonNRFinger}</span> */}
                              </p>
                          </div>
                      }
                  </div>
                  </div>
                  <div className="col-5">
                      <div className="row content">
                          <div className = 'cover'>
                              <p>GDV yêu cầu:&nbsp;</p>
                              <div className = 'text-gdv text-ellipst'>{dataUser.TellerInfos.UserName}</div>
                              <span id = 'tooltip_'>{dataUser.TellerInfos.UserName}</span>
                          </div> 
                      </div>
                      <div className="row content last">
                          <p>{elmTextDays}&nbsp;
                              <span>
                                {formatDateTimeToSpecificFormat(dataUser.CreateAt, formatDateTime.HHmmDDMMYY)}
                              </span>
                          </p>
                      </div>
                  </div>
              </div>
              {
                [types.NR, types.NR_FINGER].includes(dataUser.BioInfosType) && 
                <div className = 'c-text'>
                  <div className = 'textReason'><span className='type_reason'>{textReason}</span></div>
                </div>
              }
          </div>
      </StyleBiometric>
    )
  }
}

