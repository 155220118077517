import styled from 'styled-components'
import { color } from '../../../../../data/store';

const WrapperHeader = styled.div`
    position: relative;
    padding: 13px 20px 8px;
    box-shadow: 0px 3px 0px -1px #EFEFEF;
    background: #FFFFFF;

    .content {
        flex-grow: 1;

        .title {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 22px;
            color: #000000;
        }

        .row {
            margin-top: 16px;
            flex-wrap: nowrap;
            justify-content: space-between;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: #222222;

            .main-content {
                color: ${color.blue_005993};
                flex-shrink: 0;
            }

            .btn-upload-file {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 132%;
                text-transform: uppercase;
                color: #1477EC;
                /* padding: 6px 0; */
                display: flex;
                align-items: center;

                img {
                    margin-right: 8px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .user {
                margin-left: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            /* & > *:not(.main-content) {
                max-width: 20ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis
            } */
        }
    }

    img.ic-close {
        position: absolute;
        top: 13px;
        right: 20px;

        :hover {
            cursor: pointer;
        }
    }
`;

export { WrapperHeader }