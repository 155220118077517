/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ContainerStyled } from "./index.styles";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeManagementList } from "../../../../../actions/officeManagementAction";
import SingleChoiceDropDownControl from "../SingleChoiceDropDownControl";
import { color } from "../../../../../data/store";
import HeaderControl from "../HeaderControl";
import { officeUtils } from "../../../../Utils/OfficeUtils";
import RealTimeSystem from "./RealTimeSystem";

export default function HeaderV2({title, onOfficeInfoChange, isShowOfficeSelectionFeature = false}) {
  const dispatch = useDispatch()

  const { officeManagementList, generalOfficeSelected, dataLogin } = useSelector((state) => ({
    officeManagementList: state.officeManagementReducer.officeManagementList,
    generalOfficeSelected: state.officeManagementReducer.generalOfficeSelected,
    dataLogin: state.userReducer.dataLogin
  }))

  const [idShow, setIdShow] = useState('')

  const onOfficeInfoChangeAction = (dataInfo) => {
    const officeInfo = officeManagementList.find((item)=>item.ID === dataInfo.id)
    onOfficeInfoChange && onOfficeInfoChange(officeInfo)
  }

  useEffect(()=>{
    const isBranchOfficeType = officeUtils.isBranchOfficeType({
      dataLogin: dataLogin
    })
    //---
    if(isShowOfficeSelectionFeature && isBranchOfficeType && officeManagementList.length === 0){
         //call api get officeManagementList
         dispatch(getOfficeManagementList({
            uid: dataLogin.UID,
            isReset: true
         }))
    }
  }, [officeManagementList])

  const rendHeaderV2 = ()=>{
    return (
      <ContainerStyled>
        <div className="left-content">
          <div className="title">{title}</div>
          <RealTimeSystem/>
        </div>
        <div className="right-content">
          <SingleChoiceDropDownControl
              isNotSort={true}
              heightControl={42}
              borderWidth={0}
              setWidth='240px'
              backgroundColor={color.whiteF7}
              id="selectOfficeOfHeader"
              idShow={idShow}
              setIdShow={setIdShow}
              getText={onOfficeInfoChangeAction}
              data={officeUtils.getOfficeList({
                dataLogin: dataLogin,
                officeManagementList: officeManagementList
              }).map((item)=>{
                return {
                  id: item.ID,
                  name: `${item.BranchCode} - ${item.Name}`
                }
              })}
              defaultValue={`${generalOfficeSelected.BranchCode} - ${generalOfficeSelected.Name}`}
          />
        </div>
      </ContainerStyled>
    )
  }

  return (
    <>
      {
        isShowOfficeSelectionFeature && 
        officeUtils.isBranchOfficeType({dataLogin: dataLogin})
        ? rendHeaderV2()
        : <HeaderControl isShrink={true} title={title}/>
      }
    </>
  )
}

