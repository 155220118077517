import { color } from "./store";

export const REGISTER = 'REGISTER';
export const NR = 'NOTREGISTER';
export const NR_FINGER = 'NOTREGISTERFINGER';
export const DELETE = 'REMOVE';
export const BLOCK = 'BLOCK';
export const UNBLOCK = 'UNBLOCK';
export const UPDATE = 'UPDATE';

export const PENDING = 'PENDING';
export const ACCEPT = 'ACCEPT';
export const REJECT = 'REJECT';

export const FACE = 'FACE';
export const FINGER = 'FINGER';
export const FACE_FINGER = 'FACE_FINGER';
export const THUMB_LEFT = 'THUMB_LEFT';
export const INDEX_LEFT = 'INDEX_LEFT';
export const MIDDLE_LEFT = 'MIDDLE_LEFT';
export const RING_LEFT = 'RING_LEFT';
export const PINKY_LEFT = 'PINKY_LEFT';
export const THUMB_RIGHT = 'THUMB_RIGHT';
export const INDEX_RIGHT = 'INDEX_RIGHT';
export const MIDDLE_RIGHT = 'MIDDLE_RIGHT';
export const RING_RIGHT = 'RING_RIGHT';
export const PINKY_RIGHT = 'PINKY_RIGHT';

export const NOT_CHANGE_BIOMETRIC = 'NOT_CHANGE_BIOMETRIC';
export const NOT_REGISTER_FINGER = 'NOT_REGISTER_FINGER';
export const NOT_REGISTER_BIOMETRIC = 'NOT_REGISTER_BIOMETRIC'

export const EXCUST = 'EXCUST'
export const INCUST = 'INCUST'

export const ISACTIVE = 'ISACTIVE'
export const ACTIVE = 'ACTIVE'

export const HANG = 'HANG'

export const titleDFace = 'Hình ảnh khuôn mặt trước khi tác nghiệp'
export const titleUFace = 'Hình ảnh khuôn mặt trước thay đổi'
export const titleCloseActiveFace = 'Hình ảnh khuôn mặt'
export const titleRemoveFace = 'Hình ảnh khuôn mặt trước khi xóa'

export const statusS = 'Trạng thái khuôn mặt trước khi tác nghiệp'
export const statusU = 'Trạng thái khuôn mặt trước thay đổi'
export const statusR = 'Trạng thái khuôn mặt trước thay đổi'
export const statusN = 'Trạng thái khuôn mặt'

export const fullNameVi = 'Họ tên có dấu'
export const mobileFone = 'Số điện thoại'
export const fullName_mobie = 'Họ tên có dấu và số điện thoại'
export const fullName_mobie_face = 'Họ tên có dấu, số điện thoại, khuôn mặt'
export const fullName_mobie_finger = 'Họ tên có dấu, số điện thoại, vân tay'
export const fullName_mobie_face_finger = 'Họ tên có dấu, số điện thoại, khuôn mặt, vân tay'
export const fullName_face_finger = 'Họ tên có dấu, khuôn mặt, vân tay'
export const mobie_face_finger = 'Số điện thoại, khuôn mặt, vân tay'
export const mobie_face = 'Số điện thoại, khuôn mặt'
export const fullName_face = 'Họ tên, khuôn mặt'
export const mobie_finger = 'Số điện thoại, vân tay'
export const fullName_finger = 'Họ tên, vân tay'

export const BioInfosType = {
    [REGISTER]: {
        color: '#0fb11f',
        label: 'Đăng ký mới',
        content: 'Đăng ký mới vân tay',
        contentF: 'Đăng ký mới khuôn mặt',
        ctLabel: 'Đăng ký',
        nameLabel: 'đã đăng ký',
        value: 'Đăng ký mới dữ liệu',
        vlType: 'Đăng ký mới STH'
    },
    [NR]: {
        color: '#F31919',
        label: 'Không đăng ký',
        nameLabel: 'không đăng ký',
        value: 'Không đăng ký thông tin STH'
    },
    [NR_FINGER]: {
        color: '#F31919',
        label: 'Không đăng ký STH vân tay',
        nameLabel: 'Không đăng ký STH vân tay',
        value: 'Không đăng ký STH vân tay'
    },
    [DELETE]: {
        color: '#f31919',
        label: 'Xóa dữ liệu',
        content: 'Xóa hình ảnh vân tay',
        // contentLFace: 'Xóa hình ảnh khuôn mặt',
        contentLFace: 'Xóa khuôn mặt',
        contentFace_Finger: 'Xóa hình ảnh khuôn mặt và vân tay',
        nameLabel: 'đã xóa',
        dlFinger: 'Xóa dữ liệu vân tay',
    },
    [BLOCK]: {
        color: '#f31919',
        label: 'Khóa dữ liệu'
    },
    [UNBLOCK]: {
        color: '#0fb11f',
        label: 'Mở khóa dữ liệu'
    },
    [UPDATE]: {
        color: '#0fb11f',
        label: 'Cập nhật dữ liệu',
        content: 'Cập nhật vân tay',
        nameLabel: 'đã cập nhật',
        contentLabel: 'Cập nhật',
        value: 'Cập nhật dữ liệu STH',
        colors: color.blue_005993,
        uFace: 'Cập nhật khuôn mặt',
        cFace: '#1477EC',
        vlType: 'Cập nhật thông tin STH'
    }
}

export const Status = {
    [ACCEPT]: {
        colorA: 'rgb(15, 177, 31)',
        colorP: '#ff7e00',
        color: '#c8c8c8',
        label: 'Đồng ý'
    },
    [REJECT]: {
        colorR: 'rgb(243, 25, 25)',
        color: '#c8c8c8',
        label: 'Từ chối',
        nameLabel: 'đã từ chối'
    },
    [PENDING]: {
        color: color.blue_005993,
        colorP: '#ff7e00',
        label: 'Chi tiết',
        value: 'Chờ phê duyệt'
    }
}

export const BioType = {
    [FACE]: {
        label: 'Khuôn mặt',
        lbLower: 'khuôn mặt'
    },
    [FINGER]: {
        label: 'Vân tay',
        content: 'vân tay',
        lbLower: 'bổ sung vân tay'
    },
    [FACE_FINGER]: {
        label: 'Khuôn mặt và vân tay',
        lbLower: 'khuôn mặt và vân tay'

    },
    NOT_HAVE_BIO: "Không đăng ký",
    [NOT_CHANGE_BIOMETRIC]: {
        namelabel: 'Không thay đổi thông tin STH',
        label: 'Cập nhật thông tin CIF',
        labelC_B: 'Cập nhật thông tin STH và CIF',
        color: '#f31919',
        colorNR: 'rgb(243, 25, 25)'
    },
    [NOT_CHANGE_BIOMETRIC]: {
        namelabel: 'Không thay đổi thông tin STH',
        label: 'Cập nhật thông tin CIF',
        labelC_B: 'Cập nhật thông tin STH và CIF',
        color: '#f31919',
        colorNR: 'rgb(243, 25, 25)'
    },
    [NOT_REGISTER_BIOMETRIC]: {
        namelabel: 'Không đăng ký thông tin STH',
        // color: '#f31919'
        color: '#F31919'
    },
    [NOT_REGISTER_FINGER]: {
        namelabel: 'Không đăng ký thông tin STH vân tay',
        // color: '#f31919'
        color: '#F31919'
    },

}


export const dataFinger = [
    'THUMB_LEFT',
    'INDEX_LEFT',
    'MIDDLE_LEFT',
    'RING_LEFT',
    'PINKY_LEFT',
    'THUMB_RIGHT',
    'INDEX_RIGHT',
    'MIDDLE_RIGHT',
    'RING_RIGHT',
    'PINKY_RIGHT'
]

export const dataFaceFinger = [
    'FACE',
    'THUMB_LEFT',
    'INDEX_LEFT',
    'MIDDLE_LEFT',
    'RING_LEFT',
    'PINKY_LEFT',
    'THUMB_RIGHT',
    'INDEX_RIGHT',
    'MIDDLE_RIGHT',
    'RING_RIGHT',
    'PINKY_RIGHT'
]

export const dataFingerPrint = {
    [THUMB_LEFT]: {
        label: 'Ngón cái - bàn tay trái'
    },
    [THUMB_RIGHT]: {
        label: 'Ngón cái - bàn tay phải'
    },
    [INDEX_LEFT]: {
        label: 'Ngón trỏ - bàn tay trái'
    },
    [INDEX_RIGHT]: {
        label: 'Ngón trỏ - bàn tay phải'
    },
    [MIDDLE_LEFT]: {
        label: 'Ngón giữa - bàn tay trái'
    },
    [MIDDLE_RIGHT]: {
        label: 'Ngón giữa - bàn tay phải'
    },
    [RING_LEFT]: {
        label: 'Ngón áp út - bàn tay trái'
    },
    [RING_RIGHT]: {
        label: 'Ngón áp út - bàn tay phải'
    },
    [PINKY_LEFT]: {
        label: 'Ngón út - bàn tay trái'
    },
    [PINKY_RIGHT]: {
        label: 'Ngón út - bàn tay phải'
    },
}


export const customType = {
    [EXCUST]: {
        value: 'Khách hàng thông thường'
    },
    [INCUST]: {
        value: 'CBNH'
    },
    [ISACTIVE]: {
        value: 'Đóng khuôn mặt',
        label: 'Đóng',
        vlFinger: 'Đóng vân tay',
        vlFace_Finger: 'Đóng khuôn mặt và vân tay',
        color: '#1477EC',
        colors: '#F31919'
    },
    [ACTIVE]: {
        value: 'Kích hoạt khuôn mặt',
        vlFinger: 'Kích hoạt vân tay',
        label: 'Hoạt động',
        color: '#0FB11F'
    },
    [HANG]: {
        label: 'Treo',
        color: '#1477EC',
        clHang: '#222222',
        vlFinger: 'Treo vân tay'
    }
}