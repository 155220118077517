import Axios from 'axios'

import * as types from '../types'
import { host } from '../../host'
import {
    addLoading,
    removeLoading,
} from '../commonAction'

// helper
import catchErrorApi from '../../helper/catchErrorApi'

//api
const apiGetBioInfos = host + 'supervisor/filter-bio'
const apiUpdateBioInfosNote = host + 'supervisor/update-note'
const apiAcceptBioInfos = host + 'supervisor/accept-bio'
const apiRejectBioInfos = host + 'supervisor/reject-bio'
const apiGetBioHistoryDetail = host + 'supervisor/get-bio-history-list'
const apiGetBioHistoryInfo = host + 'supervisor/get-bio-history-info'

export const apiGetBioFile = (bioID) =>
    host + `supervisor/get/bio-file/${bioID}`
export const apiFaceHistory = (bioID) =>
    host + `supervisor/get/bio-face-image/${bioID}`
export const apiFingerHistory = (bioID) =>
    host + `supervisor/get/bio-finger-image/${bioID}`

/**
 * @param  {
 * {
 * CustNumOrName: *string,
 * OfficeID: *string,
 * TellerName: string,
 * SupervisorName: string,
 * FromCreateAt: *string,
 * ToCreateAt: string,
 * AutoReview: boolean,
 * PageSize: *number > 0,
 * PageNumber: *number > 0,
 * Segment: string,
 * Status: "PENDING" \| "ACCEPT" \| "REJECT",
 * BioType: string
 * }
 * }
 * @return {void}
 */
export const getBioInfos = (dataQuery) => async (dispatch) => {
    dispatch(addLoading())
    const dataWithoutFalsyVal = Object.keys(dataQuery).reduce(
        (lst, curr) =>
            dataQuery[curr] ? { ...lst, [curr]: dataQuery[curr] } : lst,
        {}
    )
    const queryDataFormat = JSON.stringify({
        ...dataWithoutFalsyVal,
    })
    try {
        const res = await Axios.post(apiGetBioInfos, queryDataFormat)
        const { data } = res
        dispatch({
            type: types.GET_BIO_INFOS,
            listBio: data.BioInfos ? data.BioInfos : [],
            Total: data.Total,
            lstCurrent: (data.BioInfos ? data.BioInfos : []).map((item) => ({
                ID: item.ID,
                CurrentBioData: item.CurrentBioData,
            })),
            lastConditionFilterUser: {
                ...dataQuery,
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(err, dispatch, types.GET_BIO_INFOS_ERR, () => false)
    }
}

/**
 * @param {string} idBio
 * @param {string} noteBio
 */

export const updateBioInfosNote = (idBio, noteBio) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        BioID: idBio,
        Note: noteBio,
    })

    try {
        await Axios.post(
            apiUpdateBioInfosNote,
            queryDataFormat
        )
        dispatch({
            type: types.UPDATE_BIO_INFOS_NOTE,
        })
    } catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_BIO_INFOS_NOTE_ERR,
            () => false
        )
    }
}

/**
 * @param {string} idBio
 * @param {string} approverName
 * @param {string} approverPwd
 * @param {string} role
 */
export const acceptBioInfos = (
    idBio,
    approverName,
    approverPwd,
    role,
    onCompletedFunc
) => async (dispatch) => {
    dispatch({
        type: types.CLEAR_ERR_APPROVE,
    })
    const queryDataFormat = JSON.stringify({
        BioID: idBio,
        ApproverName: approverName,
        ApproverPwd: approverPwd,
        Role: role,
    })

    try {
        const res = await Axios.post(apiAcceptBioInfos, queryDataFormat)
        const { data } = res
        // dispatch({
        //     type: types.ACCEPT_BIO_INFOS,
        //     Code: data.Code,
        // })
        onCompletedFunc && onCompletedFunc(data.Code, "")
    } catch (err) {

        const handleError400 = () => {
            // dispatch({
            //     type: types.ACCEPT_BIO_INFOS_ERR,
            //     err: err.response.data.Code,
            //     message: err.response.data.Message,
            // })
            onCompletedFunc && onCompletedFunc(err.response.data.Code, err.response.data.Message)
            return true
        }

        catchErrorApi(err, dispatch, types.ACCEPT_BIO_INFOS_ERR, handleError400)
    }
}

/**
 * @param {string} idBio
 * @param {string} rejectName
 * @param {string} rejectPwd
 * @param {string} role
 */

export const rejectBioInfos = (
    idBio,
    rejectName,
    rejectPwd,
    role,
    note,
    onCompletedFunc
) => async (dispatch) => {
    dispatch({
        type: types.CLEAR_ERR_APPROVE,
    })
    const queryDataFormat = JSON.stringify({
        BioID: idBio,
        RejecterName: rejectName,
        RejecterPwd: rejectPwd,
        Role: role,
        Note: note,
    })

    try {
        const res = await Axios.post(apiRejectBioInfos, queryDataFormat)
        const { data } = res
        // dispatch({
        //     type: types.REJECT_BIO_INFOS,
        //     Code: data.Code,
        // })
        onCompletedFunc && onCompletedFunc(data.Code)
    } catch (err) {
        const handleError400 = () => {
            // dispatch({
            //     type: types.REJECT_BIO_INFOS_ERR,
            //     err: err.response.data.Code,
            //     message: err.response.data.Message,
            // })
            onCompletedFunc && onCompletedFunc(err.response.data.Code, err.response.data.Message)
            return true
        }

        catchErrorApi(err, dispatch, types.REJECT_BIO_INFOS_ERR, handleError400)
    }
}

/**
 * @param {string} customerNumber*
 * @param {interger} pageSize*
 * @param {interger} pageNumber*
 * @param {string} lessThanCreateAt*
 */
export const getBioHistoryList = (
    customerNumber,
    pageSize,
    pageNumber,
    lessThanCreateAt
) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        CustomerNumber: customerNumber,
        PageSize: pageSize,
        PageNumber: pageNumber,
        LessthanCreateAt: lessThanCreateAt,
    })

    try {
        const res = await Axios.post(apiGetBioHistoryDetail, queryDataFormat)
        const { data } = res
        dispatch({
            type: types.GET_BIO_HISTORY_LIST,
            listBioHistoryList: data.BioHistoryDetail
                ? data.BioHistoryDetail
                : [],
            TotalHistoryList: data.Total,
        })
    } catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_BIO_HISTORY_LIST_ERR,
            () => false
        )
    }
}

/**
 * @param {string} historyID*
 */
export const getBioHistoryInfo = (historyID) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        HistoryID: historyID,
    })

    try {
        const res = await Axios.post(apiGetBioHistoryInfo, queryDataFormat)
        const { data } = res
        dispatch({
            type: types.GET_BIO_HISTORY_INFO,
            listBioHistoryInfo: data.BioDatas ? data.BioDatas : [],
        })
    } catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_BIO_HISTORY_INFO_ERR,
            () => false
        )
    }
}

export const saveTextSearchDataBiometric = (text) => ({
    type: types.SAVE_TEXT_DATA_BIOMETRIC,
    textSearch: text,
})

export const clearTextSearchDataBiometric = () => ({
    type: types.CLEAR_TEXT_DATA_BIOMETRIC,
})

export const socketGetBios = (data) => (dispatch) =>
    dispatch({
        type: types.SOCKET_GET_BIOS_INFO,
        data,
    })