/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ContainerStyled } from './index.styles'
import TransactionItem from '../TransactionItem'
import { useDispatch } from 'react-redux'
import TransPopup, { TransactionPopupType } from '../TransactionPopup'
import { heightHeader } from '../../../Header/HeaderContainer'
import { widthMenuLeft } from '../../../Menu/MenuContainer'
import { addCounterService, addGroupCounterService, updateCounterService, updateGroupCounterService } from '../../../../../actions/counterServiceAction'
import PopupOption from '../../../Popup/PopupOption'
import SubHeaderControlV2 from '../../Control/SubHeaderControlV2'
import { isArray } from 'lodash'
import { isObject } from '../../../../../helper/checkValidity'

export default function TransactionInfo({transType, headerData, title, transList, dataConfig, onReset, onHideShowErrPopup, onHideShowSuccessPopup}) {
  const dispatch = useDispatch()
  //---
  const [dataInfo, setDataInfo] = useState({
    realDataList: transList??[],
    dataList: transList??[]
  })

  const[transPopupInfo, setTransPopupInfo] = useState({
      isShow: false,
      isGroup: false,
      type: TransactionPopupType.ADD,
      dataInfo: null
  })

  const [optionPopupInfo, setOptionPopupInfo] = useState({
    isShow: false,
    dataInfo: null
  })

  const [searchText, setSearchText] = useState("")
  //---
  const onHideTransPopup = () => {
    setTransPopupInfo({
        isShow: false,
        isGroup: false,
        type: TransactionPopupType.ADD,
        dataInfo: null
    })
  }

  const onShowTransPopup = (type, isGroup, dataInfo) => {
    setTransPopupInfo({
        isShow: true,
        isGroup: isGroup,
        type: type,
        dataInfo: dataInfo
    })
  }

  const onHideOptionPopup = () => {
    setOptionPopupInfo({
        isShow: false,
        dataInfo: null
    })
  }

  const onShowOptionPopup = (parentIndex, index, dataInfo) => {
    let transItem = document.getElementById("transaction_item_" + parentIndex);
    let moreBtn = document.getElementById("more_button_id_" + index);
    let transContainer = document.getElementById("trans_container")
    //---
    
    let topTransItem = 0
    if(transItem){
      let bound = transItem.getBoundingClientRect();
      topTransItem =  bound.top - 333;
    }
    if (moreBtn) {
        let bound = moreBtn.getBoundingClientRect();
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft ;
        let scrollTop = window.pageYOffset + transContainer.scrollTop;
        let topBound = bound.top + scrollTop;
        let leftBound = bound.left + scrollLeft;
        let offsetPopup = {
            top: topTransItem + topBound + bound.height / 2 - heightHeader,
            left: leftBound - 78 + bound.width / 2 - widthMenuLeft
        };
        console.log(offsetPopup);
        //---
        setOptionPopupInfo({
            isShow: true,
            offsetPopup: offsetPopup,
            from: 'CounterService',
            dataInfo: dataInfo
        })
    }
  }

  const searchTransWhenOffline = (key) => {
    const newDataList = []
    for (var i = 0; i < transList.length; i++) {
        const item = transList[i]
        const result = searchTransInfo(key, item)
        if(!!result){
          newDataList.push(result)
        }
    }
    //----
    setDataInfo({
        ...dataInfo,
        dataList: newDataList
    })
  }

  const searchTransInfo = (key, transItem) => {
    let result = undefined
    const name = transItem.Name.toLowerCase()
    const groupCode = transItem.GroupCode.toLowerCase()
    const serviceType = (transItem.ServiceType??'').toLowerCase()
    if (name.includes(key.toLowerCase()) || 
        groupCode.includes(key.toLowerCase()) ||
        serviceType.includes(key.toLowerCase())) {
      result = transItem
    } else {
      if(isArray(transItem.Children)){
        let isExist = false
        for (var i = 0; i < transItem.Children.length; i++) {
          const children = searchTransInfo(key, transItem.Children[i])
          if(isObject(children)){
            isExist = true
            break
          }
        }
        if(isExist){
          result = transItem
        }
      }
    }
    return result
  }

  const onResetAction = () => {
    onReset && onReset()
    //---
    setSearchText("")
  }

  const showErrPopup = ({mess, note}) => {
    onHideShowErrPopup && onHideShowErrPopup({
        isShowPopup: true,
        mess: mess,
        note: note
    })
}

  const onAddCounterService = (dataInfo) => {
    dispatch(addCounterService({
        dataInfo: dataInfo,
        onCompleted: (dataResp) => {
            addOrUpdateCounterServiceCompleted("ADD_COUNTER_SERVICE", dataResp)
        }
    }))
  }

  const onUpdateCounterService = (dataInfo) => {
      dispatch(updateCounterService({
          dataInfo: dataInfo,
          onCompleted: (dataResp) => {
              addOrUpdateCounterServiceCompleted("UPDATE_COUNTER_SERVICE", dataResp)
          }
      }))
  }

  const onAddGroupCounterService = (dataInfo) => {
    dispatch(addGroupCounterService({
        dataInfo: {
          ...dataInfo,
          type: transType
        },
        onCompleted: (dataResp) => {
          addOrUpdateGroupCounterServiceCompleted("ADD_GROUP_COUNTER_SERVICE", dataResp)
        }
    }))
  }

  const onUpdateGroupCounterService = (dataInfo) => {
      dispatch(updateGroupCounterService({
          dataInfo: dataInfo,
          onCompleted: (dataResp) => {
            addOrUpdateGroupCounterServiceCompleted("UPDATE_GROUP_COUNTER_SERVICE", dataResp)
          }
      }))
  }

  const addOrUpdateCounterServiceCompleted = (typeAction, dataResp) => {
    if (dataResp.code === 0) {
        onHideTransPopup()
        //---
        onResetAction()
        //---
        onHideShowSuccessPopup({
            isShowPopup: true, 
            mess: typeAction === "ADD_ADVISE_SERVICE"
                    ?"Thêm giao dịch thành công"
                    :"Cập nhật giao dịch thành công",
        })
    } else {
        let messErr = ''
        let note = ''
        //---
        switch (dataResp.code) {
          case 102:
            messErr = 'Mã giao dịch đã được sử dụng'
            note = '(*) Bạn vui lòng chọn mã khác'
            break;
          case 103:
              messErr = 'Tên giao dịch đã được sử dụng'
              note = '(*) Bạn vui lòng chọn tên khác'
              break;
          case 104:
            messErr = 'Mã Group giao dịch không tồn tại'
            note = '(*) Bạn vui lòng kiểm tra lại'
            break;
          case 105:
            messErr = 'Loại giao dịch đang có vé sử dụng'
            note = '(*) Bạn vui lòng chỉ thay đổi khi không có vé nào đang sử dụng loại giao dịch này.'
            break;
          default:
              messErr = 'Hệ thống bị gián đoạn'
              note = '(*) Bạn vui lòng thực hiện lại'
              break;
        }
        //--
        showErrPopup({
            mess: messErr,
            note: note
        })
    }
  }

  const addOrUpdateGroupCounterServiceCompleted = (typeAction, dataResp) => {
    if (dataResp.code === 0) {
        onHideTransPopup()
        //---
        onReset()
        //---
        onHideShowSuccessPopup && onHideShowSuccessPopup({
            isShowPopup: true, 
            mess: typeAction === "ADD_GROUP_COUNTER_SERVICE"
                    ?"Thêm group thành công"
                    :"Cập nhật group thành công",
        })
    } else {
        let messErr = ''
        let note = ''
        //---
        switch (dataResp.code) {
            case 100:
                messErr = 'Mã Group giao dịch đã được sử dụng'
                note = '(*) Bạn vui lòng chọn mã khác'
                break;
            case 101:
                messErr = 'Tên Group giao dịch đã được sử dụng'
                note = '(*) Bạn vui lòng chọn tên khác'
                break;
            case 105:
              messErr = 'Loại giao dịch đang có vé sử dụng'
              note = '(*) Bạn vui lòng chỉ thay đổi khi không có vé nào đang sử dụng loại giao dịch này.'
              break;
            default:
                messErr = 'Hệ thống bị gián đoạn'
                note = '(*) Bạn vui lòng thực hiện lại'
                break;
        }
        //--
        showErrPopup({
            mess: messErr,
            note: note
        })
    }
  }

  useEffect(()=>{
    setDataInfo({
      ...dataInfo,
      dataList: transList
  })
  }, [transList])


  //---
  return (
    <ContainerStyled>
        {/* <SubHeaderControl horizontalMargin={10} data={headerData} /> */}
        <SubHeaderControlV2 
          horizontalMargin={10} 
          data={headerData}
          textSearch={searchText}
          typeSearch="searchTrans"
          searchFilter={searchTransWhenOffline}
          onReset={onResetAction}
          onAddMoreSubmitList={[
            () => {
              onShowTransPopup(TransactionPopupType.ADD, true, undefined)
            },
            () => {
              onShowTransPopup(TransactionPopupType.ADD, false, undefined)
            }
          ]}
        />

        <div className="wrap-transaction">
          <div className="wrap-transaction__title">{title}</div>
          <div className='scroll'>
            <div id="trans_container" className="scroll__list-transaction" onScroll={()=>{onHideOptionPopup()}}>
              {dataInfo.dataList.map((item, index) => (
                <TransactionItem 
                  id={`transaction_item_${index}`}
                  key={`transaction_item_${index}`}
                  index={index}
                  dataInfo={item}
                  onItemSelected={(dataInfo)=>{
                    onShowTransPopup(TransactionPopupType.UPDATE, true, dataInfo)
                  }} 
                  onSubItemSelected={(dataInfo)=>{
                    onShowTransPopup(TransactionPopupType.UPDATE, false, dataInfo)
                  }}
                  onShowOptionPopup={(i, dataInfo)=>{onShowOptionPopup(index, i, dataInfo)}}
                />
              )
              )}
            </div>
          </div>
        </div>

        {
            transPopupInfo.isShow &&
            <TransPopup
                popupType={transPopupInfo.type}
                isGroup={transPopupInfo.isGroup}
                dataInfo={transPopupInfo.dataInfo}
                dataConfig={dataConfig}
                counterServiceList={transList}
                onAdd={transPopupInfo.isGroup?onAddGroupCounterService:onAddCounterService}
                onUpdate={transPopupInfo.isGroup?onUpdateGroupCounterService:onUpdateCounterService}
                onClose={onHideTransPopup}
            />
        }
    
        {
            optionPopupInfo.isShow &&
            <PopupOption
                offsetPopup={optionPopupInfo.offsetPopup}
                dataInfo={optionPopupInfo.dataInfo}
                onModify={(dataInfo) => {
                    onShowTransPopup(TransactionPopupType.UPDATE, false, dataInfo)
                }}
                onClose={onHideOptionPopup}
            />
        }
    </ContainerStyled>
  )
}
