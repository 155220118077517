/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'

// styles
import { WrapperEditUser } from './index.styles'

// images
import ic_close from '../../../../images/ic_close.svg'

// component
import Header from './Header'
import Main from './Main'
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup'

// action
import { updateUser, filterUser } from '../../../../actions/userAction'
import { clearError } from '../../../../actions/commonAction'

// api
import { apiGetListReports } from '../../../../actions/reportsAction/listReports/listReports'
import { COORDINATOR, COUNSELOR } from '../../../../data/userRole'

const PopupAccountPermissionV2 = ({
    dataNeedModify,
    outPopup,
    alertPopup
}) => {

    const dispatch = useDispatch();

    const { userReducer, commonReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        commonReducer: state.commonReducer
    }))

    const [runCallbackUpdateUser, setRunCallbackUpdateUser] = useState(false)
    const [listReports, setListReports] = useState([]);
    const [objListReports, setObjListReports] = useState({});
    const [listParentReports, setListParentReports] = useState([]);

    const [stateConfig, dispatchConfig] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            atOffice: '',
            lstRoles: [],
            reportPermission: [],
            infosPermission: [],
            ccdPermission: [],
            idUser: ''
        }
    )

    const _callbackUpdateUser = useCallback(
        () => {
            if (typeof userReducer.err === 'object') {
                const { UPDATE_USER_ERR } = userReducer.err
                if (UPDATE_USER_ERR === 9) {
                    // clear error
                    dispatch(clearError('UPDATE_USER_ERR', 'userReducer'))
                    // show error
                    alertPopup({
                        content:
                            'User đang trong phiên giao dịch, bạn không thể thay đổi quyền của User trong lúc này',
                        iconData: ic_close,
                        width: "254px"
                    })
                    return
                }
                if (UPDATE_USER_ERR === 11) {
                    // clear error
                    dispatch(clearError('UPDATE_USER_ERR', 'userReducer'))
                    // show error
                    alertPopup({
                        content:
                            'Không thể thực hiên được, vì User không có quyền trên hệ thống VietinBank',
                        iconData: ic_close,
                    })
                    return
                }
            }
            if (commonReducer.showErrorConnection) {

                return;
            }

            outPopup()
            dispatch(filterUser(
                userReducer.lastConditionFilterUser,
                10,
                dataNeedModify.currentPage
            ))
        },
        [userReducer, commonReducer],
    )

    const _handleSubmit = useCallback(
        () => {
            const { atOffice, lstRoles, idUser, reportPermission, infosPermission, ccdPermission } = stateConfig
            //remove COUNSELOR-ROLE 
            var index = lstRoles.findIndex((element) => element === COUNSELOR)
            if(index !== -1){
                lstRoles.splice(index, 1)
            }
            //remove COORDINATOR-ROLE 
            index = lstRoles.findIndex((element) => element === COORDINATOR)
            if(index !== -1){
                lstRoles.splice(index, 1)
            }
            //---
            const dataUpdate = {
                // AtOfficeID: atOffice.id,
                AtOfficeID: atOffice.key,
                Roles: lstRoles,
                ReportPermission: reportPermission,
                InfosPermission: infosPermission,
                CoorLobbyPermission: ccdPermission
            }

            dispatch(updateUser(dataUpdate, idUser, () => setRunCallbackUpdateUser(true)))
        },
        [stateConfig, dataNeedModify, outPopup],
    )

    useEffect(() => {
        const { listUser } = userReducer;
        const userEditing = listUser.find(item => item.ID === dataNeedModify[0])
        if (!userEditing) return;
        dispatchConfig({
            atOffice: {
                // id: userEditing.AtOffice,
                // name: userEditing.AtOfficeName,

                key: userEditing.AtOffice,
                value: `${userEditing.BranchCode} - ${userEditing.AtOfficeName}`,
                BranchCode: userEditing.BranchCode
            },
            reportPermission: userEditing.ReportPermission || [],
            infosPermission: userEditing.InfosPermission || [],
            ccdPermission: userEditing.CoorLobbyPermission || [],
            lstRoles: userEditing.Roles,
            idUser: userEditing.ID
        })
    }, [])

    useEffect(() => {
        if (runCallbackUpdateUser) {
            setRunCallbackUpdateUser(false)
            _callbackUpdateUser()
        }
    }, [runCallbackUpdateUser])

    useEffect(() => {
        const getListReports = async () => {
            const dataRequest = JSON.stringify({
                ParentCode: null,
                CodeName: '',
                Searchtext: ''
            })
            try {
                const res = await Axios.post(apiGetListReports, dataRequest)
                const { data } = res
                const listReports = data.ListReports || []
                const objListReports = listReports.reduce((objList, currObj) => {
                    const parentCode = currObj.ParentCode
                    const objParent = objList[parentCode]
					//sth STH08_1 la bao dac biet chi hien thi cho TSC
					if(currObj && currObj.CodeName === "STH08_1"){
						currObj.OnlyTSC = true
					}
                    return {
                        ...objList,
                        [parentCode]: objParent ? [...objParent, currObj] : [currObj]
                    }
                }, {})
                setListReports(listReports)
                setObjListReports(objListReports)
            }
            catch (err) {
                console.log(err)
            }
        }
        getListReports()
    }, []);

    useEffect(() => {
        const getListParentReports = async () => {
            const dataRequest = JSON.stringify({
                ParentCode: '',
                CodeName: '',
                Searchtext: ''
            })
            try {
                const res = await Axios.post(apiGetListReports, dataRequest)
                const { data } = res
                const listParentReports = data.ListReports || []
                setListParentReports(listParentReports)
            }
            catch (err) {
                console.log(err)
            }
        }
        getListParentReports()
    }, []);

    return (
        <WrapperEditUser>
            <Header
                dataUser={dataNeedModify}
                outPopup={outPopup}
                readOnly={dataNeedModify.isReadOnly}
            />
            <Main
                dataNeedModify={dataNeedModify}
                stateConfig={stateConfig}
                dispatchConfig={dispatchConfig}
                listReports={listReports}
                objListReports={objListReports}
                listParentReports={listParentReports}
                readOnly={dataNeedModify.isReadOnly}
            />
            <div className="footer">
                {
                    !!stateConfig.atOffice &&
                    !!stateConfig.lstRoles.length &&
                    !dataNeedModify.isReadOnly &&
                    <ButtonConfirmPopup
                        titleConfirm='xác nhận'
                        bg_color="#005993"
                        onClick={_handleSubmit}
                    />
                }
            </div>
        </WrapperEditUser>
    )
}

export default PopupAccountPermissionV2
