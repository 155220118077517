import Axios from 'axios'

// constant
import * as types from '../types'
import { host } from '../../../../host'

// helper
import catchErrorApi from '../../../../helper/catchErrorApi'
import autoDownloadFromBlob from '../../../../helper/autoDownloadFromBlob'
import sortListStringNum from '../../../../helper/sortLIstStringNum'

// action
import { removeLoading, addLoading } from '../../../commonAction'

const apiGetListNumOfRegisterBio = `${host}admin/exportJSON/registerBioTotal`

export const getListNumOfRegisterBio = ({
    FromDate,
    ToDate,
    BranchIDs,
    ReportCodeName,
    BranchName,
}) => async (dispatch) => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        BranchIDs,
        ReportCodeName,
        BranchName,
    })

    try {
        const res = await Axios.post(apiGetListNumOfRegisterBio, dataRequest)
        const { data } = res
        dispatch({
            type: types.GET_LIST_NUM_OF_REG_BIO,
            payload: {
                condFilter: {
                    FromDate,
                    ToDate,
                    BranchIDs,
                    ReportCodeName,
                    BranchName,
                },
                listData: data.Data || [],
            },
        })
        dispatch(removeLoading())
    } catch (err) {
        dispatch(removeLoading())
        catchErrorApi(
            err,
            dispatch,
            types.GET_LIST_NUM_OF_REG_BIO_ERROR,
            () => false
        )
    }
}

export const exportListNumOfRegisterBio = ({
    FromDate,
    ToDate,
    BranchIDs,
    ReportCodeName,
    BranchName,
    BranchCodes,
}) => async (dispatch) => {
    if (!BranchCodes) return

    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        FromDate,
        ToDate,
        BranchIDs,
        ExportExcel: true,
        ReportCodeName,
        BranchName,
    })

    try {
        const res = await Axios.post(apiGetListNumOfRegisterBio, dataRequest, {
            responseType: 'blob',
        })
        dispatch(removeLoading())
        const { data } = res
        if (BranchCodes.length === 1) {
            autoDownloadFromBlob(data, `${ReportCodeName}-${BranchCodes[0]}`)
        } else {
            autoDownloadFromBlob(
                data,
                `${ReportCodeName}-${sortListStringNum(BranchCodes).join('-')}`
            )
        }
    } catch (err) {
        dispatch(removeLoading())
        console.log(err)
    }
}
