import styled from 'styled-components'
import { color } from '../../../../../../data/store'

const WrapperSelectReport = styled.div`
    width: 100%;
    padding: 24px 0 24px 25px;
    background: ${color.whiteFF};
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.12);

    .title-select {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 126%;
        color: #005993;
        text-transform: uppercase;
    }
    
    .wrap-dropdown {
        width: 441px;
        margin-top: 1px;
    }
`

export {
    WrapperSelectReport
}