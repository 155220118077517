/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { WrapContainer } from './index.styles';
import BlockNameControl from '../BlockNameControl';
import SearchControl from '../SearchControl';
import SortControl from '../PagningControl';
import AddMore from '../AddMore';
import ButtonControl from '../ButtonControl';
import ic_upload from '../../../../../images/ic_upload.svg'
import ic_save from '../../../../../images/ic_save.svg'
import { useState } from 'react';
import { useEffect } from 'react';
import { color } from '../../../../../data/store';
import { isArray } from '../../../../../helper/checkValidity';

export default function SubHeaderControlV2({
    data,
    onAddMoreSubmit,
    onReset,
    onUploadFile,
    onSaveInfo,
    isDisableSaveInfoBt=false,
    isDisableAddMoreBt=false,
    textSearch, typeSearch, total, searchFilter,
    isShowFilter=undefined, toggleFilter,
    counterRemain=undefined,
    isShowAddMoreBt=true,
    onAddMoreSubmitList=[]
}) {
    const [isDisableSaveBt, setIsDisableSaveBt] = useState(isDisableSaveInfoBt)
    const [isReset, setIsReset] = useState(false)

    const onSubmitAction = () => {
        onAddMoreSubmit && onAddMoreSubmit();
    }

    const onAddMoreSubmitAction = (index) => {
        if(index < onAddMoreSubmitList.length){
            onAddMoreSubmitList[index] && onAddMoreSubmitList[index]();
        }
    }

    const onResetAction = () => {
        onReset && onReset()
        //---
        setIsReset(true)
        //---
        setTimeout(()=>{
            setIsReset(false)
        }, 300)
    }

    const onUploadFileAction = () => {
        onUploadFile && onUploadFile()
    }

    const onSaveInfoAction = () => {
        if(isDisableSaveInfoBt){
            return
        }
        //---
        onSaveInfo && onSaveInfo()
    }

    let refSearch;

    useEffect(()=>{
        if(isDisableSaveBt !== isDisableSaveInfoBt){
            setIsDisableSaveBt(isDisableSaveBt)
        }
    }, [isDisableSaveBt, isDisableSaveInfoBt])

    return (
        <WrapContainer>
            <BlockNameControl
                mainname={data.mainTitle}
                subname={data.subTitle}
            />
            <div className="block-left">
                {
                    data.buttonArr.search &&
                    <SearchControl
                        textSearch={textSearch}
                        typeSearch = {typeSearch}
                        isReset={isReset}
                        total={total}
                        ref={ref => refSearch = ref}
                        searchFilter={searchFilter}
                        inputname={data.buttonArr.search.placeHolder} />
                }
                {
                    isShowFilter !== undefined
                        ? isShowFilter
                            ? <SortControl
                                refSearch={refSearch}
                                toggleFilter={() => { toggleFilter(refSearch) }}
                            />
                            : null
                        : data.buttonArr.filter &&
                            <SortControl
                                refSearch={refSearch}
                                toggleFilter={() => { toggleFilter(refSearch) }}
                            />
                }
                {
                    counterRemain !== undefined 
                    ?   <div className="counter-remain">
                            Số quầy còn lại: <span>{`0${counterRemain}`.slice(-2)}</span>
                        </div>
                    : null
                }
                {
                    data.buttonArr.search &&
                    <AddMore
                        namebtn={data.resetButton}
                        isActive= {true}
                        onSubmit = {onResetAction}/>
                }
                {
                    !isShowAddMoreBt
                    ?   null
                    :   data.buttonArr.addMore &&
                        <AddMore
                            bg_color={isDisableAddMoreBt?color.grayC4:color.blue_005993}
                            namebtn={data.buttonArr.addMore.title}
                            onSubmit={onSubmitAction} 
                        />
                }
                {
                    isArray(data.buttonArr.addMoreList) &&
                    data.buttonArr.addMoreList.map((dataInfo, index)=>{
                        return (
                            <AddMore
                                key={`addMoreBt_${index}`}
                                bg_color={color.blue_005993}
                                namebtn={dataInfo.title}
                                onSubmit={()=>{
                                    onAddMoreSubmitAction(index)
                                }} 
                            />
                        )
                    })
                    
                }
                {
                    data.buttonArr.uploadFile &&
                    <ButtonControl
                        widthButton={"100px"}
                        content="TẢI FILE"
                        icon={ic_upload}
                        onClick={onUploadFileAction}
                    />
                }
                {
                    data.buttonArr.saveInfo &&
                    <ButtonControl
                        isDisable={isDisableSaveInfoBt} 
                        widthButton={"100px"}
                        content="LƯU LẠI"
                        icon={ic_save}
                        onClick={onSaveInfoAction}
                    />
                }
            </div>
        </WrapContainer>
    )
}
