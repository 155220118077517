import React, { useState } from 'react'
import ic_close from "../../../../../images/ic_close_gray.svg"
import { WrapContent, WrapOverlay } from './index.styles'
import { WrapContainer } from './index.styles'
import ButtonControl from '../../Control/ButtonControl'
import InputTextControlV2 from '../../Control/InputTextControlV2'
import SingleChoiceDropDownControl from '../../Control/SingleChoiceDropDownControl'
import TextAreaControlV2 from '../../Control/TextAreaControlV2'
import { color } from '../../../../../data/store'
import { isObject } from '../../../../../helper/checkValidity'

export const TransactionPopupType = {
    ADD: 1,
    UPDATE: 2,
}
export default function TransPopup({isGroup = false, popupType, dataConfig, counterServiceList=[], dataInfo, onAdd, onUpdate, onClose}) {
    const renderStatusList = () => {
        let statusList = []
        dataConfig.statusList.forEach(element => {
            statusList.push({
                code: element.code,
                name: element.value
            })
        });
        return statusList
    }

    const statusList = renderStatusList();

    const getStatus = (statusCode) => {
        let status = statusList.find(
            (item) => item.code === statusCode
        )
        if(status === undefined){
            status = statusList[1]
        }
        return status
    }
    //---
    const renderServiceGroupList = () => {
        let groupCodeList = []
        counterServiceList.forEach(element => {
            groupCodeList.push({
                code: element.GroupCode,
                name: `${element.GroupCode} - ${element.Name}`
            })
        });
        return groupCodeList
    }


    const serviceGroupList = renderServiceGroupList();

    const getServiceGroup = (groupCode) => {
        let serviceGroup = serviceGroupList.find(
            (item) => item.code === groupCode
        )
        //---
        return serviceGroup
    }

    const getServiceCode = (isGroup) => {
        if(isGroup){
            return serviceGroup?serviceGroup.code:''
        }
        return dataInfo?dataInfo.ServiceType:''
    }
    //---
    const [idShow, setIdShow] = useState('')
    const [name, setName] = useState(dataInfo?dataInfo.Name:'')
    const [serviceGroup, setServiceGroup] = useState(dataInfo?getServiceGroup(dataInfo.GroupCode):undefined)
    const [serviceCode, setServiceCode] = useState(getServiceCode(isGroup))
    const [status, setStatus] = useState(dataInfo?getStatus(dataInfo.Status):statusList[1])
    const [description, setDescript] = useState(dataInfo?dataInfo.Description:'')
    
    //---
    const checkInvalidData = ({
        isUpdateConfirmBtStatus= true,
        nameValue= name,
        serviceCodeValue= serviceCode,
        serviceGroupValue= serviceGroup,
        descriptValue= description
    }) => {
        let isInvalid = false
        if( nameValue === "" || descriptValue === "" || 
            serviceCodeValue === "" || (!isGroup && !isObject(serviceGroupValue))){
                isInvalid = true
        }
        if(isUpdateConfirmBtStatus && isDisableConfirmBt !== isInvalid) {
            setIsDisableConfirmBt(isInvalid)
        }
        //---
        return isInvalid
    }
    const [isDisableConfirmBt, setIsDisableConfirmBt] = useState(checkInvalidData({
        isUpdateConfirmBtStatus: false
    }))
    //---
    const onAddTransAction = () => {
        onAdd && onAdd({
            name: name,
            serviceCode: serviceCode,
            groupCode: isGroup?'':serviceGroup.code,
            description: description,
            status: status.code
        })
    }

    const onUpdateTransAction = () => {
        onUpdate && onUpdate({
            id: dataInfo.ID,
            name: name,
            serviceCode: serviceCode,
            groupCode: isGroup?'':serviceGroup.code,
            description: description,
            status: status.code
        })
    }

    const onCloceBt = () => {
        onClose && onClose()
    }

    const onTransNameChanged = (value) => {
        console.log(`TransName: ${value}`);
        setName(value)
        //---
        checkInvalidData({
            nameValue: value
        })
    }

    const onServiceGroupChanged = (value) => {
        console.log(`ServiceGroup: ${value}`);
        setServiceGroup(value)
        //---
        checkInvalidData({
            serviceGroupValue: value
        })
    }

    const onServiceCodeChanged = (value) => {
        console.log(`ServiceCode: ${value}`);
        setServiceCode(value)
        //---
        checkInvalidData({
            serviceCodeValue: value
        })
    }

    const onTransStatusChanged = (value) => {
        console.log(`TransStatus: ${value.code} - ${value.name}`);
        setStatus(value)
    }

    const onTransDescriptChanged = (value) => {
        console.log(`TransDescript: ${value}`);
        setDescript(value)
        //---
        checkInvalidData({
            descriptValue: value
        })
    }

    const renderHeaderContent = () => {
        return (
            <div className="header">
                <div className="title-content">
                    <div className="label">
                        {
                            popupType===TransactionPopupType.ADD
                            ? isGroup?'Thêm mới group':'Thêm mới giao dịch'
                            : isGroup?'Tuỳ chỉnh group':'Tuỳ chỉnh giao dịch'
                        }
                    </div>
                    <div className="btn-container">
                        <img className="icon" src={ic_close} alt='ic_close' onClick={onCloceBt}/>
                    </div>
                </div>
            </div>
        )
    }

    const renderBodyContent = () => {
        return (
            <div className="body">
                {
                     !isGroup &&
                     <div className='row first'>
                        <SingleChoiceDropDownControl
                            heightControl={42}
                            setWidth='100%'
                            borderWidth={0}
                            backgroundColor={color.whiteF7}
                            isRequired={true}
                            title="GROUP GIAO DỊCH"
                            titleFontSize={14}
                            titleFontWeight={700}
                            textFontSize={17}
                            textFontWeight={500}
                            id="selectTransGroup"
                            idShow={idShow}
                            setIdShow={setIdShow}
                            getText={onServiceGroupChanged}
                            data={serviceGroupList}
                            titleChoise={"Chọn group giao dịch"}
                            defaultValue={
                                popupType === TransactionPopupType.UPDATE
                                ? serviceGroup.name
                                : undefined
                            }
                        />
                     </div>
                }
               
                <div className={isGroup?'row':'row first'}>
                    <InputTextControlV2
                        isRequired={true}
                        marginBottom={0}
                        borderWidth={0}
                        backgroundColor={color.whiteF7}
                        title={isGroup?'TÊN GROUP GIAO DỊCH':'TÊN GIAO DỊCH'}
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        onChange={onTransNameChanged}
                        placeHolder='Nhập tên giao dịch'
                        maxLength={50}
                        isDisabled={false}
                        defaultValue={name}
                    />
                </div>
                <div className='row'>
                    <InputTextControlV2
                        isRequired={true}
                        marginBottom={0}
                        borderWidth={0}
                        backgroundColor={color.whiteF7}
                        title={isGroup?'MÃ GROUP GIAO DỊCH':'MÃ LOẠI GIAO DỊCH'}
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        onChange={onServiceCodeChanged}
                        placeHolder='Nhập mã giao dịch'
                        maxLength={50}
                        isDisabled={
                            popupType === TransactionPopupType.UPDATE
                        }
                        defaultValue={serviceCode}
                    />
                </div>
                <div className="row">
                    <SingleChoiceDropDownControl
                        heightControl={42}
                        setWidth='100%'
                        borderWidth={0}
                        backgroundColor={color.whiteF7}
                        isRequired={true}
                        title="TRẠNG THÁI"
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        id="selectStatus"
                        idShow={idShow}
                        setIdShow={setIdShow}
                        getText={onTransStatusChanged}
                        data={statusList}
                        defaultValue={status.name}
                    />
                </div>
                <div className="row">
                    <TextAreaControlV2
                        isRequired={true}
                        backgroundColor={color.whiteF7}
                        borderWidth={0}
                        title='MÔ TẢ'
                        titleFontSize={14}
                        titleFontWeight={700}
                        textFontSize={17}
                        textFontWeight={500}
                        onChange={onTransDescriptChanged}
                        placeHolder='Nhập mô tả'
                        maxLength={255}
                        defaultValue={description}
                    />
                </div>
            </div>
        )
    }

    const rendFooterContent = () => {
        return (
            <div className="footer">
                <div className="btn-content">
                    <ButtonControl
                        className="btn-content"
                        content="Huỷ bỏ"
                        widthButton={"97px"}
                        heightButton={"33px"}
                        backgroundColor={color.red_D71249}
                        fontSize={"14px"}
                        fontWeight={700}
                        onClick={onCloceBt}
                    />

                    <ButtonControl
                        isDisable={isDisableConfirmBt}
                        className="btn-content space"
                        content="Xác nhận"
                        widthButton={"97px"}
                        heightButton={"33px"}
                        fontSize={"14px"}
                        fontWeight={700}
                        onClick={
                            popupType === TransactionPopupType.ADD
                            ? onAddTransAction
                            : onUpdateTransAction
                        }
                    />
                </div>
            </div>
        )
    }

    return (
        <WrapOverlay>
        <WrapContainer>
          <WrapContent isGroup={isGroup}>
            {renderHeaderContent()}
            {renderBodyContent()}
            {rendFooterContent()}
          </WrapContent>
        </WrapContainer>
      </WrapOverlay>
    )
}
