/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useCallback } from 'react'
import moment from 'moment'

// component
import SelectDateTime from '../SelectDateTime'

const SelectEndDate = ({
    defaultValue: defaultEnd,
    onChange: onChangeEnd,
    showTimeSelect = true,
    dateFormat = 'dd/MM/yyyy HH:mm',
}) => {
    const [state, dispatch] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            endTime: defaultEnd || new Date(),
        }
    )

    const _handleOnChangeEnd = useCallback((endTime) => {
        dispatch({ endTime })
        onChangeEnd(endTime)
    }, [])

    return (
        <SelectDateTime
            defaultValue={state.endTime}
            onChange={_handleOnChangeEnd}
            minDate={state.startTime}
            maxDate={moment().toDate()}
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormat}
            label="Đến ngày"
        />
    )
}

export default SelectEndDate
