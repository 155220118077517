import styled from "styled-components";
import { color } from "../../../../../../data/store";

const heightItem = 40
const DropDownContainer = styled.div`
    position: relative;
    height: auto;
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    
    .labelContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background-color: #fff;
        border: solid ${props => props.labelStyle.borderWidth}px #c8c8c8;
        padding: ${props => props.labelStyle.paddingTopBottom}px ${props => props.labelStyle.paddingLeftRight}px;
        opacity: ${props => props.isDisabled?0.5:1};
        .value {
            text-overflow: ellipsis;
            overflow: hidden; 
            white-space: nowrap;
        }

        .arrow {
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #636060;
            transition-duration: 0.3s;
            transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
            -webkit-transform: ${props => props.isShowMenu?'rotate(0)':'rotate(-90deg)'};
        }
    }

    .menuContent {
        position:absolute;
        top: ${props => props.menuStyle.top}px;
        left:0;
        z-index: 10;
        width: 100%;
        height: ${props => heightItem*props.numberOfMenuItem}px;
        overflow: ${props => props.isShowMenu?'visible':'hidden'};
        display: ${props => props.isShowMenu?'block':'none'};
        border: solid ${props => props.menuStyle.borderWidth}px #c8c8c8;
        border-radius: 4px;
        background-color: #fff;
    }
`

const DropDownMenuItem = styled.div`
    display: flex;
    width: 100%;
    width: calc(100% - 24px);
    height: 24px;
    min-height: 24px;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;
    padding: 8px 12px;
    justify-content: space-between;
    
    :hover {
        background-color: #DEF5FF;
    }

    .value {
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
        width: calc(100% - 18px);
    }

    .arrow {
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid ${color.blue_005993};
    }

`

export {DropDownContainer, DropDownMenuItem}