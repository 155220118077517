/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// styles
import { WrapList } from './index.styles'

// action
import { getListReports } from '../../../../../actions/reportsAction/listReports/listReports'
import { clearDetailsReport } from '../../../../../actions/reportsAction/detailsReport/detailsReport'

// component
import SelectReport from './SelectReport'
import SearchReport from './SearchReport'
import TableReports from './TableReports'
import EmptyReportSelected from './EmptyReportSelected'
import { clearFilterOffice } from '../../../../../actions/officeAction'

const ReportList = () => {
    const dispatch = useDispatch()

    const { condFilter } = useSelector(state => state.reports.listReports)

    const [isNoneSeleted, setIsNoneSeleted] = useState(true)

    useEffect(() => {
        dispatch(clearDetailsReport())
        dispatch(clearFilterOffice())
    }, [])

    useEffect(() => {
        if(!condFilter.ParentCode)
            dispatch(getListReports({ ...condFilter}))
    }, [JSON.stringify(condFilter)])

    useEffect(() => {
        if(!condFilter.ParentCode){
            setIsNoneSeleted(true)
            return
        }
        setIsNoneSeleted(false)
    }, [JSON.stringify(condFilter)])

    return (
        <WrapList>
            <SelectReport />
            {
                isNoneSeleted?
                    <EmptyReportSelected />
                    :
                    <>
                        <SearchReport />
                        <TableReports />
                    </>
            }
        </WrapList>
    )
}

export default ReportList
