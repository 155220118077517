/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, WrapOverlay } from './index.styles'
import { subHeaderData } from '../../../../data/data'
import AdviseServiceInfo from './AdviseServiceInfo'
import PopupError from '../../Popup/PopupError'
import PopupSuccess from '../../Popup/PopupSuccess'

export default function AdviseService() {
    const [dataSuccess, setDataSuccess] = useState({
        isShowPopup: false,
        mess: "",
        note: ""
    })
    const [dataErr, setDataErr] = useState({
        isShowPopup: false,
        mess: "",
        note: "",
    })

    const onHideShowErrPopup = (data = { isShowPopup: false, mess: "", note: ""}) => {
        setDataErr({
            isShowPopup: data.isShowPopup,
            mess: data.mess,
            note: data.note,
            listBranchCodeInvalid: data.listBranchCodeInvalid,
        })
    }

    const onHideShowSuccessPopup = (data = { isShowPopup: false, mess: "", note: "", onAcceptAction: undefined}) => {
        setDataSuccess({
            isShowPopup: data.isShowPopup,
            mess: data.mess,
            note: data.note,
            onAcceptAction: data.onAcceptAction
        })
    }

    

    useEffect(() => {
    }, [])

    return (
        <Container>
            <div className="body">
                <AdviseServiceInfo
                    headerData={subHeaderData.adviseService}
                    onHideShowErrPopup={onHideShowErrPopup}
                    onHideShowSuccessPopup={onHideShowSuccessPopup}
                />
            </div>
            {
                dataErr.isShowPopup &&
                <WrapOverlay>
                    <PopupError
                        isShowOverlay={false}
                        content={dataErr.mess}
                        note={dataErr.note}
                        onClosed={() => {
                            onHideShowErrPopup()
                        }}
                    />
                </WrapOverlay>
            }
            {
                dataSuccess.isShowPopup &&
                <WrapOverlay>
                    <PopupSuccess
                        backgroundColor='transparent'
                        titleHeader={dataSuccess.mess}
                        acceptAction={() => {
                            onHideShowSuccessPopup()
                        }}
                        outPopup={() => {
                            onHideShowSuccessPopup()
                        }}
                    />
                </WrapOverlay>
            }
        </Container>
    )
}
